import styled, { css } from 'styled-components';
import { FieldContainer } from '../form-field-text/form-field-text.styles';
import { PrimaryButton } from '../button/button.styles';
import { ClearSearchButton as ClearButton } from '../input-search/input-search.styles';
import { InputMessageContainer } from '../input-message/input-message.styles';

interface Props {
  $hIconPosition?: 'left' | 'right';
  $treatWholeInputAsSearch?: boolean;
  $disableSearchHover?: boolean;
  $showSearchIcon?: boolean;
  $showSearchButtonContainer?: boolean;
  $isLoading?: boolean;
  $isDisabled?: boolean;
}

export const InputListSearchContainer = styled.div`
  @media screen and (min-width: 650px) and (orientation: landscape) {
    min-width: 35rem;
    ${InputMessageContainer} {
      width: 35rem;
    }
  }
`;

export const ClearSearchButton = styled(ClearButton)<Props>`
  ${({ $showSearchButtonContainer }) =>
    $showSearchButtonContainer &&
    css`
      right: 35%;
    `}
`;

export const SearchIconContainer = styled.span<Props>`
  color: ${({ theme }) => theme.colors.baseColors.grey80};
  display: flex;
  position: absolute;
  bottom: 0;
  transform: translateY(-50%);
  cursor: pointer;
  justify-content: center;
  align-items: center;

  svg path {
    transition: fill 0.2s;
  }

  ${({ $hIconPosition }) =>
    $hIconPosition === 'right' &&
    css`
      right: 1.6rem;
    `}

  ${({ $hIconPosition }) =>
    $hIconPosition === 'left' &&
    css`
      left: 1.6rem;
    `}

  ${({ $disableSearchHover }) =>
    $disableSearchHover &&
    css`
      cursor: default;
    `}
`;

export const SearchButtonContainer = styled(PrimaryButton).attrs({
  type: 'button',
})`
  width: 30%;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

export const InputSearchContainer = styled(FieldContainer)<Props>`
  position: relative;

  input {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      input {
        background-color: ${({ theme }) => theme.colors.baseColors.grey5};
        color: ${({ theme }) => theme.colors.baseColors.grey80};
      }
    `}

  ${({ $hIconPosition }) =>
    $hIconPosition === 'right' &&
    css`
      ${SearchIconContainer} {
        right: 1.6rem;
      }

      input {
        padding: 1rem 4.2rem 1rem 1.6rem;
      }
    `}

  ${({ $hIconPosition }) =>
    $hIconPosition === 'left' &&
    css`
      ${SearchIconContainer} {
        left: 1.6rem;
      }

      input {
        padding: 1rem 1.6rem 1rem 4.2rem;
      }
    `}

  ${({ $showSearchIcon }) =>
    !$showSearchIcon &&
    css`
      input {
        padding: 1rem 1.6rem;
        padding-right: calc(30% + 4rem);
      }
    `}
`;

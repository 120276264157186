import React, { useEffect, useState } from 'react';
import { Formik, FormikProps, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import {
  ServicesSubSectionContainer,
  ServicesSubSectionHeader,
} from '../../services/services-subsection/services-subsection.styles';
import { FlexContainer } from './delivery-details-form.styles';
import { useStore } from '../../../stores/store';
import FormFieldText from '../../../common/form-field-text/form-field-text.component';
import { createValidationRule } from '../../../utils/forms/forms.utils';
import {
  VALIDATION_RULE_IS_NOT_CYRILLIC,
  VALIDATION_RULE_LATIN_WITH_COMMA,
  VALIDATION_RULE_LATIN_EXTENDED_WITH_COMMA,
  VALIDATION_RULE_ONLY_LETTERS,
} from '../../../constants/validation';
import InputSearchWithSidebarForm from '../../../common/input-search-with-sidebar/input-search-with-sidebar-form.component';

import { getElementTypeByName } from '../../../models/parcelCreationFlowNavigation';
import { IShipmentCountry } from '../../../stores/parcelCreationFlowStore';
import { translate } from '../../../../i18n';
import { ReactComponent as ArrowRightIcon } from '../../../assets/arrow-right-icon.svg';
import PlacesFormFieldSearch from '../../../common/form-field-places-search/form-field-places-search.component';
import { findAdminUnitFromPrediction } from '../../../utils/countries/countries.utils';
import { Address } from '../../../models/parcelDeliveryMiles';
import { getShipmentCountryName } from '../../../utils/parcel-creation/parcel-creation-utils';
import getAddressFormInitialValues from './helpers';

export const enum UserRole {
  sender = 'sender',
  receiver = 'receiver',
}

export const getCourierAddressValidationSchema = (role: UserRole) => {
  const validationRule =
    role === UserRole.receiver
      ? VALIDATION_RULE_LATIN_EXTENDED_WITH_COMMA
      : VALIDATION_RULE_LATIN_WITH_COMMA;

  return Yup.object({
    country: Yup.string().required('this_field_cannot_be_empty!'),
    region: createValidationRule(validationRule).required(
      'this_field_cannot_be_empty'
    ),
    city: createValidationRule(validationRule).required(
      'this_field_cannot_be_empty'
    ),
    post_code: createValidationRule(validationRule).required(
      'this_field_cannot_be_empty'
    ),
    street: createValidationRule(validationRule).required(
      'this_field_cannot_be_empty'
    ),
    building: createValidationRule(validationRule).required(
      'this_field_cannot_be_empty'
    ),
    section: createValidationRule(validationRule).notRequired(),
    apartment: createValidationRule(validationRule).notRequired(),
    note: createValidationRule(validationRule).notRequired(),
    buzz_code: createValidationRule(
      VALIDATION_RULE_IS_NOT_CYRILLIC
    ).notRequired(),
    full_address: Yup.string().notRequired(),
  });
};

export const isCountryWithZip = (countryCode: string | undefined): boolean =>
  countryCode === 'US' || countryCode === 'PH';

export const getRegionLabel = (countryCode: string | undefined): string => {
  if (countryCode === 'CA') return translate('Province');
  if (countryCode === 'US') return translate('State');
  return translate('Region');
};

export const getPostalCodeLabel = (countryCode: string | undefined): string => {
  if (isCountryWithZip(countryCode)) return translate('create_parcel_zip_code');

  return `${translate('create_parcel_postal_code')}`;
};

interface FormHooksHandlerProps {
  setAddress: (address: Address) => void;
  setShowInfoMessage: (showInfoMessage: boolean) => void;
  countryDeparture: IShipmentCountry | null;
  countryDestination: IShipmentCountry | null;
  isUserSender?: boolean;
  triggerSumbit?: boolean;
}

const FormHooksHandler = ({
  setAddress,
  setShowInfoMessage,
  countryDeparture,
  countryDestination,
  isUserSender,
  triggerSumbit,
}: FormHooksHandlerProps) => {
  const { values, setStatus, validateForm } = useFormikContext<Address>();

  useEffect(() => {
    setAddress(values);
  }, [values, setAddress]);

  useEffect(() => {
    if (triggerSumbit) {
      setStatus('submit');
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSumbit]);

  if (
    (isUserSender &&
      countryDeparture?.zipCode !== values.post_code &&
      values.post_code !== '') ||
    (!isUserSender &&
      countryDestination?.zipCode !== values.post_code &&
      values.post_code !== '')
    // setTimeout to overcome warning 'Cannot update a component while rendering a different component'
  ) {
    setTimeout(() => {
      setShowInfoMessage(true);
    }, 0);
  } else {
    setTimeout(() => {
      setShowInfoMessage(false);
    }, 0);
  }

  return null;
};

interface DeliveryDetailsFormProps {
  role: UserRole;
  isHomeAddress?: boolean;
  innerRef?: React.Ref<FormikProps<Address>>;
}

const DeliveryDetailsForm = ({
  role,
  isHomeAddress = false,
  innerRef,
}: DeliveryDetailsFormProps) => {
  const {
    parcelCreationStore: {
      shipment,
      countryDeparture,
      countryDestination,
      setSenderAddress,
      setReceiverAddress,
      currentElements,
      isNextButtonClicked,
      navigation,
    },
    userStore: { user: accountUser },
  } = useStore();

  const [showInfoMessage, setShowInfoMessage] = useState(false);

  const { t } = useTranslation();

  const isUserSender = role === UserRole.sender;
  const shipmentUser = isUserSender ? shipment?.sender : shipment?.recipient;
  const shipmentCountry = isUserSender ? countryDeparture : countryDestination;
  const countryNameLocalized = getShipmentCountryName(shipmentCountry, t);
  const setAddress = isUserSender ? setSenderAddress : setReceiverAddress;

  const adminUnits = navigation?.current?.elements?.find(
    (element) => element.name === 'administrative-unit'
  )?.behavior?.allowed_values;

  const adminUnitsTitle = navigation?.current?.elements?.find(
    (element) => element.name === 'administrative-unit'
  )?.behavior?.title;

  const additionalFieldsType = getElementTypeByName(
    currentElements,
    'additional-fields'
  );

  const isAdminUnitsAvailable = adminUnits && adminUnits.length > 0;

  const formTitle = isHomeAddress ? t('home_address') : t('pick_up_address');

  const isHideFields = additionalFieldsType === 'hide';

  const initialValues = getAddressFormInitialValues({
    countryNameLocalized,
    isUserSender,
    isHomeAddress,
    shipmentAddress: shipmentUser?.address,
    userAddress: accountUser?.address,
    isHideFields,
  });

  return (
    <ServicesSubSectionContainer>
      <ServicesSubSectionHeader>{formTitle}</ServicesSubSectionHeader>
      <Formik
        validationSchema={getCourierAddressValidationSchema(role)}
        initialValues={initialValues}
        innerRef={innerRef}
        onSubmit={() => {}}>
        {({ setFieldValue, setValues, validateField, values, status }) => {
          const onChange = (_?: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('full_address', '', false);
          };
          const validationMethod = status === 'submit' ? 'always' : 'default';
          return (
            <form noValidate>
              <FormHooksHandler
                setAddress={setAddress}
                setShowInfoMessage={setShowInfoMessage}
                countryDeparture={countryDeparture}
                countryDestination={countryDestination}
                isUserSender={isUserSender}
                triggerSumbit={isNextButtonClicked}
              />
              <FlexContainer>
                <FormFieldText
                  name='country'
                  label={t('country')}
                  disabled
                  validationMethod={validationMethod}
                />
              </FlexContainer>

              <FlexContainer>
                <PlacesFormFieldSearch
                  selectedCountryCode={shipmentCountry?.countryCode}
                  name='full_address'
                  placeholder=''
                  label={t('address_search')}
                  formikValidateField={validateField}
                  isOptional
                  validationRule={VALIDATION_RULE_LATIN_EXTENDED_WITH_COMMA}
                  setValues={(placeInfo) => {
                    const region = !isAdminUnitsAvailable
                      ? placeInfo.region
                      : findAdminUnitFromPrediction(
                          placeInfo.region,
                          adminUnits
                        ) ?? '';

                    setValues({
                      ...values,
                      full_address: placeInfo.address,
                      region,
                      city: placeInfo.city,
                      street: placeInfo.street,
                      post_code: placeInfo.zipCode,
                      building: placeInfo.building,
                      apartment: placeInfo.apartment,
                    });
                  }}
                />
              </FlexContainer>

              <FlexContainer>
                <FormFieldText
                  name='building'
                  label={t('building')}
                  onChange={onChange}
                  validationMethod={validationMethod}
                />
              </FlexContainer>

              <FlexContainer>
                <FormFieldText
                  name='street'
                  label={t('street')}
                  onChange={onChange}
                  validationMethod={validationMethod}
                />
              </FlexContainer>

              <FlexContainer>
                <FormFieldText
                  name='apartment'
                  label={t('apartment')}
                  onChange={onChange}
                  isOptional
                />
              </FlexContainer>

              <FlexContainer>
                <FormFieldText
                  name='city'
                  label={t('city')}
                  onChange={onChange}
                  validationMethod={validationMethod}
                />
              </FlexContainer>

              <FlexContainer>
                {isAdminUnitsAvailable ? (
                  <InputSearchWithSidebarForm
                    name='region'
                    inputIcon={ArrowRightIcon}
                    shouldValidateSearchQuery
                    displayAllOptionsWithEmptyFilter
                    inputValue={values.region}
                    defaultOption={values.region}
                    isOptionSelected={(region) => region === values.region}
                    label={adminUnitsTitle || ''}
                    sidebarTitle={adminUnitsTitle || ''}
                    sidebarInputPlaceholder={t('region_search_placeholder', {
                      region: getRegionLabel(
                        shipmentCountry?.countryCode
                      ).toLowerCase(),
                    })}
                    placeholder={t('select_region', {
                      region: getRegionLabel(
                        shipmentCountry?.countryCode
                      ).toLowerCase(),
                    })}
                    getSearchOptions={(filter) => {
                      const userRegion = shipmentUser?.address?.region;
                      const adminUnitsValues = adminUnits?.map((i) => i.value);
                      const regions = filter
                        ? adminUnitsValues!.filter((i) =>
                            i
                              .toLowerCase()
                              .includes(filter.toLocaleLowerCase().trim())
                          )
                        : adminUnitsValues;
                      if (!userRegion) return Promise.resolve(regions);

                      const found = regions!.find(
                        (region) => userRegion === region
                      );
                      if (found) {
                        const filteredArray = regions!.filter(
                          (region) => region !== userRegion
                        );
                        return Promise.resolve([found, ...filteredArray]);
                      }
                      return Promise.resolve(regions);
                    }}
                    onSearchOptionSelected={(selectedOption) => {
                      setFieldValue('region', selectedOption, true);
                      onChange();
                    }}
                    getKeyForSearchOption={(option) => option}
                    getDisplayValueForSearchOption={(option) => option}
                    validationRule={VALIDATION_RULE_ONLY_LETTERS}
                    validationMethod={validationMethod}
                  />
                ) : (
                  <FormFieldText
                    maxLength={100}
                    name='region'
                    onChange={onChange}
                    placeholder=''
                    validationMethod={validationMethod}
                    label={adminUnitsTitle || ''}
                  />
                )}

                <FormFieldText
                  name='post_code'
                  label={getPostalCodeLabel(
                    role === UserRole.sender
                      ? countryDeparture?.countryCode
                      : countryDestination?.countryCode
                  )}
                  onChange={onChange}
                  showInfoMessage={showInfoMessage}
                  infoMessage={
                    isCountryWithZip(
                      role === UserRole.sender
                        ? countryDeparture?.countryCode
                        : countryDestination?.countryCode
                    )
                      ? t('changing_zip_code_affect_price')
                      : t('create_parcel_reprice_change_postal_code_tip')
                  }
                  validationMethod={validationMethod}
                />
              </FlexContainer>
            </form>
          );
        }}
      </Formik>
    </ServicesSubSectionContainer>
  );
};

export default observer(DeliveryDetailsForm);

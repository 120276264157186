export enum ApiResponseStatus {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  PRECONDITION_FAILED = 412,
  VALIDATION_ERROR = 422,
  LOCKED = 423,
  PRECONDITION_REQUIRED = 428,
  SERVER_ERROR = 500,
}

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  DeliveryMethod,
  FirstMile,
  LastMile,
} from '../../../models/parcelDeliveryMiles';
import { useStore } from '../../../stores/store';
import {
  calculatePriceDiff,
  calculateSendingOptionPrice,
} from '../../../utils/parcel-creation/parcel-creation-utils';
import {
  DeliveryPriceContainer,
  DeliveryPriceContainerMob,
  PriceText,
} from './parcel-delivery-option.styles';
import { getFormattedPrice } from '../../../utils/price/get-formatted-price';

interface Props {
  deliveryOption: FirstMile | LastMile;
  middleMileMethod?: DeliveryMethod;
  isPriceResponsive?: boolean; // is part of the complex logic to control whether price will be inside container or as a separate element.
  responsiveContainerId?: string; // same
  hideZeroPrice?: boolean;
}

function implementsFirstMile(obj: any): obj is FirstMile {
  return 'middle_miles' in obj || 'middle_mile' in obj;
}

const DeliveryPrice = ({
  deliveryOption,
  isPriceResponsive,
  responsiveContainerId,
  middleMileMethod,
  hideZeroPrice,
}: Props) => {
  const {
    userStore: { getCurrencySymbol },
    parcelCreationStore: { selectedMiddleMileMethod, lastMiles, shipment },
  } = useStore();

  const { t } = useTranslation();

  const shipmentCurrencyCode = shipment?.converted_price?.currency;

  const middleMileMethodToUse = middleMileMethod ?? selectedMiddleMileMethod;
  const isFirstMile = implementsFirstMile(deliveryOption);

  let price = 0;
  if (isFirstMile) {
    price = calculateSendingOptionPrice(
      deliveryOption as FirstMile,
      middleMileMethodToUse!,
      lastMiles!
    );
  } else {
    price = calculatePriceDiff(deliveryOption, lastMiles!);
  }

  if (hideZeroPrice && price === 0) return;

  return (
    <>
      {!isPriceResponsive && (
        <DeliveryPriceContainer>
          {!isFirstMile && '+ '}
          {getFormattedPrice(price, getCurrencySymbol(shipmentCurrencyCode))}
          {middleMileMethod === 'expedited' && '*'}
        </DeliveryPriceContainer>
      )}
      {isPriceResponsive && (
        <DeliveryPriceContainerMob id={responsiveContainerId}>
          <PriceText>{t('price')}</PriceText>
          <span>
            {!isFirstMile && '+ '}
            {getFormattedPrice(price, getCurrencySymbol(shipmentCurrencyCode))}
          </span>
        </DeliveryPriceContainerMob>
      )}
    </>
  );
};

export default observer(DeliveryPrice);

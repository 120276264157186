import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../stores/store';
import ModalDialog from '../../../common/modal-dialog/modal-dialog.component';
import SignatureDetailsSummary from '../signature-details-summary/signature-details-summary.component';
import Button from '../../../common/button/button.component';
import { ActionButtonsContainer } from './signature-dialog.styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleCreateParcel: () => void;
}

const SignatureDialog = ({
  isOpen = false,
  onClose,
  handleCreateParcel,
}: Props) => {
  const {
    parcelCreationStore: {
      validateSignature,
      isNextStepButtonLoading,
      setIsSignButtonClicked,
      submitSignature,
      signature,
    },
  } = useStore();

  const { t } = useTranslation();

  const handleApprove = async () => {
    if (validateSignature()) {
      if (signature) {
        const response = await fetch(signature);
        const blob = await response.blob();
        const file = new File([blob], 'signature.jpeg', { type: 'image/jpeg' });
        const formData = new FormData();
        formData.append('signature', file);

        await submitSignature(formData);
      }
      handleCreateParcel();
      onClose();
    } else {
      setIsSignButtonClicked(true);
    }
  };

  return (
    <ModalDialog
      isOpen={isOpen}
      onClose={onClose}
      width='60rem'
      displayButtons={false}>
      <SignatureDetailsSummary>
        <ActionButtonsContainer>
          <Button
            appearance='secondary'
            onClick={(e) => {
              e.stopPropagation();
              onClose();
              setIsSignButtonClicked(false);
            }}>
            {t('cancel')}
          </Button>
          <Button
            appearance='primary'
            isLoading={isNextStepButtonLoading}
            onClick={(e) => {
              e.stopPropagation();
              handleApprove();
            }}>
            {t('sign_and_create')}{' '}
          </Button>
        </ActionButtonsContainer>
      </SignatureDetailsSummary>
    </ModalDialog>
  );
};

export default observer(SignatureDialog);

import styled, { css } from 'styled-components';
import { FontSizes } from '../../../common/typography/typography.styles';
import { BasePaddingComponent } from '../../../common/base-padding/base-padding.component';
import { Container } from '../common/container.styles';
import { FlexContainer } from '../../../common/flex-container/flex-container.styles';
import { Section } from '../common/section.styles';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../common/button/button.styles';
import { InfoTextContainer } from '../../../common/form-field-text/form-field-text.styles';
import { SpinnerCircle } from '../../../common/spinner/spinner.styles';
import { InputField, LabelWrapper } from '../../../common/input/input.styles';
import { SectionSubtitle } from '../common/typography.styles';
import { InputMessageContainer } from '../../../common/input-message/input-message.styles';

interface IDataInputFieldProps {
  $minWidth?: string;
  $isFieldValid?: boolean;
  $isDisabled?: boolean;
}
interface IDataInputLabelButtonProps {
  $isInvalid?: boolean;
  $errorColor?: string;
}

export const WidgetCalculatorWrapper = styled(Container)`
  @media (max-width: 1024px) {
    width: 100%;
    padding: 0;
  }
`;

export const WidgetCalculatorFrameWrapper = styled(Container)`
  --padding: 0;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
`;

export const WidgetCalculatorSection = styled(Section)`
  @media (max-width: 1024px) {
    padding-top: 0;
  }
`;

export const BackgroundImage = styled.div`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 49.2rem;
  border-radius: 30px;

  object-fit: cover;
  object-position: center;
  border-radius: var(--container-border-radius);

  @media (max-width: 1024px) {
    height: 40%;
  }
`;

export const WidgetCalculatorContainer = styled.div`
  --container-border-radius: 3rem;
  position: relative;
  isolation: isolate;
  width: 100%;
  height: 100%;
  min-height: 49.2rem;
  padding: 4rem 1.6rem 4.8rem;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;

  background: linear-gradient(
      102deg,
      rgba(0, 112, 204, 0.8) 38.29%,
      rgba(0, 56, 102, 0.8) 100%
    ),
    #0070cc;

  @media (min-width: 1025px) {
    padding: 8rem 6rem;
    border-radius: var(--container-border-radius);
  }

  ${SectionSubtitle} {
    padding: 0.6rem 1.6rem;
    border-radius: 0.8rem;
    background-color: ${({ theme }) =>
      theme.colors.namedColors.secondaryPrimary};
  }
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.namedColors.white};
  font-family: ${({ theme }) => theme.fonts.body.fontFamily};
  font-size: 5.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 7rem;
  letter-spacing: 1px;
  margin-bottom: 4rem;

  @media (max-width: 1024px) {
    font-size: ${FontSizes.huge};
    line-height: 4.8rem;
    margin-bottom: 2.4rem;
  }
`;

export const ParcelTabsContainer = styled.div`
  display: flex;
  gap: 4px;
`;

interface ParcelTabProps {
  $isActive?: boolean;
}

export const ParcelTab = styled.div<ParcelTabProps>`
  cursor: pointer;
  display: flex;
  padding: 1.2rem 1.6rem;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  border-radius: 1.2rem 1.2rem 0 0;

  color: ${({ $isActive, theme }) =>
    $isActive
      ? theme.colors.parcelTab.colorActive
      : theme.colors.namedColors.white};
  background: ${({ $isActive, theme }) =>
    $isActive
      ? theme.colors.namedColors.white
      : theme.colors.baseColors.blue200};

  @media (min-width: 1025px) {
    padding: 1.8rem 2.4rem;
  }
`;

interface ParcelTabContentProps {
  $fontWeight?: string;
  $lineHeight?: string;
}

export const ParcelTabContent = styled.div<ParcelTabContentProps>`
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.body.fontFamily};
  font-size: ${FontSizes.normal};
  line-height: ${({ $lineHeight = '1.6rem' }) => $lineHeight};
  font-style: normal;
  font-weight: ${({ $fontWeight = '700' }) => $fontWeight};
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;

  & > svg {
    width: 2rem;
    height: 2rem;
  }

  @media (min-width: 1025px) {
    font-size: ${FontSizes.medium};
    line-height: 2.2rem;
  }
`;

interface CalculatoContainerProps {
  $hidden?: boolean;
}

export const CalculatoContainer = styled.div<CalculatoContainerProps>`
  display: ${({ $hidden }) => ($hidden ? 'none' : 'flex')};
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  border-radius: 1.6rem;
  border-top-left-radius: 0;
  background: ${({ theme }) => theme.colors.namedColors.white};
`;

export const DataInputFields = styled.div`
  width: 100%;
  height: 100%;
  min-height: 104px;
  padding: 1.2rem;

  display: flex;
  gap: 2.4rem;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;

  border-radius: 0 1.6rem 1.6rem 1.6rem;
  background: ${({ theme }) => theme.colors.namedColors.white};

  @media screen and (min-width: 1025px) {
    gap: 3.2rem;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  & > *:nth-child(1) {
    width: 80%;
    @media (max-width: 1024px) {
      width: 100%;
    }
  }
`;

export const DataInputFieldsContainer = styled(FlexContainer)`
  justify-content: start;
  gap: 1.2rem;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const DataInputField = styled.div<IDataInputFieldProps>`
  display: flex;
  position: relative;
  width: 100%;
  height: 8rem;
  padding: 0.4rem 0.8rem 0 1.2rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 0 1 24.8rem;
  gap: 0.4rem;

  &:nth-child(n + 4) {
    @media (max-width: 1024px) {
      width: 50%;
    }
  }

  ${InputField} {
    padding: 0.8rem 0;
    font-family: ${({ theme }) => theme.fonts.body.fontFamily};
    font-size: ${FontSizes.mediumPlus};
    font-style: normal;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.5px;
    min-width: 80px;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    border: none;

    @media (max-width: 1024px) {
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
  }

  input {
    color: ${({ $isFieldValid = true, theme }) =>
      $isFieldValid
        ? theme.colors.baseColors.grey150
        : theme.colors.baseColors.red100};
  }

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      ${InputField}, input {
        background: none;
        // required on iOS
        opacity: 1;
      }
    `}

  & ${InfoTextContainer} {
    span {
      color: ${({ theme }) => theme.colors.baseColors.grey150};
    }
  }

  @media (max-width: 1024px) {
    height: 6.4rem;
    flex: 1 1 6.4rem;
    border: 1px solid #d1d1d1;
    border-radius: 1rem;
    padding: 0.8rem 1.2rem 0.4rem 1.6rem;
    gap: 0;
  }
`;

export const MeasureInputField = styled(DataInputField)`
  flex: 1 1 100px;
  width: 100px;
  flex-shrink: 0;
`;

export const DataInputLabelButton = styled.button.attrs({
  type: 'button',
})<IDataInputLabelButtonProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.4rem;
  border: none;
  background: transparent;

  color: ${({
    $isInvalid = false,
    theme,
    $errorColor = theme.colors.baseColors.red100,
  }) => ($isInvalid ? $errorColor : theme.colors.baseColors.grey100)};
  font-family: ${({ theme }) => theme.fonts.body.fontFamily};
  font-size: ${FontSizes.normal};
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.5px;
  padding: 0;
  cursor: pointer;
`;

export const DimensionFlexContainer = styled(FlexContainer)`
  width: 100%;
  flex-direction: row;
  border: 1px solid #d1d1d1;
  border-radius: 1rem;

  & > * {
    border: none;
    margin: 0.8rem 0;
    height: 4.8rem;
  }

  & > *:nth-child(1) {
    border-radius: 0;
    @media (max-width: 1024px) {
      border-right: 1px solid #d1d1d1;
    }
  }

  @media (min-width: 1025px) {
    border: none;
    flex: 1 1 24.8rem;
    gap: 1.2rem;
  }
`;

export const DividerPipe = styled.div`
  width: 0.1rem;
  height: 6rem;
  background-color: ${({ theme }) => theme.colors.baseColors.grey50};

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const CalculateButton = styled.div`
  width: 100%;
  align-self: center;
  white-space: nowrap;
  ${PrimaryButton},
  ${SecondaryButton} {
    max-width: 100%;
    height: 6.4rem;
    transition: opacity 0.2s;
    border-radius: 1.2rem;
    ${SpinnerCircle} {
      border-width: 3px;
    }

    &:hover {
      opacity: 0.8;
    }

    svg {
      width: 2.1rem;
      height: 2.1rem;
    }
  }

  @media (min-width: 1025px) {
    max-width: 20rem;
    ${PrimaryButton}, ${SecondaryButton} {
      height: 8rem;
      font-size: ${FontSizes.mediumPlus};
    }
  }
`;

export const MenuItemsContainer = styled.div`
  width: fit-content;
  margin-left: -1.2rem;
  max-height: 21.6rem;
  overflow-y: auto;
  position: absolute;
  top: 8rem;
  background: ${({ theme }) => theme.colors.namedColors.white};
  border-radius: 0 0 1rem 1rem;
  border: 1px solid #dcdde0;
  border-top: none;
  box-shadow: 0 0 2.2rem -0.6rem rgba(5, 35, 107, 0.12);
  z-index: 100;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 6px;
    background: ${({ theme }) => theme.colors.baseColors.grey65};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.baseColors.grey100};
    border-radius: 6px;
  }

  @media (max-width: 1024px) {
    width: 101%;
    border: 1px solid #d1d1d1;
    border-radius: 1rem;
    top: 7rem;
    margin-left: -18px;
  }
`;

export const PaddingContainer = styled(BasePaddingComponent('div'))`
  width: 100%;
`;

export const VolumetricWeightWarning = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #ffbe0a;
  font-size: ${FontSizes.normal};

  svg {
    width: 1.28em;
    height: 1.28em;
  }

  @media (max-width: 650px) {
    align-items: start;

    svg {
      width: 3em;
    }
  }
`;

export const MessagesContainer = styled(FlexContainer)`
  padding: 12px;
  &:empty {
    display: none;
  }
`;

export const TrackingFormContainer = styled.div<{ $isSearchVisible?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3.2rem;
  padding: 1.2rem;
  ${({ $isSearchVisible }) =>
    $isSearchVisible &&
    `
    ${LabelWrapper} {
      padding-top: 1.2rem;
    }
    ${InputMessageContainer} {
      padding-bottom: 1.2rem;
  }`}

  > :nth-child(1) {
    width: calc(100% - 23.2rem);
    input {
      padding: 0.8rem 0 0.8rem 1.2rem;
      font-size: ${FontSizes.mediumPlus};
      line-height: 2rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.namedColors.grey150};
      margin-top: 4px;
      &::placeholder {
        font-size: ${FontSizes.normal};
        color: ${({ theme }) => theme.colors.namedColors.grey80};
      }
      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
        appearance: none;
      }
    }
  }
  > :nth-child(2) {
    width: 20rem;
  }

  ${PrimaryButton} {
    &:hover {
      opacity: 1;
    }
  }

  @media (max-width: 650px) {
    flex-direction: column;
    gap: 1.6rem;
    > :nth-child(1) {
      width: 100%;

      input {
        max-height: 6.4rem;
        height: 6.4rem;
      }
    }
    > :nth-child(2) {
      width: 100%;
    }
  }

  ${LabelWrapper} {
    margin-bottom: 0;
    padding-left: 1.2rem;
    label {
      cursor: pointer;
    }
  }
  ${CalculateButton} {
    align-self: flex-start;
  }
  ${InputMessageContainer} {
    margin-top: 3.2rem;
    padding-left: 1.2rem;
  }
`;

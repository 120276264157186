import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { AxiosError } from 'axios';
import { getCountryName } from '../../common/form-field-phone/form-field-phone.component';
import { ShipmentCountryInfo } from '../../models/shipmentCountryInfo';
import { store } from '../../stores/store';
import {
  DeliveryMethod,
  DeliveryType,
  FirstMile,
  LastMile,
  MiddleMile,
  ServiceType,
  Shipment,
  StaticTimeStrings,
} from '../../models/parcelDeliveryMiles';
import { I18TranslateFunc } from '../../../i18n';
import { IShipmentCountry } from '../../stores/parcelCreationFlowStore';
import { ErrorResponse } from '../../models/apiResponse';

export function getShipmentCountryCode(country: ShipmentCountryInfo | null) {
  return country ? `${country.countryCode}` : '';
}

export function getShipmentCountryText(country: ShipmentCountryInfo | null) {
  return country ? `${getCountryName(country.countryCode)}` : '';
}

export function getShipmentCountryName(
  country: ShipmentCountryInfo | null,
  t: I18TranslateFunc
) {
  if (country?.countryDetails.emoji && country.countryDetails.localized_name) {
    return `${country?.countryDetails.emoji} ${country?.countryDetails.localized_name}`;
  }

  return country && country.countryCode
    ? `${country?.countryDetails.emoji ?? getUnicodeFlagIcon(country.countryCode)} ${t(getCountryName(country.countryCode) || '')}`
    : '';
}

const moveCountryToBeginning = (
  arr: IShipmentCountry[],
  countryCode: string | undefined
): IShipmentCountry[] => {
  const found = arr.find((obj) => obj.countryCode === countryCode);
  if (found) {
    const filteredArray = arr.filter((obj) => obj.countryCode !== countryCode);
    return [found, ...filteredArray];
  }
  return arr;
};

export function getShipmentCountriesByFilter(
  countries: IShipmentCountry[],
  filter?: string
) {
  const userCountry = store.userStore.user?.country;
  const primaryCountry = countries.find(
    (country) => country.countryCode === 'UA'
  );

  const filteredCountries = filter
    ? countries!.filter((i) =>
        i.countryDetails.localized_name
          .toLowerCase()
          .includes(filter.toLocaleLowerCase().trim())
      )
    : countries;

  filteredCountries.sort((a, b) =>
    a.countryDetails.localized_name.localeCompare(
      b.countryDetails.localized_name
    )
  );

  const primaryFirstCountries = moveCountryToBeginning(
    filteredCountries,
    primaryCountry?.countryCode
  );

  return userCountry
    ? moveCountryToBeginning(primaryFirstCountries, userCountry)
    : primaryFirstCountries;
}

export function getZipCodeDisplayValue(country: IShipmentCountry | null) {
  const infos = [country?.zipCode, country?.city];
  return infos.filter(Boolean).join(', ');
}

export function getFirstMilesByDeliveryMethodSorted(
  firstMiles: FirstMile[],
  method: DeliveryMethod
): FirstMile[] {
  if (firstMiles.length === 0) {
    return [];
  }

  return firstMiles
    .filter((mile) =>
      mile.middle_miles.some(
        (middleMile) => middleMile.delivery_method === method
      )
    )
    .sort((mileA, mileB) => {
      if (mileA.carrier.slug === 'meest') return -1;
      if (mileB.carrier.slug === 'meest') return 1;
      return mileA.price - mileB.price;
    });
}

export function getMiddleMileByDeliveryMethod(
  firstMile: FirstMile,
  method: DeliveryMethod
): MiddleMile | undefined {
  return (
    firstMile.middle_miles?.find(
      (middleMile) => middleMile.delivery_method === method
    ) ?? firstMile.middle_mile
  );
}

export function getAvailableMiddleMileMethodsWithMaxDuration(
  firstMiles: FirstMile[]
): { method: DeliveryMethod; maxDurationDays: number }[] {
  const middleMiles: { [key: string]: number } = {};

  firstMiles.forEach((firstMile) => {
    firstMile.middle_miles.forEach((middleMile) => {
      if (
        (middleMiles[middleMile.delivery_method] ?? -1) < middleMile.duration
      ) {
        middleMiles[middleMile.delivery_method] = middleMile.duration;
      }
    });
  });

  interface DurationItem {
    method: DeliveryMethod;
    maxDurationDays: number;
  }

  let expeditedItem: DurationItem | undefined;

  const middleMilesDurationArr: DurationItem[] = Object.entries(middleMiles)
    .filter(([key]) => Object.prototype.hasOwnProperty.call(middleMiles, key))
    .reduce((acc, [key, value]) => {
      if (key === 'expedited') {
        expeditedItem = { method: key, maxDurationDays: value };
        return acc;
      }
      acc.push({ method: key, maxDurationDays: value } as DurationItem);
      return acc;
    }, [] as DurationItem[]);

  // sort in alphabetic order so air will be first
  middleMilesDurationArr.sort((a, b) => a.method.localeCompare(b.method));
  if (expeditedItem) {
    middleMilesDurationArr.push(expeditedItem);
  }

  return middleMilesDurationArr;
}

export function getSenderType(shipment: Shipment): DeliveryType {
  return shipment?.sender?.carrier.delivery_service.service_type === 'courier'
    ? 'courier'
    : 'post_box';
}

export function getReceiverType(shipment: Shipment): DeliveryType {
  return shipment?.recipient?.carrier.delivery_service.service_type ===
    'courier'
    ? 'courier'
    : 'post_box';
}

interface DeliveryInfo {
  building?: string;
  street?: string;
  apartment?: string;
  section?: string;
  buzzCode?: string;
  city?: string;
}

export const hasInfoAboutDelivery = (info: DeliveryInfo) =>
  Object.values(info).filter(Boolean).length > 0;

export const getSubjectDetailsSummary = (info: DeliveryInfo) => {
  if (!hasInfoAboutDelivery(info)) return null;
  const { building, street, apartment, section, buzzCode, city } = info;
  const buildingPart = building ? `, ${building}` : '';
  const streetPart = street ? ` ${street}` : '';
  const apartmentPart = apartment ? `, ${apartment}` : '';
  const sectionPart = section ? `, ${section}` : '';
  const buzzCodePart = buzzCode ? `, ${buzzCode}` : '';

  return `${city}${buildingPart}${streetPart}${apartmentPart}${sectionPart}${buzzCodePart}`;
};

export const calculateTotalDuration = (
  firstMile: FirstMile,
  middleMileMethod: DeliveryMethod,
  lastMile?: LastMile
) => {
  const duration =
    firstMile.duration +
    (getMiddleMileByDeliveryMethod(firstMile, middleMileMethod)?.duration ||
      0) +
    (lastMile?.duration || 0);

  return duration;
};

export function implementsFirstMile(obj: any): obj is FirstMile {
  return 'middle_miles' in obj;
}

export const getCheapestMile = (miles?: LastMile[], rateSchemaId?: string) => {
  if (!miles || miles.length === 0) return undefined;

  const filtered = rateSchemaId
    ? miles.filter((mile) => mile.rate_schema_id === rateSchemaId)
    : miles;

  return filtered.toSorted((a, b) => a.price - b.price)[0];
};

export const calculateSendingOptionPrice = (
  firstMile: FirstMile,
  middleMileMethod: DeliveryMethod,
  allLastMiles?: LastMile[]
) => {
  const cheapestLastMilePrice =
    getCheapestMile(allLastMiles, firstMile.rate_schema_id)?.price ?? 0;

  const price =
    (getMiddleMileByDeliveryMethod(firstMile, middleMileMethod)
      ?.summary_price ?? 0) + cheapestLastMilePrice;

  return price;
};

export const calculatePriceDiff = (
  currentLastMile: LastMile,
  allLastMiles: LastMile[]
) => {
  // current - min
  const cheapestLastMilePrice =
    getCheapestMile(allLastMiles, currentLastMile.rate_schema_id)?.price ?? 0;
  return currentLastMile.price - cheapestLastMilePrice;
};

export const calculateDurationDiff = (
  currentLastMile: LastMile,
  allLastMiles: LastMile[]
) => {
  // current - min
  const cheapestLastMile = getCheapestMile(allLastMiles)!;
  return currentLastMile.duration - cheapestLastMile.duration;
};

export const sortLastMilesByPrice = (miles: LastMile[]) =>
  [...miles].sort((a, b) => a.price - b.price);

export const pudoServiceTypes: ServiceType[] = [
  'post_office',
  'post_box',
  'agent',
];

const getTypeByName = (
  elements: Array<{ name: string; type: string }>,
  name: string
) => {
  const element = elements.find((el) => el.name === name);
  return element?.type ?? null;
};

export const getDeliveryTimeTitle = (
  method: DeliveryMethod | null,
  staticStrings?: StaticTimeStrings
) => {
  if (!method || !staticStrings) return;
  return staticStrings[method];
};

export const tryGetErrorMessage = (
  error: unknown,
  statusCode: number,
  errorName: string
) => {
  let errorMessage: string | undefined;
  if (error instanceof AxiosError && error.response?.status === statusCode) {
    errorMessage = (
      error as AxiosError<ErrorResponse, any>
    ).response?.data.errors
      .find((e) => e.key === errorName)
      ?.values?.at(0);
  }

  return errorMessage;
};

export const scrollToFirstInvalidInput = () => {
  const invalidInputs = Array.from(
    document.querySelectorAll('[data-type="invalid-input"]')
  ) as HTMLElement[];

  if (invalidInputs.length === 0) return;
  const firstInvalidInput = invalidInputs.reduce((highest, input) =>
    input.getBoundingClientRect().top < highest.getBoundingClientRect().top
      ? input
      : highest
  );

  firstInvalidInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
  firstInvalidInput.focus();
};

import { StepName } from '../models/parcelCreationFlowNavigation';

export const AppRoute = {
  ROOT: '/',
  AUTH: 'auth',
  MY: 'my',
  COMING_SOON: 'coming-soon',
  NOT_FOUND: 'not-found',
  SERVER_ERROR: 'server-error',
  PERSONAL_DATA: 'personaldata',
  PHONE_VERIFICATION: 'phone-verification',
  DATA_MIGRATION: 'data-migration',
  SECURITY_SETTINGS: 'securitysettings',
  PARCEL_LIST: 'parcellist',
  DRAFTS: 'drafts',
  ARCHIVE: 'archive',
  INTERNATIONAL: 'international',
  DOMESTIC: 'domestic',
  RECEIVERS: 'receivers',
  PARCEL_CREATE: 'create',
  PARCEL_CREATION_PARCEL_DETAILS: 'parcel-details' as StepName,
  PARCEL_CREATION_DELIVERY_OPTION: 'delivery-option' as StepName,
  PARCEL_CREATION_DELIVERY_INSTRUCTIONS: 'delivery-instructions' as StepName,
  PARCEL_CREATION_SENDER_PUDO: 'sender-details-pudo' as StepName,
  PARCEL_CREATION_SENDER_COURIER: 'sender-details-courier' as StepName,
  PARCEL_CREATION_RECEIVER_PUDO: 'receiver-details-pudo' as StepName,
  PARCEL_CREATION_RECEIVER_COURIER: 'receiver-details-courier' as StepName,
  PARCEL_CREATION_CONTENT: 'parcel-content' as StepName,
  PARCEL_CREATION_SUMMARY: 'summary' as StepName,
  SIGNATURE: 'signature' as StepName,
  PARCEL_CREATION_PHONE_VERIFICATION: 'verify-phone' as StepName,
  PORTAL: 'portal',
  CALCULATOR: 'calc',
  LEAVE_FEEDBACK: 'leavefeedback',
};

export const LOCALE_PARAM = 'locale';

export enum SearchParam {
  REDIRECT = 'redirect',
  PARCEL_LIST_VIEW_MODE = 'view-mode',
  PAGE = 'page',
  DATA = 'data',
  NAVIGATE_TO = 'navigate_to',
  PER_PAGE = 'per-page',
  REQUEST_SOURCE = 'request-source',
}

import { v4 as uuid } from 'uuid';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { HsCode, HsCodeResponse } from '../../../models/hsCode';
import InputSearchWithSidebar from '../../../common/input-search-with-sidebar/input-search-with-sidebar.component';
import { useStore } from '../../../stores/store';
import { ReactComponent as ArrowRightIcon } from '../../../assets/arrow-right-icon.svg';
import { getPopularCategories } from './configs/config';
import { PopularCategoryMenuItem } from './parcel-items-management.styles';

const ItemCategorySearch = () => {
  const { setFieldValue, getFieldProps } = useFormikContext();

  const {
    parcelCreationStore: {
      searchItemsByDescription,
      isNextButtonClicked,
      setIsNextButtonClicked,
      shipment,
      countryDeparture,
    },
  } = useStore();

  const { t } = useTranslation();
  const triggerFieldValidation =
    isNextButtonClicked && !shipment?.items?.length;

  const popularCategories = useMemo(
    () => getPopularCategories(countryDeparture?.countryCode),
    [countryDeparture?.countryCode]
  );

  const searchPopularCategories = async (filter: string | undefined) => {
    if (!filter) return popularCategories;
    return popularCategories?.filter((category) =>
      category.description
        .toLocaleLowerCase()
        .includes(filter.toLocaleLowerCase())
    );
  };

  const formatHsCode = (hsCode: HsCodeResponse) =>
    !hsCode.icon ? (
      hsCode.description ?? ''
    ) : (
      <PopularCategoryMenuItem>
        <hsCode.icon /> <p>{hsCode.description}</p>
      </PopularCategoryMenuItem>
    );

  return (
    <InputSearchWithSidebar<HsCodeResponse>
      name='category_search'
      inputValue={getFieldProps('category').value ?? ''}
      placeholder={t('choose_category')}
      label={t('item_category')}
      sidebarTitle={t('category')}
      sidebarInputPlaceholder=''
      inputIcon={ArrowRightIcon}
      isOptionSelected={(a, b) => a?.description === b?.description}
      getSearchOptions={(filter) => searchItemsByDescription(filter)}
      defaultOptionsTitle='Popular'
      getDefaultSearchOptions={
        popularCategories
          ? (filter) => searchPopularCategories(filter)
          : undefined
      }
      debounceSidebarInputChange
      onSearchOptionSelected={(category) => {
        setFieldValue('category', category.description, true);
        setFieldValue('hsCode', new HsCode(category), false);
        setIsNextButtonClicked(false);
      }}
      getKeyForSearchOption={() => uuid()}
      getDisplayValueForSearchOption={formatHsCode}
      errorMessage='this_field_cannot_be_empty'
      mainInputValidationPredicate={(value) => !!value}
      triggerValidation={triggerFieldValidation}
    />
  );
};

export default ItemCategorySearch;

import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import {
  AppLinksContainer,
  FooterBottomContainer,
  SocialLinksContainer,
  StoreLink,
} from './footer.styles';
import { client } from '../../config';
import { getClientIcons } from '../../assets/social-icons/socialIcons';
import { ReactComponent as GoogleMarketIcon } from '../../assets/google-play-store.svg';
import { ReactComponent as AppleStoreIcon } from '../../assets/apple-store.svg';
import { TextCopyright } from '../../common/typography/typography.styles';
import { VisuallyHidden } from '../../common/visually-hidden/visually-hidden.styles';
import { useStore } from '../../stores/store';
import { AppRoute } from '../../constants/route';
import { isMeest } from '../../utils/client/client.utils';

const FooterBottom = () => {
  const {
    marketingStore: { footerLinks },
    localizationsStore: { selectedCountry },
  } = useStore();
  const location = useLocation();
  const isRootRoute = location.pathname === AppRoute.ROOT;

  const links: any = isMeest(client)
    ? footerLinks
    : selectedCountry?.links || {};
  const iconSet = getClientIcons();

  const { t } = useTranslation();

  const currentYear = new Date().getFullYear();

  return (
    <FooterBottomContainer>
      <SocialLinksContainer $isHidden={isRootRoute}>
        {links && (
          <SocialLinksContainer $isHidden={isRootRoute}>
            {Object.entries(links).map(([platform, href]) => {
              const Icon = iconSet[platform as keyof typeof iconSet];
              if (!href || !Icon) return null;

              return (
                <a
                  key={platform}
                  href={href as string}
                  target='_blank'
                  rel='noreferrer'>
                  <Icon />
                  <VisuallyHidden>{t(`${platform} link`)}</VisuallyHidden>
                </a>
              );
            })}
          </SocialLinksContainer>
        )}
      </SocialLinksContainer>
      <TextCopyright>
        © {currentYear} - Meest Group - Postal & Transport Services.{' '}
        {t('all_rights_reserved')}
      </TextCopyright>
      {(links?.playMarket || links?.appStore) && (
        <AppLinksContainer>
          {links?.playMarket && (
            <StoreLink
              href={links.playMarket}
              type='google'>
              <VisuallyHidden>{t('get_it_on_google_play')}</VisuallyHidden>
              <GoogleMarketIcon />
            </StoreLink>
          )}
          {links?.appStore && (
            <StoreLink
              href={links.appStore}
              type='apple'>
              <VisuallyHidden>{t('download_on_the_app_store')}</VisuallyHidden>
              <AppleStoreIcon />
            </StoreLink>
          )}
        </AppLinksContainer>
      )}
    </FooterBottomContainer>
  );
};

export default observer(FooterBottom);

import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { VolumetricWeightWarning } from '../../widget-calculator.styles';
import { ReactComponent as VolumetricWeightWarningIcon } from '../../../../../assets/warning-tip.svg';
import { FormValues } from '../../widget-calculator-form.component';
import { useStore } from '../../../../../stores/store';
import {
  calculateVolumetricWeight,
  getFormulaDivider,
} from '../../../../parcel-creation/parcel-details-dimensions/dimensionsConfig';
import InputMessage from '../../../../../common/input-message/input-message.component';

const VolumetricWeightMessage = () => {
  const {
    userStore: { measures },
    widgetCalculatorStore: { deliveryLimitations },
  } = useStore();
  const { t } = useTranslation();
  const { values, setFieldValue, validateField, errors } =
    useFormikContext<FormValues>();

  useEffect(() => {
    setFieldValue(
      'volumetricWeight',
      calculateVolumetricWeight({
        width: Number(values.size_x),
        height: Number(values.size_y),
        length: Number(values.size_z),
        measure: measures,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measures, values.size_x, values.size_y, values.size_z]);

  useEffect(() => {
    validateField('volumetricWeight');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.volumetricWeight]);

  if (
    errors.destination_country ||
    errors.size_x ||
    errors.size_y ||
    errors.size_z ||
    errors.weight
  )
    return;

  const shouldShowVolumetricError = !!errors.volumetricWeight;

  const shouldShowVolumetricWarning =
    !shouldShowVolumetricError &&
    values.volumetricWeight > Number(values.weight);

  return (
    <>
      {shouldShowVolumetricWarning && (
        <VolumetricWeightWarning>
          <VolumetricWeightWarningIcon />
          {t('create_parcel_volumetric_tip')}
        </VolumetricWeightWarning>
      )}
      {shouldShowVolumetricError && (
        <InputMessage
          noMargin
          type='error'>
          {t('create_parcel_volumetric_large')}{' '}
          {`(${t('length')}×${t('width')}×${t('height')})/${getFormulaDivider(measures)}`}{' '}
          {'<'} {deliveryLimitations?.maxWeight}
        </InputMessage>
      )}
    </>
  );
};

export default VolumetricWeightMessage;

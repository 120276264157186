import { type TabProps } from 'react-tabs';
import { TabContainer } from './tabs.styles';

export type TabTheme = 'blue' | 'red' | 'polonez' | 'polonez-calculator';

interface Props extends TabProps {
  theme?: TabTheme;
  fullWidth?: boolean;
  tabAnimationCondition?: boolean;
}

const Tab = ({
  theme,
  fullWidth = true,
  tabAnimationCondition,
  selected,
  ...props
}: Props) => {
  let tabAnimationClass;

  if (tabAnimationCondition && !selected) {
    tabAnimationClass = 'react-tabs_animation--active';
  } else if (selected) {
    tabAnimationClass = 'react-tabs__tab--selected';
  }

  return (
    <TabContainer
      $theme={theme}
      $fullWidth={fullWidth}
      {...props}
      className={`react-tabs__tab ${tabAnimationClass}`}
    />
  );
};

Tab.tabsRole = 'Tab';

export default Tab;

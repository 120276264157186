import styled, { css } from 'styled-components';
import { Tab as ReactTab, TabProps } from 'react-tabs';
import { TabsCursor, TabsSize, TabsTheme } from './tabs.component';
import { TabTheme } from './tab.component';

interface ContainerProps {
  $theme?: TabsTheme;
  $size?: TabsSize;
  $contentWeight?: 500 | 700;
  $cursor?: TabsCursor;
  $gap?: string;
  $justifyContent?: string;
}

const getSize = (size: TabsSize) =>
  ({
    mini: {
      tabList: css`
        padding: 4px;
        border-radius: 8px;
      `,
      tab: css`
        font-size: 12px;
        padding: 4px 8px;
        border-radius: 6px;
      `,
    },
    small: {
      tabList: css`
        padding: 6px;
        border-radius: 12px;
      `,
      tab: css`
        font-size: 14px;
        padding: 6px 16px;
        border-radius: 10px;
      `,
    },
    medium: {
      tabList: css`
        padding: 6px;
        border-radius: 12px;
      `,
      tab: css`
        font-size: 14px;
        padding: 12px 16px;
        border-radius: 10px;
      `,
    },
    large: {
      tabList: css`
        padding: 8px 6px;
        border-radius: 24px;
      `,
      tab: css`
        font-size: 14px;
        padding: 8px 16px;
        border-radius: 24px;
      `,
    },
  })[size];

export const Container = styled.div<ContainerProps>`
  ${({ $size = 'small' }) => {
    const sizes = getSize($size);
    return css`
      .react-tabs {
        &__tab-list {
          ${sizes.tabList};
        }

        &__tab {
          ${sizes.tab};
        }
      }
    `;
  }}
  .react-tabs {
    -webkit-tap-highlight-color: transparent;

    &__tab-list {
      margin: 0;
      display: flex;
      justify-content: ${({ $justifyContent = 'space-between' }) =>
        $justifyContent};
      gap: ${({ $gap = '2px' }) => $gap};
      background-color: ${({ $theme = 'white', theme }) =>
        $theme === 'white'
          ? theme.colors.namedColors.white
          : theme.colors.baseColors.grey5};
    }

    &__tab {
      position: relative;
      list-style: none;
      cursor: ${({ $cursor = 'pointer' }) => $cursor};
      display: flex;
      justify-content: center;
      align-items: center;
      transition-property: color, background-color;
      transition-duration: 0.2s;
      font-family: ${({ theme }) => theme.fonts.body.fontFamily};
      font-weight: ${({ $contentWeight = 500 }) => $contentWeight};
      line-height: 1.14;
      letter-spacing: 0.5px;
      text-align: center;
      color: ${({ theme }) => theme.colors.baseColors.grey150};

      &--selected {
        color: ${({ theme }) => theme.colors.namedColors.white};
        background-color: var(--tab-background-color-selected);
        svg path {
          fill: ${({ theme }) => theme.colors.namedColors.white};
        }
      }

      &--disabled {
        cursor: default;
      }

      &:focus {
        outline: none;
      }
    }

    &__tab-panel {
      display: none;
      overflow-y: auto;

      &--selected {
        display: block;
      }
    }
  }
`;

const getTabTheme = (tabTheme: TabTheme) =>
  ({
    blue: css`
      --tab-background-color-selected: ${({ theme }) =>
        theme.colors.namedColors.primary};
    `,
    red: css`
      --tab-background-color-selected: ${({ theme }) =>
        theme.colors.baseColors.red100};
    `,
    polonez: css`
      --tab-background-color-selected: ${({ theme }) =>
        theme.colors.baseColors.blue100};
    `,
    'polonez-calculator': css`
      --tab-background-color-selected: ${({ theme }) =>
        theme.colors.namedColors.accent};
    `,
  })[tabTheme];

interface TabContainerProps extends TabProps {
  $theme?: TabTheme;
  $fullWidth?: boolean;
}

export const TabContainer = styled(ReactTab)<TabContainerProps>`
  display: block;
  width: ${({ $fullWidth = true }) => ($fullWidth ? '100%' : 'auto')};
  &.react-tabs_animation--active {
    position: relative;
    &:before {
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: ${({ theme }) => theme.colors.baseColors.blue100};
      opacity: 0;
      border-radius: 10px;
      animation: blinkAnimation 4s linear infinite;
    }
    &:first-child:not(.react-tabs__tab--selected) ~ &:nth-child(2) {
      &:before {
        animation-delay: 2s;
      }
    }
  }

  &:nth-child(3).react-tabs__tab--selected ~ &:nth-child(2) {
    &:before {
      animation-delay: 2s;
    }
  }

  ${({ $theme = 'blue' }) => css`
    ${getTabTheme($theme)}
    ${$theme === 'red' &&
    css`
      &.react-tabs_animation--active {
        &:before {
          opacity: 0;
          background-color: ${({ theme }) => theme.colors.baseColors.red100};
          animation: blinkAnimation 4s linear infinite;
          animation-delay: 2s;
        }
      }
    `}
  `}

  @keyframes blinkAnimation {
    0%,
    20% {
      opacity: 0;
    }
    25%,
    30% {
      opacity: 0.1;
    }
    35%,
    50% {
      opacity: 0;
    }
    55%,
    60% {
      opacity: 0.1;
    }
    65%,
    100% {
      opacity: 0;
    }
  }
`;

import {
  LabelBody,
  LabelTailBottom,
  RibbonLabelContainer,
} from './ribbon-label.styles';

interface RibbonLabelProps {
  type?: 'blue' | 'green' | 'red';
  text: string;
}

const RibbonLabel = ({ type, text }: RibbonLabelProps) => (
  <RibbonLabelContainer>
    <LabelBody $type={type}>{text}</LabelBody>
    <LabelTailBottom $type={type} />
  </RibbonLabelContainer>
);

export default RibbonLabel;

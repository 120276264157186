import { useTranslation } from 'react-i18next';
import { QRCodeContainer } from './qr-code-item.styles';

interface Props {
  children?: React.ReactNode;
  href?: string;
}

const QRCodeItem = ({ children, href }: Props) => {
  const { t } = useTranslation();
  return <QRCodeContainer>{children}</QRCodeContainer>;
};

export default QRCodeItem;

import { css } from 'styled-components';
import { ButtonAppearance, ButtonContentStyle, ButtonSize } from './types';

export const getContentStyle = (contentStyle: ButtonContentStyle = 'normal') =>
  ({
    thin: '500',
    normal: '700',
  })[contentStyle];

export const getIconSize = (size: 'large' | 'default' | 'mini') =>
  ({
    large: {
      iconSize: '1.5em',
    },
    mini: {
      iconSize: '1.33em',
    },
    default: {
      iconSize: '1.14em',
    },
  })[size];

const getCommonProperties = (
  size: ButtonSize,
  appearance?: ButtonAppearance
) => {
  if (appearance === 'polonez-calculator' && size === 'large') {
    return {
      borderRadius: '36px',
      gap: '8px',
      fontSize: '1.6rem',
      fontWeight: '600!important',
      minHeight: 'fit-content',
      ...getIconSize('large'),
    };
  }
  if (appearance === 'polonez-clear' && size === 'large') {
    return {
      borderRadius: '36px',
      gap: '8px',
      fontSize: '1.4rem',
      fontWeight: '500!important',
      minHeight: 'fit-content',
      ...getIconSize('default'),
    };
  }
  if (appearance === 'polonez-calculator-link' && size === 'medium') {
    return {
      borderRadius: '0',
      gap: '0',
      fontSize: '1.2rem',
      fontWeight: '400!important',
      minHeight: 'fit-content',
      ...getIconSize('default'),
    };
  }

  const commonProps = {
    mini: {
      borderRadius: '5px',
      gap: '4px',
      fontSize: '1.2rem',
      fontWeight: '500',
      minHeight: '24px',
      ...getIconSize('mini'),
    },
    small: {
      borderRadius: '10px',
      gap: '8px',
      fontSize: '1.4rem',
      fontWeight: '500',
      minHeight: '32px',
      ...getIconSize('default'),
    },
    medium: {
      borderRadius: '10px',
      gap: '8px',
      fontSize: '1.4rem',
      fontWeight: '700',
      minHeight: '40px',
      ...getIconSize('default'),
    },
    large: {
      borderRadius: '10px',
      gap: '8px',
      fontSize: '1.6rem',
      fontWeight: '700',
      minHeight: '48px',
      ...getIconSize('large'),
    },
  };

  return commonProps[size];
};

export const getSizePaddings = (
  size: ButtonSize,
  appearance?: ButtonAppearance
) => {
  type PaddingConfig = {
    pt: string;
    pb: string;
    pl: string;
    pr: string;
    minHeight?: string;
  };

  const defaultConfigs: Record<
    string,
    Partial<Record<ButtonSize, PaddingConfig>>
  > = {
    text: {
      mini: {
        minHeight: '24px',
        pt: '5px',
        pb: '5px',
        pl: '3px',
        pr: '3px',
      },
      small: {
        minHeight: '28px',
        pt: '3px',
        pb: '3px',
        pl: '3px',
        pr: '3px',
      },
      medium: {
        minHeight: '24px',
        pt: '3px',
        pb: '3px',
        pl: '3px',
        pr: '3px',
      },
      large: {
        minHeight: '32px',
        pt: '3px',
        pb: '3px',
        pl: '3px',
        pr: '3px',
      },
    },
    default: {
      mini: {
        pt: '3px',
        pb: '3px',
        pl: '7px',
        pr: '7px',
      },
      small: {
        pt: '5px',
        pb: '5px',
        pl: '15px',
        pr: '15px',
      },
      medium: {
        pt: '11px',
        pb: '11px',
        pl: '15px',
        pr: '15px',
      },
      large: {
        pt: '13px',
        pb: '13px',
        pl: '15px',
        pr: '15px',
      },
    },
    'polonez-calculator': {
      large: {
        pt: '14px',
        pb: '14px',
        pl: '36px',
        pr: '36px',
        minHeight: 'fit-content',
      },
    },
    'polonez-clear': {
      large: {
        pt: '14px',
        pb: '14px',
        pl: '36px',
        pr: '36px',
        minHeight: 'fit-content',
      },
    },
    'polonez-calculator-link': {
      medium: {
        pt: '0',
        pb: '0',
        pl: '0',
        pr: '0',
        minHeight: 'fit-content',
      },
    },
  };

  const configKey = appearance || 'default';

  return (
    (defaultConfigs[configKey] && defaultConfigs[configKey][size]) ||
    defaultConfigs['default'][size]
  );
};

export const getLoadingSizePaddings = (
  size: ButtonSize,
  appearance?: ButtonAppearance
) => {
  const paddings =
    appearance === 'text'
      ? {
          mini: {
            minHeight: '24px',
            pl: '3px',
            pr: '3px',
            pt: '5px',
            pb: '5px',
          },
          small: {
            minHeight: '28px',
            pl: '3px',
            pr: '3px',
            pt: '3px',
            pb: '3px',
          },
          medium: {
            minHeight: '24px',
            pl: '3px',
            pr: '3px',
            pt: '3px',
            pb: '3px',
          },
          large: {
            minHeight: '32px',
            pl: '3px',
            pr: '3px',
            pt: '3px',
            pb: '3px',
          },
        }
      : {
          mini: {
            pl: '7px',
            pr: '7px',
            pt: '7px',
            pb: '7px',
            iconSize: '0.66em',
          },
          small: {
            pl: '15px',
            pr: '15px',
            pt: '7px',
            pb: '7px',
          },
          medium: {
            pl: '15px',
            pr: '15px',
            pt: '11px',
            pb: '11px',
          },
          large: {
            pl: '11px',
            pr: '11px',
            pt: '11px',
            pb: '11px',
          },
        };

  return paddings[size];
};

export const getSizeConfig = (
  size: ButtonSize = 'medium',
  appearance?: ButtonAppearance,
  isLoading: boolean = false
) =>
  ({
    mini: {
      ...getCommonProperties('mini', appearance),
      ...(isLoading
        ? getLoadingSizePaddings('mini', appearance)
        : getSizePaddings('mini', appearance)),
    },
    small: {
      ...getCommonProperties('small', appearance),
      ...(isLoading
        ? getLoadingSizePaddings('small', appearance)
        : getSizePaddings('small', appearance)),
    },
    medium: {
      ...getCommonProperties('medium', appearance),
      ...(isLoading
        ? getLoadingSizePaddings('medium', appearance)
        : getSizePaddings('medium', appearance)),
    },
    large: {
      ...getCommonProperties('large', appearance),
      ...(isLoading
        ? getLoadingSizePaddings('large', appearance)
        : getSizePaddings('large', appearance)),
    },
  })[size];

export const getIconSizePaddings = (
  size: ButtonSize,
  appearance?: ButtonAppearance
) => {
  const paddings =
    appearance === 'text'
      ? {
          default: {
            pl: '3px',
            pr: '3px',
            pt: '3px',
            pb: '3px',
          },
        }
      : {
          mini: {
            pl: '3px',
            pr: '3px',
            pt: '3px',
            pb: '3px',
          },
          small: {
            pl: '7px',
            pr: '7px',
            pt: '7px',
            pb: '7px',
          },
          medium: {
            pl: '11px',
            pr: '11px',
            pt: '11px',
            pb: '11px',
          },
          large: {
            pl: '11px',
            pr: '11px',
            pt: '11px',
            pb: '11px',
          },
        };

  return paddings[size] || paddings['default'];
};

export const getIconSizeConfig = (
  size: ButtonSize = 'medium',
  appearance?: ButtonAppearance
) =>
  ({
    mini: {
      ...getCommonProperties('mini'),
      ...getIconSizePaddings('mini', appearance),
    },
    small: {
      ...getCommonProperties('small'),
      ...getIconSizePaddings('small', appearance),
    },
    medium: {
      ...getCommonProperties('medium'),
      ...getIconSizePaddings('medium', appearance),
    },
    large: {
      ...getCommonProperties('large'),
      ...getIconSizePaddings('large', appearance),
    },
  })[size];

export const getBadgeSizes = (size: ButtonSize) =>
  ({
    large: css`
      top: 11px;
      right: 10px;
      width: 0.8rem;
      height: 0.8rem;
    `,
    medium: css`
      top: 8px;
      right: 9px;
      width: 0.8rem;
      height: 0.8rem;
    `,
    small: css`
      top: 5px;
      right: 5px;
      width: 0.8rem;
      height: 0.8rem;
    `,
    mini: css`
      top: 2px;
      right: 2px;
      width: 0.8rem;
      height: 0.8rem;
    `,
  })[size];

import { useTranslation } from 'react-i18next';
import {
  PasswordValidatorContainer,
  ValidationMarker,
  ValidationMarkerContainer,
  ValidationOption,
} from './password-validator.styles';

interface ValidatorProps {
  validationErrorIndexes: number[];
}

const errorMessages = [
  { id: '1', label: 'from_8_to_32_characters' },
  { id: '2', label: 'at_least_1_digit' },
  { id: '3', label: 'at_least_1_lowercase_letter' },
  { id: '4', label: 'at_least_1_uppercase_letter' },
  { id: '5', label: 'at_least_1_of_[@$%*#&-]' },
  { id: '6', label: 'no_whitespaces' },
] as const;

const PasswordValidator = ({ validationErrorIndexes }: ValidatorProps) => {
  const { t } = useTranslation();

  const validRequirementsCount =
    errorMessages.length - validationErrorIndexes.length;

  return (
    <div>
      <ValidationMarkerContainer>
        {errorMessages.map((message, index) => (
          <ValidationMarker
            key={message.id}
            $valid={index < validRequirementsCount}
          />
        ))}
      </ValidationMarkerContainer>
      <PasswordValidatorContainer>
        {errorMessages.map((message, index) => (
          <ValidationOption
            key={message.id}
            $valid={!validationErrorIndexes.includes(index)}>
            {t(message.label)}
          </ValidationOption>
        ))}
      </PasswordValidatorContainer>
    </div>
  );
};

export default PasswordValidator;

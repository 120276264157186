import { useEffect, useRef, useState } from 'react';

interface SliderProps<T> {
  items: T[];
}

export function useSliderMethods<T>(
  { items }: SliderProps<T>,
  enableAutoSlide: boolean = false
) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const touchStartX = useRef<number | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const moveToSlide = (index: number) => {
    setCurrentSlide(index);
  };

  const moveNextSlide = () => {
    const newSlide = Math.min(currentSlide + 1, items.length - 1);
    setCurrentSlide(newSlide);
  };

  const moveNextInfinitySlide = () => {
    setCurrentSlide((nextSlide) =>
      nextSlide === (items?.length ? items.length - 1 : 0) ? 0 : nextSlide + 1
    );
  };

  const moveNextThreeSlides = () => {
    const newSlide = Math.min(currentSlide + 1, items.length - 3);
    setCurrentSlide(newSlide);
  };

  const movePrevThreeSlides = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prevSlide) =>
        prevSlide === 0 ? items.length - 3 : prevSlide - 1
      );
    }
  };

  const movePrevSlide = () => {
    const newSlide = Math.max(currentSlide - 1, 0);
    setCurrentSlide(newSlide);
  };

  const movePrevInfinitySlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? items.length - 1 : prevSlide - 1
    );
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (touchStartX.current === null) return;
    const currentX = e.touches[0].clientX;
    const diff = touchStartX.current - currentX;

    if (Math.abs(diff) > 50) {
      if (diff > 0) {
        moveNextSlide();
      } else {
        movePrevSlide();
      }
      touchStartX.current = null;
    }
  };

  const handleTouchMoveInfinitySlide = (
    e: React.TouchEvent<HTMLDivElement>
  ) => {
    if (touchStartX.current === null) return;
    const currentX = e.touches[0].clientX;
    const diff = touchStartX.current - currentX;

    if (Math.abs(diff) > 50) {
      if (diff > 0) {
        moveNextInfinitySlide();
      } else {
        movePrevInfinitySlide();
      }
      touchStartX.current = null;
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (enableAutoSlide) {
      interval = setInterval(() => {
        let newSlideIndex = 0;
        if (currentSlide + 1 < items.length) {
          newSlideIndex = currentSlide + 1;
        }
        setCurrentSlide(newSlideIndex);
      }, 4000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [currentSlide, enableAutoSlide, items.length]);

  const handleBulletClick = (index: number) => {
    setCurrentSlide(index);
  };

  return {
    setCurrentSlide,
    currentSlide,
    handleBulletClick,
    moveNextSlide,
    movePrevSlide,
    handleTouchStart,
    handleTouchMove,
    containerRef,
    moveNextThreeSlides,
    movePrevThreeSlides,
    moveNextInfinitySlide,
    movePrevInfinitySlide,
    handleTouchMoveInfinitySlide,
    moveToSlide,
  };
}

import React, { forwardRef, Ref } from 'react';
import { Counter, StyledTextArea, TextAreaContainer } from './textarea.styles';
import InputMessage from '../input-message/input-message.component';
import { FlexContainer } from '../../features/parcel-creation/delivery-details-form/delivery-details-form.styles';

interface TextAreaProps {
  id?: string;
  name: string;
  placeholder?: string;
  disabled?: boolean;
  value?: string;
  isInvalid?: boolean;
  maxLength?: number;
  required?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  style?: React.CSSProperties;
  readOnly?: boolean;
  autoComplete?: string;
  inputMode?: React.HTMLAttributes<HTMLTextAreaElement>['inputMode'];
  validationMessage?: string;
}

const TextArea = (
  {
    id,
    name,
    placeholder,
    disabled,
    value,
    isInvalid,
    maxLength,
    required,
    onChange,
    onFocus,
    onBlur,
    style,
    readOnly,
    autoComplete,
    inputMode,
    validationMessage,
  }: TextAreaProps,
  ref: Ref<HTMLTextAreaElement>
) => (
  <>
    <TextAreaContainer $error={isInvalid}>
      <StyledTextArea
        ref={ref}
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        maxLength={maxLength}
        required={required}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        style={style}
        readOnly={readOnly}
        autoComplete={autoComplete}
        inputMode={inputMode}
        disabled={disabled}
        $borderNone
      />
    </TextAreaContainer>
    <FlexContainer $mt='0.4rem'>
      {validationMessage && (
        <InputMessage
          type='error'
          noMargin>
          {validationMessage}
        </InputMessage>
      )}
      {maxLength && (
        <Counter>
          {value?.length || 0}/{maxLength}
        </Counter>
      )}
    </FlexContainer>
  </>
);

export default forwardRef(TextArea);

import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../stores/store';
import ServicesSubsection, {
  SubSectionData,
} from '../../features/services/services-subsection/services-subsection.component';
import PersonalDataEditForm from './personal-data-form.component';
import ContactDetailsEditForm from './contact-details-form.component';
import MyAddressEditForm from './my-address-form.component';
import { PersonalDataContainer } from './personal-data.styles';
import LocationSettingsForm from './location-settings-form.component';
import { Measure } from '../../utils/parcel-measurements.ts/parcel-measurements-utils';
import { DEFAULT_COUNTRY } from '../../stores/localizationsStore';
import Tip from '../../common/toasts/tip.component';
import { useLoadPlacesLibs } from '../../hooks/use-load-places-libs.hook';
import { getPostalCodeLabel } from '../../features/parcel-creation/delivery-details-form/delivery-details-form.component';

const PersonalData = () => {
  const {
    userStore: { user, getAddress },
    localizationsStore: { availableCountries, availableLocalizations },
  } = useStore();
  useLoadPlacesLibs();

  const { t } = useTranslation();

  const personalDataSectionData: SubSectionData[] = useMemo(
    () => [
      {
        label: t('first_name'),
        value: user?.first_name,
      },
      {
        label: t('middle_name'),
        value: user?.middle_name,
      },
      {
        label: t('last_name'),
        value: user?.last_name,
      },
    ],
    [user, t]
  );

  const contactDetailsSectionData: SubSectionData[] = useMemo(
    () => [
      {
        label: t('email'),
        value: user?.email,
      },
      {
        label: t('phone_number'),
        value: user?.phone?.phone_number ? (
          `+${user?.phone?.phone_number}`
        ) : (
          <Tip
            appearance='error'
            title={t('please_add_your_phone_number')}
          />
        ),
      },
    ],
    [user, t]
  );

  const myAddressSectionData: SubSectionData[] = useMemo(() => {
    const chosenCountry =
      (user?.country &&
        availableCountries!.find((i) => i.country_code === user.country)
          ?.countryDetails.localized_name) ||
      DEFAULT_COUNTRY.en_name;

    return [
      {
        label: t('country'),
        value: chosenCountry,
      },
      {
        label: t('province_/_state'),
        value: user?.address?.region ?? '',
      },
      {
        label: t('city'),
        value: user?.address?.city ?? '',
      },
      {
        label: getPostalCodeLabel(user?.country),
        value: user?.address?.post_code ?? '',
      },
      {
        label: t('address'),
        value: getAddress() ?? '',
      },
    ];
  }, [user, availableCountries, getAddress, t]);

  const locationSettingsData: SubSectionData[] = useMemo(() => {
    const chosenLanguage = user?.lang
      ? availableLocalizations.find((i) => i.code === user.lang)?.languageLabel
      : 'English';

    const chosenCurrency = user?.currency || 'USD';

    const chosenMeasures =
      user?.measures === Measure.KG_CM ? t('kg_/_cm') : t('lb_/_in');

    return [
      {
        label: t('language'),
        value: chosenLanguage,
      },
      {
        label: t('currency'),
        value: chosenCurrency,
      },
      {
        label: t('measures'),
        value: chosenMeasures,
      },
    ];
  }, [user, availableLocalizations, t]);

  if (!user) return null;

  return (
    <PersonalDataContainer>
      <ServicesSubsection
        title={t('user_data')}
        data={personalDataSectionData}
        EditForm={PersonalDataEditForm}
      />
      <ServicesSubsection
        title={t('contact_details')}
        data={contactDetailsSectionData}
        EditForm={ContactDetailsEditForm}
        allowEdit={false}
      />
      <ServicesSubsection
        title={t('location_settings')}
        data={locationSettingsData}
        EditForm={LocationSettingsForm}
      />
      <ServicesSubsection
        title={t('my_address')}
        data={myAddressSectionData}
        EditForm={MyAddressEditForm}
      />
    </PersonalDataContainer>
  );
};

export default observer(PersonalData);

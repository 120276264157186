import styled from 'styled-components';

export const Container = styled.div`
  display: block;
`;

export const WrapperMobile = styled.div`
  display: flex;
  gap: 1.2rem;
`;

export const Header = styled.div`
  color: ${({ theme }) => theme.colors.baseColors.grey150};
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.body.fontFamily};
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.4rem;
  letter-spacing: 0.05rem;
  margin-bottom: 1.6rem;
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.colors.baseColors.grey100};
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.body.fontFamily};
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.05rem;
  margin-bottom: 2.4rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;

  div {
    button {
      width: 160px;
    }
  }

  @media (max-width: 650px) {
    flex-direction: column;
    gap: 16px;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BulletContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  padding: 4px 6px;
`;

interface BulletProps {
  $isActive: boolean;
}

export const Bullet = styled.span<BulletProps>`
  display: block;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  background-color: ${({ $isActive, theme }) =>
    $isActive ? theme.colors.namedColors.primary : '#f1f2f4'};
`;

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  Content,
  ParcelItemsManagementContainer,
} from './parcel-items-management.styles';
import { useStore } from '../../../stores/store';
import ParcelItem from './parcel-item.component';
import ParcelItemEditForm from './parcel-item-form.component';
import Button from '../../../common/button/button.component';
import ParcelProhibitedItemsWithSidebar from '../parcel-prohibited-items-sidebar/parcel-prohibited-items-sidebar.component';
import { ServicesSubSectionHeader } from '../../services/services-subsection/services-subsection.styles';
import { isMeest } from '../../../utils/client/client.utils';
import { client } from '../../../config';
import TotalValue from './total-value.component';
import { FlexContainer } from '../../../common/flex-container/flex-container.styles';
import { useEditForm } from './edit-form-manager';

const NEW_ITEM_EDIT_FORM_ID = 1212121212;

const ParcelItemsManagement = () => {
  const {
    parcelCreationStore: { shipment, isLoadingShipmentApi },
    localizationsStore: { isLanguageChanging },
    userStore: { getCurrencySymbol },
  } = useStore();

  const { openedItemId, openEditForm } = useEditForm();

  const { t } = useTranslation();

  const addingNewItemMode = openedItemId === NEW_ITEM_EDIT_FORM_ID;

  const numOfItemsInShipment = shipment?.items?.length ?? 0;

  const totalDeclaredValue = shipment?.items?.at(0)
    ? shipment?.items?.map((i) => i.value)?.reduce((prev, cur) => cur + prev)
    : 0;

  const currencySymbol = getCurrencySymbol(shipment?.converted_price?.currency);

  return (
    <ParcelItemsManagementContainer>
      <ServicesSubSectionHeader>{t('parcel_content')}</ServicesSubSectionHeader>
      <Content>
        <ParcelProhibitedItemsWithSidebar />
        {numOfItemsInShipment > 0 && (
          <FlexContainer
            $gap='1.6rem'
            $direction='column'
            $mobDirection='column'
            $align='start'>
            {shipment?.items
              ?.slice()
              .sort((a, b) => a.id - b.id)
              .map((item, i) => (
                <ParcelItem
                  shouldShowDeleteButton={numOfItemsInShipment > 1}
                  currencySymbol={currencySymbol}
                  key={item.id}
                  item={item}
                  withSeparator={
                    i + 1 < numOfItemsInShipment ||
                    (addingNewItemMode && numOfItemsInShipment > 0)
                  }
                />
              ))}
          </FlexContainer>
        )}
        {numOfItemsInShipment > 0 && !addingNewItemMode && (
          <Button
            appearance={isMeest(client) ? 'link' : 'text-danger'}
            hideLinkUnderline
            $pb='0'
            $pt='0'
            onClick={() => {
              openEditForm(NEW_ITEM_EDIT_FORM_ID);
            }}
            icon={{ glyph: 'plus' }}
            disabled={isLoadingShipmentApi || isLanguageChanging}>
            {t('add_new_item')}
          </Button>
        )}
        {(numOfItemsInShipment === 0 || addingNewItemMode) && (
          <ParcelItemEditForm
            displayCancelButton={numOfItemsInShipment > 0}
            currencySymbol={currencySymbol}
          />
        )}
        {numOfItemsInShipment > 0 && (
          <TotalValue
            totalValue={totalDeclaredValue ?? 0}
            currencySymbol={currencySymbol}
          />
        )}
      </Content>
    </ParcelItemsManagementContainer>
  );
};

export default observer(ParcelItemsManagement);

import { TFunction } from 'i18next';
import { ICountriesMarketing } from '../../../models/countries-marketing/countries-marketing';
import { TransitTimeItem, TransitTimeWrapper } from './countries-list.styles';
import { MiddleMileIcon } from '../../parcel-creation/parcel-creation-bottom/parcel-creation-bottom.component';
import { DeliveryMethod } from '../../../models/parcelDeliveryMiles';

const PRIORITY_CODES = ['UA', 'CA', 'US'];

export const getCountryName = (
  country: ICountriesMarketing,
  languageCode: string
) => {
  switch (languageCode) {
    case 'uk':
      return country.name.uk;
    case 'ru':
      return country.name.ru;
    default:
      return country.name.en;
  }
};

export const getRegionTranslation = (
  region: string,
  t: (key: string) => string
) => {
  const normalizedRegion = region.toLowerCase().replace(/\s+/g, '_');
  return t(`main_page_delivery_countries_${normalizedRegion}`);
};

export const sortCountries = (
  countriesMarketing: ICountriesMarketing[],
  languageCode: string,
  selectedRegion: string
) => {
  if (!countriesMarketing) return [];

  const filteredByRegion =
    selectedRegion === 'All'
      ? countriesMarketing
      : countriesMarketing.filter(
          (country) => country.region === selectedRegion
        );

  const priorityCountries = PRIORITY_CODES.map((code) =>
    filteredByRegion.find((country) => country.code === code)
  ).filter(Boolean) as ICountriesMarketing[];

  const otherCountries = filteredByRegion
    .filter((country) => !PRIORITY_CODES.includes(country.code!))
    .sort((a, b) => {
      const aName = getCountryName(a, languageCode).toLowerCase();
      const bName = getCountryName(b, languageCode).toLowerCase();
      return aName.localeCompare(bName);
    });

  return [...priorityCountries, ...otherCountries];
};

export const filterCountries = (
  countries: ICountriesMarketing[],
  searchQuery: string,
  languageCode: string
) =>
  countries.filter((country) => {
    const name = getCountryName(country, languageCode).toLowerCase();
    return name.includes(searchQuery.toLowerCase());
  });

export const getDeliveryInfo = (
  selectedCountry: ICountriesMarketing | null,
  t: TFunction
) => {
  if (!selectedCountry) return [];

  const {
    transitTime,
    maxWeight,
    maxDimensions,
    dutyFreeCustomsLimit,
    maxValue,
    insurance,
  } = selectedCountry;

  const transitIcons = transitTime
    ? [
        {
          method: 'air',
          value: transitTime.air?.value,
          units: transitTime.air?.units,
          prefix: transitTime.air?.prefix,
        },
        {
          method: 'sea',
          value: transitTime.sea?.value,
          units: transitTime.sea?.units,
          prefix: transitTime.sea?.prefix,
        },
        {
          method: 'expedited',
          value: transitTime.expedited?.value,
          units: transitTime.expedited?.units,
          prefix: transitTime.expedited?.prefix,
        },
        {
          method: 'car',
          value: transitTime.car?.value,
          units: transitTime.car?.units,
          prefix: transitTime.car?.prefix,
        },
      ].filter(({ value }) => value)
    : [];

  const normalizeTranslationKey = (key: string) =>
    key.toLowerCase().replace(/\s+/g, '_');

  return [
    {
      label: `${t('main_page_delivery_countries_transit_time')}:`,
      value:
        transitIcons.length > 0 ? (
          <TransitTimeWrapper>
            {transitIcons.map(({ method, value, units, prefix }) => (
              <TransitTimeItem key={method}>
                <MiddleMileIcon method={method as DeliveryMethod} />
                <span>
                  {`${prefix ? `${t(prefix)} ` : ''}` +
                    `${value} ${t(normalizeTranslationKey(`${units}`))}`}
                </span>
              </TransitTimeItem>
            ))}
          </TransitTimeWrapper>
        ) : null,
    },
    {
      label: `${t('main_page_delivery_countries_max_parcel_weight')}:`,
      value: maxWeight
        ? `${maxWeight.value} ${t(maxWeight.units) || ''}`
        : null,
    },
    {
      label: `${t('main_page_delivery_countries_max_parcel_dimensions')}:`,
      value: (() => {
        if (!maxDimensions) {
          return null;
        }

        if (maxDimensions.perimeter) {
          return t('main_page_delivery_countries_max_perimeter', {
            side: maxDimensions.width,
            unit: t(maxDimensions?.units ?? ''),
            perimeter: maxDimensions.perimeter,
            units: t(maxDimensions?.units ?? ''),
          });
        }

        if (
          maxDimensions.min &&
          maxDimensions.max &&
          maxDimensions.min.length === 2
        ) {
          return (
            <>
              {t('main_page_delivery_countries_min_italy', {
                cm: t(maxDimensions.min[0].units),
                in: t(maxDimensions.min[1].units),
                minMetric: maxDimensions.min[0].value,
                minImperial: maxDimensions.min[1].value,
              })}
              <br />
              {t('main_page_delivery_countries_max_italy', {
                cm: t(maxDimensions.min[0].units),
                in: t(maxDimensions.min[1].units),
                maxMetric: maxDimensions.max[0].value,
                maxImperial: maxDimensions.max[1].value,
              })}
            </>
          );
        }

        return `${maxDimensions.width} x ${maxDimensions.height} x ${maxDimensions.depth} ${
          t(maxDimensions?.units ?? '') || maxDimensions.units
        }`;
      })(),
    },
    {
      label: `${t('main_page_delivery_countries_duty_free')}:`,
      value: dutyFreeCustomsLimit || null,
    },
    {
      label: `${t('main_page_delivery_countries_max_parcel_value')}:`,
      value: maxValue || null,
    },
    {
      label: `${t('insurance')}:`,
      value: insurance
        ? t('main_page_delivery_countries_insurance_description', {
            price: '$60',
            procent: '5%',
          })
        : null,
    },
  ].filter((item) => item.value);
};

export const getCountriesToRender = (
  filteredCountries: ICountriesMarketing[],
  selectedCountry: ICountriesMarketing | null
): ICountriesMarketing[] => {
  if (filteredCountries.length > 0) {
    return filteredCountries;
  }

  if (selectedCountry) {
    return [selectedCountry];
  }

  return [];
};

import styled, { css } from 'styled-components';

export const Cards = styled.div`
  --cards-gap-desktop: 24px;
  --card-border-radius: 20px;

  list-style: none;
  margin: 0;
  display: grid;
  gap: 12px;

  @media screen and (min-width: 650px) {
    gap: var(--cards-gap-desktop);
  }
`;

export const CardImage = styled.div``;

export const CardContent = styled.div`
  display: grid;
  gap: 4px;
  @media screen and (min-width: 650px) {
    gap: 8px;
  }
`;

interface CardDescriptionProps {
  $fontWeight?: 400 | 500 | 700;
}

export const CardDescription = styled.p<CardDescriptionProps>`
  font-size: 1.8rem;
  font-weight: ${({ $fontWeight = 500 }) => $fontWeight};
  line-height: 1.42;
  letter-spacing: 0.05rem;
  color: #75777b;
`;

export const CardTitle = styled.h3`
  font-size: 16px;
  font-weight: 700;
  line-height: 1.37;
  letter-spacing: 0.5px;
  color: #2c363f;
  transition: color 0.2s;

  @media screen and (min-width: 650px) {
    font-size: 18px;
    line-height: 1.33;
  }
`;

type CardContentAlign = 'left' | 'center' | 'right';

interface CardProps {
  $isBordered?: boolean;
  $contentAlign?: CardContentAlign;
  $isHoverable?: boolean;
  $index?: number;
  $isLast?: boolean;
}

const getContentAlignStyles = (contentAlign: CardContentAlign) =>
  ({
    left: css`
      justify-items: left;
      text-align: left;
    `,
    center: css`
      justify-items: center;
      text-align: center;
    `,
    right: css`
      justify-items: right;
      text-align: right;
    `,
  })[contentAlign];

export const Card = styled.div<CardProps>`
  position: relative;
  isolation: isolate;
  display: grid;
  gap: 12px;
  align-content: start;
  padding: 24px;
  background-color: #fff;
  border-radius: var(--card-border-radius, 20px);
  ${({ $contentAlign = 'left' }) => getContentAlignStyles($contentAlign)}
  ${({ $isHoverable }) =>
    $isHoverable &&
    css`
      transition: background-color 0.2s;
      &:hover {
        background-color: #005fad;
        ${CardTitle} {
          color: #fff;
        }
      }
    `}

  ${({ $isBordered }) =>
    $isBordered &&
    css`
      border: 1px solid
        ${({ theme }) => theme.colors.passwordValidationMarker.backgroundColor};
    `}

  @media screen and (min-width: 650px) {
    gap: 16px;
  }
`;

import { getCategories } from './popular-categories-common';

const popularCategoriesTitlesCa = [
  'Books',
  'Cellphone',
  `Children's Clothing`,
  `Children's Shoes`,
  'Coffee',
  'Cosmetics',
  'Dry Foods',
  'Electronics',
  'Hat',
  'Household Items',
  'Jacket',
  'Pants',
  'Personal Documents',
  'Shoes',
  'Skirt',
  'Socks',
  'Souvenirs',
  'Sweater',
  'Souvenirs',
  'Sweets',
  'Tea',
  'Toys',
  'T-Shirt',
  'Underwear',
  'Used Clothing',
  'Vitamins',
];

export const PopularCategoriesCA = getCategories(popularCategoriesTitlesCa);

import { FooterLinks } from '../../../models/footerLinksModel';

const LinkProps: (keyof FooterLinks)[] = [
  'countryCode',
  'about_us',
  'privacy_policy',
  'terms_of_use',
  'delivery_rules',
  'international_shipping',
  'all_services',
  'contact_link',
  'customer_support',
  'faq',
  'play_market',
  'app_store',
  'facebook',
  'instagram',
  'linkedin',
  'twitter',
  'become_agent',
];

export function mergeLinks(
  links1?: FooterLinks,
  links2?: FooterLinks
): FooterLinks | undefined {
  if (!links1 && links2) return { ...links2 };
  if (links1 && !links2) return { ...links1 };

  if (links1 && links2) {
    const result: FooterLinks = {};

    LinkProps.forEach((key) => {
      const val1 = links1[key];
      const val2 = links2[key];
      if (val1 === undefined && val2 === undefined) {
        result[key] = '';
      } else {
        result[key] = val1 !== undefined ? val1 : val2;
      }
    });

    return result;
  }
}

import styled from 'styled-components';
import { Card, CardDescription, CardImage, Cards } from '../common/card.styles';
import { BrandButton } from '../../../common/button/button.styles';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;

  @media screen and (min-width: 650px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    margin-bottom: 32px;
  }
`;

interface InstructionsCardsProps {
  $length: number;
}

export const InstructionsCards = styled(Cards)<InstructionsCardsProps>`
  display: grid;
  justify-content: center;
  gap: 2.4rem;

  @media screen and (min-width: 650px) {
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  }

  ${BrandButton} {
    min-height: 4.8rem;
    border-radius: var(--card-border-radius);
  }
`;

export const SendParcel = styled.div``;
export const ReceiveParcel = styled.div``;

export const InstructionsCard = styled(Card)`
  &::before {
    content: '${({ $index }) => $index}';
    position: absolute;
    bottom: -7.6rem;
    right: 0.1rem;
    font-size: 30rem;
    font-weight: bold;
    color: #f4f7fc;
    z-index: 0;
    ${({ $isLast }) => $isLast && 'content: none;'}

    @media screen and (max-width: 650px) {
      font-size: 20rem;
      bottom: -5.1rem;
    }
  }
`;

export const InstructionsCardImage = styled(CardImage)`
  z-index: 1;
  color: #0061af;
`;

export const InstructionsTextInfo = styled.div`
  display: inline;
  z-index: 1;

  > h3,
  > p {
    display: inline;
  }
`;

export const InstructionsCardText = styled(CardDescription)<{
  $isLast?: boolean;
}>`
  font-weight: ${({ $isLast }) => ($isLast ? '500' : '400')};
  color: ${({ theme }) => theme.colors.baseColors.grey150};
`;

export const CreateNowButton = styled.div`
  width: 100%;

  ${BrandButton} {
    border-radius: 1rem;
  }
`;

import styled from 'styled-components';
import { ToastStyles } from './app/common/toasts/toasts.styles';

export const AppContainer = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.body.backgroundColor};

  ${ToastStyles};
`;

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import {
  ServicesSubSectionContainer,
  ServicesSubSectionHeader,
} from '../../services/services-subsection/services-subsection.styles';
import SignatureField from '../../../common/signature-field/signature-field.component';
import { useStore } from '../../../stores/store';
import { Title } from './signature-details-summary.styles';
import { useIsMobile } from '../../../hooks/use-is-mobile.hook';

interface Props {
  children?: React.ReactNode;
}

const SignatureDetailsSummary = ({ children }: Props) => {
  const {
    parcelCreationStore: {
      setSignature,
      fetchSignature,
      signatureIsPresent,
      isNextStepButtonLoading,
      isSignButtonClicked,
      setIsSignButtonClicked,
    },
  } = useStore();

  const isMobile = useIsMobile();
  const { t } = useTranslation();

  useEffect(() => {
    if (signatureIsPresent) fetchSignature();
  }, [fetchSignature, signatureIsPresent]);

  const renderSignatureField = (isMobileLayout: boolean) => (
    <>
      <ServicesSubSectionHeader>{t('signature')}</ServicesSubSectionHeader>
      <Title>{t('adding_signature_required_step')}</Title>
      <SignatureField
        onChange={(dataUrl) => {
          setSignature(dataUrl);
        }}
        onClear={() => {
          setSignature('');
          setIsSignButtonClicked(false);
        }}
        isSignatureProcessing={isNextStepButtonLoading}
        isSignButtonClicked={isSignButtonClicked}>
        {!isMobileLayout && children}
      </SignatureField>
    </>
  );

  return isMobile ? (
    <ServicesSubSectionContainer $marginTop='-5rem'>
      {renderSignatureField(true)}
    </ServicesSubSectionContainer>
  ) : (
    renderSignatureField(false)
  );
};

export default observer(SignatureDetailsSummary);

import styled from 'styled-components';
import { VisuallyHiddenStyles } from '../visually-hidden/visually-hidden.styles';

export const RadioContainer = styled.span<{ checked: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  border: 0.1rem solid;
  border-color: ${(props) =>
    props.checked
      ? props.theme.colors.namedColors.primary
      : props.theme.colors.baseColors.grey50};
  border-radius: 1rem;
  color: ${(props) =>
    props.checked
      ? props.theme.colors.namedColors.primary
      : props.theme.colors.baseColors.grey150};
  outline: none;
  transition-property: border-color, background-color, color, outline-color;
  transition-duration: 0.2s;

  &:hover {
    border: 0.1rem solid ${({ theme }) => theme.colors.radioContainer.color};
    color: ${({ theme }) => theme.colors.radioContainer.color};
  }
`;

export const Label = styled.label`
  display: flex;
  height: 4.2rem;
  width: 100%;
  padding: 1rem 1.6rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 1rem;
  cursor: pointer;

  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.2rem;
  letter-spacing: 0.05rem;
  transition-property: outline-color;
  transition-duration: 0.2s;
  outline: 3px solid transparent;
`;

export const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  ${VisuallyHiddenStyles}

  &:focus-visible ~ ${Label} {
    outline-color: #b3ddff;
  }
`;

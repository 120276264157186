import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import {
  FormValues,
  IDataInputNames,
} from '../../widget-calculator-form.component';
import InputMessage from '../../../../../common/input-message/input-message.component';
import { useStore } from '../../../../../stores/store';
import { getMeasureUnits } from '../../../../../utils/parcel-measurements.ts/parcel-measurements-utils';

const ValidationHandler = ({
  setShowEstimatedDeliveryCost,
  activeField,
}: {
  setShowEstimatedDeliveryCost: (show: boolean) => void;
  handleDataInputClose: (inputName?: keyof FormValues) => void;
  activeField: IDataInputNames | null;
}) => {
  const { t } = useTranslation();
  const {
    localizationsStore: { selectedCountry },
    widgetCalculatorStore: { deliveryLimitations },
    userStore: { measures },
  } = useStore();
  const {
    validateField,
    touched,
    errors,
    values,

    handleReset,
    isValid,
  } = useFormikContext<FormValues>();

  const measureUnits = getMeasureUnits(measures);

  useEffect(() => {
    Object.keys(values).forEach((field) => {
      // Ignore validation while dropdown is still opened.
      const ignoreActiveDestCountry =
        activeField === 'destination_country' && activeField === field;

      if (!ignoreActiveDestCountry && touched[field as keyof FormValues]) {
        validateField(field);
      }
    });
  }, [touched, activeField, validateField, values]);

  useEffect(() => {
    if (selectedCountry) {
      handleReset();
      setShowEstimatedDeliveryCost(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry?.country_code]);

  const showCountryInvalidError =
    Object.values(errors).includes('country_incorrect') && !isValid;

  const showFieldRequiredError =
    Object.values(errors).includes('fields_required') && !isValid;

  const showDimensionsError =
    Object.keys(errors).length > 0 &&
    !Object.keys(errors).includes('volumetricWeight') &&
    !Object.values(errors).includes('fields_required') &&
    !isValid &&
    deliveryLimitations;

  let element: React.ReactNode = null;

  if (showCountryInvalidError) {
    element = (
      <InputMessage
        noMargin
        type='error'>
        {t('country_incorrect')}
      </InputMessage>
    );
  } else if (showFieldRequiredError) {
    element = (
      <InputMessage
        noMargin
        type='error'>
        {t('fields_required')}
      </InputMessage>
    );
  } else if (showDimensionsError) {
    element = (
      <InputMessage
        noMargin
        type='error'>
        {t(`widget_calculator_dimensions_validations_error`, {
          minWeight: `${deliveryLimitations?.minWeight} ${t(measureUnits.weight)}`,
          maxWeight: `${deliveryLimitations?.maxWeight} ${t(measureUnits.weight)}`,
          maxSide: `${deliveryLimitations?.maxSide} ${t(measureUnits.length)}`,
        })}
      </InputMessage>
    );
  }

  return element;
};
export default observer(ValidationHandler);

import styled from 'styled-components';

export const Header = styled.div`
  color: ${({ theme }) => theme.colors.baseColors.grey150};
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.body.fontFamily};
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.4rem;
  letter-spacing: 0.05rem;
  margin-bottom: 1.6rem;
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.colors.baseColors.grey100};
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.body.fontFamily};
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.05rem;
  margin-bottom: 2.4rem;
`;

import { FieldMetaProps } from 'formik';
import * as Yup from 'yup';
import {
  DIGITS_REGEX,
  IS_NOT_CYRILLIC,
  LATIN_LETTER_REGEX,
  LATIN_LETTER_WITH_COMMA_REGEX,
  LATIN_LETTER_EXTENDED_WITH_COMMA_REGEX,
  LATIN_LETTER_WITH_NUMBERS_REGEX,
  ONLY_LETTERS_REGEX,
  VALIDATION_RULE_IS_NOT_CYRILLIC,
  VALIDATION_RULE_LATIN,
  VALIDATION_RULE_LATIN_WITH_COMMA,
  VALIDATION_RULE_LATIN_EXTENDED_WITH_COMMA,
  VALIDATION_RULE_LATIN_WITH_NUMBERS,
  VALIDATION_RULE_ONLY_DIGITS,
  VALIDATION_RULE_ONLY_LETTERS,
  NO_RULE_REGEX,
  NO_VALIDATION_RULE,
  VALIDATION_RULE_DIGITS_WITH_DOT,
  DIGITS_WITH_DOT,
} from '../../constants/validation';
import { ValidationRuleType } from '../../types/input-validation-types';

export type TValidationMethod = 'always' | 'touched' | 'default' | 'on-type';

interface GetIsFieldInvalidProps {
  validationMethod: TValidationMethod;
  meta: FieldMetaProps<any>;
  isTyped?: boolean; // means that user modified value of this vield (doesn't matter if it returned to original state)
}

export const getIsFieldInvalid = ({
  validationMethod,
  meta,
  isTyped,
}: GetIsFieldInvalidProps) =>
  ({
    always: meta.error !== undefined,
    touched: meta.touched && meta.error !== undefined,
    default: meta.touched && meta.error !== undefined && !!isTyped,
    'on-type': meta.error !== undefined && !!isTyped,
  })[validationMethod];

const validationRules = {
  [VALIDATION_RULE_LATIN]: {
    errorMessage: 'the_field_must_contain_latin_letters',
    regex: LATIN_LETTER_REGEX,
  },
  [VALIDATION_RULE_LATIN_WITH_NUMBERS]: {
    errorMessage: 'the_field_must_contain_latin_letters_or_numbers',
    regex: LATIN_LETTER_WITH_NUMBERS_REGEX,
  },
  [VALIDATION_RULE_LATIN_WITH_COMMA]: {
    errorMessage: 'the_field_must_contain_latin_letters',
    regex: LATIN_LETTER_WITH_COMMA_REGEX,
  },
  [VALIDATION_RULE_LATIN_EXTENDED_WITH_COMMA]: {
    errorMessage: 'the_field_must_contain_latin_letters',
    regex: LATIN_LETTER_EXTENDED_WITH_COMMA_REGEX,
  },
  [VALIDATION_RULE_IS_NOT_CYRILLIC]: {
    errorMessage: 'the_field_must_contain_latin_letters',
    regex: IS_NOT_CYRILLIC,
  },
  [VALIDATION_RULE_ONLY_LETTERS]: {
    errorMessage: '',
    regex: ONLY_LETTERS_REGEX,
  },
  [VALIDATION_RULE_ONLY_DIGITS]: {
    errorMessage: '',
    regex: DIGITS_REGEX,
  },
  [VALIDATION_RULE_DIGITS_WITH_DOT]: {
    errorMessage: '',
    regex: DIGITS_WITH_DOT,
  },
  [NO_VALIDATION_RULE]: {
    errorMessage: '',
    regex: NO_RULE_REGEX,
  },
};

// Generic function to create a validation rule
export const createValidationRule = (validationRule: ValidationRuleType) => {
  const { errorMessage, regex } = validationRules[validationRule];
  return Yup.string().test(
    validationRule,
    errorMessage,
    (value) => value == null || regex.test(value)
  );
};

export const createValidationRuleWithRequired = (
  validationRule: ValidationRuleType
) => {
  const { errorMessage, regex } = validationRules[validationRule];
  return Yup.string()
    .required('fields_required')
    .test(
      validationRule,
      errorMessage,
      (value) => value == null || regex.test(value)
    );
};

export const transformToDecimal = (
  value: string,
  digitsAfterComma: number = 2
) =>
  value
    // Remove any characters that are not digits, dots, or commas
    .replace(/[^\d.,]/g, '')
    // Remove leading dots and commas if they are at the beginning of the string
    .replace(/^[.,]+/, '')
    // Replace multiple dots and commas with a single dot
    .replace(/[,.]+/g, '.')
    // Allow only one decimal separator
    .replace(/(?<=\.\d*)\./g, '')
    .replace(/(?<=,\d*),/g, '')
    .replace(new RegExp(`(\\.[0-9]{${digitsAfterComma}})[0-9]+`, 'g'), '$1');

export function isInitialValuesEqualCurrentValues<T>(
  initialValues: T,
  currentValues: T
) {
  return JSON.stringify(initialValues) === JSON.stringify(currentValues);
}

import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Container } from '../common/container.styles';
import {
  CountriesHeader,
  CountriesListContainer,
  CountriesSection,
  CountriesSubHeader,
  CountryCard,
  BackgroundImage,
  CardContainer,
  DeliveryInfoWrapper,
  DeliveryInfoTitle,
  DeliveryInfoList,
  DeliveryInfoItem,
  DeliveryInfoLabel,
  DeliveryInfoValue,
  DeliveryInfoMore,
  SearchPanelWrapper,
  WrapperSearch,
  WrapperFilter,
  FilterChips,
  DeliveryInfoDescription,
  DeliveryInfoWrapperEmpty,
} from './countries-list.styles';
import { FlexContainer } from '../../../common/flex-container/flex-container.styles';
import { ReactComponent as AddressDeliveryIcon } from '../../../assets/main-page/advantages/address-delivery.svg';
import { useStore } from '../../../stores/store';
import countriesListWebp2x from '../../../assets/main-page/countries-list/countries-list@2x.webp';
import countriesList2x from '../../../assets/main-page/countries-list/countries-list@2x.png';
import { ICountriesMarketing } from '../../../models/countries-marketing/countries-marketing';
import InputSearch from '../../../common/input-search/input-search.component';
import {
  filterCountries,
  getCountriesToRender,
  getCountryName,
  getDeliveryInfo,
  getRegionTranslation,
  sortCountries,
} from './countries-list.helpers';

const CountriesList = () => {
  const {
    localizationsStore: { languageCode, selectedCountry },
    marketingStore: {
      fetchCountriesMarketing,
      countriesMarketing,
      countriesRegions,
    },
  } = useStore();

  const { t } = useTranslation();

  const [selectedMarketingCountry, setSelectedMarketingCountry] =
    useState<ICountriesMarketing | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRegion, setSelectedRegion] = useState<string>('All');

  useEffect(() => {
    fetchCountriesMarketing(selectedCountry?.country_code);
  }, [fetchCountriesMarketing, selectedCountry]);

  const sortedCountries = useMemo(
    () => sortCountries(countriesMarketing ?? [], languageCode, selectedRegion),
    [countriesMarketing, languageCode, selectedRegion]
  );

  const filteredCountries = useMemo(
    () => filterCountries(sortedCountries, searchQuery, languageCode),
    [sortedCountries, searchQuery, languageCode]
  );

  const deliveryInfo = useMemo(
    () => getDeliveryInfo(selectedMarketingCountry, t),
    [selectedMarketingCountry, t]
  );

  useEffect(() => {
    setSearchQuery('');
    setSelectedMarketingCountry(null);
  }, [selectedRegion]);

  useEffect(() => {
    if (!searchQuery.trim()) {
      setSelectedMarketingCountry(null);
      return;
    }

    if (filteredCountries.length > 0) {
      setSelectedMarketingCountry(filteredCountries[0]);
      return;
    }

    setSelectedMarketingCountry(null);
  }, [searchQuery, filteredCountries]);

  const regionsWithAll = ['All', ...countriesRegions];

  const countriesToRender = getCountriesToRender(
    filteredCountries,
    selectedMarketingCountry
  );

  return (
    <CountriesSection>
      <Container>
        <CountriesSubHeader>{t('global_delivery')}</CountriesSubHeader>
        <CountriesHeader>{t('delivery_countries')}</CountriesHeader>

        <SearchPanelWrapper>
          <WrapperSearch>
            <InputSearch
              name='search'
              placeholder={t('search_for_country')}
              searchIconPosition='right'
              onChange={(value) => setSearchQuery(value)}
              inputValue={searchQuery}
            />
          </WrapperSearch>

          <WrapperFilter>
            {regionsWithAll.map((region) => (
              <FilterChips
                key={region}
                onClick={() => setSelectedRegion(region)}
                $isSelected={selectedRegion === region}>
                {getRegionTranslation(region, t)}
              </FilterChips>
            ))}
          </WrapperFilter>
        </SearchPanelWrapper>

        <CountriesListContainer>
          <CardContainer>
            {countriesToRender.map((country) => (
              <CountryCard
                key={country.code}
                onClick={() => setSelectedMarketingCountry(country)}
                $isSelected={selectedMarketingCountry?.code === country.code}>
                <FlexContainer
                  $gap='16px'
                  as='span'>
                  <img
                    width={34}
                    height={24}
                    decoding='async'
                    loading='lazy'
                    alt={country.code}
                    src={country.flagSrc}
                  />
                  <span>{getCountryName(country, languageCode)}</span>
                </FlexContainer>
              </CountryCard>
            ))}
          </CardContainer>

          {selectedMarketingCountry ? (
            <DeliveryInfoWrapper>
              <DeliveryInfoTitle>
                {t('main_page_delivery_countries_delivery_restrictions')}{' '}
                {getCountryName(selectedMarketingCountry, languageCode)}{' '}
                <img
                  width={24}
                  height={12}
                  decoding='async'
                  loading='lazy'
                  alt={selectedMarketingCountry.code}
                  src={selectedMarketingCountry.flagSrc}
                />
              </DeliveryInfoTitle>
              <DeliveryInfoList>
                {deliveryInfo.map(({ label, value }) => (
                  <DeliveryInfoItem key={label}>
                    <DeliveryInfoLabel>{label}</DeliveryInfoLabel>
                    <DeliveryInfoValue>{value}</DeliveryInfoValue>
                  </DeliveryInfoItem>
                ))}
                {selectedMarketingCountry.moreInformation && (
                  <DeliveryInfoMore
                    href={selectedMarketingCountry.moreInformation}
                    target='_blank'
                    rel='noopener noreferrer'>
                    {t('main_page_delivery_countries_more_info')}
                  </DeliveryInfoMore>
                )}
              </DeliveryInfoList>
            </DeliveryInfoWrapper>
          ) : (
            <DeliveryInfoWrapperEmpty>
              <DeliveryInfoDescription>
                <AddressDeliveryIcon />
                {t('main_page_delivery_details_empty_state')}
              </DeliveryInfoDescription>
            </DeliveryInfoWrapperEmpty>
          )}
        </CountriesListContainer>
      </Container>
      <picture>
        <source
          width={1440}
          height={810}
          srcSet={countriesListWebp2x}
          type='image/webp'
        />
        <BackgroundImage
          width={1440}
          height={810}
          src={countriesList2x}
          decoding='async'
          loading='lazy'
          alt=''
        />
      </picture>
    </CountriesSection>
  );
};

export default observer(CountriesList);

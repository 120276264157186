import styled, { css } from 'styled-components';
import { MarginContainer } from '../base-margin/base-margin.component';

export interface FlexContainerProps {
  children: React.ReactNode;
  $align?: React.CSSProperties['alignItems'];
  $mobAlign?: React.CSSProperties['alignItems'];
  $justify?: React.CSSProperties['justifyContent'];
  $mobJustify?: React.CSSProperties['justifyContent'];
  $direction?: React.CSSProperties['flexDirection'];
  $mobDirection?: React.CSSProperties['flexDirection'];
  $gap?: string;
  $mobGap?: string;
  $width?: string;
  $wrap?: 'wrap' | 'nowrap';
  $color?: React.CSSProperties['color'];
}

export const FlexContainer = styled(MarginContainer)<FlexContainerProps>`
  width: ${({ $width = 'auto' }) => $width};
  display: flex;
  align-items: ${({ $align = 'center' }) => $align};
  justify-content: ${({ $justify = 'space-between' }) => $justify};
  flex-direction: ${({ $direction = 'row' }) => $direction};
  flex-wrap: ${({ $wrap = 'nowrap' }) => $wrap};
  color: ${({ $color = 'inherit' }) => $color};
  ${({ $gap }) =>
    $gap &&
    css`
      gap: ${$gap};
    `};

  @media (max-width: 650px) {
    align-items: ${({ $mobAlign = 'center' }) => $mobAlign};
    align-items: ${({ $mobAlign, $align }) => {
      if ($mobAlign) {
        return $mobAlign;
      }
      return $align ?? 'center';
    }};
    flex-direction: ${({ $mobDirection, $direction }) => {
      if ($mobDirection) {
        return $mobDirection;
      }
      return $direction ?? 'row';
    }};
    justify-content: ${({ $mobJustify, $justify }) => {
      if ($mobJustify) {
        return $mobJustify;
      }
      return $justify ?? 'space-between';
    }};
    ${({ $mobGap }) =>
      $mobGap &&
      css`
        gap: ${$mobGap};
      `};
  }
`;

import Button from '../../../common/button/button.component';
import { useModal } from '../../../common/modal';
import { Modals } from '../../../constants/modals';

interface Props {
  children: React.ReactNode;
  href: string;
}

const SwitchToOldPortalButton = ({ children, href }: Props) => {
  const { openModal, closeModal } = useModal();

  return (
    <Button
      appearance='link'
      hideLinkUnderline
      contentStyle='thin'
      size='small'
      fullWidth
      justifyContent='space-between'
      isExternalLink
      href={href}
      icon={{ glyph: 'chevron-right', position: 'right' }}
      onClick={(e) => {
        e.preventDefault();
        // Cannot use use-navigation-blocker.hook here because we cannot intercept external navigation attempt via useBlocker.
        // OR, I don't know how to do it. Please correct if you know better approach.
        openModal({
          id: Modals.LEAVE_THIS_PAGE,
          name: Modals.LEAVE_THIS_PAGE,
          props: {
            onApprove: () => {
              closeModal(Modals.LEAVE_THIS_PAGE);
            },
            onClose: () => {
              window.location.href = href;
            },
          },
        });
      }}>
      {children}
    </Button>
  );
};

export default SwitchToOldPortalButton;

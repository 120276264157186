export const countryInfoList = [
  {
    countryCode: 'US',
    countryName: 'United States of America',
    flagEmoji: '🇺🇸',
    defaultZIP: '20001',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    flagEmoji: '🇨🇦',
    defaultZIP: 'K2J 0E9',
  },

  {
    countryCode: 'AD',
    countryName: 'Andorra',
    flagEmoji: '🇦🇩',
    defaultZIP: 'AD500',
  },
  {
    countryCode: 'AL',
    countryName: 'Albania',
    flagEmoji: '🇦🇱',
    defaultZIP: '1001',
  },
  {
    countryCode: 'AM',
    countryName: 'Armenia',
    flagEmoji: '🇦🇲',
    defaultZIP: '0001',
  },
  {
    countryCode: 'AT',
    countryName: 'Austria',
    flagEmoji: '🇦🇹',
    defaultZIP: '1010',
  },
  {
    countryCode: 'AZ',
    countryName: 'Azerbaijan',
    flagEmoji: '🇦🇿',
    defaultZIP: 'AZ1000',
  },
  {
    countryCode: 'BA',
    countryName: 'Bosnia and Herzegovina',
    flagEmoji: '🇧🇦',
    defaultZIP: '71000',
  },
  {
    countryCode: 'BE',
    countryName: 'Belgium',
    flagEmoji: '🇧🇪',
    defaultZIP: '1000',
  },
  {
    countryCode: 'BG',
    countryName: 'Bulgaria',
    flagEmoji: '🇧🇬',
    defaultZIP: '1000',
  },
  {
    countryCode: 'BT',
    countryName: 'Bhutan',
    flagEmoji: '🇧🇹',
    defaultZIP: '11001',
  },
  {
    countryCode: 'CH',
    countryName: 'Switzerland',
    flagEmoji: '🇨🇭',
    defaultZIP: '3001',
  },
  {
    countryCode: 'CN',
    countryName: 'China',
    flagEmoji: '🇨🇳',
    defaultZIP: '100000',
  },
  {
    countryCode: 'CY',
    countryName: 'Cyprus',
    flagEmoji: '🇨🇾',
    defaultZIP: '1010',
  },
  {
    countryCode: 'CZ',
    countryName: 'Czechia',
    flagEmoji: '🇨🇿',
    defaultZIP: '110 00',
  },
  {
    countryCode: 'DE',
    countryName: 'Germany',
    flagEmoji: '🇩🇪',
    defaultZIP: '10117',
  },
  {
    countryCode: 'DK',
    countryName: 'Denmark',
    flagEmoji: '🇩🇰',
    defaultZIP: '1050',
  },
  {
    countryCode: 'EE',
    countryName: 'Estonia',
    flagEmoji: '🇪🇪',
    defaultZIP: '10111',
  },
  {
    countryCode: 'EG',
    countryName: 'Egypt',
    flagEmoji: '🇪🇬',
    defaultZIP: '11511',
  },
  {
    countryCode: 'ES',
    countryName: 'Spain',
    flagEmoji: '🇪🇸',
    defaultZIP: '28001',
  },
  {
    countryCode: 'FI',
    countryName: 'Finland',
    flagEmoji: '🇫🇮',
    defaultZIP: '00100',
  },
  {
    countryCode: 'FR',
    countryName: 'France',
    flagEmoji: '🇫🇷',
    defaultZIP: '75001',
  },
  {
    countryCode: 'GB',
    countryName: 'United Kingdom of Great Britain and Northern Ireland',
    flagEmoji: '🇬🇧',
    defaultZIP: 'SW1A 1AA',
  },
  {
    countryCode: 'GE',
    countryName: 'Georgia',
    flagEmoji: '🇬🇪',
    defaultZIP: '0105',
  },
  {
    countryCode: 'GI',
    countryName: 'Gibraltar',
    flagEmoji: '🇬🇮',
    defaultZIP: 'GX11 1AA',
  },
  {
    countryCode: 'GL',
    countryName: 'Greenland',
    flagEmoji: '🇬🇱',
    defaultZIP: '3900',
  },
  {
    countryCode: 'GR',
    countryName: 'Greece',
    flagEmoji: '🇬🇷',
    defaultZIP: '105 57',
  },
  {
    countryCode: 'HR',
    countryName: 'Croatia',
    flagEmoji: '🇭🇷',
    defaultZIP: '10000',
  },
  {
    countryCode: 'HU',
    countryName: 'Hungary',
    flagEmoji: '🇭🇺',
    defaultZIP: '1051',
  },
  {
    countryCode: 'ID',
    countryName: 'Indonesia',
    flagEmoji: '🇮🇩',
    defaultZIP: '10110',
  },
  {
    countryCode: 'IE',
    countryName: 'Ireland',
    flagEmoji: '🇮🇪',
    defaultZIP: 'D01',
  },
  {
    countryCode: 'IL',
    countryName: 'Israel',
    flagEmoji: '🇮🇱',
    defaultZIP: '91000',
  },
  {
    countryCode: 'IN',
    countryName: 'India',
    flagEmoji: '🇮🇳',
    defaultZIP: '110001',
  },
  {
    countryCode: 'IS',
    countryName: 'Iceland',
    flagEmoji: '🇮🇸',
    defaultZIP: '101',
  },
  {
    countryCode: 'IT',
    countryName: 'Italy',
    flagEmoji: '🇮🇹',
    defaultZIP: '00184',
  },
  {
    countryCode: 'JP',
    countryName: 'Japan',
    flagEmoji: '🇯🇵',
    defaultZIP: '100-0001',
  },
  {
    countryCode: 'KG',
    countryName: 'Kyrgyzstan',
    flagEmoji: '🇰🇬',
    defaultZIP: '720001',
  },
  {
    countryCode: 'KH',
    countryName: 'Cambodia',
    flagEmoji: '🇰🇭',
    defaultZIP: '120000',
  },
  {
    countryCode: 'KR',
    countryName: 'Korea (Republic of)',
    flagEmoji: '🇰🇷',
    defaultZIP: '04524',
  },
  {
    countryCode: 'KW',
    countryName: 'Kuwait',
    flagEmoji: '🇰🇼',
    defaultZIP: '15000',
  },
  {
    countryCode: 'KZ',
    countryName: 'Kazakhstan',
    flagEmoji: '🇰🇿',
    defaultZIP: '010000',
  },
  {
    countryCode: 'LA',
    countryName: "Lao People's Democratic Republic",
    flagEmoji: '🇱🇦',
    defaultZIP: '01000',
  },
  {
    countryCode: 'LB',
    countryName: 'Lebanon',
    flagEmoji: '🇱🇧',
    defaultZIP: '1100',
  },
  {
    countryCode: 'LI',
    countryName: 'Liechtenstein',
    flagEmoji: '🇱🇮',
    defaultZIP: '9490',
  },
  {
    countryCode: 'LK',
    countryName: 'Sri Lanka',
    flagEmoji: '🇱🇰',
    defaultZIP: '00100',
  },
  {
    countryCode: 'LT',
    countryName: 'Lithuania',
    flagEmoji: '🇱🇹',
    defaultZIP: 'LT-01100',
  },
  {
    countryCode: 'LU',
    countryName: 'Luxembourg',
    flagEmoji: '🇱🇺',
    defaultZIP: 'L-1116',
  },
  {
    countryCode: 'LV',
    countryName: 'Latvia',
    flagEmoji: '🇱🇻',
    defaultZIP: 'LV-1050',
  },
  {
    countryCode: 'MC',
    countryName: 'Monaco',
    flagEmoji: '🇲🇨',
    defaultZIP: '98000',
  },
  {
    countryCode: 'MD',
    countryName: 'Moldova (Republic of)',
    flagEmoji: '🇲🇩',
    defaultZIP: 'MD-2001',
  },
  {
    countryCode: 'ME',
    countryName: 'Montenegro',
    flagEmoji: '🇲🇪',
    defaultZIP: '81000',
  },
  {
    countryCode: 'MK',
    countryName: 'North Macedonia',
    flagEmoji: '🇲🇰',
    defaultZIP: '1000',
  },
  {
    countryCode: 'MM',
    countryName: 'Myanmar',
    flagEmoji: '🇲🇲',
    defaultZIP: '15011',
  },
  {
    countryCode: 'MN',
    countryName: 'Mongolia',
    flagEmoji: '🇲🇳',
    defaultZIP: '14200',
  },
  {
    countryCode: 'MT',
    countryName: 'Malta',
    flagEmoji: '🇲🇹',
    defaultZIP: 'VLT 1110',
  },
  {
    countryCode: 'MV',
    countryName: 'Maldives',
    flagEmoji: '🇲🇻',
    defaultZIP: '20094',
  },
  {
    countryCode: 'MY',
    countryName: 'Malaysia',
    flagEmoji: '🇲🇾',
    defaultZIP: '50050',
  },
  {
    countryCode: 'NL',
    countryName: 'Netherlands',
    flagEmoji: '🇳🇱',
    defaultZIP: '1012 JS',
  },
  {
    countryCode: 'NO',
    countryName: 'Norway',
    flagEmoji: '🇳🇴',
    defaultZIP: '0151',
  },
  {
    countryCode: 'NP',
    countryName: 'Nepal',
    flagEmoji: '🇳🇵',
    defaultZIP: '44600',
  },
  {
    countryCode: 'PH',
    countryName: 'Philippines',
    flagEmoji: '🇵🇭',
    defaultZIP: '1000',
  },
  {
    countryCode: 'PL',
    countryName: 'Poland',
    flagEmoji: '🇵🇱',
    defaultZIP: '00-001',
  },
  {
    countryCode: 'PT',
    countryName: 'Portugal',
    flagEmoji: '🇵🇹',
    defaultZIP: '1100-148',
  },
  {
    countryCode: 'RO',
    countryName: 'Romania',
    flagEmoji: '🇷🇴',
    defaultZIP: '010011',
  },
  {
    countryCode: 'RS',
    countryName: 'Serbia',
    flagEmoji: '🇷🇸',
    defaultZIP: '11000',
  },
  {
    countryCode: 'SE',
    countryName: 'Sweden',
    flagEmoji: '🇸🇪',
    defaultZIP: '111 20',
  },
  {
    countryCode: 'SG',
    countryName: 'Singapore',
    flagEmoji: '🇸🇬',
    defaultZIP: '049213',
  },
  {
    countryCode: 'SI',
    countryName: 'Slovenia',
    flagEmoji: '🇸🇮',
    defaultZIP: '1000',
  },
  {
    countryCode: 'SK',
    countryName: 'Slovakia',
    flagEmoji: '🇸🇰',
    defaultZIP: '811 01',
  },
  {
    countryCode: 'TH',
    countryName: 'Thailand',
    flagEmoji: '🇹🇭',
    defaultZIP: '10330',
  },
  {
    countryCode: 'TJ',
    countryName: 'Tajikistan',
    flagEmoji: '🇹🇯',
    defaultZIP: '734025',
  },
  {
    countryCode: 'TL',
    countryName: 'Timor-Leste',
    flagEmoji: '🇹🇱',
    defaultZIP: '0101',
  },
  {
    countryCode: 'TR',
    countryName: 'Turkey',
    flagEmoji: '🇹🇷',
    defaultZIP: '06010',
  },
  {
    countryCode: 'TW',
    countryName: 'Taiwan (Province of China)',
    flagEmoji: '🇹🇼',
    defaultZIP: '100',
  },
  {
    countryCode: 'UA',
    countryName: 'Ukraine',
    flagEmoji: '🇺🇦',
    defaultZIP: '01001',
  },
  {
    countryCode: 'UZ',
    countryName: 'Uzbekistan',
    flagEmoji: '🇺🇿',
    defaultZIP: '100000',
  },
  {
    countryCode: 'VN',
    countryName: 'Viet Nam',
    flagEmoji: '🇻🇳',
    defaultZIP: '100000',
  },
];

import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import agent from '../api/agent';
import { MarketingBanners } from '../models/marketingStoreModels';
import { Advantages } from '../models/advantagesModels';
import { Partners } from '../models/PartnersModels';
import { ReactComponent as FastDeliveryIcon } from '../assets/main-page/advantages/fast-delivery.svg';
import { ReactComponent as ConvenientTarrifsIcon } from '../assets/main-page/advantages/convenient-tarrifs.svg';
import { ReactComponent as AddressDeliveryIcon } from '../assets/main-page/advantages/address-delivery.svg';
import { ReactComponent as TrackingParcelIcon } from '../assets/main-page/advantages/tracking-parcel.svg';
import { ReactComponent as BringParcelIcon } from '../assets/main-page/instructions/bring-your-parcel.svg';
import { ReactComponent as CreateParcelIcon } from '../assets/main-page/instructions/create-parcel.svg';
import { ReactComponent as ParcelIcon } from '../assets/main-page/instructions/parcel.svg';
import { ReactComponent as PhoneIcon } from '../assets/main-page/instructions/phone_and_number.svg';
import { ReactComponent as PayForDeliveryIcon } from '../assets/main-page/instructions/pay-for-delivery.svg';
import { ReactComponent as SMSPushIcon } from '../assets/main-page/instructions/sms-push.svg';
import { ReactComponent as EmptyBoxIcon } from '../assets/main-page/instructions/empty-box.svg';
import { ReactComponent as FedexIcon } from '../assets/main-page/partners/fedex.svg';
import { ReactComponent as CanadapostIcon } from '../assets/main-page/partners/canadapost.svg';
import { ReactComponent as CanparIcon } from '../assets/main-page/partners/canpar.svg';
import { ReactComponent as MeestPostIcon } from '../assets/main-page/partners/meest-post.svg';
import { ReactComponent as MeestShoppingIcon } from '../assets/main-page/partners/meest-shopping.svg';
import { ReactComponent as PurolatorIcon } from '../assets/main-page/partners/purolator.svg';
import { ReactComponent as RosanIcon } from '../assets/main-page/partners/rosan.svg';
import { ReactComponent as USPSIcon } from '../assets/main-page/partners/usps.svg';
import { ReactComponent as UPSIcon } from '../assets/main-page/partners/ups.svg';
import newsImageSrc from '../assets/main-page/news/image.jpg';
import { Instructions } from '../models/instructionsModels';
import { INews } from '../models/newsModels';
import { ServicesModel } from '../models/servicesModel';
import { FooterLinks } from '../models/footerLinksModel';
// import { ReactComponent as DeliveryServiceOf } from '../assets/main-page/services/delivery-service.svg';
// import { ReactComponent as DeliveryToUkraine } from '../assets/main-page/services/delivery-to-ukraine.svg';
// import { ReactComponent as DeliveryFromUkraine } from '../assets/main-page/services/delivery-from-ukraine.svg';
import { TopDirections } from '../models/topDirectionsModels';
import { store } from './store';
import { handleError } from '../utils/generic/generic.utils';
import { ICountriesMarketing } from '../models/countries-marketing/countries-marketing';
import { CountriesMarketingAmerica } from '../features/main/countries-list/countries-new-america.config';
import { CountriesMarketingEurope } from '../features/main/countries-list/countries-new-europe.config';

const defaultText = 'main_page_advantages_default_text';

const items = [
  {
    id: 'fast-delivery',
    title: 'main_page_advantages_fast_delivery',
    text: 'main_page_advantages_fast_delivery_excerpt',
    icon: FastDeliveryIcon,
  },
  {
    id: 'tracking-parcel',
    title: 'main_page_advantages_tracking_parcel',
    text: 'main_page_advantages_tracking_parcel_excerpt',
    icon: TrackingParcelIcon,
  },
  {
    id: 'convenient-tarrifs',
    title: 'main_page_advantages_convenient_tariffs',
    text: 'main_page_advantages_convenient_tariffs_excerpt',
    icon: ConvenientTarrifsIcon,
  },
  {
    id: 'address-delivery',
    title: 'main_page_advantages_address_delivery',
    text: 'main_page_advantages_address_delivery_excerpt',
    icon: AddressDeliveryIcon,
  },
];

// strings here are ids to translation
const instructions = {
  send: {
    items: [
      {
        id: 'create-parcel',
        title: 'main_page_how_does_it_work_create',
        icon: CreateParcelIcon,
        description: 'a_parcel_in_the_mobile_app_or_on_the_website',
      },
      {
        id: 'pay-for-delivery',
        title: 'main_page_how_does_it_work_pay',
        icon: PayForDeliveryIcon,
        description: 'main_page_how_it_work_pay_text',
      },
      {
        id: 'pack-the-parcel',
        title: 'main_page_how_does_it_work_pack',
        icon: EmptyBoxIcon,
        description: 'main_page_how_it_work_pack_text',
      },
      {
        id: 'bring-your-parcel',
        title: 'main_page_how_does_it_work_drop_off',
        icon: BringParcelIcon,
        description: 'main_page_how_it_work_dropoff_text',
      },
      {
        id: 'create-now',
        title: '',
        icon: ParcelIcon,
        description: 'main_page_how_it_work_create_text',
      },
    ],
  },
  receive: {
    items: [
      {
        id: 'get-sms-notification',
        title: 'sms_message',
        icon: SMSPushIcon,
        description: 'receive_a_notification_about_parcel',
      },
      {
        id: 'provide-phone-number',
        title: 'provide',
        icon: PhoneIcon,
        description: 'your_phone_number_or_parcel_number',
      },
      {
        id: 'pick-up',
        title: 'pick_up',
        icon: BringParcelIcon,
        description: 'your_parcel_via_qr_code',
      },
    ],
  },
};

const topDirectionsItems = [
  {
    name: 'Ukraine',
    directions: ['Ukraine → Canada', 'Ukraine → USA'],
    directionsUk: ['Україна → Канада', 'Україна → США'],
    directionsRu: ['Украина → Канада', 'Украина → США'],
  },
  {
    name: 'United States of America',
    directions: ['USA → Ukraine', 'USA → Canada'],
    directionsUk: ['США → Україна', 'США → Канада'],
    directionsRu: ['США → Украина', 'США → Канада'],
  },
  {
    name: 'Canada',
    directions: ['Canada → Ukraine'],
    directionsUk: ['Канада → Україна'],
    directionsRu: ['Канада → Украина'],
  },
];

export const PartnersSlides = [
  {
    countryCode: 'UK',
    icons: [RosanIcon, MeestPostIcon, MeestShoppingIcon],
  },
  {
    countryCode: 'US',
    icons: [UPSIcon, USPSIcon, FedexIcon],
  },
  {
    countryCode: 'CA',
    icons: [PurolatorIcon, CanadapostIcon, UPSIcon, CanparIcon, UPSIcon],
  },
  {
    countryCode: 'GB',
    icons: [],
  },
];

const news = [
  {
    id: '1',
    imgSrc: newsImageSrc,
    title: 'Delivery to Ukraine',
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus et libero quis lacus pulvinar vehicula egestas ut lacus. ',
    date: 'dd.mm.yy',
    readingTime: '~Reading time',
    url: '#',
  },
  {
    id: '2',
    imgSrc: newsImageSrc,
    title: 'Delivery to Ukraine',
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus et libero quis lacus pulvinar vehicula egestas ut lacus. ',
    date: 'dd.mm.yy',
    readingTime: '~Reading time',
    url: '#',
  },
  {
    id: '3',
    imgSrc: newsImageSrc,
    title: 'Delivery to Ukraine',
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus et libero quis lacus pulvinar vehicula egestas ut lacus. ',
    date: 'dd.mm.yy',
    readingTime: '~Reading time',
    url: '#',
  },
  {
    id: '4',
    imgSrc: newsImageSrc,
    title: 'Delivery to Ukraine',
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus et libero quis lacus pulvinar vehicula egestas ut lacus. ',
    date: 'dd.mm.yy',
    readingTime: '~Reading time',
    url: '#',
  },
  {
    id: '5',
    imgSrc: newsImageSrc,
    title: 'Delivery to Ukraine',
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus et libero quis lacus pulvinar vehicula egestas ut lacus. ',
    date: 'dd.mm.yy',
    readingTime: '~Reading time',
    url: '#',
  },
];
// const servicesArray = [
//   {
//     title: '1Delivery to Ukraine',
//     text: '1Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus et libero quis lacus pulvinar vehicula egestas ut lacus.',
//     icon: DeliveryToUkraine,
//   },
//   {
//     title: 'Delivery from Ukraine',
//     text: '2Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus et libero quis lacus pulvinar vehicula egestas ut lacus.',
//     icon: DeliveryFromUkraine,
//   },
//   {
//     title: 'Delivery service of goods from online stores in Europe and the USA',
//     text: '3Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus et libero quis lacus pulvinar vehicula egestas ut lacus.',
//     icon: DeliveryServiceOf,
//   },
//   {
//     title: '4Delivery to Ukraine',
//     text: '4Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus et libero quis lacus pulvinar vehicula egestas ut lacus.',
//     icon: DeliveryToUkraine,
//   },
//   {
//     title: '5Delivery from Ukraine',
//     text: '5Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus et libero quis lacus pulvinar vehicula egestas ut lacus.',
//     icon: DeliveryFromUkraine,
//   },
//   {
//     title:
//       '6Delivery service of goods from online stores in Europe and the USA',
//     text: '6Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus et libero quis lacus pulvinar vehicula egestas ut lacus.',
//     icon: DeliveryServiceOf,
//   },
// ];

const commonDownloadLinks = {
  appStore: 'https://apps.apple.com/app/id6480413214',
  playMarket: 'https://play.google.com/store/apps/details?id=com.meest.com',
};

const defaultFooterLinks: FooterLinks = {
  app_store: commonDownloadLinks.appStore,
  play_market: commonDownloadLinks.playMarket,
};

const hardcodedFooterLinks: FooterLinks[] = [
  {
    countryCode: 'us',
    about_us: 'https://us.meest.com/about-us-in-us',
    privacy_policy: 'https://us.meest.com/privacy-policy-us',
    terms_of_use: 'https://us.meest.com/services/service/compliance',
    delivery_rules: 'https://us.meest.com/important-information-compliance',
    international_shipping:
      'https://us.meest.com/services/service/from-us-to-international',
    all_services: 'https://us.meest.com/services',
    customer_support: 'https://us.meest.com/contact-center',
    faq: 'https://us.meest.com/faq',
    facebook: 'https://www.facebook.com/meestusa',
    instagram: 'https://www.instagram.com/meestusa/',
    linkedin: 'https://www.linkedin.com/company/meestmerica/',
    become_agent: 'https://us.meest.com/become-an-agent-us',
    app_store: commonDownloadLinks.appStore,
    play_market: commonDownloadLinks.playMarket,
  },
  {
    ...defaultFooterLinks,
    countryCode: 'ca',
    about_us: 'https://ca.meest.com/about-us-in-canada',
    privacy_policy: 'https://ca.meest.com/privacy-policy-canada',
    terms_of_use:
      'https://www.mymeest.ca/StaticContent/Terms.htm?_gl=1*16q4d83*_gcl_au*MTg5MTExMDgwOC4xNjkxMDY5NDE0&_ga=2.40303015.449870188.1693816429-1297318442.1674658910',
    delivery_rules: 'https://ca.meest.com/conditions-of-carriage',
    international_shipping:
      'https://ca.meest.com/services/service/parcels-delivery-from-canada',
    all_services: 'https://ca.meest.com/services',
    customer_support: 'https://ca.meest.com/contact-center',
    faq: 'https://ca.meest.com/faq',
    facebook: 'https://www.facebook.com/MeestCanada',
    instagram: 'https://www.instagram.com/meestcanada/',
    become_agent: 'https://ca.meest.com/become-an-agent',
    app_store: commonDownloadLinks.appStore,
    play_market: commonDownloadLinks.playMarket,
  },
  {
    ...defaultFooterLinks,
    countryCode: 'ua',
    about_us: 'https://ua.meest.com/pro-kompaniu',
    privacy_policy:
      'https://ua.meest.com/polityka-konfidentsiynosti-meest-ukrayina',
    terms_of_use:
      'https://ua.meest.com/storage/pdf/standartni_umovi_servisu.pdf?_t=1711016159',
    delivery_rules:
      'https://ua.meest.com/storage/images/tariffs/pdf/table/Вимоги%20до%20упакування.pdf?_t=1693487196',
    international_shipping:
      'https://ua.meest.com/services/mizhnarodna-dostavka/mezhdunarodnaya-dostavка',
    all_services: 'https://ua.meest.com/services/international-delivery',
    customer_support: 'https://ua.meest.com/contact-center',
    faq: 'https://ua.meest.com/faq',
    facebook: 'https://www.facebook.com/MeestPoshta',
    instagram: 'https://www.instagram.com/meest_poshta/',
    app_store: commonDownloadLinks.appStore,
    play_market: commonDownloadLinks.playMarket,
  },
  {
    ...defaultFooterLinks,
    countryCode: 'gb',
    about_us: 'https://uk.meest.com/about-us-in-uk',
    terms_of_use:
      'https://meest.com/uploads/elFinder/UK/Terms-and-Condition-12-03-2025.pdf',
    privacy_policy:
      'https://uk.meest.com/storage/pdf/privacy-policy-uk-26022025.pdf',
    international_shipping:
      'https://uk.meest.com/services/international/from-uk-to-international',
    all_services: 'https://uk.meest.com/services',
    customer_support: 'https://uk.meest.com/contact-center',
    faq: 'https://uk.meest.com/faq',
    instagram: 'https://www.instagram.com/meestunitedkingdom/#',
    facebook: 'https://www.facebook.com/meestunitedkingdom',
  },
  { ...defaultFooterLinks },
];

export default class MarketingStore {
  banners: MarketingBanners[] | undefined = undefined;
  advantages: Advantages | undefined = undefined;
  instructions: Instructions | undefined = undefined;
  partners: Partners[] | undefined = undefined;
  topDirections: TopDirections[] | undefined = undefined;
  news: INews[] | undefined = undefined;
  services: ServicesModel[] | undefined = undefined;
  footerLinks: FooterLinks | undefined = undefined;
  countriesMarketing: ICountriesMarketing[] | undefined = undefined;

  isLoadingCountriesMarketing: boolean = false;
  isLoadingBannersApi: boolean = false;

  get countriesRegions(): string[] {
    if (!this.countriesMarketing) return [];

    const uniqueRegions = new Set(
      this.countriesMarketing.map((country) => country.region).filter(Boolean)
    );

    return Array.from(uniqueRegions).sort((a, b) => a.localeCompare(b));
  }

  get countriesWithTermsAndConditions() {
    return ['GB'];
  }

  get countriesWithoutBanners() {
    return ['GB', 'IT'];
  }

  get countriesWithoutCountryList() {
    return ['GB'];
  }

  get countriesWithoutOldPortal() {
    return ['GB'];
  }

  get countriesWithoutPartners() {
    return ['GB'];
  }

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'MarketingStore',
      properties: ['isLoadingBannersApi', 'isLoadingCountriesMarketing'],
      storage: window.localStorage,
    });
  }

  fetchNews = async () => {
    try {
      runInAction(() => {
        // @ts-ignore
        this.news = news;
      });
    } catch (error) {
      handleError(error);
    }
  };

  fetchInstructions = async () => {
    try {
      runInAction(() => {
        // @ts-ignore
        this.instructions = instructions;
      });
      return defaultText;
    } catch (error) {
      handleError(error);
    }
  };

  fetchAdvantages = async () => {
    try {
      runInAction(() => {
        this.advantages = {
          items,
          defaultText,
        };
      });
      return defaultText;
    } catch (error) {
      handleError(error);
    }
  };

  fetchBanners = async () => {
    try {
      this.isLoadingBannersApi = true;

      const { selectedCountry } = store.localizationsStore;
      if (selectedCountry) {
        const banners = await agent.Banners.banners(
          selectedCountry.country_code
        );

        runInAction(() => {
          this.banners = banners;
        });
        return this.banners;
      }
    } catch (error) {
      handleError(error);
    } finally {
      runInAction(() => {
        this.isLoadingBannersApi = false;
      });
    }
  };

  fetchPartners = async () => {
    if (this.partners) return;

    try {
      runInAction(() => {
        this.partners = PartnersSlides;

        return this.partners;
      });
    } catch (error) {
      handleError(error);
    }
  };

  getAllBanners = async () => this.fetchBanners();

  fetchTopDirections = async () => {
    try {
      runInAction(() => {
        this.topDirections = topDirectionsItems;
      });
      return topDirectionsItems;
    } catch (error) {
      handleError(error);
    }
  };

  fetchServices = async () => {
    try {
      this.isLoadingBannersApi = true;
      runInAction(() => {
        // this.services = servicesArray;
        this.services = undefined;
      });
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoadingBannersApi = false;
    }
  };

  getAllServices = async () => this.fetchServices();

  fetchFooterLinks = async (countryCode: string) => {
    try {
      const foundFooterLinks =
        hardcodedFooterLinks.find(
          (i) => i.countryCode === countryCode.toLocaleLowerCase()
        ) || defaultFooterLinks;

      runInAction(() => {
        this.footerLinks = foundFooterLinks;
      });
    } catch (error) {
      handleError(error);
    }
  };

  fetchCountriesMarketing = async (countryCode?: string) => {
    if (!countryCode) return;

    try {
      this.isLoadingCountriesMarketing = true;

      await new Promise((resolve) => {
        setTimeout(resolve, 1000);
      });

      const mockCountriesMarketing: ICountriesMarketing[] =
        countryCode === 'IT'
          ? CountriesMarketingEurope
          : CountriesMarketingAmerica;

      runInAction(() => {
        this.countriesMarketing = mockCountriesMarketing;
      });

      return this.countriesMarketing;
    } catch (error) {
      handleError(error);
    } finally {
      runInAction(() => {
        this.isLoadingCountriesMarketing = false;
      });
    }
  };

  getTrackingUrl = (trackingId?: string) => {
    // Special handling of GB -> UK
    const countryCodeToUse =
      store.localizationsStore.selectedCountry?.country_code === 'GB'
        ? 'uk'
        : store.localizationsStore.selectedCountry?.country_code.toLocaleLowerCase();

    const trackUrl = `https://${countryCodeToUse}.meest.com/parcel-track`;
    return trackingId ? `${trackUrl}?parcel_number=${trackingId}` : trackUrl;
  };
}

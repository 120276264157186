import styled from 'styled-components';
import { SidebarInner } from '../sidebar-right/sidebar.styles';
import { scrollStyles } from '../scroll/scroll.styles';

export const Container = styled(SidebarInner)<{ $hasInfoNode: boolean }>`
  display: grid;
  gap: 1.6rem;
  grid-template-rows: ${({ $hasInfoNode }) =>
    $hasInfoNode ? 'auto auto 1fr' : 'auto 1fr'};
`;

export const Content = styled.div`
  height: 100%;
  overflow: auto;
  ${scrollStyles};
`;

export const Info = styled.div`
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.baseColors.grey50};
  margin-bottom: 1.2rem;
`;

import { ICountriesMarketing } from '../../../models/countries-marketing/countries-marketing';

export const CountriesMarketingEurope: ICountriesMarketing[] = [
  {
    name: {
      en: 'Ukraine',
      uk: 'Україна',
      ru: 'Украина',
    },
    code: 'UA',
    flagSrc: '/download/flags/svg/Ukraine.svg',
    region: 'Europe',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '5',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Poland',
      uk: 'Польща',
      ru: 'Польша',
    },
    code: 'PL',
    flagSrc: '/download/flags/svg/Poland.svg',
    region: 'Europe',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '2',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Czechia',
      uk: 'Чехія',
      ru: 'Чехия',
    },
    code: 'CZ',
    flagSrc: '/download/flags/svg/Czechia.svg',
    region: 'Europe',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '2',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Latvia',
      uk: 'Латвія',
      ru: 'Латвия',
    },
    code: 'LV',
    flagSrc: '/download/flags/svg/Latvia.svg',
    region: 'Europe',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '5',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Lithuania',
      uk: 'Литва',
      ru: 'Литва',
    },
    code: 'LT',
    flagSrc: '/download/flags/svg/Lithuania.svg',
    region: 'Europe',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '5',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Estonia',
      uk: 'Естонія',
      ru: 'Эстония',
    },
    code: 'EE',
    flagSrc: '/download/flags/svg/Estonia.svg',
    region: 'Europe',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '5',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Austria',
      uk: 'Австрія',
      ru: 'Австрия',
    },
    code: 'AT',
    flagSrc: '/download/flags/svg/Austria.svg',
    region: 'Europe',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '2',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Belgium',
      uk: 'Бельгія',
      ru: 'Бельгия',
    },
    code: 'BE',
    flagSrc: '/download/flags/svg/Belgium.svg',
    region: 'Europe',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '3',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Bulgaria',
      uk: 'Болгарія',
      ru: 'Болгария',
    },
    code: 'BG',
    flagSrc: '/download/flags/svg/Bulgaria.svg',
    region: 'Europe',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '4',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Greece',
      uk: 'Греція',
      ru: 'Греция',
    },
    code: 'GR',
    flagSrc: '/download/flags/svg/Greece.svg',
    region: 'Europe',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '5',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Denmark',
      uk: 'Данія',
      ru: 'Дания',
    },
    code: 'DK',
    flagSrc: '/download/flags/svg/Denmark.svg',
    region: 'Europe',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '3',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Ireland',
      uk: 'Ірландія',
      ru: 'Ирландия',
    },
    code: 'IE',
    flagSrc: '/download/flags/svg/Ireland.svg',
    region: 'Europe',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '4',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Cyprus',
      uk: 'Кіпр',
      ru: 'Кипр',
    },
    code: 'CY',
    flagSrc: '/download/flags/svg/Cyprus.svg',
    region: 'Europe',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '5',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Luxembourg',
      uk: 'Люксембург',
      ru: 'Люксембург',
    },
    code: 'LU',
    flagSrc: '/download/flags/svg/Luxembourg.svg',
    region: 'Europe',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '3',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Portugal',
      uk: 'Португалія',
      ru: 'Португалия',
    },
    code: 'PT',
    flagSrc: '/download/flags/svg/Portugal.svg',
    region: 'Europe',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '4',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Romania',
      uk: 'Румунія',
      ru: 'Румыния',
    },
    code: 'RO',
    flagSrc: '/download/flags/svg/Romania.svg',
    region: 'Europe',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '4',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Slovakia',
      uk: 'Словаччина',
      ru: 'Словакия',
    },
    code: 'SK',
    flagSrc: '/download/flags/svg/Slovakia.svg',
    region: 'Europe',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '3',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Slovenia',
      uk: 'Словенія',
      ru: 'Словения',
    },
    code: 'SI',
    flagSrc: '/download/flags/svg/Slovenia.svg',
    region: 'Europe',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '3',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Hungary',
      uk: 'Угорщина',
      ru: 'Венгрия',
    },
    code: 'HU',
    flagSrc: '/download/flags/svg/Hungary.svg',
    region: 'Europe',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '3',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Netherlands',
      uk: 'Нідерланди',
      ru: 'Нидерланды',
    },
    code: 'NL',
    flagSrc: '/download/flags/svg/Netherlands.svg',
    region: 'Europe',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '3',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Finland',
      uk: 'Фінляндія',
      ru: 'Финляндия',
    },
    code: 'FI',
    flagSrc: '/download/flags/svg/Finland.svg',
    region: 'Europe',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '4',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'France',
      uk: 'Франція',
      ru: 'Франция',
    },
    code: 'FR',
    flagSrc: '/download/flags/svg/France.svg',
    region: 'Europe',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '3',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Croatia',
      uk: 'Хорватія',
      ru: 'Хорватия',
    },
    code: 'HR',
    flagSrc: '/download/flags/svg/Croatia.svg',
    region: 'Europe',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '3',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Sweden',
      uk: 'Швеція',
      ru: 'Швеция',
    },
    code: 'SE',
    flagSrc: '/download/flags/svg/Sweden.svg',
    region: 'Europe',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '4',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Germany',
      uk: 'Німеччина',
      ru: 'Германия',
    },
    code: 'DE',
    flagSrc: '/download/flags/svg/Germany.svg',
    region: 'Europe',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '2',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Italy',
      uk: 'Італія',
      ru: 'Италия',
    },
    code: 'IT',
    flagSrc: '/download/flags/svg/Italy.svg',
    region: 'Europe',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '2',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Italy (Venice and islands)',
      uk: 'Італія (Венеція та острови)',
      ru: 'Италия (Венеция и острова)',
    },
    code: 'ITV',
    flagSrc: '/download/flags/svg/Italy.svg',
    region: 'Europe',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '2',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Spain',
      uk: 'Іспанія',
      ru: 'Испания',
    },
    code: 'ES',
    flagSrc: '/download/flags/svg/Spain.svg',
    region: 'Europe',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '3',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Georgia (EMG)',
      uk: 'Грузія (EMG)',
      ru: 'Грузия (EMG)',
    },
    code: 'GE',
    flagSrc: '/download/flags/svg/Georgia.svg',
    region: 'Europe',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '10',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Uzbekistan',
      uk: 'Узбекистан',
      ru: 'Узбекистан',
    },
    code: 'UZ',
    flagSrc: '/download/flags/svg/Uzbekistan.svg',
    region: 'Asia',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '10',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Kyrgyzstan',
      uk: 'Киргизстан',
      ru: 'Кыргызстан',
    },
    code: 'KG',
    flagSrc: '/download/flags/svg/Kyrgyzstan.svg',
    region: 'Asia',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '10',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Kazakhstan',
      uk: 'Казахстан',
      ru: 'Казахстан',
    },
    code: 'KZ',
    flagSrc: '/download/flags/svg/Kazakhstan.svg',
    region: 'Asia',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '10',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Armenia',
      uk: 'Вірменія',
      ru: 'Армения',
    },
    code: 'AM',
    flagSrc: '/download/flags/svg/Armenia.svg',
    region: 'Asia',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '10',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Azerbaijan',
      uk: 'Азербайджан',
      ru: 'Азербайджан',
    },
    code: 'AZ',
    flagSrc: '/download/flags/svg/Azerbaijan.svg',
    region: 'Asia',
    transitTime: {
      car: {
        prefix: 'from_countable',
        value: '10',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'USA',
      uk: 'США',
      ru: 'США',
    },
    code: 'US',
    flagSrc: '/download/flags/svg/United-States-of-America.svg',
    region: 'North America',
    transitTime: {
      air: {
        prefix: 'from_countable',
        value: '7',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
  {
    name: {
      en: 'Canada',
      uk: 'Канада',
      ru: 'Канада',
    },
    code: 'CA',
    flagSrc: '/download/flags/svg/Canada.svg',
    region: 'North America',
    transitTime: {
      air: {
        prefix: 'from_countable',
        value: '7',
        units: 'working days',
      },
    },
    maxWeight: {
      value: '30',
      units: 'kg',
    },
    maxDimensions: {
      min: [
        {
          value: '1',
          units: 'cm',
        },
        {
          value: '0.39',
          units: 'in',
        },
      ],
      max: [
        {
          value: '105',
          units: 'cm',
        },
        {
          value: '41',
          units: 'in',
        },
      ],
    },
    dutyFreeCustomsLimit: '150 EUR',
    insurance:
      'The insurance matches the declared value, up to a maximum of €150.',
  },
];

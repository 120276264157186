import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { ServicesSubSectionHeader } from '../../services/services-subsection/services-subsection.styles';
import {
  PriceValue,
  PriceTitle,
  GridContainer,
} from '../../../routes/parcel-content/parcel-content.styles';
import TaxesSidebar from './taxes-sidebar.component';
import { useStore } from '../../../stores/store';
import { getFormattedPrice } from '../../../utils/price/get-formatted-price';
import { TaxesSectionContainer } from './parcel-taxes-info.styles';
import { Shipment } from '../../../models/parcelDeliveryMiles';
import TaxesInfoItaly from './country-specific/taxes-info.italy.component';

interface Props {
  shipment?: Shipment;
  hideRules?: boolean;
}

const ParcelTaxesInfo = ({ shipment, hideRules }: Props) => {
  const {
    userStore: { getCurrencySymbol },
    parcelCreationStore: { countryDeparture, navigation },
  } = useStore();
  const { t } = useTranslation();

  const isParcelContentStep = navigation.current?.name === 'parcel-content';

  if (countryDeparture?.countryCode === 'IT')
    return <TaxesInfoItaly isContentStep={isParcelContentStep} />;

  const showTaxesSidebar =
    isParcelContentStep &&
    navigation.current?.elements.find((el) => el.name === 'taxes') &&
    !hideRules;

  if (!shipment) return;

  return (
    <TaxesSectionContainer>
      <ServicesSubSectionHeader>{t('taxes')}</ServicesSubSectionHeader>
      <GridContainer>
        {shipment.taxes > 0 && (
          <>
            <PriceTitle>
              {t('create_parcel_summary_taxes_paid_by_sender')}
            </PriceTitle>
            <PriceValue>
              {getFormattedPrice(
                shipment.taxes,
                getCurrencySymbol(shipment?.converted_price?.currency)
              )}
            </PriceValue>
          </>
        )}
        <PriceTitle>
          {t('create_parcel_summary_taxes_paid_by_receiver')}
        </PriceTitle>

        <PriceValue>
          {t('create_parcel_summary_taxes_paid_by_receiver_text')}
        </PriceValue>
      </GridContainer>
      {showTaxesSidebar && <TaxesSidebar />}
    </TaxesSectionContainer>
  );
};

export default observer(ParcelTaxesInfo);

import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper } from 'swiper/react';
import SwiperClass from 'swiper';
import { Container } from '../common/container.styles';
import { Section } from '../common/section.styles';
import {
  PartnersCard,
  PartnersCardImage,
  PartnersCardContainer,
  SwiperSlideCustom,
} from './partners.styles';
import { useStore } from '../../../stores/store';
import SliderArrow from '../../../common/slider/slider-arrow.component';
import { useIsMobile } from '../../../hooks/use-is-mobile.hook';

import 'swiper/css';

type SwiperInstance = SwiperClass;

const Partners = () => {
  const {
    marketingStore: { partners, fetchPartners },
    localizationsStore: { selectedCountry },
  } = useStore();
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const filteredPartners = useMemo(
    () =>
      partners?.find(
        (item) => item.countryCode === selectedCountry?.country_code
      )?.icons || [],
    [partners, selectedCountry]
  );

  const [partnerIconsLength, setPartnerIconsLength] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState<SwiperInstance | null>(
    null
  );

  useEffect(() => {
    setPartnerIconsLength(filteredPartners.length);
  }, [filteredPartners]);

  const isCardsEnough = partnerIconsLength > 4;

  useEffect(() => {
    fetchPartners();
  }, [fetchPartners]);

  useEffect(() => {
    if (swiperInstance) {
      swiperInstance.autoplay?.start();
    }
  }, [selectedCountry, swiperInstance]);

  const prevButtonRef = useRef(null);
  const nextButtonRef = useRef(null);

  const handleSwiperInit = (swiper: SwiperInstance) => {
    setSwiperInstance(swiper);
  };

  if (partnerIconsLength === 0) return null;

  return (
    <Container>
      <Section>
        <PartnersCardContainer>
          <div ref={prevButtonRef}>
            {!isMobile && isCardsEnough && <SliderArrow theme='white' />}
          </div>

          <Swiper
            observer
            observeParents
            spaceBetween={isMobile ? '16px' : '24px'}
            loop
            slidesPerView={!isMobile ? Math.min(partnerIconsLength, 4) : 2}
            navigation={{
              prevEl: prevButtonRef.current,
              nextEl: nextButtonRef.current,
            }}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
              pauseOnMouseEnter: false,
              stopOnLastSlide: false,
            }}
            modules={[Autoplay, Navigation]}
            onSwiper={handleSwiperInit}>
            {filteredPartners.map((Icon, iconIndex) => (
              // eslint-disable-next-line react/no-array-index-key
              <SwiperSlideCustom key={iconIndex}>
                <PartnersCard>
                  <PartnersCardImage>
                    <Icon />
                  </PartnersCardImage>
                </PartnersCard>
              </SwiperSlideCustom>
            ))}
          </Swiper>
          <div ref={nextButtonRef}>
            {!isMobile && isCardsEnough && (
              <SliderArrow
                theme='white'
                direction='right'
              />
            )}
          </div>
        </PartnersCardContainer>
      </Section>
    </Container>
  );
};

export default observer(Partners);

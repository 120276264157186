import React, { KeyboardEventHandler, MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { DefaultTheme } from 'styled-components';
import {
  PrimaryButton,
  SecondaryButton,
  SecondaryFilledButton,
  InvertedDangerButton,
  DangerButton,
  TextButton,
  LinkButton,
  TertiaryButton,
  ButtonSpinner,
  DefaultButton,
  GoogleSigninButton,
  AppleSigninButton,
  TextDangerButton,
  BrandButton,
  PolonezCalculatorButton,
  PolonezClearAllButton,
  PolonezCalculatorLinkButton,
} from './button.styles';
import { MarginProps } from '../base-margin/base-margin.component';
import { PaddingProps } from '../base-padding/base-padding.component';
import { ButtonIconGlyph, getIcon } from './icons';
import {
  ButtonContentStyle,
  ButtonAppearance,
  ButtonIconPosition,
  ButtonSize,
} from './types';

export type ButtonProps = {
  contentStyle?: ButtonContentStyle;
  isLoading?: boolean;
  selectable?: boolean;
  isSelected?: boolean;
  appearance?: ButtonAppearance;
  children?: React.ReactNode;
  tabIndex?: number;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onMouseUp?: MouseEventHandler<HTMLButtonElement>;
  onKeyDown?: KeyboardEventHandler<HTMLButtonElement>;
  id?: string;
  icon?: {
    glyph: ButtonIconGlyph;
    position?: ButtonIconPosition;
  };
  size?: ButtonSize;
  fullWidth?: boolean;
  showBadge?: boolean;
  badgeContent?: string | number;
  href?: string;
  target?: '_blank';
  isExternalLink?: boolean;
  className?: string;
  justifyContent?: string;
  hideLinkUnderline?: boolean;
  theme?: DefaultTheme;
} & MarginProps &
  PaddingProps;

const getButton = (type: ButtonAppearance): typeof PrimaryButton =>
  ({
    primary: PrimaryButton,
    secondary: SecondaryButton,
    brand: BrandButton,
    'secondary-filled': SecondaryFilledButton,
    tertiary: TertiaryButton,
    text: TextButton,
    link: LinkButton,
    'inverted-danger': InvertedDangerButton,
    danger: DangerButton,
    'text-danger': TextDangerButton,
    'polonez-calculator': PolonezCalculatorButton,
    'polonez-clear': PolonezClearAllButton,
    'polonez-calculator-link': PolonezCalculatorLinkButton,
    google: GoogleSigninButton,
    apple: AppleSigninButton,
    default: DefaultButton,
  })[type];

const Button = (
  {
    isExternalLink,
    href,
    target,
    selectable,
    isSelected,
    isLoading,
    appearance = 'primary',
    icon,
    children,
    type = 'button',
    tabIndex,
    disabled,
    onClick,
    onMouseUp,
    onKeyDown,
    contentStyle = 'normal',
    size = 'medium',
    fullWidth = true,
    badgeContent,
    showBadge = false,
    className,
    justifyContent,
    hideLinkUnderline,
    id,
    $mr,
    $ml,
    $mt,
    $mb,
    $pt,
    $pb,
    $pr,
    $pl,
    $mbDesktop,
    $mbMobile,
    theme,
  }: ButtonProps & MarginProps & PaddingProps,
  ref: React.Ref<HTMLButtonElement>
) => {
  const CustomButton = getButton(appearance);
  const iconEl: React.ReactNode | undefined = icon
    ? getIcon(icon.glyph)
    : undefined;
  const iconPosition: ButtonIconPosition = icon?.position || 'left';
  const linkProps = {
    [isExternalLink ? 'href' : 'to']: href,
    as: isExternalLink ? 'a' : Link,
    target,
  };

  const buttonProps = {
    type,
  };

  return (
    <CustomButton
      $hideLinkUnderline={hideLinkUnderline}
      ref={ref}
      theme={theme}
      $showBadge={showBadge}
      $badgeContent={badgeContent}
      $selectable={selectable}
      $isSelected={isSelected}
      $fullWidth={fullWidth}
      $size={size}
      $isLoading={isLoading}
      $appearance={appearance}
      tabIndex={tabIndex}
      disabled={disabled}
      onClick={onClick}
      onMouseUp={onMouseUp}
      onKeyDown={onKeyDown}
      $contentStyle={contentStyle}
      $onlyIcon={!children}
      id={id}
      $mr={$mr}
      $mb={$mb}
      $mt={$mt}
      $ml={$ml}
      $pt={$pt}
      $pb={$pb}
      $pr={$pr}
      $pl={$pl}
      $mbDesktop={$mbDesktop}
      $mbMobile={$mbMobile}
      $justifyContent={justifyContent}
      className={className}
      {...(!href && buttonProps)}
      {...(href && linkProps)}>
      {!isLoading && iconPosition === 'left' && iconEl}
      {isLoading ? <ButtonSpinner /> : children}
      {!isLoading && iconPosition === 'right' && iconEl}
    </CustomButton>
  );
};

export default React.forwardRef(Button);

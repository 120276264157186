import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';
import {
  navigateToNewCountry,
  navigateToNewLanguage,
} from '../utils/uri/uri.utils';
import { getRouteWithParams } from '../common/private-route/private-route.component';

interface Props {
  langCode?: string;
  countryCode?: string;
  withPageReload?: boolean;
}

const useNavigateNewLocale = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();

  const navigateToNewLocale = useCallback(
    ({ langCode, countryCode, withPageReload }: Props) => {
      if (langCode) {
        navigateToNewLanguage(
          navigate,
          getRouteWithParams(location.pathname, searchParams),
          langCode,
          withPageReload
        );
      }
      if (countryCode) {
        navigateToNewCountry(
          navigate,
          getRouteWithParams(location.pathname, searchParams),
          countryCode,
          withPageReload
        );
      }
    },
    [navigate, location.pathname, searchParams] // Dependencies
  );

  return navigateToNewLocale;
};

export default useNavigateNewLocale;

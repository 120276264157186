import styled, { css } from 'styled-components';
import { FlexContainer } from '../../../../../common/flex-container/flex-container.styles';
import {
  BrandButton,
  LinkButton,
} from '../../../../../common/button/button.styles';
import { DurationText } from '../../../../parcel-creation/parcel-delivery-options-tabs/parcel-delivery-options-tabs.styles';

export const Container = styled.div`
  width: 100%;
  padding: 24px 16px;
  border-radius: 0px 0px 2rem 2rem;
  background-color: ${({ theme }) =>
    theme.colors.estimatedDeliveryContainer.backgroundColor};

  @media screen and (min-width: 768px) {
    padding: 12px 24px 24px;
  }
`;

interface TabListContainerProps {
  $isOneTab?: boolean;
  $minWidth?: string;
}

export const TabListContainer = styled(FlexContainer)<TabListContainerProps>`
  ${FlexContainer} {
    justify-content: center;
  }
  > *:last-child {
    flex-shrink: 0;
  }
  & > * {
    width: 100%;
  }
  @media (max-width: 768px) {
    ${({ $isOneTab, $minWidth }) => {
      if (!$isOneTab) {
        return css`
          .react-tabs__tab-list {
            min-width: ${$minWidth || '51rem'};
          }
          overflow-x: scroll;
        `;
      }
      return null;
    }}

    ${FlexContainer} {
      flex-wrap: wrap;
    }
    ${DurationText} {
      margin-top: -0.4rem;
      flex-basis: 100%;
      order: 1;
    }
  }
`;

export const OptionsContainer = styled.div<{ $isOneOption: boolean }>`
  display: grid;
  gap: 1.2rem;

  @media screen and (min-width: 400px) and (max-width: 767px) {
    grid-template-columns: 1fr;
  }

  ${({ $isOneOption }) =>
    $isOneOption &&
    css`
      grid-template-columns: 1fr;
    `}

  @media screen and (max-width: 767px) {
    & > * {
      padding: 1.2rem;
    }
  }

  @media (min-width: 800px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`;
export const TabPanelFlexContainer = styled(FlexContainer)`
  & > * {
    width: 100%;
  }
  .react-tabs__tab-panel {
    overflow: visible;
  }
`;
export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2.4rem;
`;

export const FooterInfoBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  border-radius: 10px;
  background-color: ${({ theme }) =>
    theme.colors.estimatedDeliveryContainer.backgroundColor};

  svg {
    width: 2rem;
    height: 2rem;
    flex-shrink: 0;
    fill: #008cff;
  }

  @media (max-width: 768px) {
    flex: 1 1 auto;
  }
`;

export const FooterInfoText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.8rem;
  width: 100%;
  color: #75777b;
  font-family: ${({ theme }) => theme.fonts.body.fontFamily};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;

  ${LinkButton} {
    padding: 0;
    min-height: 1.6rem;
  }
`;

export const FooterButtons = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  ${BrandButton} {
    height: 6.2rem;
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    ${BrandButton} {
      height: 8rem;
      width: 48rem;
    }
  }
`;

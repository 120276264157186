import { observer } from 'mobx-react-lite';
import { useLayoutEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useStore } from '../../stores/store';
import ParcelDetailsDeparture from '../../features/parcel-creation/parcel-details-departure/parcel-details-departure.component';
import ParcelDetailsDestination from '../../features/parcel-creation/parcel-details-destination/parcel-details-destination.component';
import ParcelDetailsDimensions from '../../features/parcel-creation/parcel-details-dimensions/parcel-details-dimensions.component';
import { SearchParam } from '../../constants/route';
import { useStartParcelCreation } from '../../hooks/use-start-parcel-creation.hook';

const ParcelDetails = () => {
  const {
    parcelCreationStore: { loadShipmentCountries, setCalculatorDataFromUrl },
  } = useStore();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const handleStartParcelCreation = useStartParcelCreation();

  // It should be useLayoutEffect otherwise you get enldess loop error for iframe mode!
  useLayoutEffect(
    () => {
      async function loadData() {
        const dataParam = searchParams.get(SearchParam.DATA);
        const requestSource = searchParams.get(SearchParam.REQUEST_SOURCE);

        if (dataParam) {
          try {
            if (requestSource === 'iframe') {
              handleStartParcelCreation();
            }
            const parsedData = JSON.parse(decodeURIComponent(dataParam));

            const shipmentCountries = await loadShipmentCountries();
            if (!shipmentCountries?.at(0)) return;

            await setCalculatorDataFromUrl(shipmentCountries, parsedData);
            navigate(location.pathname, { replace: true });
          } catch (error) {
            console.error('Failed to decode URI parameter', error);
          }
        } else {
          await loadShipmentCountries(true);
        }
      }
      loadData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // need keys for case when user clicks Create new when still on route step-1 to remount components and clear their states.
  return (
    <>
      <ParcelDetailsDeparture key={`${location.state?.key}dep`} />
      <ParcelDetailsDestination key={`${location.state?.key}dest`} />
      <ParcelDetailsDimensions key={`${location.state?.key}dimen`} />
    </>
  );
};

export default observer(ParcelDetails);

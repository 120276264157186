import { styled } from 'styled-components';
import { FontSizes } from '../typography/typography.styles';

interface LabelProps {
  $type?: 'blue' | 'green' | 'red';
}

export const RibbonLabelContainer = styled.span`
  position: absolute;
  top: -10px;
  left: -12px;
  border-radius: 4px 4px 4px 0px;
`;

export const LabelBody = styled.span<LabelProps>`
  position: relative;
  background-color: ${({ $type }) => {
    if ($type === 'green') return '#47ce72';
    if ($type === 'blue') return '#008CFF';
    return '#ED5576';
  }};
  color: white;
  font-size: ${FontSizes.small};
  font-weight: 600;
  letter-spacing: -0.3px;
  padding: 3px 6px 4px 5px;
  border-radius: 4px 4px 4px 0px;
  text-transform: lowercase;
  display: inline-block;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.2);
`;

export const LabelTailBottom = styled.span<LabelProps>`
  position: absolute;
  bottom: -5px;
  left: -0.2px;
  width: 11.3px;
  height: 5px;
  background-color: ${({ $type }) => {
    if ($type === 'green') return '#39A55B';
    if ($type === 'blue') return '#005FAD';
    return '#BA2243';
  }};
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
`;

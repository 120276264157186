import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  GenericAbortSignal,
} from 'axios';
import { store } from '../stores/store';
import {
  ApiResponse,
  GuestDeliveryOptionsResponseData,
  GuestStoreDeliveryOptionsResponseData,
  HscodeSearchResponseData,
  InsuranceLimitsResponseData,
  LoginResponseData,
  ParcelItemResponseData,
  ParcelPromocodesResponseData,
  ProfileResponseData,
  ProhibitedItemsResponseData,
  PudoSearchResponseData,
  ReceiverCourierResponseData,
  ReceiverPudoResponseData,
  RefreshResponseData,
  RegisterResponseData,
  SenderCourierResponseData,
  SenderPudoResponseData,
  StoreDeliveryOptionsResponseData,
  SubmitSignatureResponseData,
  SummaryResponseData,
  TaxesResponse,
  UpdateParcelInsuranceResponseData,
  ShipmentCountryInfoResponseData,
  SelectedDeliveryOptionsResponseData,
  FeedbackResponseData,
  FeedbackLimitData,
} from '../models/apiResponse';
import { PersonalDataValues } from '../models/personalDataValues';
import { PreferencesDataValues } from '../models/preferencesDataValues';
import { AddressDataValues } from '../models/addressDataValues';
import { router } from '../../Router';
import { DeliveryOptionsRequest } from '../models/shipmentCountryInfo';
import { StoreDeliveryOptionsRequest } from '../models/storeDeliveryOptions';
import { PudoSearch } from '../models/pudoSearchRequest';
import { HsCodeRequest } from '../models/hsCode';
import {
  Shipment as IShipment,
  ParcelListShipment,
} from '../models/parcelDeliveryMiles';
import { AppRoute } from '../constants/route';
import { ApiResponseStatus } from '../constants/server';
import { MarketingBanners } from '../models/marketingStoreModels';
import { CountriesResponse } from '../models/countriesModel';
import { SubmitParcelRequest } from '../models/submitParcelRequest';
import { Phone, Receiver, User as UserModel } from '../models/user';
import { ManualResponse, transformManualResponse } from '../models/manual';
import { PickupWindowsResponseData } from '../models/pickupWindowsModels';
import { generateLocaleRoute } from '../utils/uri/uri.utils';
import {
  IDeliveryLimitationsRequest,
  IDeliveryLimitationsResponse,
  transformDeliveryLimitationsResponse,
} from '../models/parcelDimensions';
import { client, envConfig } from '../config';
import { FeedbackData } from '../models/feedBackModel';
import { StoreCourierRequest } from '../models/recipient-details/store-courier-request';
import { StorePudoSenderRequest } from '../models/recipient-details/store-pudo-sender-request';
import { StorePudoReceiverRequest } from '../models/recipient-details/store-pudo-receiver-request';

export const sleep = (delayMs: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, delayMs);
  });

axios.defaults.baseURL = envConfig.REACT_APP_API_URL ?? '';

axios.interceptors.request.use((config) => {
  const token =
    config.url !== '/refresh'
      ? store.commonStore.meestToken
      : store.commonStore.refreshToken;

  if (token && config) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  config.headers.set('Platform', `web; client: ${client}; app-version: 1.0.0`);

  const langCodeLowerCase =
    store.localizationsStore.selectedLocalization?.code.toLowerCase();
  const countryUpperCase =
    store.localizationsStore.selectedCountry?.country_code.toUpperCase();

  if (langCodeLowerCase) {
    config.headers.set('Language', langCodeLowerCase);
  }

  if (countryUpperCase) {
    config.headers.set('Country', countryUpperCase);
  }

  return config;
});

axios.interceptors.response.use(
  // DO NOT REMOVE. FOR TESTING PURPOSES.
  // async (response) => {
  //   await sleep(2500);
  //   return response;
  // },
  async (response) => response,
  async (error: AxiosError) => {
    // Currently in all cases error is handled and logged in one of the stores in handleError method.

    // Error interception logic may be injected here in future.

    console.error('error', error);

    // in case we received not expected error then default to 500
    const status = (error.response as AxiosResponse)?.status ?? 500;

    switch (status) {
      case ApiResponseStatus.BAD_REQUEST:
        // alert('bad request: ' + data.message);
        break;
      case ApiResponseStatus.UNAUTHORIZED:
        {
          // execute token refresh
          const originalRequest = error.config;

          if (originalRequest?.url !== '/refresh') {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            const { meestToken, refreshToken } = await agent.Auth.refresh();
            store.commonStore.setMeestToken(meestToken!);
            store.commonStore.setRefreshToken(refreshToken!);
            originalRequest!.headers!.Authorization = `Bearer ${meestToken}`;

            return axios(originalRequest!);
          }

          store.userStore.logout({
            omitApiLogout: true,
            shouldRedirectToMainPage: false,
          });
        }
        break;
      case ApiResponseStatus.FORBIDDEN:
        break;
      case ApiResponseStatus.NOT_FOUND:
        break;
      case ApiResponseStatus.VALIDATION_ERROR:
        break;
      case ApiResponseStatus.SERVER_ERROR:
        if (store.commonStore.isOffline) {
          store.commonStore.toastOffline();
        } else {
          if (axios.isCancel(error)) {
            return Promise.reject(error);
          }

          if (!window.location.pathname.includes(AppRoute.PARCEL_CREATE)) {
            router.navigate(generateLocaleRoute(AppRoute.SERVER_ERROR));
          }
        }
        break;
      default:
        break;
    }

    return Promise.reject(error);
  }
);

interface UpdateData {
  field: keyof IShipment;
  value: unknown;
}

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
  get: <T>(url: string, config?: AxiosRequestConfig<any>) =>
    axios.get<T>(url, config).then(responseBody),
  post: <T>(url: string, body?: {}) =>
    axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const getAuthResponse = (authResponse: ApiResponse<LoginResponseData>) => ({
  meestToken: authResponse.data!.token,
  refreshToken: authResponse.data!.refresh_token,
  isPhoneVerified: !!authResponse.data!.phone_verified,
  isEmailVerified: !!authResponse.data!.email_verified,
  isNeedMigrationAgreement: !!authResponse.data!.need_migration_agreement,
});

const Auth = {
  login: ({
    login,
    password,
    newsletters,
    terms_and_conditions,
  }: {
    login: string;
    password: string;
    newsletters?: boolean;
    terms_and_conditions?: boolean;
  }) =>
    requests
      .post<ApiResponse<LoginResponseData>>('/login', {
        login,
        password,
        newsletters,
        terms_and_conditions,
      })
      .then(getAuthResponse),

  socialLogin: ({
    firebase_uid,
    firebase_token,
    device_uuid,
    newsletters,
    terms_and_conditions,
  }: {
    firebase_uid: string;
    firebase_token: string;
    device_uuid: string;
    newsletters?: boolean;
    terms_and_conditions?: boolean;
  }) =>
    requests
      .post<ApiResponse<LoginResponseData>>('/social-login', {
        firebase_uid,
        firebase_token,
        device_uuid,
        newsletters,
        terms_and_conditions,
      })
      .then(getAuthResponse),

  register: (email: string, password: string, newsletters: boolean) =>
    requests
      .post<ApiResponse<RegisterResponseData>>('/register', {
        email,
        password,
        newsletters,
        confirm_password: password,
        terms_and_conditions: true,
      })
      .then((response) => ({
        isEmailSent: response.data?.sent_email,
        isSmsSent: response.data?.sent_sms,
      })),

  logout: () => requests.post<ApiResponse>('/logout', {}),

  refresh: () =>
    requests
      .post<ApiResponse<RefreshResponseData>>('/refresh', {})
      .then((authResponse) => ({
        meestToken: authResponse.data?.token,
        refreshToken: authResponse.data?.refresh_token,
      })),

  deleteAccount: () => requests.post<ApiResponse>('/profile/delete-user'),
};

const User = {
  confirmOnboardingManual: () =>
    requests
      .post<ApiResponse<{ user: UserModel }>>('/user/confirm-manual')
      .then((response) => response.data),
};

const Profile = {
  profile: () =>
    requests
      .get<ApiResponse<ProfileResponseData>>('/profile')
      .then((authResponse) => authResponse.data?.user),
  updatePreferences: (preferences: PreferencesDataValues) =>
    requests
      .post<
        ApiResponse<ProfileResponseData>
      >('/profile/update-user-location', preferences)
      .then((authResponse) => authResponse.data?.user),
  updatePersonalData: (personalData: PersonalDataValues) =>
    requests
      .post<
        ApiResponse<ProfileResponseData>
      >('/profile/update-personal-data', personalData)
      .then((authResponse) => authResponse.data?.user),
  updateAddress: (addressInfo: AddressDataValues) =>
    requests
      .post<
        ApiResponse<ProfileResponseData>
      >('/profile/update-user-address', addressInfo)
      .then((authResponse) => authResponse.data?.user),
  updatePassword: (password: string) =>
    requests
      .post<ApiResponse<ProfileResponseData>>('/profile/update-password', {
        password,
        confirm_password: password,
      })
      .then((authResponse) => authResponse.data?.user),

  verifyPhone: (phoneCountryCode: string, phoneNumber: string, code: string) =>
    requests
      .post<ApiResponse>('/profile/verify-phone', {
        phone_country_code: phoneCountryCode,
        phone: phoneNumber,
        code,
      })
      .then((authResponse) => authResponse.data),
  updatePhone: (phone: Phone | null) =>
    requests.post<ApiResponse>('/profile/update-phone', {
      phone_country_code: phone?.country_code,
      phone: phone?.phone_number,
    }),
  receivers: (query: string) =>
    requests
      .get<ApiResponse<Receiver[]>>(`/receivers?${query}`)
      .then((authResponse) => authResponse),
  migrate: (shouldMigrate: boolean) =>
    requests.post('/profile/migration-response', {
      migrate_user_data: shouldMigrate,
    }),
};

const Shipment = {
  summary: (id: number) =>
    requests
      .get<ApiResponse<SummaryResponseData>>(`/shipment/${id}`)
      .then((response) => ({
        shipment: response.data?.shipment,
        messageBus: response.message_bus,
      })),

  prohibitedItems: (id: number) =>
    requests
      .get<
        ApiResponse<ProhibitedItemsResponseData>
      >(`/shipment/${id}/prohibited-items`)
      .then((response) => response.data?.prohibited_items),

  customsAndDuty: (id: number) =>
    requests.get<ApiResponse>(`shipment/${id}/customs-and-duty`),

  deliveryLimitations: (body: IDeliveryLimitationsRequest) =>
    requests
      .post<
        ApiResponse<IDeliveryLimitationsResponse>
      >(`/shipment/delivery-limitations`, body)
      .then((response) => {
        if (!response.data) {
          response.success = false;
          response.message =
            'Delivery limitations not found for this package origin and destination.';
        }
        if (!response.success) return response;
        return transformDeliveryLimitationsResponse(response.data!);
      }),

  taxes: (shipmentId: number) =>
    requests
      .get<ApiResponse<TaxesResponse>>(`/shipment/${shipmentId}/taxes`)
      .then((response) => response?.data),

  // step 1 preparation
  parcelDetails: () =>
    requests
      .get<
        ApiResponse<ShipmentCountryInfoResponseData>
      >('/shipment/parcel-details')
      .then((response) => response.data?.countries),
  // step 2 preparation
  deliveryOptions: (request: DeliveryOptionsRequest) =>
    requests
      .post<ApiResponse>('/shipment/delivery-options', request)
      .then((response) => ({
        firstMiles: response.data?.first_miles ?? [],
        lastMiles: response.data?.last_miles ?? [],
        staticTimeStrings: response.data?.static_time_strings,
        staticDeliveryTime: response.data?.static_delivery_time,
        staticDeliveryTimeString: response.data?.static_delivery_time_string,
        deliveryTimeName: response.data?.delivery_time_name,
        messageBus: response.message_bus ?? [],
      })),
  // step 3 preparation
  storeDeliveryOptions: (deliveryOptions: StoreDeliveryOptionsRequest) =>
    requests
      .post<
        ApiResponse<StoreDeliveryOptionsResponseData>
      >('/shipment/store-delivery-options', deliveryOptions)
      .then((response) => response.data?.shipment),

  // step 4 preparation (pudo)
  senderPudo: (storePudoRequest: StorePudoSenderRequest, shipmentId: number) =>
    requests
      .post<
        ApiResponse<SenderPudoResponseData>
      >(`/shipment-steps/${shipmentId}/sender-details-pudo`, storePudoRequest)
      .then((response) => response),
  // step 4 preaparation (courier)
  senderCourier: (
    storeCourierRequest: StoreCourierRequest,
    shipmentId: number
  ) =>
    requests
      .post<
        ApiResponse<SenderCourierResponseData>
      >(`/shipment-steps/${shipmentId}/sender-details-courier`, storeCourierRequest)
      .then((response) => response),

  // step 5 preparation (pudo)
  receiverPudo: (
    storePudoRequest: StorePudoReceiverRequest,
    shipmentId: number
  ) =>
    requests
      .post<
        ApiResponse<ReceiverPudoResponseData>
      >(`/shipment-steps/${shipmentId}/receiver-details-pudo`, storePudoRequest)
      .then((response) => response),
  // step 5 preparation (courier)
  receiverCourier: (
    storeCourierRequest: StoreCourierRequest,
    shipmentId: number
  ) =>
    requests
      .post<
        ApiResponse<ReceiverCourierResponseData>
      >(`/shipment-steps/${shipmentId}/receiver-details-courier`, storeCourierRequest)
      .then((response) => response),

  // step 5 parcel content management
  addParcelItem: (item: HsCodeRequest, shipmentId: number) =>
    requests
      .put<
        ApiResponse<ParcelItemResponseData>
      >(`/shipment/${shipmentId}/parcel-content`, item)
      .then((response) => response.data?.shipment),

  parcelContent: (shipmentId: number) =>
    requests
      .post<
        ApiResponse<ParcelItemResponseData>
      >(`/shipment-steps/${shipmentId}/parcel-content`)
      .then((response) => ({
        messageBus: response.message_bus ?? [],
      })),

  updateParcelItem: (item: HsCodeRequest, shipmentId: number) =>
    requests
      .post<
        ApiResponse<ParcelItemResponseData>
      >(`/shipment/${shipmentId}/parcel-content/${item.id}`, item)
      .then((response) => response.data?.shipment),

  deleteParcelItem: (itemId: number, shipmentId: number) =>
    requests
      .del<
        ApiResponse<ParcelItemResponseData>
      >(`/shipment/${shipmentId}/parcel-content/${itemId}`)
      .then((response) => response.data?.shipment),

  updateParcelInsurance: (
    shipmentId: number,
    insurance: { insurance: number }
  ) =>
    requests
      .post<
        ApiResponse<UpdateParcelInsuranceResponseData>
      >(`/shipment/${shipmentId}/insurance`, insurance)
      .then((response) => response.data?.shipment),

  getParcelInsuranceLimits: (shipmentId: number) =>
    requests
      .get<
        ApiResponse<InsuranceLimitsResponseData>
      >(`/shipment/${shipmentId}/insurance-limits`)
      .then((response) => ({
        min_insurance: response.data!.min_insurance,
        max_insurance: response.data!.max_insurance,
        currency: response.data!.currency,
      })),

  submitParcel: (shipmentId: number, request: SubmitParcelRequest) =>
    requests
      .post<ApiResponse>(`/shipment/${shipmentId}/submit`, request)
      .then((response) => response.data),

  submitSignature: (signature: FormData, shipmentId: number) =>
    requests
      .post<
        ApiResponse<SubmitSignatureResponseData>
      >(`/shipment/${shipmentId}/signature`, signature)
      .then((response) => response.data?.shipment),

  fetchSignature: (shipmentId: number) =>
    requests
      .get<Blob>(`/shipment/${shipmentId}/signature`, {
        responseType: 'blob',
      })
      .then((response) => response),

  shipment: (query: string, signal?: GenericAbortSignal) =>
    requests
      .get<ApiResponse<ParcelListShipment[]>>(`/shipment?${query}`, { signal })
      .then((response) => response),

  manual: (shipmentId: number, signal?: GenericAbortSignal) =>
    requests
      .get<
        ApiResponse<ManualResponse>
      >(`/manual/shipment/${shipmentId}`, { signal })
      .then((response) => transformManualResponse(response)),

  confirmManual: (shipmentId: number) =>
    requests.post(`/manual/shipment/${shipmentId}/confirm-manual`),

  onboarding: (signal?: GenericAbortSignal) =>
    requests
      .get<ApiResponse<ManualResponse>>(`/manual/onboarding`, { signal })
      .then((response) => transformManualResponse(response)),

  update: (shipmentId: number, data: UpdateData) =>
    requests
      .post<
        ApiResponse<ParcelListShipment>
      >(`/shipment/${shipmentId}/update`, data)
      .then((response) => response.data),

  payment: (shipmentId: number) =>
    requests
      .post<ApiResponse<{ link: string }>>(`/shipment/${shipmentId}/payment`)
      .then((response) => response.data?.link),

  label: (shipmentId: number) =>
    requests
      .get<Blob>(`/shipment/${shipmentId}/label`, {
        responseType: 'blob',
      })
      .then((response) => response),

  sendVerificationMessage: (phone: Phone | null) =>
    requests.post<ApiResponse>('/shipment/send-verification-message', {
      phone_country_code: phone?.country_code,
      phone: phone?.phone_number,
    }),

  verifyPhone: (phoneCountryCode: string, phoneNumber: string, code: string) =>
    requests
      .post<ApiResponse>('/shipment/verify-phone', {
        phone_country_code: phoneCountryCode,
        phone: phoneNumber,
        code,
      })
      .then((authResponse) => authResponse.data),

  invoice: (shipmentId: number) =>
    requests
      .get<Blob>(`/shipment/${shipmentId}/invoice`, {
        responseType: 'blob',
      })
      .then((response) => response),

  pickupWindows: (shipmentId: number, timezone: string) =>
    requests
      .post<
        ApiResponse<PickupWindowsResponseData>
      >(`/shipment/${shipmentId}/pickup-windows`, { timezone })
      .then((response) => response.data),

  getSelectedDeliveryOptions: (shipmentId: number) =>
    requests
      .get<
        ApiResponse<SelectedDeliveryOptionsResponseData>
      >(`/shipment/${shipmentId}/selected-delivery-options`)
      .then((response) => response.data),

  shipmentSteps: (shipmentId: number) =>
    requests
      .get<ApiResponse>(`/shipment-steps/${shipmentId}`)
      .then((response) => ({
        navigation: response.data?.navigation,
        shipment: response.data?.shipment,
        messageBus: response.message_bus,
      })),
};

const Guest = {
  guestParcelDetails: () =>
    requests
      .get<
        ApiResponse<ShipmentCountryInfoResponseData>
      >('/guest/parcel-details')
      .then((response) => response.data?.countries),

  guestDeliveryLimitations: (body: IDeliveryLimitationsRequest) =>
    requests
      .post<
        ApiResponse<IDeliveryLimitationsResponse>
      >(`/guest/delivery-limitations`, body)
      .then((response) => {
        if (!response.data) {
          response.success = false;
          response.message =
            'Delivery limitations not found for this package origin and destination.';
        }
        if (!response.success) return response;
        return transformDeliveryLimitationsResponse(response.data!);
      }),

  guestDeliveryOptionsV2: (request: DeliveryOptionsRequest) =>
    requests
      .post<
        ApiResponse<GuestDeliveryOptionsResponseData>
      >('/guest/delivery-options', request)
      .then((response) => ({
        firstMiles: response.data?.first_miles ?? [],
        lastMiles: response.data?.last_miles ?? [],
        staticTimeStrings: response.data?.static_time_strings,
        staticDeliveryTime: response.data?.static_delivery_time,
        staticDeliveryTimeString: response.data?.static_delivery_time_string,
        deliveryTimeName: response.data?.delivery_time_name,
        messageBus: response.message_bus ?? [],
      })),

  guestStoreDeliveryOptions: (deliveryOptions: StoreDeliveryOptionsRequest) =>
    requests
      .post<
        ApiResponse<GuestStoreDeliveryOptionsResponseData>
      >('/guest/store-delivery-options', deliveryOptions)
      .then((response) => ({
        shipment: response.data?.shipment,
        tokens: response.data?.tokens,
      })),

  guestSearchPudo: (carrier: string, pudoSearch: PudoSearch) =>
    requests
      .post<PudoSearchResponseData>(`/guest/search/pudo/${carrier}`, pudoSearch)
      .then((response) => response),
};

const OobActions = {
  sendVerificationEmail: (email: string) =>
    requests.post<ApiResponse>('/send-verification-email', { email }),
  verifyEmail: (oobCode: string) =>
    requests.post<ApiResponse>('/verify-email', { oob_code: oobCode }),
  sendResetPasswordEmail: (email: string) =>
    requests.post<ApiResponse>('/reset-password-email', { email }),
  canUpdatePassword: (oobCode: string) =>
    requests.post<ApiResponse>('/can-update-password', { oob_code: oobCode }),
  updatePassword: (oobCode: string, password: string) =>
    requests.post<ApiResponse>('/update-password', {
      oob_code: oobCode,
      password,
      confirm_password: password,
    }),
};

const Search = {
  searchPudo: (carrier: string, pudoSearch: PudoSearch) =>
    requests
      .post<PudoSearchResponseData>(`/search/pudo/${carrier}`, pudoSearch)
      .then((response) => response),
  hscode: (description: string) =>
    requests
      .post<HscodeSearchResponseData>(`/search/hscode`, { description })
      .then((response) => response.data),
};

const Banners = {
  banners: (country: string) =>
    requests
      .get<ApiResponse<MarketingBanners[]>>(`/banners/${country}`)
      .then((response) => response.data),
};

export interface InvoiceDetails {
  type: string;
  description: string;
  value: number;
}

const Countries = {
  countries: () =>
    requests
      .get<ApiResponse<CountriesResponse[]>>('/countries')
      .then((response) => response.data!),
};

const ErrorTracking = {
  trackError: (error: string, info: string | null | undefined) => {
    // eslint-disable-next-line no-console
    console.log('OUR ERROR TRACKING: message ->', error);
    // eslint-disable-next-line no-console
    console.log('OUR ERROR TRACKING: info ->', info);
  },
};

const Promocodes = {
  promocodes: (shipmentId: number, promocode: string) =>
    requests
      .put<
        ApiResponse<ParcelPromocodesResponseData>
      >(`/shipment/${shipmentId}/promocodes`, { promocode })
      .then((response) => response.data?.shipment),
  deletePromocode: (shipmentId: number, promocodeId: number) =>
    requests
      .del<
        ApiResponse<ParcelPromocodesResponseData>
      >(`/shipment/${shipmentId}/promocodes/${promocodeId}`)
      .then((response) => response.data?.shipment),
};

const Feedback = {
  submitFeedback: (feedbackData: FeedbackData) =>
    requests
      .post<ApiResponse<FeedbackResponseData>>('/feedback', feedbackData)
      .then((response) => response.data),

  limitFeedback: () =>
    requests
      .get<ApiResponse<FeedbackLimitData>>('/feedback/limit')
      .then((response) => response.data),
};

const agent = {
  Auth,
  Profile,
  Shipment,
  Search,
  Banners,
  Countries,
  Guest,
  OobActions,
  User,
  ErrorTracking,
  Promocodes,
  Feedback,
};

export default agent;

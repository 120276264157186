import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import { useStore } from '../stores/store';
import { AppRoute, SearchParam } from '../constants/route';
import {
  generateLocaleRoute,
  generateStartParcelCreationRoute,
} from '../utils/uri/uri.utils';

export const useStartParcelCreation = () => {
  const {
    navStore: {
      isLeftSidebarOpened,
      closeAllRightSidebars,
      toggleLeftSidebar,
      setSelectedService,
      setSelectedMenuSections,
      setServicesSidebarVisibility,
    },
    parcelCreationStore: {
      resetAllData,
      prefillDraftParcelData,
      setIsDraftEditing,
      shippingType,
      updateShipmentAndNavigation,
      setPrefillInProgress,
    },
  } = useStore();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const redirectParam = searchParams.get(SearchParam.REDIRECT);
    if (redirectParam) {
      setServicesSidebarVisibility(false);
    }
  }, [searchParams, setServicesSidebarVisibility]);

  const handleStartParcelCreation = useCallback(
    async (
      url: string = generateStartParcelCreationRoute(),
      shipmentId: number | undefined = undefined
    ) => {
      resetAllData();

      // Required for draft-editing scenario
      if (shipmentId) {
        setIsDraftEditing(true);
        setPrefillInProgress(true);
        updateShipmentAndNavigation(shipmentId, true).then(() =>
          prefillDraftParcelData()
        );
      }

      if (location.pathname === generateLocaleRoute()) {
        setServicesSidebarVisibility(false);
      }

      if (isLeftSidebarOpened) {
        toggleLeftSidebar();
      }
      closeAllRightSidebars();
      setSelectedService(undefined);
      setSelectedMenuSections([]);

      window.scrollTo({ top: 0, behavior: 'smooth' });

      // TODO: domestic is not supported currently.
      if (shippingType !== 'international') {
        navigate(generateLocaleRoute(AppRoute.COMING_SOON), {
          state: { key: new Date().getTime() },
        });
        return;
      }

      navigate(url, {
        state: { key: new Date().getTime() },
      });
    },
    [
      location,
      setServicesSidebarVisibility,
      setPrefillInProgress,
      prefillDraftParcelData,
      isLeftSidebarOpened,
      closeAllRightSidebars,
      setSelectedService,
      setSelectedMenuSections,
      shippingType,
      toggleLeftSidebar,
      navigate,
      setIsDraftEditing,
      updateShipmentAndNavigation,
      resetAllData,
    ]
  );

  return handleStartParcelCreation;
};

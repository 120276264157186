import { TFunction } from 'i18next';
import WidgetCalculatorForm, {
  FormValues,
} from './widget-calculator-form.component';
import { ParcelTab, ParcelTabContent } from './widget-calculator.styles';
import TrackingForm from './tracking-form.component';
import { ReactComponent as MeestShipmentsIcon } from '../../../assets/shipments-icon.svg';
import { ReactComponent as PolonezShipmentsIcon } from '../../../assets/polonez-shipments-icon.svg';
import { ReactComponent as MagnifyingGlassIcon } from '../../../assets/magnifying-glass-icon.svg';
import { client } from '../../../config';
import { isMeest } from '../../../utils/client/client.utils';

export type WidgetTabs = 'calculator' | 'tracking';

const getIcon = () => {
  const icons = {
    meest: <MeestShipmentsIcon />,
    polonez: <PolonezShipmentsIcon />,
  };
  return icons[client];
};

const isMeestClient = isMeest(client);
export function getTabs(
  activeTab: WidgetTabs,
  setActiveTab: (activeTab: WidgetTabs) => void,
  formData: FormValues | undefined,
  t: TFunction<'translation', undefined>
) {
  const tabs: {
    name: 'calculator' | 'tracking';
    isActive: boolean;
    tab: JSX.Element;
    element: JSX.Element;
  }[] = [
    {
      name: 'calculator',
      get isActive() {
        return activeTab === this.name;
      },
      get tab() {
        return (
          <ParcelTab
            key='calculator'
            $isActive={this.isActive}
            onClick={(e) => {
              e.preventDefault();
              setActiveTab(this.name);
            }}>
            <ParcelTabContent>{getIcon()}</ParcelTabContent>
            <ParcelTabContent
              $fontWeight={isMeestClient ? '700' : '500'}
              $lineHeight={isMeestClient ? '1.6rem' : '2.2rem'}>
              {t('main_page_calculator_ship_tab')}
            </ParcelTabContent>
          </ParcelTab>
        );
      },
      get element() {
        return (
          <WidgetCalculatorForm
            key='calculator'
            defaultValues={formData}
            hidden={!this.isActive}
          />
        );
      },
    },
    {
      name: 'tracking',
      get isActive() {
        return activeTab === this.name;
      },
      get tab() {
        return (
          <ParcelTab
            key='tracking'
            $isActive={this.isActive}
            onClick={(e) => {
              e.preventDefault();
              setActiveTab('tracking');
            }}>
            <ParcelTabContent>
              <MagnifyingGlassIcon />
            </ParcelTabContent>
            <ParcelTabContent>
              {t('main_page_calculator_tracking_tab')}
            </ParcelTabContent>
          </ParcelTab>
        );
      },
      get element() {
        return (
          <TrackingForm
            key='tracking'
            hidden={!this.isActive}
          />
        );
      },
    },
  ];

  return tabs;
}

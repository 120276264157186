import { observer } from 'mobx-react-lite';
import InsuranceFee from '../../features/parcel-creation/insurance-fee/insurance-fee.component';
import ParcelTaxesInfo from '../../features/parcel-creation/parcel-taxes-info/parcel-taxes-info';
import ParcelItemsManagement from '../../features/parcel-creation/parcel-items-management/parcel-items-management.component';
import { useStore } from '../../stores/store';
import { EditFormProvider } from '../../features/parcel-creation/parcel-items-management/edit-form-manager';

const ParcelContent = () => {
  const {
    parcelCreationStore: { shipment, countryDeparture },
  } = useStore();

  return (
    <>
      <EditFormProvider>
        <ParcelItemsManagement />
      </EditFormProvider>
      <InsuranceFee editable={countryDeparture?.countryCode !== 'GB'} />
      <ParcelTaxesInfo shipment={shipment} />
    </>
  );
};

export default observer(ParcelContent);

import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { AxiosError } from 'axios';
import agent from '../api/agent';
import { store } from './store';
import { IDeliveryLimitations } from '../models/parcelDimensions';
import { IShipmentCountry } from './parcelCreationFlowStore';
import {
  DeliveryOptionsRequest,
  ParcelDimensions,
} from '../models/shipmentCountryInfo';

import {
  DeliveryMethod,
  FirstMile,
  LastMile,
  MiddleMileMethodWithMaxDuration,
  StaticTimeStrings,
} from '../models/parcelDeliveryMiles';
import { getCountryZip } from '../utils/countries/countries.utils';
import { getAvailableMiddleMileMethodsWithMaxDuration } from '../utils/parcel-creation/parcel-creation-utils';
import { handleError } from '../utils/generic/generic.utils';
import { Measure } from '../utils/parcel-measurements.ts/parcel-measurements-utils';

export interface ICalculatorData {
  countryDeparture: {
    countryCode: string;
  };
  countryDestination: {
    countryCode: string;
  };
  parcelDimensions: ParcelDimensions;
  measures?: Measure;
}

export default class WidgetCalculatorStore {
  countryDestination: IShipmentCountry | null = null;

  countryDeparture: IShipmentCountry | null = null;

  deliveryLimitations: IDeliveryLimitations | undefined;

  shipmentCountries: IShipmentCountry[] = [];

  staticDeliveryTimeString?: StaticTimeStrings = undefined;

  isLoadingCountries: boolean = false;

  isLoadingLimitations: boolean = false;

  isCalculating: boolean = false;

  firstMiles: FirstMile[] | null = [];

  lastMiles: LastMile[] | null = [];

  selectedMiddleMileMethod: DeliveryMethod | null = null;

  parcelDimensions: ParcelDimensions | null = null;

  middleMileMethodsWithMaxDuration: MiddleMileMethodWithMaxDuration[] = [];

  showEstimatedDeliveryCost: boolean = false;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.shipmentCountries,
      (shipmentCountries) => {
        if (!this.countryDeparture) return;

        const newCountryDeparture = shipmentCountries.find(
          (country) =>
            country.countryCode === this.countryDeparture!.countryCode
        );
        if (newCountryDeparture) {
          this.countryDeparture = {
            ...this.countryDeparture,
            ...newCountryDeparture,
          };
        }

        if (this.countryDestination !== null && newCountryDeparture) {
          const newCountryDestination =
            newCountryDeparture.destinationCountries.find(
              (country) =>
                country.countryCode === this.countryDestination!.countryCode
            );
          if (newCountryDestination) {
            this.countryDestination = {
              ...this.countryDestination,
              ...newCountryDestination,
            };
          }
        }
      }
    );

    reaction(
      () => this.firstMiles,
      (firstMiles) => {
        runInAction(() => {
          if (firstMiles) {
            this.middleMileMethodsWithMaxDuration =
              getAvailableMiddleMileMethodsWithMaxDuration(firstMiles);
            this.setMiddleMileMethod(
              this.middleMileMethodsWithMaxDuration[0]?.method ?? 'air'
            );
          }
        });
      }
    );
  }

  setShowEstimatedDeliveryCost = (value: boolean) => {
    this.showEstimatedDeliveryCost = value;
  };

  makeShipmentCalculation = async (request: DeliveryOptionsRequest) => {
    this.setIsCalculating(true);
    let success = false;
    try {
      const res = store.userStore.user
        ? await agent.Shipment.deliveryOptions(request)
        : await agent.Guest.guestDeliveryOptionsV2(request);
      const { firstMiles, lastMiles, staticDeliveryTimeString } = res;

      runInAction(() => {
        this.firstMiles = firstMiles;
        this.lastMiles = lastMiles;
        this.staticDeliveryTimeString = staticDeliveryTimeString;

        if (this.firstMiles.length === 0) {
          throw new Error(
            "Info about first miles wasn't obtained from the API!"
          );
        }

        if (this.lastMiles.length === 0) {
          throw new Error(
            "Info about last miles wasn't obtained from the API!"
          );
        }
      });
      success = true;
    } catch (error) {
      handleError(error);
      success = false;
    } finally {
      this.setIsCalculating(false);
    }
    return success;
  };

  findShipmentCountry = (
    countryCode: string,
    countries?: IShipmentCountry[]
  ) => {
    const countriesToSearchIn = countries ?? this.shipmentCountries;

    const foundCountry = countriesToSearchIn?.find(
      (c) => c.countryCode === countryCode
    );

    if (!foundCountry) return null;

    const zipCode = getCountryZip(foundCountry.countryCode);

    if (!zipCode) return null;

    return {
      ...foundCountry,
      zipCode,
    } as IShipmentCountry;
  };

  // Encapsulates the setting of the departure country because the calculator is always tied to the selected locale
  loadShipmentCountriesAndSetDeparture = async (shouldLoad?: boolean) => {
    if (this.shipmentCountries.length === 0 || shouldLoad) {
      this.isLoadingCountries = true;

      const getParcelDetails = store.userStore.user
        ? agent.Shipment.parcelDetails
        : agent.Guest.guestParcelDetails;

      try {
        const countries = (await getParcelDetails()) ?? [];
        runInAction(() => {
          this.shipmentCountries = countries;
        });
      } catch (error) {
        handleError(error);
      } finally {
        runInAction(() => {
          this.isLoadingCountries = false;
        });
      }
    }

    const selectedCountryCode =
      store.localizationsStore.selectedCountry?.country_code;

    if (this.shipmentCountries.length <= 0 || !selectedCountryCode) return;

    const departureCountry = this.findShipmentCountry(selectedCountryCode);

    this.setDepartureCountry(departureCountry ?? null);

    this.setMiddleMileMethod(
      this.middleMileMethodsWithMaxDuration[0]?.method ?? 'air'
    );
  };

  getDeliveryLimitations = async () => {
    if (!this.countryDestination) return;

    runInAction(() => {
      this.isLoadingLimitations = true;
    });

    const getLimitations =
      store.commonStore.isGuestParcelCreation || !store.userStore.user
        ? agent.Guest.guestDeliveryLimitations
        : agent.Shipment.deliveryLimitations;

    try {
      const data = await getLimitations({
        destination_country: this.countryDestination!.countryCode,
        destination_zip: this.countryDestination!.zipCode!,
        departure_country: this.countryDeparture!.countryCode,
        departure_zip: this.countryDeparture!.zipCode!,
      });

      // TODO: can this be deleted??
      if ('success' in data) {
        throw new Error(data.message);
      }

      runInAction(() => {
        this.deliveryLimitations = data;
      });
    } catch (error) {
      runInAction(() => {
        this.deliveryLimitations = undefined;
      });
      if (error instanceof AxiosError && error.response?.data.message) {
        store.commonStore.toastError(error.response?.data.message);
      }
    } finally {
      runInAction(() => {
        this.isLoadingLimitations = false;
      });
    }
  };

  setDepartureCountry = (country: IShipmentCountry | null) => {
    this.countryDeparture = country;
  };

  setDestinationCountry = (country: IShipmentCountry | null) => {
    this.countryDestination = country;
  };

  setMiddleMileMethod = (method: DeliveryMethod) => {
    this.selectedMiddleMileMethod = method;
  };

  setParcelDimensions = (dimensions: ParcelDimensions | null) => {
    this.parcelDimensions = dimensions;
  };

  setIsCalculating = (isCalculating: boolean) => {
    this.isCalculating = isCalculating;
  };

  resetStore = (departureCountry?: IShipmentCountry | null) => {
    if (departureCountry !== undefined) {
      this.countryDeparture = departureCountry;
    }
    this.countryDestination = null;
    this.deliveryLimitations = undefined;
    this.shipmentCountries = [];
    this.isLoadingCountries = false;
    this.isCalculating = false;
    this.firstMiles = null;
    this.lastMiles = null;
    this.selectedMiddleMileMethod = null;
    this.parcelDimensions = null;
    this.middleMileMethodsWithMaxDuration = [];
    this.showEstimatedDeliveryCost = false;
    this.isLoadingLimitations = false;
  };
}

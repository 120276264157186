import { makeAutoObservable, reaction, runInAction, toJS } from 'mobx';
import { NavigateFunction } from 'react-router-dom';
import {
  clearPersistedStore,
  isPersisting,
  makePersistable,
} from 'mobx-persist-store';
import { AxiosError } from 'axios';
import { t } from 'i18next';
import _ from 'lodash';
import {
  DeliveryOptionsRequest,
  ParcelDimensions,
  ShipmentCountryInfo,
} from '../models/shipmentCountryInfo';
import agent from '../api/agent';
import {
  Address,
  CourierPickup,
  DeliveryMethod,
  FirstMile,
  LastMile,
  MiddleMileMethodWithMaxDuration,
  ServiceType,
  Shipment,
  StaticTimeStrings,
  StaticTimeNumbers,
} from '../models/parcelDeliveryMiles';
import {
  calculatePriceDiff,
  calculateSendingOptionPrice,
  getAvailableMiddleMileMethodsWithMaxDuration,
  getCheapestMile,
  getMiddleMileByDeliveryMethod,
  tryGetErrorMessage,
} from '../utils/parcel-creation/parcel-creation-utils';
import { StoreDeliveryOptionsRequest } from '../models/storeDeliveryOptions';
import {
  contactDetailsValidationSchema,
  contactDetailsValidationSchemaWithAdditionalPhone,
} from '../routes/personal-data/contact-details-form.component';
import { PudoFilters, PudoSearch } from '../models/pudoSearchRequest';
import { Geocode, PudoItem, PudoSearchMeta } from '../models/pudoItem';
import {
  getCityByZipCode,
  getRegionByZipCode,
} from '../utils/google-places/google-places.utils';
import { senderPersonalDataValidationSchema } from '../features/parcel-creation/parcel-participant-personal/parcel-participant-personal.component';
import {
  getCurrentTimezoneOffset,
  handleError,
  removeDuplicates,
} from '../utils/generic/generic.utils';
import { store } from './store';
import { HsCode, HsCodeRequest } from '../models/hsCode';
import {
  getCourierAddressValidationSchema,
  UserRole,
} from '../features/parcel-creation/delivery-details-form/delivery-details-form.component';
import { AppRoute } from '../constants/route';
import { SubmitParcelRequest } from '../models/submitParcelRequest';
import { InsuranceLimits } from '../models/insuranceLimits';
import { Phone } from '../models/user';
import { DateInfo } from '../models/pickupWindowsModels';
import { IDeliveryLimitations } from '../models/parcelDimensions';
import { calculateVolumetricWeight } from '../features/parcel-creation/parcel-details-dimensions/dimensionsConfig';
import { ApiResponseStatus } from '../constants/server';
import { ITaxes } from '../features/parcel-creation/parcel-taxes-info/types';
import { ICalculatorData } from './widgetCalculatorStore';
import {
  convertDimensions,
  getMeasureUnits,
} from '../utils/parcel-measurements.ts/parcel-measurements-utils';
import {
  movePointerBack,
  movePointerForward,
  ParcelCreationNavigation,
  Step,
  StepName,
} from '../models/parcelCreationFlowNavigation';
import {
  getSetting,
  getSettingReceiverCountry,
} from '../utils/countries/countries.utils';
import { CountriesResponse } from '../models/countriesModel';
import { MessageBus } from '../models/apiResponse';
import {
  BusMessageHandler,
  handleBusMessages,
} from '../utils/message-bus/message-bus-utils';
import { countryInfoList } from '../assets/data/countryInfo';
import { DEFAULT_COUNTRY } from './localizationsStore';
import { StoreCourierRequest } from '../models/recipient-details/store-courier-request';
import { StorePudoReceiverRequest } from '../models/recipient-details/store-pudo-receiver-request';
import { StorePudoSenderRequest } from '../models/recipient-details/store-pudo-sender-request';
import {
  AddressFields,
  ContactInfoFields,
  CourierPickupFields,
  PersonalDataFields,
  PudoFields,
} from '../models/recipient-details/common-fields';
import { ToastContent } from '../common/toasts/toast-message';

const defaultNavigationMap: Step[] = [
  { name: 'parcel-details', type: '', elements: [] },
  { name: 'delivery-option', type: '', elements: [] },
];

const signatureStep: Step = {
  name: AppRoute.SIGNATURE,
  type: '',
  elements: [],
};

const agentFlowDeliveryInstructions: Step = {
  name: 'delivery-instructions',
  type: '',
  elements: [],
};

const defaultNavigation: ParcelCreationNavigation = {
  map: defaultNavigationMap,
  current: defaultNavigationMap[0],
  next: defaultNavigationMap[1],
  previous: null,
};

export type ShippingType = 'international' | 'domestic';

export type TRecaptchaToken = string | null;

export interface IShipmentCountry extends ShipmentCountryInfo {
  zipCode?: string;
  city?: string;
  region?: string;
  settings?: [];
}

interface MessageHandlers {
  postalCodeChangeHandler: BusMessageHandler;
  rateSchemaChangeHandler: BusMessageHandler;
  promoCodeDeletedHandler: BusMessageHandler;
  messageNotification: BusMessageHandler;
}

interface GetPudosForRoleProps {
  role?: UserRole;
  useCache?: boolean;
  searchString?: string;
}

export default class ParcelCreationFlowStore {
  isParcelCreationInProgress: boolean = false;

  isLoadingShipmentApi: boolean = false;

  isLoadingSearchApi: boolean = false;

  isLoadingTaxes: boolean = false;

  savedSteps: StepName[] = []; // array which defines which steps were already sent to backend

  shipmentCountries: IShipmentCountry[] = [];

  countryDeparture: IShipmentCountry | null = null;

  countryDestination: IShipmentCountry | null = null;

  parcelDimensions: ParcelDimensions | null = null;

  firstMiles: FirstMile[] | null = [];

  lastMiles: LastMile[] | null = [];

  // Ugly pos...This is static localized data about delivery duration for each of the midle miles
  staticTimeStrings: StaticTimeStrings | undefined = undefined;
  staticDeliveryTime: StaticTimeNumbers | undefined = undefined;
  staticDeliveryTimeString: StaticTimeStrings | undefined = undefined;
  deliveryTimeName: StaticTimeStrings | undefined = undefined;

  lastMilesForSelectedFirstMile: LastMile[] = [];

  middleMileMethodsWithMaxDuration: MiddleMileMethodWithMaxDuration[] = [];

  selectedFirstMile: FirstMile | undefined = undefined;

  selectedMiddleMileMethod: DeliveryMethod | null = null;

  selectedLastMile: LastMile | undefined = undefined;

  selectedSenderPudo: PudoItem | undefined = undefined;

  selectedReceiverPudo: PudoItem | undefined = undefined;

  shipment: Shipment | undefined = undefined;

  foundPudoItems: PudoItem[] | undefined = undefined;

  foundPudoMeta: PudoSearchMeta | undefined = undefined;

  preservePudos: boolean = false;

  pudoFilterTimeWeight: PudoFilters | undefined = undefined;

  pudoFilterGeocode: Geocode | undefined = undefined;

  pudoFilterDistance: number | undefined = undefined;

  pudoFilterSearch: string | undefined = undefined;

  summaryConfirmFormValues: boolean = false;

  newsletterSubscriptionAllowed: boolean = false;

  signature: string | undefined = undefined;

  signatureIsPresent: boolean = false;

  insuranceLimits: InsuranceLimits | undefined = undefined;

  taxesFee: number = 0; // currently hardcoded

  currentLocation: Geocode | undefined = undefined;

  navigation: ParcelCreationNavigation = { ...defaultNavigation };

  shippingType: ShippingType = 'international';

  createdParcelPaymentLink: string | undefined = undefined;

  phoneVerificationCode: string | undefined;

  isPhoneCodeValid: boolean = true;

  isGuestPhoneVerified: boolean = false;

  guestRecaptchaToken: TRecaptchaToken = null;

  isNextStepButtonLoading: boolean = false;

  dateInfo: DateInfo[] | undefined = undefined;

  isLoadingApiCallCourier: boolean = false;

  cachedTimezone: string | null = null;

  deliveryLimitations: IDeliveryLimitations | undefined = undefined;

  deliveryLimitationsNotFound: boolean = false;

  shouldShowManual: boolean = false;

  isDeliveryOptionsFailed: boolean = false;

  taxes: ITaxes | undefined = undefined;

  isDraftEditing: boolean = false;

  isPriceChanged: boolean = false;

  isRateSchemaChanged: boolean = false;

  noDeliveryOptionAvailable: boolean = false;

  prefillInProgress: boolean = false;

  isNextButtonClicked: boolean = false;

  isSignButtonClicked: boolean = false;

  omitLimitationsRequest: boolean = false;

  senderAddressInvalid: boolean = false;

  setShippingType = (type: ShippingType) => {
    this.shippingType = type;
  };

  setSenderAddressInvalid = (value: boolean) => {
    this.senderAddressInvalid = value;
  };

  get useAdditionalPhone() {
    const isCountryCodeEqualsUserCountryCode =
      this.countryDeparture?.countryCode.toUpperCase() ===
      store.userStore.user?.phone?.country_code.toUpperCase();

    return (
      !isCountryCodeEqualsUserCountryCode &&
      !store.commonStore.isGuestParcelCreation
    );
  }

  get contactDetailsSchema() {
    return this.useAdditionalPhone
      ? contactDetailsValidationSchemaWithAdditionalPhone
      : contactDetailsValidationSchema;
  }

  get countAppliedFilters() {
    let count = 0;

    if (this.pudoFilterDistance) count += 1;
    if (this.pudoFilterTimeWeight?.weight) count += 1;
    if (this.pudoFilterTimeWeight?.open24_7) count += 1;
    if (this.pudoFilterTimeWeight?.open_now) count += 1;
    if (this.pudoFilterTimeWeight?.open_on_weekends) count += 1;

    return count;
  }

  get totalPrice() {
    let totalPrice = 0;

    if (this.selectedFirstMile && this.selectedMiddleMileMethod) {
      totalPrice += this.sendingOptionPrice;
    }

    if (this.selectedLastMile) {
      totalPrice += this.lastMilePriceDiff;
    }

    if (this.shipment?.insurance_fee) {
      totalPrice += this.shipment.insurance_fee;
    }

    if (this.promocode) {
      totalPrice += this.promocode.discount;
    }

    if (this.shipment?.taxes) {
      totalPrice += this.shipment.taxes;
    }

    return parseFloat(totalPrice.toFixed(2));
  }

  // This is sum of first mile + middle mile + cheapest last mile
  get sendingOptionPrice() {
    const { selectedFirstMile, selectedMiddleMileMethod, lastMiles } = this;

    if (selectedFirstMile && selectedMiddleMileMethod && lastMiles) {
      return calculateSendingOptionPrice(
        selectedFirstMile,
        selectedMiddleMileMethod,
        lastMiles
      );
    }
    return 0;
  }

  // This is a difference between cheapest and selected last mile (e.g. + 10$)
  get lastMilePriceDiff() {
    const { selectedLastMile, lastMiles } = this;

    if (selectedLastMile && lastMiles) {
      return calculatePriceDiff(selectedLastMile, lastMiles);
    }
    return 0;
  }

  // decided not to delete it because in the future they might say to return the counting as it was
  // get totalDuration() {
  //   const { selectedFirstMile, selectedLastMile, selectedMiddleMileMethod } =
  //     this;
  //
  //   if (!selectedFirstMile || !selectedMiddleMileMethod) return 0;
  //
  //   return calculateTotalDuration(
  //     selectedFirstMile,
  //     selectedMiddleMileMethod,
  //     selectedLastMile
  //   );
  // }

  get totalDuration() {
    const { selectedMiddleMileMethod } = this;

    if (!selectedMiddleMileMethod) return '0';

    const apprxDurationForSelectedMiddleMile =
      this.staticDeliveryTimeString?.[selectedMiddleMileMethod];

    if (!apprxDurationForSelectedMiddleMile) return '0';

    return apprxDurationForSelectedMiddleMile;
  }

  get canSkipVerifyPhone() {
    return store.commonStore.isGuestParcelCreation && this.isGuestPhoneVerified;
  }

  get matchedCountry(): CountriesResponse | undefined {
    if (!this.countryDeparture) return undefined;

    const availableCountries =
      store.localizationsStore.availableCountries ?? [];

    return availableCountries.find(
      (country) =>
        country.country_code ===
        store.localizationsStore.selectedCountry?.country_code
    );
  }

  get currentElements() {
    return this.navigation.current?.elements ?? [];
  }

  get isAgentFlowShowMap() {
    const firstMileServiceType: ServiceType = this.selectedFirstMile?.service
      .code as ServiceType;
    const res =
      firstMileServiceType === 'agent' &&
      getSetting(
        this.countryDeparture?.settings ?? [],
        'delivery_options',
        'meest_agent_flow'
      ) === 'show_map';
    return res;
  }

  get isSignatureRequired() {
    return this.navigation.map.some(
      (s) =>
        s.name === 'summary' &&
        s.elements.some(
          (e) => e.name === 'signature' && e.type === 'input-signature'
        )
    );
  }

  get isFinishWithPay() {
    return this.navigation.map.some(
      (s) =>
        s.name === 'summary' &&
        s.elements.some(
          (e) =>
            e.name === 'action-pay-parcel' && e.type === 'action-pay-parcel'
        )
    );
  }

  get promocode() {
    return Array.isArray(this.shipment?.promocodes) &&
      this.shipment.promocodes.length > 0
      ? this.shipment.promocodes[0]
      : undefined;
  }

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'ParcelCreationFlowStore',
      properties: [
        'savedSteps',
        'shipmentCountries',
        'countryDestination',
        'countryDeparture',
        'parcelDimensions',
        'firstMiles',
        'lastMiles',
        'staticTimeStrings',
        'staticDeliveryTimeString',
        'deliveryTimeName',
        'lastMilesForSelectedFirstMile',
        'middleMileMethodsWithMaxDuration',
        'selectedFirstMile',
        'selectedMiddleMileMethod',
        'selectedLastMile',
        'selectedSenderPudo',
        'selectedReceiverPudo',
        'shipment',
        'foundPudoItems',
        'pudoFilterTimeWeight',
        'pudoFilterGeocode',
        'pudoFilterDistance',
        'summaryConfirmFormValues',
        'newsletterSubscriptionAllowed',
        'signature',
        'insuranceLimits',
        'taxesFee',
        'currentLocation',
        'navigation',
        'isGuestPhoneVerified',
        'guestRecaptchaToken',
        'isParcelCreationInProgress',
        'deliveryLimitations',
        'signatureIsPresent',
        'isDeliveryOptionsFailed',
        'isDraftEditing',
        'foundPudoMeta',
        'isPriceChanged',
        'isRateSchemaChanged',
        'noDeliveryOptionAvailable',
        'staticDeliveryTime',
      ],
      storage: window.sessionStorage,
    });

    reaction(
      () => this.shipmentCountries,
      (shipmentCountries) => {
        if (!this.countryDeparture) return;

        const newCountryDeparture = shipmentCountries.find(
          (country) =>
            country.countryCode === this.countryDeparture!.countryCode
        );

        if (newCountryDeparture) {
          Object.assign(this.countryDeparture, toJS(newCountryDeparture));
        }

        if (this.countryDestination !== null && newCountryDeparture) {
          const newCountryDestination =
            newCountryDeparture.destinationCountries.find(
              (country) =>
                country.countryCode === this.countryDestination!.countryCode
            );

          if (newCountryDestination) {
            Object.assign(this.countryDestination, toJS(newCountryDestination));
          }
        }
      }
    );

    reaction(
      () => this.firstMiles,
      (firstMiles) => {
        if (isPersisting(this)) {
          runInAction(() => {
            if (firstMiles) {
              this.middleMileMethodsWithMaxDuration =
                getAvailableMiddleMileMethodsWithMaxDuration(firstMiles);
              this.selectedMiddleMileMethod =
                this.middleMileMethodsWithMaxDuration[0]?.method ?? 'air';
            }
          });
        }
      }
    );

    // For draft editing case when first and last miles are selected before step 2 is reached
    reaction(
      () => this.lastMiles,
      (lastMiles) => {
        if (isPersisting(this)) {
          runInAction(() => {
            if (this.selectedFirstMile && lastMiles) {
              const suitableLastMiles = lastMiles.filter(
                (lm) =>
                  this.selectedFirstMile?.rate_schema_id === lm.rate_schema_id
              );

              this.lastMilesForSelectedFirstMile = suitableLastMiles;
              this.removeStepFromSaved('delivery-option');
              this.clearDateInfo(); // Clear cached dateInfo when the first mile changes
            }
          });
        }
      }
    );

    // 2) When First mile is changed then clear both PUDO's in store
    reaction(
      () => this.selectedFirstMile,
      (selectedFirstMile) => {
        if (isPersisting(this)) {
          runInAction(() => {
            if (selectedFirstMile && this.lastMiles) {
              this.selectedLastMile = undefined;
              const suitableLastMiles = this.lastMiles.filter(
                (lm) => selectedFirstMile.rate_schema_id === lm.rate_schema_id
              );

              this.lastMilesForSelectedFirstMile = suitableLastMiles;
              this.setLastMile(getCheapestMile(suitableLastMiles));
              this.selectedSenderPudo = undefined;
              this.selectedReceiverPudo = undefined;
              this.removeStepFromSaved('delivery-option');
              this.resetNavigationAfterMileChange();
              this.clearDateInfo(); // Clear cached dateInfo when the first mile changes
            }
          });
        }
      }
    );

    // 3) When Last mile is changed then clear only receiver Pudo
    reaction(
      () => this.selectedLastMile,
      (value) => {
        if (isPersisting(this)) {
          runInAction(() => {
            if (value) {
              this.selectedReceiverPudo = undefined;
              this.removeStepFromSaved('delivery-option');
              this.resetNavigationAfterMileChange();
              this.clearDateInfo(); // Clear cached dateInfo when the last mile changes
            }
          });
        }
      }
    );

    //  this reaction is required for cases when pudo is pre-filled by backend
    reaction(
      () => this.shipment?.sender?.carrier.pudo_info,
      () => {
        runInAction(() => {
          if (this.shipment?.sender?.carrier.pudo_info)
            this.setSenderPudo(this.shipment.sender.carrier.pudo_info);
        });
      }
    );
  }

  setIsRateSchemaChanged = (value: boolean) => {
    this.isRateSchemaChanged = value;
  };

  setNoDeliveryOptionAvailable = (value: boolean) => {
    this.noDeliveryOptionAvailable = value;
  };

  setGuestRecaptchaToken = (value: TRecaptchaToken) => {
    this.guestRecaptchaToken = value;
  };

  markStepAsSaved = (stepName: StepName) => {
    if (!this.savedSteps.includes(stepName)) {
      this.savedSteps.push(stepName);
    }
  };

  removeStepFromSaved = (stepName: StepName) => {
    this.savedSteps = this.savedSteps.filter((i) => i !== stepName);
  };

  isStepSaved = (stepName: StepName) => this.savedSteps.includes(stepName);

  getShipmentDetails = async (shipmentId: number) => {
    this.isLoadingShipmentApi = true;

    try {
      const { shipment } = await agent.Shipment.summary(shipmentId);

      runInAction(() => {
        this.shipment = shipment;
      });

      return shipment;
    } catch (error) {
      handleError(error);
    } finally {
      runInAction(() => {
        this.isLoadingShipmentApi = false;
      });
    }
  };

  validateCurrentStep = () => {
    if (!this.navigation.current) return false;

    switch (this.navigation.current.name) {
      case 'parcel-details': {
        const isParcelDimensionsValid =
          this.parcelDimensions?.weight &&
          this.parcelDimensions?.size_x &&
          this.parcelDimensions?.size_y &&
          this.parcelDimensions?.size_z &&
          this.deliveryLimitations &&
          calculateVolumetricWeight({
            width: this.parcelDimensions.size_x,
            height: this.parcelDimensions.size_y,
            length: this.parcelDimensions.size_z,
            measure: store.userStore.measures,
          }) <= this.deliveryLimitations!.maxVolWeight &&
          this.parcelDimensions.weight <= this.deliveryLimitations!.maxWeight &&
          this.parcelDimensions.weight >= this.deliveryLimitations!.minWeight &&
          this.parcelDimensions.size_x <= this.deliveryLimitations!.maxSide &&
          this.parcelDimensions.size_y <= this.deliveryLimitations!.maxSide &&
          this.parcelDimensions.size_z <= this.deliveryLimitations!.maxSide &&
          this.parcelDimensions.size_x >= 0.1 &&
          this.parcelDimensions.size_y >= 0.1 &&
          this.parcelDimensions.size_z >= 0.1;

        const isCountriesValid =
          this.countryDeparture &&
          this.countryDestination &&
          this.countryDeparture.zipCode &&
          (getSettingReceiverCountry(this.countryDeparture) === 'without_zip' ||
            this.countryDestination.zipCode) &&
          this.countryDeparture.countryCode &&
          this.countryDestination.countryCode;
        return !!(
          isParcelDimensionsValid &&
          isCountriesValid &&
          !this.isDeliveryOptionsFailed &&
          !this.isRateSchemaChanged
        );
      }
      case 'delivery-option': {
        const isMileSelectionValid =
          this.selectedFirstMile && this.selectedLastMile;
        return !!isMileSelectionValid;
      }
      case 'sender-details-pudo':
      case 'sender-details-courier': {
        const contactDetails = {
          phone: this.shipment?.sender?.phone?.phone_number,
          email: this.shipment?.sender?.email,
          ...(this.useAdditionalPhone && {
            contact_phone_number:
              this.shipment?.sender?.contact_phone?.phone_number,
          }),
        };

        const isSenderContactValid =
          this.contactDetailsSchema.isValidSync(contactDetails);

        const isSenderPersonalDataValid =
          senderPersonalDataValidationSchema.isValidSync({
            first_name: this.shipment?.sender?.first_name,
            last_name: this.shipment?.sender?.last_name,
          });

        const isPudoValid = !!this.selectedSenderPudo;
        const isCourierValid = getCourierAddressValidationSchema(
          UserRole.sender
        ).isValidSync({
          country: this.shipment?.sender?.address?.country,
          region: this.shipment?.sender?.address?.region,
          city: this.shipment?.sender?.address?.city,
          post_code: this.shipment?.sender?.address?.post_code,
          street: this.shipment?.sender?.address?.street,
          building: this.shipment?.sender?.address?.building,
          section: this.shipment?.sender?.address?.section,
          apartment: this.shipment?.sender?.address?.apartment,
          note: this.shipment?.sender?.address?.note,
          buzz_code: this.shipment?.sender?.address?.buzz_code,
        });

        const isPudoDelivery =
          this.shipment?.sender?.carrier?.delivery_service?.service_type !==
          'courier';

        const senderDataValid = isPudoDelivery
          ? isPudoValid && isCourierValid // check also courier address because of home address
          : isCourierValid;

        return !!(
          isSenderContactValid &&
          isSenderPersonalDataValid &&
          senderDataValid
        );
      }
      case 'receiver-details-pudo':
      case 'receiver-details-courier': {
        const isContactDetailsValid =
          contactDetailsValidationSchema.isValidSync({
            phone: this.shipment?.recipient?.phone?.phone_number,
            email: this.shipment?.recipient?.email,
          });

        const isPersonalDataValid =
          senderPersonalDataValidationSchema.isValidSync({
            first_name: this.shipment?.recipient?.first_name,
            last_name: this.shipment?.recipient?.last_name,
          });

        const isPudoValid = !!this.selectedReceiverPudo;
        const isCourierValid = getCourierAddressValidationSchema(
          UserRole.receiver
        ).isValidSync({
          country: this.shipment?.recipient?.address?.country,
          region: this.shipment?.recipient?.address?.region,
          city: this.shipment?.recipient?.address?.city,
          post_code: this.shipment?.recipient?.address?.post_code,
          street: this.shipment?.recipient?.address?.street,
          building: this.shipment?.recipient?.address?.building,
          section: this.shipment?.recipient?.address?.section,
          apartment: this.shipment?.recipient?.address?.apartment,
          note: this.shipment?.recipient?.address?.note,
          buzz_code: this.shipment?.recipient?.address?.buzz_code,
        });

        return !!(
          isContactDetailsValid &&
          isPersonalDataValid &&
          (isPudoValid || isCourierValid)
        );
      }
      case 'parcel-content': {
        return !!(this.shipment?.items?.length ?? -1 > 0);
      }
      case 'summary': {
        return !!this.summaryConfirmFormValues;
      }
      case 'signature': {
        return !!this.validateSignature();
      }
      case 'verify-phone': {
        const isVerificationCodeValid =
          this.phoneVerificationCode && this.phoneVerificationCode.length === 4;

        return !!isVerificationCodeValid;
      }
      default:
        return true;
    }
  };

  validateSignature = () =>
    this.isSignatureRequired ? this.signatureIsPresent && this.signature : true;

  loadShipmentCountries = async (shouldLoad?: boolean) => {
    if (this.shipmentCountries.length > 0 && !shouldLoad)
      return this.shipmentCountries;

    this.isLoadingShipmentApi = true;
    const getParcelDetails = store.userStore.user
      ? agent.Shipment.parcelDetails
      : agent.Guest.guestParcelDetails;

    try {
      const countries = (await getParcelDetails()) ?? [];
      runInAction(() => {
        this.shipmentCountries = countries;
      });
      return countries;
    } catch (error) {
      handleError(error);
    } finally {
      runInAction(() => {
        this.isLoadingShipmentApi = false;
      });
    }
  };

  getDeliveryLimitations = async () => {
    const receiverCountrySetting = getSettingReceiverCountry(
      this.countryDeparture
    );

    // In cases when delivery-limitations request consumes a lot of time
    // we need to somehow show the user why 'Next step' button is disabled.
    // (reminder: isNextStepButtonLoading controls spinner on 'Next step' button)
    this.isNextStepButtonLoading = true;

    runInAction(() => {
      this.deliveryLimitationsNotFound = false;
    });

    const getLimitations =
      store.commonStore.isGuestParcelCreation || !store.userStore.user
        ? agent.Guest.guestDeliveryLimitations
        : agent.Shipment.deliveryLimitations;

    const isDestinationZipRequired = receiverCountrySetting !== 'without_zip';

    if (
      !this.countryDestination ||
      !this.countryDeparture ||
      !this.countryDeparture?.zipCode ||
      (isDestinationZipRequired && !this.countryDestination?.zipCode)
    ) {
      this.isNextStepButtonLoading = false;
      return;
    }

    try {
      const data = await getLimitations({
        destination_country: this.countryDestination.countryCode,
        destination_zip: this.countryDestination.zipCode,
        departure_country: this.countryDeparture.countryCode,
        departure_zip: this.countryDeparture.zipCode,
      });

      // TODO: can this be deleted??
      if ('success' in data) {
        throw new Error(data.message);
      }

      runInAction(() => {
        this.deliveryLimitations = data;
        this.deliveryLimitationsNotFound = false;
      });
    } catch (error) {
      runInAction(() => {
        this.deliveryLimitations = undefined;
        this.deliveryLimitationsNotFound = true;
      });
    } finally {
      runInAction(() => {
        this.isNextStepButtonLoading = false;
      });
    }
  };

  setIsLoadingShipmentApi = (value: boolean) => {
    this.isLoadingShipmentApi = value;
  };

  setDepartureShipmentCountry = (country: IShipmentCountry | null) => {
    this.countryDeparture = country;
  };

  setDestinationShipmentCountry = (country: IShipmentCountry | null) => {
    this.countryDestination = country;
  };

  setParcelDimensions = (dimensions: ParcelDimensions | null) => {
    this.parcelDimensions = dimensions;
  };

  setMiddleMileMethod = (method: DeliveryMethod) => {
    this.selectedMiddleMileMethod = method;
  };

  setFirstMile = (firstMile: FirstMile | undefined) => {
    this.selectedFirstMile = firstMile;
  };

  setLastMile = (lastMile: LastMile | undefined) => {
    this.selectedLastMile = lastMile;
  };

  resetAllData = () => {
    runInAction(() => {
      this.isParcelCreationInProgress = false;
      this.isLoadingShipmentApi = false;
      this.navigation = { ...defaultNavigation };
      this.savedSteps = [];
      this.countryDeparture = null;
      this.countryDestination = null;
      this.parcelDimensions = null;
      this.firstMiles = null;
      this.lastMiles = null;
      this.staticTimeStrings = undefined;
      this.staticDeliveryTimeString = undefined;
      this.deliveryTimeName = undefined;
      this.lastMilesForSelectedFirstMile = [];
      this.middleMileMethodsWithMaxDuration = [];
      this.selectedFirstMile = undefined;
      this.selectedMiddleMileMethod = null;
      this.selectedLastMile = undefined;
      this.selectedSenderPudo = undefined;
      this.selectedReceiverPudo = undefined;
      this.shipment = undefined;
      this.foundPudoItems = undefined;
      this.foundPudoMeta = undefined;
      this.pudoFilterTimeWeight = undefined;
      this.pudoFilterGeocode = undefined;
      this.signature = undefined;
      this.signatureIsPresent = false;
      this.insuranceLimits = undefined;
      this.isPhoneCodeValid = true;
      this.isGuestPhoneVerified = false;
      this.phoneVerificationCode = undefined;
      this.deliveryLimitations = undefined;
      this.isDraftEditing = false;
      this.currentLocation = undefined;
      this.clearDateInfo();
      this.summaryConfirmFormValues = false;
      this.newsletterSubscriptionAllowed = false;
      this.isNextStepButtonLoading = false;
      this.isNextButtonClicked = false;
      this.isSignButtonClicked = false;
      this.omitLimitationsRequest = false;
      this.senderAddressInvalid = false;
      // this.taxesFee  = 0; // currently hardcoded

      clearPersistedStore(this);
    });
  };

  resetShipmentDataInCaseDeliveryDetailsChanged = () => {
    runInAction(() => {
      this.selectedFirstMile = undefined;
      this.selectedLastMile = undefined;
      this.selectedMiddleMileMethod = null;
      this.selectedSenderPudo = undefined;
      this.selectedReceiverPudo = undefined;
      this.isDeliveryOptionsFailed = false;
      this.clearDateInfo();
      this.savedSteps = [];
      this.resetNavigationAfterMileChange();
      this.isNextButtonClicked = false;
      this.isSignButtonClicked = false;
      // Clear city for US case
      if (
        this.countryDeparture?.countryCode === DEFAULT_COUNTRY.country_code &&
        this.countryDestination
      ) {
        this.countryDestination.city = undefined;
        this.countryDestination.region = undefined;
      }
    });
  };

  findShipmentCountry = (
    countryCode?: string,
    zipCode?: string,
    city?: string,
    departureCountryCode?: string
  ): IShipmentCountry | null => {
    const countries = !departureCountryCode
      ? this.shipmentCountries
      : this.shipmentCountries?.find(
          (c) => c.countryCode === departureCountryCode
        )?.destinationCountries;

    const localizationCountry = countries?.find(
      (c) => c.countryCode === countryCode
    );

    if (!localizationCountry) return null;

    return {
      ...localizationCountry,
      zipCode,
      city,
    };
  };

  setIsDraftEditing = (isDraftEditing: boolean) => {
    this.isDraftEditing = isDraftEditing;
  };

  setPrefillInProgress = (value: boolean) => {
    this.prefillInProgress = value;
  };

  prefillDraftParcelData = async () => {
    if (!this.shipment) return;

    await this.loadShipmentCountries();

    const departureCountry = this.findShipmentCountry(
      this.shipment.sender?.country,
      this.shipment.sender?.address?.post_code,
      this.shipment.sender?.address?.city
    );

    this.setDepartureShipmentCountry(departureCountry);

    const destinationCountry = this.findShipmentCountry(
      this.shipment.recipient?.country,
      this.shipment.recipient?.address?.post_code,
      this.shipment.recipient?.address?.city,
      this.shipment.sender?.country
    );

    this.setDestinationShipmentCountry(destinationCountry);
    const parcelDimensions: ParcelDimensions = {
      size_x: this.shipment.parcel?.size_x ?? 0,
      size_y: this.shipment.parcel?.size_y ?? 0,
      size_z: this.shipment.parcel?.size_z ?? 0,
      weight: this.shipment.parcel?.weight ?? 0,
    };

    this.setParcelDimensions(parcelDimensions);

    await this.getDeliveryLimitations();

    const selectedDeliveryOptions = await this.fetchSelectedDeliveryOptions(
      this.shipment.id
    );

    this.setFirstMile(selectedDeliveryOptions?.first_mile);
    this.setLastMile(selectedDeliveryOptions?.last_mile);

    if (this.shipment.sender?.carrier.pudo_info)
      this.setSenderPudo(this.shipment.sender.carrier.pudo_info);

    if (this.shipment.recipient?.carrier.pudo_info)
      this.setReceiverPudo(this.shipment.recipient.carrier.pudo_info);

    runInAction(() => {
      this.prefillInProgress = false;
    });
  };

  fetchSignature = async () => {
    if (!this.isDraftEditing) return;

    this.isNextStepButtonLoading = true;
    if (!this.shipment?.id) return;

    try {
      const signatureBlob = await agent.Shipment.fetchSignature(
        this.shipment.id
      );
      if (signatureBlob) {
        const signatureDataUrl = URL.createObjectURL(signatureBlob);

        this.setSignature(signatureDataUrl);
      } else if (this.shipment?.signature?.url && !this.signature) {
        this.setSignature(this.shipment.signature.url);
      }

      return signatureBlob;
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error.response?.status === ApiResponseStatus.NOT_FOUND
      ) {
        return;
      }

      handleError(error);
    } finally {
      runInAction(() => {
        this.isNextStepButtonLoading = false;
      });
    }
  };

  createParcel = async () => {
    if (!this.shipment?.id) return;

    try {
      const createdParcel = await agent.Shipment.submitParcel(
        this.shipment.id,
        {
          condition_of_carriage: true,
          correct_information: true,
          information_confirmed: true,
          newsletters: this.newsletterSubscriptionAllowed,
          generate_payment_link: true,
        } as SubmitParcelRequest
      );

      runInAction(() => {
        this.createdParcelPaymentLink = createdParcel?.payment_link;
      });
    } catch (error) {
      handleError(error);
    } finally {
      this.setIsParcelCreationInProgress(false);
    }
  };

  getPaymentLink = () => {
    if (!this.createdParcelPaymentLink) return;

    return this.createdParcelPaymentLink;
  };

  proceedToNextStep = async (navigate: NavigateFunction) => {
    const ignoreSavedStateForSteps: StepName[] = [
      'sender-details-pudo',
      'sender-details-courier',
      'receiver-details-pudo',
      'receiver-details-courier',
      'parcel-content',
      'summary',
      'verify-phone',
    ];

    const { current } = this.navigation;

    if (!current) return;

    this.setIsNextButtonClicked(false);

    // For steps 3, 4, 5 (sender/receiver details, summary) we always store/request data from backend (a candidate for future optimization!).
    // Exception is guest parcel creation where call to step 3 creates new token
    if (
      ignoreSavedStateForSteps.includes(current.name) ||
      !this.isStepSaved(current.name)
    ) {
      switch (current.name) {
        case 'parcel-details':
          await this.obtainDeliveryOptions();
          break;
        case 'delivery-option':
          this.isAgentFlowShowMap
            ? await this.prepareAgentShowMapFlow()
            : await this.storeDeliveryOptions();
          break;
        case 'sender-details-pudo':
        case 'sender-details-courier':
          await this.submitSenderDetails();
          break;
        case 'receiver-details-pudo':
        case 'receiver-details-courier':
          await this.submitReceiverDetails();
          break;
        case 'parcel-content':
          await this.finalizeSummaryStep();
          break;
        case 'verify-phone':
          await this.finalizePhoneVerification();

          if (!this.isPhoneCodeValid) {
            return;
          }

          break;
        default:
          break;
      }

      this.markStepAsSaved(current.name);
    }

    if (this.isRateSchemaChanged) return;
    if (this.noDeliveryOptionAvailable) return;
    if (this.senderAddressInvalid) {
      this.setSenderAddressInvalid(false);
      return;
    }

    const stepToSkip = this.canSkipVerifyPhone ? 'verify-phone' : undefined;
    this.navigation = movePointerForward(this.navigation, stepToSkip);

    if (this.navigation.current) {
      const nextRoute = `${store.localizationsStore.localeParam}/${AppRoute.PORTAL}/${AppRoute.INTERNATIONAL}/${AppRoute.PARCEL_CREATE}/${this.navigation.current.name}`;
      navigate(nextRoute);
    }
  };

  proceedToPrevStep = (navigate: NavigateFunction) => {
    const { current } = this.navigation;
    const preserveData = this.isStepSaved(current!.name);
    const stepToSkip = this.canSkipVerifyPhone ? 'verify-phone' : undefined;
    this.navigation = movePointerBack(this.navigation, stepToSkip);
    this.setSenderAddressInvalid(false);

    if (this.navigation.current)
      navigate(
        `${store.localizationsStore.localeParam}/${AppRoute.PORTAL}/${AppRoute.INTERNATIONAL}/${AppRoute.PARCEL_CREATE}/${this.navigation.current.name}`
      );

    this.setIsNextButtonClicked(false);

    switch (current!.name) {
      case 'delivery-option':
        runInAction(() => {
          if (!preserveData && !isPersisting(this)) {
            this.selectedFirstMile = undefined;
            this.selectedLastMile = undefined;
          }
        });
        break;
      case 'receiver-details-pudo':
      case 'receiver-details-courier':
        if (store.commonStore.isGuestParcelCreation) {
          runInAction(() => {
            this.isPhoneCodeValid = true;
          });
        }
        break;
      default:
        break;
    }
  };

  obtainDeliveryOptions = async () => {
    this.isNextStepButtonLoading = true;
    try {
      const request: DeliveryOptionsRequest = {
        departure_country: this.countryDeparture!.countryCode,
        destination_country: this.countryDestination!.countryCode,
        departure_zip: this.countryDeparture!.zipCode!,
        destination_zip: this.countryDestination!.zipCode!,
        weight: this.parcelDimensions!.weight!,
        size_x: this.parcelDimensions!.size_x,
        size_y: this.parcelDimensions!.size_y,
        size_z: this.parcelDimensions!.size_z,
      };

      const {
        firstMiles,
        lastMiles,
        staticTimeStrings,
        staticDeliveryTime,
        staticDeliveryTimeString,
        deliveryTimeName,
        messageBus,
      } = store.commonStore.isGuestParcelCreation
        ? await agent.Guest.guestDeliveryOptionsV2(request)
        : await agent.Shipment.deliveryOptions(request);

      await this.handleDeliveryOptionsResponse({
        firstMiles,
        lastMiles,
        staticTimeStrings,
        staticDeliveryTime,
        staticDeliveryTimeString,
        deliveryTimeName,
      });

      if (messageBus?.length) {
        this.handleMessages(messageBus, ['rateSchemaChangeHandler']);
      }
      if (this.isRateSchemaChanged) {
        throw new Error(
          t('no_route_for_entered_zip', {
            CapitalZIP: countryInfoList.filter(
              (country) =>
                country.countryCode === this.countryDeparture?.countryCode
            )[0]?.defaultZIP,
          })
        );
      }
    } catch (error) {
      this.isDeliveryOptionsFailed = true;
      handleError(error);
    } finally {
      runInAction(() => {
        this.isNextStepButtonLoading = false;
      });
    }
  };

  setUpAdditionalPhoneIfNeeded() {
    if (!this.shipment?.sender?.contact_phone) {
      this.setSenderAdditionalPhone(
        this.shipment?.sender?.contact_phone?.phone_number ?? ''
      );
      this.setSenderAdditionalPhoneCountryCode(
        this.shipment?.sender?.contact_phone?.country_code ?? ''
      );
    }
  }

  storeDeliveryOptions = async () => {
    this.isNextStepButtonLoading = true;

    const destinationWithoutZip =
      getSettingReceiverCountry(this.countryDeparture) === 'without_zip' &&
      !this.countryDestination?.zipCode;

    try {
      const deliveryOptions: StoreDeliveryOptionsRequest = {
        shipment_id:
          this.shipment?.id && this.shipment.id > 0
            ? this.shipment.id.toString()
            : undefined,
        departure_carrier: this.selectedFirstMile!.carrier.slug,
        departure_service: {
          service_type: this.selectedFirstMile!.service.code as ServiceType,
        },
        destination_carrier: this.selectedLastMile!.carrier.slug,
        destination_service: {
          service_type: this.selectedLastMile!.service.code as ServiceType,
        },
        middle_mile_id: getMiddleMileByDeliveryMethod(
          this.selectedFirstMile!,
          this.selectedMiddleMileMethod!
        )!.id,
        rate_schema_id: this.selectedFirstMile!.rate_schema_id,
        departure_country: this.countryDeparture!.countryCode,
        departure_region: this.countryDeparture!.region!,
        departure_city: this.countryDeparture!.city!,
        departure_zip: this.countryDeparture!.zipCode!,
        destination_country: this.countryDestination!.countryCode,
        destination_region: this.countryDestination!.region!,
        destination_city: this.countryDestination!.city!,
        destination_zip: this.countryDestination!.zipCode!,
        weight: this.parcelDimensions!.weight!,
        size_x: this.parcelDimensions!.size_x,
        size_y: this.parcelDimensions!.size_y,
        size_z: this.parcelDimensions!.size_z,
      };

      let shipment: Shipment | undefined;

      // Call non-guest api also in case guest user navigated back and changed first/last mile
      if (
        !store.commonStore.isGuestParcelCreation ||
        store.commonStore.meestToken
      ) {
        shipment = await agent.Shipment.storeDeliveryOptions(deliveryOptions);
      } else {
        const res =
          await agent.Guest.guestStoreDeliveryOptions(deliveryOptions);

        shipment = res.shipment;
        store.commonStore.setMeestToken(res.tokens?.token ?? null);
        store.commonStore.setRefreshToken(res.tokens?.refresh_token ?? null);
      }

      if (!shipment) {
        throw new Error("Info about shipment wasn't obtained from the API!");
      }

      if (destinationWithoutZip && this.countryDestination) {
        if (!shipment.recipient?.address?.post_code) {
          throw new Error("Destination zip-code wasn't obtained from the API!");
        }

        this.countryDestination.zipCode =
          shipment.recipient?.address?.post_code;

        if (this.countryDestination.zipCode) {
          const city = await getCityByZipCode(
            this.countryDestination.zipCode,
            this.countryDestination.countryCode
          );
          this.countryDestination.city = city;
        }
      }
      await this.updateShipmentAndNavigation(shipment!.id, true);
      this.setUpAdditionalPhoneIfNeeded();
    } catch (error) {
      handleError(error);
    } finally {
      runInAction(() => {
        this.isNextStepButtonLoading = false;
      });
    }
  };

  submitSenderDetails = async () => {
    this.isNextStepButtonLoading = true;

    const {
      personalDataFields,
      contactInfoFields,
      addressFields,
      courierPickupFields,
    } = this.prepareSenderDetails();

    if (addressFields.post_code && this.countryDeparture) {
      this.countryDeparture.zipCode = addressFields.post_code;
    }

    try {
      const isPudoDelivery =
        this.shipment?.sender?.carrier?.delivery_service?.service_type !==
        'courier';

      if (isPudoDelivery && this.selectedSenderPudo) {
        const pudoFields: PudoFields = {
          branch_uuid: this.selectedSenderPudo.id,
          delivery_carrier: this.selectedFirstMile?.carrier.slug ?? '',
        };

        const storePudoRequest: StorePudoSenderRequest = {
          shipment_id: this.shipment!.id,
          ...personalDataFields,
          ...contactInfoFields,
          ...pudoFields,
          ...addressFields,
        };

        const response = await agent.Shipment.senderPudo(
          storePudoRequest,
          this.shipment!.id
        );

        runInAction(() => {
          this.shipment = response.data?.shipment;
        });

        if (response?.message_bus?.length) {
          this.handleMessages(
            response?.message_bus,
            ['postalCodeChangeHandler', 'messageNotification'],
            UserRole.sender
          );
        }
      } else if (this.shipment!.sender?.address?.post_code) {
        // in case courier info (at least zip) was provided
        const storeCourierRequest: StoreCourierRequest = {
          shipment_id: this.shipment!.id,
          ...personalDataFields,
          ...contactInfoFields,
          ...addressFields,
          ...courierPickupFields,
        };

        const response = await agent.Shipment.senderCourier(
          storeCourierRequest,
          this.shipment!.id
        );

        runInAction(() => {
          this.shipment = response.data?.shipment;
        });

        if (response?.message_bus?.length) {
          this.handleMessages(
            response?.message_bus,
            ['postalCodeChangeHandler', 'messageNotification'],
            UserRole.sender
          );
        }
      }
    } catch (error) {
      await handleError(error);
      this.handleUnprocessableAddress(error);
    } finally {
      runInAction(() => {
        this.isNextStepButtonLoading = false;
      });
    }
  };

  private createStorePudoRequest = (
    shipment: Shipment,
    selectedReceiverPudo: PudoItem,
    selectedLastMile: LastMile
  ): StorePudoReceiverRequest => ({
    shipment_id: shipment.id,
    first_name: shipment.recipient!.first_name,
    last_name: shipment.recipient!.last_name,
    email: shipment.recipient!.email,
    phone: shipment.recipient!.phone!.phone_number ?? '',
    phone_country_code: shipment.recipient!.phone!.country_code ?? '',
    branch_uuid: selectedReceiverPudo.id,
    delivery_carrier: selectedLastMile.carrier.slug,
  });

  private createStoreCourierRequest = (
    shipment: Shipment
  ): StoreCourierRequest => ({
    shipment_id: shipment.id,
    first_name: shipment.recipient!.first_name,
    last_name: shipment.recipient!.last_name,
    email: shipment.recipient!.email,
    phone: shipment.recipient!.phone!.phone_number ?? '',
    phone_country_code: shipment.recipient!.phone!.country_code ?? '',
    country: shipment.recipient!.country,
    post_code: shipment.recipient!.address!.post_code ?? '',
    region: shipment.recipient!.address!.region ?? '',
    city: shipment.recipient!.address!.city ?? '',
    street: shipment.recipient!.address!.street ?? '',
    building: shipment.recipient!.address!.building ?? '',
    section: shipment.recipient!.address!.section ?? '',
    apartment: shipment.recipient!.address!.apartment ?? '',
    buzz_code: shipment.recipient!.address!.buzz_code ?? '',
    note: shipment.recipient!.address!.note ?? '',
    courier_pickup: {
      time_to: shipment!.recipient!.courier_pickup?.time_to ?? '',
      date: shipment!.recipient!.courier_pickup?.date ?? '',
      timezone: shipment!.recipient!.courier_pickup?.timezone ?? '',
      time_from: shipment!.recipient!.courier_pickup?.time_from ?? '',
    },
  });

  submitReceiverDetails = async () => {
    this.isNextStepButtonLoading = true;

    try {
      await this.handleReceiverDetails();
    } catch (error) {
      await handleError(error);
      this.handleUnprocessableAddress(error);
    } finally {
      runInAction(() => {
        this.isNextStepButtonLoading = false;
      });
    }
  };

  finalizePhoneVerification = async () => {
    await this.verifyPhone();
    this.setIsGuestPhoneVerified(this.isPhoneCodeValid);
  };

  finalizeSummaryStep = async () => {
    this.isNextStepButtonLoading = true;

    try {
      const { messageBus } = await agent.Shipment.parcelContent(
        this.shipment!.id
      );

      await this.getShipmentDetails(this.shipment!.id);

      if (messageBus)
        return this.handleMessages(messageBus, ['promoCodeDeletedHandler']);
    } catch (error) {
      await handleError(error);
    } finally {
      runInAction(() => {
        this.isNextStepButtonLoading = false;
      });
    }
  };

  private handleStorePudoRequest = async (
    storePudoRequest: StorePudoReceiverRequest
  ) => agent.Shipment.receiverPudo(storePudoRequest, this.shipment!.id);

  private handleStoreCourierRequest = async (
    storeCourierRequest: StoreCourierRequest
  ) => {
    await this.fetchInsuranceLimits();
    return agent.Shipment.receiverCourier(
      storeCourierRequest,
      this.shipment!.id
    );
  };

  updateShipmentAndNavigation = async (
    shipmentId: number,
    updateNavigationFromBackend: boolean = false
  ) => {
    const {
      navigation: newNavigation,
      shipment,
      messageBus,
    } = await agent.Shipment.shipmentSteps(shipmentId);
    runInAction(() => {
      this.shipment = shipment;
      if (updateNavigationFromBackend && newNavigation) {
        // We should merge the map obtained from backend (steps > 2) with default steps for seamless navigation.
        // Cur, prev and next pointers are preserved on purpose.
        // We do not rely on backend here and move the pointer manually when user navigates back and forth.
        this.navigation = {
          ...this.navigation,
          next:
            this.navigation.current?.name === 'delivery-option'
              ? newNavigation.map[0]
              : this.navigation.next,
          map: [...defaultNavigationMap, ...newNavigation.map],
        };
        if (this.isSignatureRequired) {
          this.navigation.map = [...this.navigation.map, signatureStep];
        }
      }

      if (messageBus)
        return this.handleMessages(messageBus, ['promoCodeDeletedHandler']);
    });
  };

  private handleReceiverDetails = async () => {
    if (this.selectedReceiverPudo) {
      const storePudoRequest = this.createStorePudoRequest(
        this.shipment!,
        this.selectedReceiverPudo,
        this.selectedLastMile!
      );
      const response = await this.handleStorePudoRequest(storePudoRequest);

      runInAction(() => {
        this.shipment = response.data?.shipment;
      });

      if (response?.message_bus?.length) {
        this.handleMessages(
          response?.message_bus,
          ['postalCodeChangeHandler'],
          UserRole.receiver
        );
      }
    } else if (this.shipment!.recipient?.address?.street) {
      const storeCourierRequest = this.createStoreCourierRequest(
        this.shipment!
      );

      if (storeCourierRequest.post_code && this.countryDestination) {
        this.countryDestination.zipCode = storeCourierRequest.post_code;
      }

      const response =
        await this.handleStoreCourierRequest(storeCourierRequest);

      runInAction(() => {
        this.shipment = response.data?.shipment;
      });

      if (response?.message_bus?.length) {
        this.handleMessages(
          response?.message_bus,
          ['postalCodeChangeHandler'],
          UserRole.receiver
        );
      }
    }
  };

  setSenderFirstName = (firstName: string) => {
    if (!this.shipment?.sender) return;
    this.shipment.sender.first_name = firstName;
  };

  setSenderLastName = (lastName: string) => {
    if (!this.shipment?.sender) return;
    this.shipment!.sender!.last_name = lastName;
  };

  setSenderPhone = (phone: string) => {
    if (!this.shipment?.sender) return;

    if (!this.shipment.sender.phone) {
      this.shipment!.sender!.phone = { phone_number: null, country_code: null };
    }
    if (this.shipment!.sender!.phone.phone_number !== phone) {
      // In case phone was changed we need to reset isGuestPhoneVerified flag
      this.setIsGuestPhoneVerified(false);
      this.setPhoneVerificationCode(undefined);
    }
    this.shipment!.sender!.phone.phone_number = phone;
  };

  setSenderAdditionalPhone = (phone: string) => {
    if (!this.shipment!.sender!.contact_phone) {
      this.shipment!.sender!.contact_phone = {
        phone_number: null,
        country_code: null,
      };
    }

    this.shipment!.sender!.contact_phone!.phone_number = phone;
  };

  setSenderAdditionalPhoneCountryCode = (countryCode: string) => {
    if (!this.shipment!.sender!.contact_phone) {
      this.shipment!.sender!.contact_phone = {
        phone_number: null,
        country_code: null,
      };
    }
    this.shipment!.sender!.contact_phone!.country_code = countryCode;
  };

  setSenderPhoneCountryCode = (countryCode: string) => {
    if (!this.shipment!.sender!.phone) {
      this.shipment!.sender!.phone = { phone_number: null, country_code: null };
    }
    this.shipment!.sender!.phone.country_code = countryCode;
  };

  setSenderEmail = (email: string) => {
    this.shipment!.sender!.email = email;
  };

  setSenderPudo = (pudo?: PudoItem) => {
    this.selectedSenderPudo = pudo;
  };

  verifyPhone = async () => {
    this.isNextStepButtonLoading = true;
    try {
      await agent.Shipment.verifyPhone(
        this.shipment!.sender!.phone!.country_code!,
        this.shipment!.sender!.phone!.phone_number!,
        this.phoneVerificationCode!
      );
      runInAction(() => {
        this.isPhoneCodeValid = true;
      });
    } catch (error) {
      runInAction(() => {
        this.isPhoneCodeValid = false;
      });
      handleError(error);
    } finally {
      runInAction(() => {
        this.isNextStepButtonLoading = false;
      });
    }
  };

  sendVerificationMessage = async (phone: Phone) => {
    this.isLoadingShipmentApi = true;
    try {
      await agent.Shipment.sendVerificationMessage(phone);

      return true;
    } catch (error) {
      handleError(error);
      return false;
    } finally {
      runInAction(() => {
        this.isLoadingShipmentApi = false;
      });
    }
  };

  setPhoneVerificationCode = (code: string | undefined) => {
    this.phoneVerificationCode = code;
  };

  setReceiverFirstName = (firstName: string) => {
    this.shipment!.recipient!.first_name = firstName;
  };

  setReceiverLastName = (lastName: string) => {
    this.shipment!.recipient!.last_name = lastName;
  };

  setReceiverPhone = (phone: string) => {
    if (!this.shipment!.recipient!.phone) {
      this.shipment!.recipient!.phone = {
        phone_number: null,
        country_code: null,
      };
    }
    this.shipment!.recipient!.phone.phone_number = phone;
  };

  setReceiverPhoneCountryCode = (countryCode: string) => {
    if (!this.shipment!.recipient!.phone) {
      this.shipment!.recipient!.phone = {
        phone_number: null,
        country_code: null,
      };
    }
    this.shipment!.recipient!.phone.country_code = countryCode;
  };

  setReceiverEmail = (email: string) => {
    this.shipment!.recipient!.email = email;
  };

  setReceiverPudo = (pudo?: PudoItem) => {
    this.selectedReceiverPudo = pudo;
  };

  getPudosForRole = async ({ role, useCache }: GetPudosForRoleProps) => {
    if (useCache && this.foundPudoItems)
      return { pudos: this.foundPudoItems, pudosMeta: this.foundPudoMeta };

    const country =
      role === UserRole.sender
        ? this.countryDeparture
        : this.countryDestination;

    const carrier =
      role === UserRole.sender
        ? this.selectedFirstMile?.carrier.slug
        : this.selectedLastMile?.carrier.slug;

    if (!carrier || !country) return;

    this.isLoadingSearchApi = true;

    try {
      const pudoSearchRequest: PudoSearch = {
        shipment_id: this.shipment?.id?.toString(),
        country: country.countryCode,
        postal_code: this.pudoFilterGeocode ? undefined : country.zipCode,
        mode: 'drop_off',
        search: this.pudoFilterSearch,
        filters: {
          weight: this.pudoFilterTimeWeight?.weight,
          open24_7: this.pudoFilterTimeWeight?.open24_7,
          open_on_weekends: this.pudoFilterTimeWeight?.open_on_weekends,
          open_now: this.pudoFilterTimeWeight?.open_now,
          timezone: getCurrentTimezoneOffset(),
        },
        location: {
          distance: this.pudoFilterDistance
            ? `${this.pudoFilterDistance}${getMeasureUnits(store.userStore.measures).distance}`
            : `${100}${getMeasureUnits(store.userStore.measures).distance}`,
          lat: this.pudoFilterGeocode?.latitude,
          lon: this.pudoFilterGeocode?.longitude,
        },
      };

      if (this.isAgentFlowShowMap) {
        pudoSearchRequest.pudo_type = 'agent';
      }

      const searchPudo = store.commonStore.isGuestParcelCreation
        ? agent.Guest.guestSearchPudo
        : agent.Search.searchPudo;

      const response = await searchPudo(carrier, pudoSearchRequest);

      runInAction(() => {
        // Temp solution until backend will clear its DB from duplicates
        this.foundPudoItems = removeDuplicates(
          response.data,
          (pudo) => pudo.id
        );
        this.foundPudoMeta = response.meta;
      });

      return { pudos: this.foundPudoItems, pudosMeta: this.foundPudoMeta };
    } catch (error) {
      handleError(error);
    } finally {
      runInAction(() => {
        this.isLoadingSearchApi = false;
      });
    }
  };

  setFoundPudos = (pudos: PudoItem[] | undefined) => {
    this.foundPudoItems = pudos;
  };

  setFoundPudoMeta = (meta: PudoSearchMeta | undefined) => {
    this.foundPudoMeta = meta;
  };

  clearPudoFilters = () => {
    this.pudoFilterTimeWeight = undefined;
    this.pudoFilterDistance = undefined;
    this.pudoFilterGeocode = undefined;
    this.pudoFilterSearch = undefined;
  };

  setPudoFilterTimeWeight = (timeWeightFilter?: PudoFilters) => {
    this.pudoFilterTimeWeight = timeWeightFilter;
  };

  setPudoFilterDistance = (distance?: number) => {
    this.pudoFilterDistance = distance;
  };

  setPudoFilterGeocode = (geocode?: Geocode) => {
    this.pudoFilterGeocode = geocode;
  };

  setPudoFilterSearch = (searchString?: string) => {
    this.pudoFilterSearch = searchString;
  };

  setPreservePudos = (preserve: boolean) => {
    this.preservePudos = preserve;
  };

  setSenderAddress = (address: Address) => {
    if (this.shipment?.sender) {
      this.shipment.sender.address = address;
    }
  };

  setReceiverAddress = (address: Address) => {
    if (this.shipment?.recipient) {
      this.shipment.recipient.address = address;
    }
  };

  submitSignature = async (signature: FormData) => {
    if (!this.shipment?.id) return;

    this.isNextStepButtonLoading = true;

    try {
      const response = await agent.Shipment.submitSignature(
        signature,
        this.shipment.id
      );

      if (response?.signature) {
        const { is_present: isPresent } = response.signature;

        runInAction(() => {
          this.signatureIsPresent = isPresent || false;
        });
      }

      return response;
    } catch (error) {
      handleError(error);
    } finally {
      runInAction(() => {
        this.isNextStepButtonLoading = false;
      });
    }
  };

  setSignature = (signature: string) => {
    runInAction(() => {
      this.signature = signature;
      this.signatureIsPresent = !!signature;
    });
  };

  setSummaryConfirmFormValues = (value: boolean) => {
    this.summaryConfirmFormValues = value;
  };

  setTaxes = (taxes: ITaxes | undefined) => {
    this.taxes = taxes;
  };

  getTaxes = async () => {
    if (!this.shipment?.id) return;
    this.isLoadingTaxes = true;

    let success = false;

    try {
      const res = await agent.Shipment.taxes(this.shipment.id);
      this.setTaxes(res);
      success = true;
    } catch (error: any) {
      if (error.response.status === ApiResponseStatus.NOT_FOUND) {
        return false;
      }

      handleError(error);
      success = false;
    } finally {
      runInAction(() => {
        this.isLoadingTaxes = false;
      });
    }

    return success;
  };

  getProhibitedItems = async () => {
    if (!this.shipment?.id) return;

    this.isLoadingShipmentApi = true;

    try {
      const prohibitedItems = await agent.Shipment.prohibitedItems(
        this.shipment.id
      );

      return prohibitedItems;
    } catch (error) {
      handleError(error);
    } finally {
      runInAction(() => {
        this.isLoadingShipmentApi = false;
      });
    }
  };

  searchItemsByDescription = async (description?: string) => {
    if (!description || description?.trim().length < 3) return undefined;

    this.isLoadingSearchApi = true;

    try {
      const foundItems = await agent.Search.hscode(description);

      return foundItems;
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error.response?.status === ApiResponseStatus.BAD_REQUEST
      )
        return [];
      handleError(error);
    } finally {
      runInAction(() => {
        this.isLoadingSearchApi = false;
      });
    }
  };

  addItemToShipment = async (product: HsCode) => {
    if (!this.shipment) return;

    this.isLoadingShipmentApi = true;

    try {
      const shipment = await agent.Shipment.addParcelItem(
        new HsCodeRequest(product),
        this.shipment.id
      );
      runInAction(() => {
        this.shipment = shipment;
      });
    } catch (error) {
      const declaredValueError = tryGetErrorMessage(
        error,
        ApiResponseStatus.PRECONDITION_FAILED,
        'declared_value'
      );

      if (declaredValueError) return declaredValueError;
      handleError(error);
    } finally {
      runInAction(() => {
        this.isLoadingShipmentApi = false;
      });
    }
  };

  modifyItemInShipment = async (product: HsCode) => {
    if (!this.shipment) return;

    this.isLoadingShipmentApi = true;

    try {
      const shipment = await agent.Shipment.updateParcelItem(
        new HsCodeRequest(product),
        this.shipment.id
      );
      runInAction(() => {
        this.shipment = shipment;
      });
    } catch (error) {
      const declaredValueError = tryGetErrorMessage(
        error,
        ApiResponseStatus.PRECONDITION_FAILED,
        'declared_value'
      );

      if (declaredValueError) return declaredValueError;
      handleError(error);
    } finally {
      runInAction(() => {
        this.isLoadingShipmentApi = false;
      });
    }
  };

  deleteItemInShipment = async (productId: number) => {
    if (!this.shipment) return;

    this.isLoadingShipmentApi = true;

    try {
      const shipment = await agent.Shipment.deleteParcelItem(
        productId,
        this.shipment.id
      );

      runInAction(() => {
        this.shipment = shipment;
      });
    } catch (error) {
      handleError(error);
    } finally {
      runInAction(() => {
        this.isLoadingShipmentApi = false;
      });
    }
  };

  modifyShipmentInsurance = async (insurance: number) => {
    if (!this.shipment) return;

    this.isLoadingShipmentApi = true;

    try {
      const updatedShipment = await agent.Shipment.updateParcelInsurance(
        this.shipment.id,
        { insurance }
      );
      runInAction(() => {
        this.shipment = updatedShipment;
      });
    } catch (error) {
      handleError(error);
    } finally {
      runInAction(() => {
        this.isLoadingShipmentApi = false;
      });
    }
  };

  fetchInsuranceLimits = async () => {
    if (!this.shipment || this.insuranceLimits) return;

    this.isLoadingShipmentApi = true;

    try {
      const limits = await agent.Shipment.getParcelInsuranceLimits(
        this.shipment.id
      );
      runInAction(() => {
        this.insuranceLimits = limits;
      });
    } catch (error) {
      handleError(error);
    } finally {
      runInAction(() => {
        this.isLoadingShipmentApi = false;
      });
    }
  };

  setCurrentLocation = (location: Geocode | undefined) => {
    this.currentLocation = location;
  };

  setNewsletterSubscriptionAllowed = (isAllow: boolean) => {
    this.newsletterSubscriptionAllowed = isAllow;
  };

  setIsGuestPhoneVerified = (isVerified: boolean) => {
    this.isGuestPhoneVerified = isVerified;
  };

  setIsParcelCreationInProgress = (inProgress: boolean) => {
    this.isParcelCreationInProgress = inProgress;
  };

  fetchDates = async () => {
    try {
      if (!this.shipment) return;

      this.isLoadingApiCallCourier = true;

      const response = await agent.Shipment.pickupWindows(
        this.shipment.id,
        getCurrentTimezoneOffset()
      );

      runInAction(() => {
        this.dateInfo = response?.dates as DateInfo[];
        this.cachedTimezone = getCurrentTimezoneOffset();
      });

      return this.dateInfo;
    } catch (error) {
      handleError(error);
    } finally {
      runInAction(() => {
        this.isLoadingApiCallCourier = false;
      });
    }
  };
  getAllDates = async () => {
    const currentTimezone = getCurrentTimezoneOffset();

    // Check if we have a cached dateInfo and if it's still valid
    if (
      this.dateInfo &&
      this.shipment &&
      this.cachedTimezone === currentTimezone
    ) {
      return this.dateInfo;
    }

    return this.fetchDates();
  };

  setCourierPickupValues = (pickupInfo: CourierPickup) => {
    const user = this.shipment?.sender;

    if (user) {
      user.courier_pickup = {
        ...user.courier_pickup,
        ...pickupInfo,
        timezone: getCurrentTimezoneOffset(),
      };
    }
  };

  clearDateInfo = () => {
    this.dateInfo = undefined;
    this.cachedTimezone = null;
  };

  applyPromoCode = async (code: string) => {
    try {
      if (!this.shipment) return;

      const shipmentId = this.shipment.id;

      const updatedShipment = await agent.Promocodes.promocodes(
        shipmentId,
        code
      );

      const appliedPromoCode = updatedShipment?.promocodes?.find(
        (promo) => promo.code === code
      );

      if (!appliedPromoCode) return;

      runInAction(() => {
        this.shipment = updatedShipment;
      });

      return {
        code: appliedPromoCode.code,
        discount: appliedPromoCode.discount,
      };
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log('PROMOCODE', error);

      if (error.response?.status === ApiResponseStatus.SERVER_ERROR) {
        handleError(error);
        return;
      }

      throw new Error(error.response.data.message);
    }
  };

  handleRemovePromoCode = async (code: string) => {
    try {
      if (!this.shipment || !this.shipment.promocodes) return;

      const promoCodeToRemove = this.shipment.promocodes.find(
        (promo) => promo.code === code
      );

      if (!promoCodeToRemove) return;

      const updatedShipment = await agent.Promocodes.deletePromocode(
        this.shipment.id,
        promoCodeToRemove.id
      );

      runInAction(() => {
        this.shipment = updatedShipment;
      });
    } catch (error) {
      console.error(error);
    }
  };

  setCalculatorDataFromStore = () => {
    this.setDepartureShipmentCountry(
      store.widgetCalculatorStore.countryDeparture
    );
    this.setDestinationShipmentCountry(
      store.widgetCalculatorStore.countryDestination
    );
    this.setParcelDimensions(store.widgetCalculatorStore.parcelDimensions);
  };

  setCalculatorDataFromUrl = async (
    shipmentCountries: IShipmentCountry[],
    {
      countryDeparture: { countryCode: departureCountryCode },
      countryDestination: { countryCode: destinationCountryCode },
      parcelDimensions,
      measures: urlMeasures,
    }: ICalculatorData
  ) => {
    const departureCountry =
      shipmentCountries.find(
        (country) => country.countryCode === departureCountryCode
      ) || null;
    const destinationCountry = departureCountry
      ? departureCountry.destinationCountries.find(
          (country) => country.countryCode === destinationCountryCode
        ) || null
      : null;

    await runInAction(async () => {
      this.setOmitLimitationsRequest(true);
      this.setDepartureShipmentCountry(departureCountry);
      this.setDestinationShipmentCountry(destinationCountry);
    });

    const conversionRequired =
      urlMeasures && urlMeasures !== store.userStore.measures;

    if (conversionRequired) {
      const convertedDimensions = convertDimensions(
        parcelDimensions,
        urlMeasures
      );
      this.setParcelDimensions(convertedDimensions);
    } else {
      this.setParcelDimensions(parcelDimensions);
    }

    if (store.widgetCalculatorStore.deliveryLimitations) {
      runInAction(() => {
        this.deliveryLimitations = _.cloneDeep(
          store.widgetCalculatorStore.deliveryLimitations
        );
      });
    } else {
      await this.getDeliveryLimitations();
    }
    store.widgetCalculatorStore.resetStore();
  };

  fetchSelectedDeliveryOptions = async (shipmentId: number) => {
    this.isNextStepButtonLoading = true;

    try {
      const deliveryOptions =
        await agent.Shipment.getSelectedDeliveryOptions(shipmentId);

      return deliveryOptions;
    } catch (error) {
      handleError(error);
    } finally {
      runInAction(() => {
        this.isNextStepButtonLoading = false;
      });
    }
  };

  private handleMessages = (
    messageBus: MessageBus[] | undefined,
    messageTypes: (keyof MessageHandlers)[],
    userRole?: UserRole
  ) => {
    if (!messageBus) return;

    const messageHandlers: MessageHandlers = {
      postalCodeChangeHandler: {
        selector: ({ type }) => type === 'reprice',
        handler: async (msg) => {
          runInAction(async () => {
            if (
              this.shipment?.sender?.address?.post_code &&
              this.countryDeparture &&
              userRole === UserRole.sender
            ) {
              this.countryDeparture.zipCode =
                this.shipment?.sender?.address?.post_code;
            }

            if (
              this.shipment?.recipient?.address?.post_code &&
              this.countryDestination &&
              userRole === UserRole.receiver
            ) {
              this.countryDestination.zipCode =
                this.shipment?.recipient?.address?.post_code;
            }
          });

          const isPriceChanged = msg.additional_information.some(
            (info) => info.key === 'difference' && info.value !== '0'
          );

          if (isPriceChanged && this.shipment?.id) {
            store.commonStore.toastInfo(t('price_update_message'), true);
            await this.obtainDeliveryOptions();

            const selectedDeliveryOptions =
              await this.fetchSelectedDeliveryOptions(this.shipment.id);

            this.setFirstMile(selectedDeliveryOptions?.first_mile);
            this.setLastMile(selectedDeliveryOptions?.last_mile);

            this.setSenderPudo(this.shipment?.sender?.carrier?.pudo_info);
            this.setReceiverPudo(this.shipment?.recipient?.carrier?.pudo_info);
          }
        },
      },
      rateSchemaChangeHandler: {
        selector: ({ type }) => type === 'reprice',
        handler: (msg) => {
          this.isRateSchemaChanged = msg.additional_information.some(
            (info) => info.key === 'rate_schema_was_changed'
          );
        },
      },
      promoCodeDeletedHandler: {
        selector: (msg: MessageBus) =>
          msg.type === 'promoCode' && msg.subtype === 'promoCodeDeleted',
        handler: (msg: MessageBus) => {
          store.commonStore.toastError(msg.message);
        },
      },
      messageNotification: {
        selector: (msg: MessageBus) =>
          msg.type === 'messageNotification' &&
          msg.subtype === 'success' &&
          !store.commonStore.isGuestParcelCreation,
        handler: (msg: MessageBus) => {
          const toastMessage: ToastContent = {
            primaryMessage: msg.additional_information[0].value,
            secondaryMessage: msg.additional_information[1].value,
          };
          store.commonStore.toastSuccess(toastMessage);
          store.userStore.getCurrentUser();
        },
      },
    };

    const handlers: BusMessageHandler[] = [];

    messageTypes.forEach((type) => {
      handlers.push(messageHandlers[type]);
    });

    handleBusMessages(messageBus, handlers, true);
  };

  prepareAgentShowMapFlow = async () => {
    runInAction(() => {
      this.isNextStepButtonLoading = true;
    });

    const response = await this.getPudosForRole({ role: UserRole.sender });
    if (!response) return;

    const { pudos } = response;

    const nearestPudo = pudos?.sort(
      (a, b) =>
        Number.parseFloat(a.distance.distance) -
        Number.parseFloat(b.distance.distance)
    )[0];

    if (nearestPudo) {
      this.setSenderPudo(nearestPudo);
    }

    runInAction(() => {
      this.navigation = {
        ...this.navigation,
        next: agentFlowDeliveryInstructions,
        map: [...defaultNavigationMap, agentFlowDeliveryInstructions],
      };
    });
    runInAction(() => {
      this.isNextStepButtonLoading = false;
    });
  };

  resetNavigationAfterMileChange = () => {
    if (this.prefillInProgress) return;

    if (this.navigation.current?.name === 'parcel-details') {
      this.navigation = { ...defaultNavigation };
    }

    if (this.navigation.current?.name === 'delivery-option') {
      this.navigation = { ...defaultNavigation };
      this.navigation.current = defaultNavigation.map.at(1);
      this.navigation.previous = defaultNavigation.map.at(0);
      this.navigation.next = null;
    }
  };

  setIsNextStepButtonLoading = (isLoading: boolean) => {
    this.isNextStepButtonLoading = isLoading;
  };

  setIsNextButtonClicked = (value: boolean) => {
    this.isNextButtonClicked = value;
  };

  setIsSignButtonClicked = (value: boolean) => {
    this.isSignButtonClicked = value;
  };

  setOmitLimitationsRequest = (value: boolean) => {
    this.omitLimitationsRequest = value;
  };

  handleDeliveryOptionsResponse = async ({
    firstMiles,
    lastMiles,
    staticTimeStrings,
    staticDeliveryTime,
    staticDeliveryTimeString,
    deliveryTimeName,
  }: {
    firstMiles: FirstMile[];
    lastMiles: LastMile[];
    staticTimeStrings?: StaticTimeStrings;
    staticDeliveryTime?: StaticTimeNumbers;
    staticDeliveryTimeString?: StaticTimeStrings;
    deliveryTimeName?: StaticTimeStrings;
  }) => {
    if (firstMiles?.length === 0) {
      throw new Error("Info about first miles wasn't obtained from the API!");
    }

    if (lastMiles?.length === 0) {
      throw new Error("Info about last miles wasn't obtained from the API!");
    }

    runInAction(() => {
      this.firstMiles = firstMiles?.filter((fm) => !!fm.middle_miles) || null;
      this.lastMiles = lastMiles;
      this.staticTimeStrings = staticTimeStrings;
      this.staticDeliveryTime = staticDeliveryTime;
      this.staticDeliveryTimeString = staticDeliveryTimeString;
      this.deliveryTimeName = deliveryTimeName;
      this.isDeliveryOptionsFailed = false;
    });

    // Search for city by zip-code in case user entered zip-code manually

    if (!this.countryDeparture?.city && this.countryDeparture?.zipCode) {
      const departureCity = await getCityByZipCode(
        this.countryDeparture.zipCode,

        this.countryDeparture.countryCode.toLocaleLowerCase()
      );
      runInAction(() => {
        if (!this.countryDeparture) return;
        this.countryDeparture.city = departureCity;
      });
    }

    if (!this.countryDestination?.city && this.countryDestination?.zipCode) {
      const destinationCity = await getCityByZipCode(
        this.countryDestination.zipCode,

        this.countryDestination.countryCode.toLocaleLowerCase()
      );
      runInAction(() => {
        if (!this.countryDestination) return;
        this.countryDestination.city = destinationCity;
      });
    }

    if (this.countryDeparture?.zipCode) {
      const departureRegion = await getRegionByZipCode(
        this.countryDeparture.zipCode,

        this.countryDeparture.countryCode.toLocaleLowerCase()
      );
      runInAction(() => {
        if (!this.countryDeparture) return;
        this.countryDeparture.region = departureRegion;
      });
    }

    if (this.countryDestination?.zipCode) {
      const destinationRegion = await getRegionByZipCode(
        this.countryDestination.zipCode,

        this.countryDestination.countryCode.toLocaleLowerCase()
      );
      runInAction(() => {
        if (!this.countryDestination) return;
        this.countryDestination.region = destinationRegion;
      });
    }
  };

  prepareSenderDetails = () => {
    const personalDataFields: PersonalDataFields = {
      first_name: this.shipment?.sender?.first_name ?? '',
      last_name: this.shipment?.sender?.last_name ?? '',
    };

    const contactInfoFields: ContactInfoFields = {
      email: this.shipment?.sender?.email ?? '',
      phone: this.shipment?.sender?.phone?.phone_number ?? '',
      phone_country_code: this.shipment?.sender?.phone?.country_code ?? '',
      ...(this.useAdditionalPhone && {
        contact_phone_number:
          this.shipment?.sender?.contact_phone?.phone_number ?? '',
        contact_phone_country_code:
          this.shipment?.sender?.contact_phone?.country_code ?? '',
      }),
    };

    const addressFields: AddressFields = {
      country: this.shipment?.sender?.country,
      post_code: this.shipment?.sender?.address?.post_code ?? '',
      region: this.shipment?.sender?.address?.region ?? '',
      city: this.shipment?.sender?.address?.city ?? '',
      street: this.shipment?.sender?.address?.street ?? '',
      building: this.shipment?.sender?.address?.building ?? '',
      section: this.shipment?.sender?.address?.section ?? '',
      apartment: this.shipment?.sender?.address?.apartment ?? '',
      buzz_code: this.shipment?.sender?.address?.buzz_code ?? '',
      note: this.shipment?.sender?.address?.note ?? '',
      full_address: this.shipment?.sender?.address?.full_address,
    };

    const courierPickupFields: CourierPickupFields = {
      courier_pickup: {
        time_to: this.shipment?.sender?.courier_pickup?.time_to ?? '',
        date: this.shipment?.sender?.courier_pickup?.date ?? '',
        timezone: this.shipment?.sender?.courier_pickup?.timezone ?? '',
        time_from: this.shipment?.sender?.courier_pickup?.time_from ?? '',
      },
    };

    return {
      personalDataFields,
      contactInfoFields,
      addressFields,
      courierPickupFields,
    };
  };

  handleUnprocessableAddress = (error: any) => {
    const isValidationError =
      error instanceof AxiosError &&
      (error.response?.status === ApiResponseStatus.VALIDATION_ERROR ||
        error.response?.status === ApiResponseStatus.PRECONDITION_FAILED);
    this.setSenderAddressInvalid(isValidationError);
  };
}

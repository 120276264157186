const formats = {
  dateTime: {
    US: 'dd.MM.yyyy HH:mm:ss',
    IT: 'dd.MM.yyyy HH:mm:ss',
    GB: 'MM.dd.yyyy hh:mm:ss a',
    default: 'dd.MM.yyyy HH:mm:ss',
  },
  date: {
    US: 'dd.MM.yyyy',
    IT: 'dd.MM.yyyy',
    GB: 'MM.dd.yyyy',
    default: 'dd.MM.yyyy',
  },
};

const isValidKey = (key: string = ''): key is keyof typeof formats.dateTime =>
  key in formats.dateTime;

export const getDateTimeFormat = (
  countryCode?: string,
  includeTime?: boolean,
  dateTimeSeparator?: string
) => {
  const formatString = isValidKey(countryCode)
    ? formats[includeTime ? 'dateTime' : 'date'][countryCode]
    : formats[includeTime ? 'dateTime' : 'date'].default;

  return includeTime && dateTimeSeparator
    ? formatString.split(' ').join(dateTimeSeparator)
    : formatString;
};

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  Title,
  WidgetCalculatorContainer,
  WidgetCalculatorWrapper,
  WidgetCalculatorSection,
  ParcelTabsContainer,
} from './widget-calculator.styles';
import { FormValues } from './widget-calculator-form.component';
import { SectionSubtitle } from '../common/typography.styles';
import { SearchParam } from '../../../constants/route';
import { theme } from '../../../styles/themes';
import { getTabs, WidgetTabs } from './config';

const WidgetCalculator = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [formData, setFormData] = useState<FormValues | undefined>(undefined);

  const [activeTab, setActiveTab] = useState<WidgetTabs>('calculator');

  useEffect(() => {
    const dataParam = searchParams.get(SearchParam.DATA);

    if (dataParam) {
      try {
        const parsedData: FormValues = JSON.parse(
          decodeURIComponent(dataParam)
        );
        setFormData(parsedData);
        navigate(location.pathname, { replace: true });
      } catch (error) {
        console.error('Failed to decode URI parameter', error);
      }
    }
  }, [searchParams, navigate, location.pathname]);

  const tabs = getTabs(activeTab, setActiveTab, formData, t);

  return (
    <WidgetCalculatorWrapper>
      <WidgetCalculatorSection>
        <WidgetCalculatorContainer>
          <SectionSubtitle $color={theme.colors.namedColors.white}>
            {t('main_page_title_meest_portal')}
          </SectionSubtitle>
          <Title>{t('main_page_title_international_shipping_worldwide')}</Title>
          <ParcelTabsContainer>
            {tabs.map((tab) => tab.tab)}
          </ParcelTabsContainer>
          {tabs.map((tab) => tab.element)}
        </WidgetCalculatorContainer>
      </WidgetCalculatorSection>
    </WidgetCalculatorWrapper>
  );
};

export default WidgetCalculator;

import styled, { css, DefaultTheme } from 'styled-components';
import {
  FontSizes,
  TextParcelDeliveryOptionTabTitle,
} from '../../../common/typography/typography.styles';
import { Container as TabsContainer } from '../../../common/tabs/tabs.styles';
import { scrollStyles } from '../../../common/scroll/scroll.styles';
import { DeliveryMethod } from '../../../models/parcelDeliveryMiles';

export const Container = styled.div`
  height: 100%;

  .react-tabs {
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 2.4rem;
    height: 100%;

    @media (max-width: 650px) {
      gap: 1.6rem;
    }
  }
  .react-tabs__tab {
    padding: 0.6rem !important;
  }

  .react-tabs__tab-panel {
    overflow: auto;
    ${scrollStyles};
  }

  ${TabsContainer} {
    height: 100%;
  }
`;

export const AdvantageTag = styled.span`
  @media (max-width: 650px) {
    flex: 1 1 60%;
  }
`;

export const TabsContainerTop = styled.div`
  display: grid;
  gap: 0.8rem;
`;

const getTextColor = (
  theme: DefaultTheme,
  isSelected?: boolean,
  method?: DeliveryMethod,
  isCalculator?: boolean,
  isMeestClient?: boolean
) => {
  if (isSelected) return 'inherit';
  if (method === 'expedited' && isMeestClient)
    return theme.colors.tab.colorExpedited;
  if (isCalculator && !isMeestClient) return '#484847';
  return theme.colors.tab.color;
};

const getFillColor = (
  theme: DefaultTheme,
  method?: DeliveryMethod,
  isCalculator?: boolean,
  isMeestClient?: boolean
) => {
  if (method === 'expedited' && isMeestClient)
    return theme.colors.baseColors.red100;
  if (isCalculator && !isMeestClient) return '#484847';
  return theme.colors.namedColors.primary;
};

export const DeliveryTabTitleContainer = styled.span<{
  $method?: DeliveryMethod;
  $isSelected?: boolean;
  $isCalculator?: boolean;
  $isMeestClient?: boolean;
}>`
  color: ${({ theme, $isSelected, $method, $isCalculator, $isMeestClient }) =>
    getTextColor(theme, $isSelected, $method, $isCalculator, $isMeestClient)};

  ${TextParcelDeliveryOptionTabTitle}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  text-align: center;
  position: relative;
  z-index: 10;

  svg {
    width: 2.4rem;
    height: 2.4rem;
    fill: ${({ theme, $method, $isCalculator, $isMeestClient }) =>
      getFillColor(theme, $method, $isCalculator, $isMeestClient)};
  }

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      ${AdvantageTag} {
        color: var(--tab-background-color-selected);
      }
    `}

  @media (max-width: 650px) {
    flex-direction: column;
    gap: 0.6rem;
  }
  @media (min-width: 651px) {
    min-width: 10rem;
  }
`;

interface OptionProps {
  $isSelected?: boolean;
}

export const SelectOption = styled.div<OptionProps>`
  padding: 1.2rem 1.2rem 1.2rem 1.6rem;
  border: 1px solid ${({ theme }) => theme.colors.baseColors.grey50};
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.25s ease-out;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.baseColors.grey5};
  }

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background-color: ${({ theme }) => theme.colors.baseColors.grey5};
    `}
`;
interface DurationTextProps {
  $fontWeight?: string;
  $fontSize?: string;
}
export const DurationText = styled.span<DurationTextProps>`
  text-transform: lowercase;
  font-weight: ${({ $fontWeight }) => $fontWeight || '400'};
  font-size: $ ${({ $fontSize }) => $fontSize || FontSizes.small};
  margin-bottom: -2px;
  margin-left: -3px;
`;

export const Footnote = styled.p`
  display: block;
  font-size: ${FontSizes.small};
  font-style: italic;
  color: ${({ theme }) => theme.colors.baseColors.grey100};
  margin-top: 8px;
`;

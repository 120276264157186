import { client } from '../config';
import { Language } from '../models/language';
import { isMeest } from '../utils/client/client.utils';

export const getLocalizations: (countryCode: string) => Language[] = (
  countryCode: string
) =>
  isMeest(client)
    ? [
        {
          code: 'en',
          languageLabel: 'English',
          flagEmoji: countryCode === 'GB' ? '🇬🇧' : '🇺🇸',
        },
        { code: 'uk', languageLabel: 'Українська', flagEmoji: '🇺🇦' },
        { code: 'ru', languageLabel: 'Русский', flagEmoji: '🇷🇺' },
        { code: 'it', languageLabel: 'Italiano', flagEmoji: '🇮🇹' },
      ]
    : [{ code: 'en', languageLabel: 'English', flagEmoji: '🇺🇸' }];

export const getLocalizationsMap = (countryCode: string) =>
  getLocalizations(countryCode).reduce<{
    [key: string]: Language;
  }>((acc, curr) => {
    acc[curr.code] = curr;
    return acc;
  }, {});

import { Address } from '../../../models/parcelDeliveryMiles';

function getSenderAddressForHomeCase(
  shipmentAddress: Address,
  userAddress?: Address
) {
  const isUserHasFullAddress = !!userAddress?.street;
  const isShipmentHasFullAddress = !!shipmentAddress?.street;
  const isShipmentMatchUser =
    shipmentAddress?.post_code === userAddress?.post_code;

  const addressToUse =
    !isShipmentHasFullAddress && isUserHasFullAddress && isShipmentMatchUser
      ? userAddress
      : shipmentAddress;

  return addressToUse;
}

function getSenderAddressForCourier(
  shipmentAddress: Address,
  userAddress?: Address
) {
  const isUserHasFullAddress = !!userAddress?.street;
  const isShipmentHasFullAddress = !!shipmentAddress?.street;

  const isShipmentMatchUser =
    shipmentAddress?.post_code === userAddress?.post_code &&
    shipmentAddress?.region === userAddress?.region &&
    shipmentAddress?.city === userAddress?.city;

  const addressToUse =
    !isShipmentHasFullAddress && isUserHasFullAddress && isShipmentMatchUser
      ? userAddress
      : shipmentAddress;

  return addressToUse;
}

function getReceiverAddress(isHideFields: boolean, shipmentAddress: Address) {
  const isDefaultsEmpty = isHideFields && !shipmentAddress?.street;

  const addressToUse: Address = {
    country: shipmentAddress.country,
    region: isDefaultsEmpty ? '' : shipmentAddress?.region ?? '',
    city: isDefaultsEmpty ? '' : shipmentAddress?.city ?? '',
    post_code: isDefaultsEmpty ? '' : shipmentAddress?.post_code ?? '',
    street: shipmentAddress?.street ?? '',
    building: shipmentAddress?.building ?? '',
    section: shipmentAddress?.section ?? '',
    apartment: shipmentAddress?.apartment ?? '',
    buzz_code: shipmentAddress?.buzz_code ?? '',
    note: shipmentAddress?.note ?? '',
    full_address: shipmentAddress?.full_address ?? '',
  };

  return addressToUse;
}

function getEmptyAddress() {
  return {
    country: '',
    region: '',
    city: '',
    post_code: '',
    street: '',
    building: '',
    section: '',
    apartment: '',
    buzz_code: '',
    note: '',
    full_address: '',
  } as Address;
}

interface UseAddressInitializationProps {
  countryNameLocalized: string;
  isUserSender: boolean;
  isHomeAddress: boolean;
  shipmentAddress?: Address;
  userAddress?: Address;
  isHideFields: boolean;
}

const getAddressFormInitialValues = ({
  countryNameLocalized,
  isUserSender,
  isHomeAddress,
  shipmentAddress,
  userAddress,
  isHideFields,
}: UseAddressInitializationProps): Address => {
  if (!shipmentAddress) return getEmptyAddress();

  let addressToUse: Address;

  if (isUserSender) {
    addressToUse = isHomeAddress
      ? getSenderAddressForHomeCase(shipmentAddress, userAddress)
      : getSenderAddressForCourier(shipmentAddress, userAddress);
  } else {
    addressToUse = getReceiverAddress(isHideFields, shipmentAddress);
  }

  return { ...addressToUse, country: countryNameLocalized };
};

export default getAddressFormInitialValues;

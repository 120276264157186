import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  AdvantagesCards,
  Header,
  AdvantagesCard,
  AdvantagesCardImage,
} from './advantages.styles';
import { SectionDescription, SectionTitle } from '../common/typography.styles';
import { CardContent, CardTitle } from '../common/card.styles';
import { Container } from '../common/container.styles';
import { Section } from '../common/section.styles';
import { useStore } from '../../../stores/store';

const Advantages = () => {
  const {
    marketingStore: { advantages, fetchAdvantages, getTrackingUrl },
  } = useStore();
  const { t } = useTranslation();
  const [textToShow, setTextToShow] = useState(advantages?.defaultText);
  const handleMouseEnter = (text: string) => {
    setTextToShow(text);
  };
  const handleMouseLeave = () => {
    setTextToShow(advantages?.defaultText);
  };

  useEffect(() => {
    const handler = async () => {
      const defaultText = await fetchAdvantages();
      setTextToShow(defaultText);
    };

    handler();
  }, [fetchAdvantages]);

  const handleTrackingClick = () => {
    const trackUrl = getTrackingUrl();
    window.open(trackUrl, '_blank');
  };

  return (
    <Container>
      <Section>
        <Header>
          <SectionTitle>{t('main_page_advantages_title')}</SectionTitle>
          <SectionDescription>{t(textToShow!)}</SectionDescription>
        </Header>
        <AdvantagesCards>
          {advantages?.items.map(({ id, title, text, icon: Icon }) => (
            <AdvantagesCard
              key={id}
              onMouseEnter={() => handleMouseEnter(text)}
              onMouseLeave={handleMouseLeave}
              onClick={
                id === 'tracking-parcel' ? handleTrackingClick : undefined
              }
              $isHoverable
              $contentAlign='center'>
              <AdvantagesCardImage>
                <Icon />
              </AdvantagesCardImage>
              <CardContent>
                <CardTitle>{t(title)}</CardTitle>
              </CardContent>
            </AdvantagesCard>
          ))}
        </AdvantagesCards>
      </Section>
    </Container>
  );
};

export default observer(Advantages);

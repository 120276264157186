import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { ServicesSubSectionContainer } from '../../services/services-subsection/services-subsection.styles';
import { CustomCheckbox } from './summary-checkbox-section.styles';
import { FlexContainer } from '../../../common/flex-container/flex-container.styles';
import { LinkSmall } from '../../../common/typography/typography.styles';
import { useStore } from '../../../stores/store';

interface CheckboxValues {
  confirmProhibitedItems: boolean;
  confirmConditionsOfCarriage: boolean;
  confirmDimensions: boolean;
  confirmNewsletter: boolean;
  confirmCheckAll: boolean;
  [key: string]: boolean;
}

interface CheckboxesChangeHandlerProps {
  setSummaryConfirmFormValues: (value: boolean) => void;
}

const CheckboxesChangeHandler = ({
  setSummaryConfirmFormValues,
}: CheckboxesChangeHandlerProps) => {
  const { isValid } = useFormikContext();

  useEffect(() => {
    setSummaryConfirmFormValues(isValid);
  }, [isValid, setSummaryConfirmFormValues]);

  return null;
};

const SummaryCheckboxSection = () => {
  const {
    parcelCreationStore: {
      setSummaryConfirmFormValues,
      setNewsletterSubscriptionAllowed,
      summaryConfirmFormValues,
      newsletterSubscriptionAllowed,
    },
    localizationsStore: { availableCountries },
    parcelCreationStore: { shipment, isNextButtonClicked },
  } = useStore();

  const initialValues: CheckboxValues = {
    confirmProhibitedItems: summaryConfirmFormValues,
    confirmConditionsOfCarriage: summaryConfirmFormValues,
    confirmDimensions: summaryConfirmFormValues,
    confirmNewsletter: newsletterSubscriptionAllowed,
    confirmCheckAll: summaryConfirmFormValues && newsletterSubscriptionAllowed,
  };

  const senderCountry = availableCountries?.find(
    (country) => country.country_code === shipment?.sender?.country
  );

  const deliveryRules = senderCountry?.links.delivery_rules;

  const termsAndConditions = !deliveryRules
    ? senderCountry?.links.terms_of_use
    : undefined;

  const [checkboxValues, setCheckboxValues] =
    useState<CheckboxValues>(initialValues);

  const { t } = useTranslation();

  const validationSchema = Yup.object({
    confirmProhibitedItems: Yup.boolean().oneOf([true]),
    confirmConditionsOfCarriage:
      deliveryRules || termsAndConditions
        ? Yup.boolean().oneOf([true])
        : Yup.boolean(),
    confirmDimensions: Yup.boolean().oneOf([true]),
  });

  const handleChecked = (field: string, value: boolean) => {
    if (field === `confirmNewsletter`) {
      setNewsletterSubscriptionAllowed(value);
    }

    if (field === 'confirmCheckAll') {
      const newValues = Object.keys(checkboxValues).reduce(
        (acc: { [key: string]: boolean }, key) => {
          acc[key] = value;
          return acc;
        },
        {}
      ) as CheckboxValues;
      setCheckboxValues(newValues);
      setNewsletterSubscriptionAllowed(value);
    } else {
      setCheckboxValues((prevValues) => {
        const newValues = {
          ...prevValues,
          [field]: value,
        };
        const allExceptAgreeToAllChecked = Object.values(newValues)
          .slice(0, -1)
          .every((v) => v === true);

        newValues.confirmCheckAll = allExceptAgreeToAllChecked;

        return newValues;
      });
    }
  };

  return (
    <ServicesSubSectionContainer
      $paddingBottom='3.2rem'
      $paddingLeft='2.4rem'
      $paddingRight='2.4rem'
      $paddingTop='2.4rem'>
      <FlexContainer $justify='start'>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={checkboxValues}
          onSubmit={() => {}}
          validateOnMount>
          {() => (
            <form>
              <CustomCheckbox
                name='confirmProhibitedItems'
                isChecked={checkboxValues.confirmProhibitedItems}
                isError={
                  isNextButtonClicked && !checkboxValues.confirmProhibitedItems
                }
                onChecked={handleChecked}>
                {t('confirm_above_info_true_no_prohibited_items')}
              </CustomCheckbox>

              {deliveryRules && (
                <CustomCheckbox
                  name='confirmConditionsOfCarriage'
                  isChecked={checkboxValues.confirmConditionsOfCarriage}
                  isError={
                    isNextButtonClicked &&
                    !checkboxValues.confirmConditionsOfCarriage
                  }
                  onChecked={handleChecked}>
                  {t('i_accept')}{' '}
                  <LinkSmall
                    underline='hover'
                    href={deliveryRules}
                    target='_blank'>
                    {t('the_conditions_of_carriage')}
                  </LinkSmall>
                </CustomCheckbox>
              )}

              {termsAndConditions && (
                <CustomCheckbox
                  name='confirmConditionsOfCarriage'
                  isChecked={checkboxValues.confirmConditionsOfCarriage}
                  isError={
                    isNextButtonClicked &&
                    !checkboxValues.confirmConditionsOfCarriage
                  }
                  onChecked={handleChecked}>
                  {t('i_accept')}{' '}
                  <LinkSmall
                    underline='hover'
                    href={termsAndConditions}
                    target='_blank'>
                    {t('summary_checkbox_terms_and_condtitions')}
                  </LinkSmall>
                </CustomCheckbox>
              )}

              <CustomCheckbox
                name='confirmDimensions'
                isChecked={checkboxValues.confirmDimensions}
                isError={
                  isNextButtonClicked && !checkboxValues.confirmDimensions
                }
                onChecked={handleChecked}>
                {t('certify_dimensions_input_correctly')}
              </CustomCheckbox>

              <CustomCheckbox
                name='confirmNewsletter'
                isChecked={checkboxValues.confirmNewsletter}
                onChecked={handleChecked}>
                {t('agree_to_newsletter')}
              </CustomCheckbox>

              <CustomCheckbox
                name='confirmCheckAll'
                isChecked={checkboxValues.confirmCheckAll}
                onChecked={handleChecked}>
                {t('agree_to_all')}
              </CustomCheckbox>

              <CheckboxesChangeHandler
                setSummaryConfirmFormValues={setSummaryConfirmFormValues}
              />
            </form>
          )}
        </Formik>
      </FlexContainer>
    </ServicesSubSectionContainer>
  );
};

export default observer(SummaryCheckboxSection);

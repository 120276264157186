import styled from 'styled-components';
import { TertiaryButton } from '../button/button.styles';

interface Props {
  $currentLocationsState: 'nearme' | 'city' | 'denied' | 'hidden';
}

export const ButtonWrapper = styled.div<Props>`
  display: grid;
  position: absolute;
  bottom: 1.8rem;
  right: 1.8rem;
  gap: 0.8rem;
  ${TertiaryButton} {
    --button-color: ${({ theme }) => theme.colors.baseColors.grey100};
    --button-color--disabled: ${({ theme }) => theme.colors.baseColors.grey50};
    --button-background-color: ${({ theme }) => theme.colors.baseColors.grey0};
    --button-background-color--disabled: ${({ theme }) =>
      theme.colors.baseColors.grey0};
  }

  #current-location {
    --button-color: ${({ $currentLocationsState, theme }) => {
      if ($currentLocationsState === 'city') {
        return theme.colors.baseColors.grey100;
      }
      if ($currentLocationsState === 'nearme') {
        return theme.colors.baseColors.blue100;
      }
      return theme.colors.baseColors.grey50;
    }};
  }
`;

export const StyledMapDataLabel = styled.div`
  position: absolute;
  bottom: 0;
  right: 0.5rem;
  font-size: 1rem;
  pointer-events: none;
`;

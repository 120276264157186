import React from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import { AppRoute, SearchParam } from '../../constants/route';
import useLogoutUnverifiedUser from '../../hooks/use-logout-unverified-user.hook';
import { generateLocaleRoute } from '../../utils/uri/uri.utils';
import PageLoader from '../page-loader/page-loader.component';

export const getRouteWithParams = (
  path: string,
  searchParams: URLSearchParams
) => (searchParams.toString() ? `${path}?${searchParams.toString()}` : path);

interface Props {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<Props> = ({ children }) => {
  const {
    userStore: { user, isLoadingUser },
    commonStore: { refreshToken, meestToken, appLoaded, isGuestParcelCreation },
  } = useStore();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigateToParam = searchParams.get('navigate_to');

  useLogoutUnverifiedUser();

  if (!navigateToParam) {
    // For parcel creation flow return user to parcel list.
    // TODO: In future, enhance with possibility to restore parcel creation from draft.
    const redirectToUrl = location.pathname.includes(AppRoute.PARCEL_CREATE)
      ? generateLocaleRoute(`${AppRoute.MY}/${AppRoute.PARCEL_LIST}`)
      : location.pathname;
    searchParams.append(SearchParam.REDIRECT, redirectToUrl);
  }

  if (!appLoaded) {
    return <PageLoader isVisible={!appLoaded} />;
  }

  return user ||
    (refreshToken && !meestToken && !navigateToParam) ||
    isGuestParcelCreation
    ? children
    : !isLoadingUser && (
        <Navigate
          to={
            navigateToParam ||
            generateLocaleRoute(getRouteWithParams(AppRoute.AUTH, searchParams))
          }
          replace={!navigateToParam}
          state={{ from: location.pathname }}
        />
      );
};

export default observer(PrivateRoute);

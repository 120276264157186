import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Advantages from '../../features/main/advantages/advantages.component';
import WidgetCalculator from '../../features/main/widget-calculator/widget-calculator.component';
import Instructions from '../../features/main/instructions/instructions.component';
import Banner from '../../features/main/banner/banner.component';
import DownloadApp from '../../features/main/download-app/download-app.component';
import Partners from '../../features/main/partners/partners.component';
import AuthenticationComponent from '../../features/main/authentication/authentication.component';
import Cookies from '../../features/main/cookies/cookies.component';
import CountriesList from '../../features/main/countries-list/countries-list';
import useLogoutUnverifiedUser from '../../hooks/use-logout-unverified-user.hook';
import BackToTop from '../../features/main/back-to-top/back-to-top.component';
import { SearchParam } from '../../constants/route';
import { useStore } from '../../stores/store';

const Main = () => {
  const {
    localizationsStore: { selectedCountry },
    marketingStore: {
      countriesWithoutBanners,
      countriesWithoutCountryList,
      countriesWithoutPartners,
    },
  } = useStore();

  useLogoutUnverifiedUser();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has(SearchParam.NAVIGATE_TO)) {
      searchParams.delete(SearchParam.NAVIGATE_TO);
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const omitBanners = countriesWithoutBanners.includes(
    selectedCountry?.country_code ?? ''
  );
  const omitCounriesList = countriesWithoutCountryList.includes(
    selectedCountry?.country_code ?? ''
  );

  const omitPartners = countriesWithoutPartners.includes(
    selectedCountry?.country_code ?? ''
  );

  return (
    <>
      <WidgetCalculator />
      <Advantages />
      {!omitBanners && <Banner />}
      <Instructions />
      <DownloadApp />
      {/* <ServicesMarketing /> - for now disabled until marketing will provide required info */}
      {!omitCounriesList && <CountriesList />}
      {/* <TopDirections /> */}
      {/* <News /> - for now disabled until marketing will provide required info */}
      <AuthenticationComponent />
      {!omitPartners && <Partners />}
      <Cookies />
      <BackToTop />
    </>
  );
};

export default observer(Main);

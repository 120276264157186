import Bowser from 'bowser';
import { AxiosError } from 'axios';
import { FirebaseError } from 'firebase/app';
import React from 'react';
import { parse } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { translate } from '../../../i18n';
import { HTML_CLASSES } from '../../constants/html-classes';
import { scrollbarWidth } from '../../hooks/use-init-app.hook';
import { store } from '../../stores/store';
import { ApiResponseStatus } from '../../constants/server';
import {
  FirebaseErrorCodes,
  handleFirebaseError,
} from '../firebase/firebase.utils';
import { Geocode } from '../../models/pudoItem';
import { Modals } from '../../constants/modals';
import { Address } from '../../models/parcelDeliveryMiles';
import { getDateTimeFormat } from '../../constants/date-time-formats';

export function getCurrentTimezoneOffset(): string {
  const offset = new Date().getTimezoneOffset();
  const hours = Math.abs(offset / 60);
  const minutes = Math.abs(offset % 60);
  const sign = offset <= 0 ? '+' : '-';

  return `${sign}${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;
}

interface CopyToClipboardProps {
  text: string;
  successMessage: string;
  errorMessage?: string;
}

export const copyToClipboardFallback = ({
  text,
  successMessage,
  errorMessage = translate('unable_to_copy_text_to_clipboard.'),
}: CopyToClipboardProps) => {
  const { toastSuccess, toastError } = store.commonStore;
  const textArea = document.createElement('textarea');
  Object.assign(textArea, {
    value: text,
    position: 'fixed',
    opacity: '0',
    pointerEvents: 'none',
    zIndex: '-1',
    left: '-9999px',
  });

  document.body.appendChild(textArea);
  textArea.select();
  try {
    document.execCommand('copy');
    toastSuccess(successMessage);
  } catch (err) {
    toastError(errorMessage);
  }
  document.body.removeChild(textArea);
};

export const copyToClipboard = async ({
  text,
  successMessage,
  errorMessage = translate('unable_to_copy_text_to_clipboard.'),
}: CopyToClipboardProps) => {
  const { toastSuccess, toastError } = store.commonStore;
  try {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(text);
      toastSuccess(successMessage);
    } else {
      copyToClipboardFallback({ text, successMessage });
    }
  } catch (error) {
    toastError(errorMessage);
    console.error('Failed to copy text: ', error);
  }
};

export const formatDate = (
  dateTime: string | null,
  includeTime?: boolean,
  dateTimeSeparator: string = ' '
) => {
  if (!dateTime) return;

  const formatStringLocalized = getDateTimeFormat(
    store.localizationsStore.selectedCountry?.country_code,
    includeTime,
    dateTimeSeparator
  );

  const formatStringBackend =
    dateTime.split(' ').length > 1 ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd';

  const date = parse(dateTime, formatStringBackend, new Date());
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  return formatInTimeZone(date, timeZone, formatStringLocalized);
};

export const exhaustiveGuard = (value: never) => {
  throw new Error(
    `ERROR! Reached forbidden guard function with unexpected value: ${JSON.stringify(value)}`
  );
};

export const getFullUserAddress = (address: Address) => {
  const addressParts: Array<keyof Address> = [
    'city',
    'building',
    'street',
    'apartment',
    'section',
    'buzz_code',
  ];

  return addressParts
    .map((part) => address?.[part] ?? '')
    .filter(Boolean)
    .join(', ');
};

let scrollPosition = 0;

export const lockBodyScroll = () => {
  scrollPosition = window.scrollY;

  document.body.classList.add(HTML_CLASSES.noScroll);

  if (scrollbarWidth > 0) {
    document.body.style.paddingRight = `${scrollbarWidth}px`;
  }

  window.scrollTo(0, scrollPosition);
};

export const unlockBodyScroll = () => {
  document.body.classList.remove(HTML_CLASSES.noScroll);
  document.body.style.paddingRight = '0';

  window.scrollTo(0, scrollPosition);
};

export const createLinkToFile = ({
  data,
  fileName,
  shouldDownloadImmediately = true,
}: {
  data: string;
  fileName: string;
  shouldDownloadImmediately?: boolean;
}) => {
  const link = document.createElement('a');
  Object.assign(link, {
    href: data,
    target: !shouldDownloadImmediately ? '_self' : '_blank',
    ...(!shouldDownloadImmediately && { download: fileName }),
  });
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const printBlob = async (
  blob: Blob | null | undefined,
  fileName: string
) => {
  if (!blob) {
    console.error('No blob provided for printing');
    return;
  }
  const data = window.URL.createObjectURL(blob);
  const parser = Bowser.getParser(navigator.userAgent);

  if (parser.getPlatformType() === 'mobile') {
    const file = new File([blob], fileName, {
      type: blob.type,
    });
    const shareData = {
      files: [file],
      ...(parser.getOS().name === 'Android' && {
        title: fileName,
      }),
    };
    if (!navigator.share || !navigator.canShare(shareData)) {
      createLinkToFile({ data, fileName, shouldDownloadImmediately: false });
    } else {
      navigator.share(shareData).catch((err) => {
        if (err.name === 'AbortError') {
          return;
        }
        throw new Error('something_went_wrong_try_again');
      });
    }
  } else {
    createLinkToFile({
      data,
      fileName,
      shouldDownloadImmediately: parser.getBrowserName() !== 'Firefox',
    });
  }
};

export const snakeToCamel = (value: string) =>
  value.replace(/(_\w)/g, (match) => match[1].toUpperCase());

// GPT generated
export function copyValues<T extends object>(target: T, source: Partial<T>): T {
  Object.keys(source).forEach((key) => {
    const typedKey = key as keyof T;

    // Check if both target and source values for the key are objects and not null
    const sourceValue = source[typedKey];
    const targetValue = target[typedKey];

    if (
      typeof sourceValue === 'object' &&
      sourceValue !== null &&
      typeof targetValue === 'object' &&
      targetValue !== null
    ) {
      // Recursively copy nested objects
      copyValues(targetValue, sourceValue);
    } else {
      // Otherwise, just assign the value
      target[typedKey] = sourceValue as T[keyof T];
    }
  });

  return target;
}

// Shallow comparison
export function isObjectsEqual(
  objectA: object | null | undefined,
  objectB: object | null | undefined,
  sourceOfKeys: object | null = null
) {
  if (!objectA && !objectB) return true;
  if (!objectA) return false;
  if (!objectB) return false;
  const keys = Object.keys(sourceOfKeys ?? objectA);

  return keys.every((key) => (objectA as any)[key] === (objectB as any)[key]);
}

export function isAnyValueEmpty(object: object | undefined | null | unknown) {
  if (!object) return true;
  return Object.values(object).some((value) => !value);
}

async function handleAxiosErrors(
  error: AxiosError,
  customFormatter?: (
    error: string,
    status?: number,
    code?: FirebaseErrorCodes
  ) => React.ReactNode
): Promise<string | React.ReactNode> {
  if (error?.name === 'CanceledError') {
    console.error('agent: Request aborted');
    return;
  }

  let toastMessage: string = '';

  let errorData = error.response?.data as any;

  if (errorData instanceof Blob) {
    errorData = JSON.parse(await errorData.text());
  }

  switch (error.response?.status) {
    case ApiResponseStatus.VALIDATION_ERROR:
      if (
        Array.isArray(errorData?.errors) &&
        errorData?.errors.some((err: any) => err.key === 'post_code')
      ) {
        toastMessage = `modal:${errorData.message}+${errorData.data.message}`;
      } else if (errorData.data) {
        toastMessage = errorData.data.message;
      } else {
        toastMessage = errorData.message;
      }
      break;

    case ApiResponseStatus.SERVER_ERROR:
      toastMessage = translate('server_error_description');
      break;

    case ApiResponseStatus.PRECONDITION_FAILED:
      // TODO: refactor later to support server driven validation!
      // eslint-disable-next-line no-case-declarations
      const validationErrors = errorData.errors as {
        key: string;
        values: string[];
      }[];
      toastMessage = validationErrors
        .map((e) => e.values.join('\n'))
        .join('\n');
      break;

    default:
      toastMessage = errorData.message;
      break;
  }

  if (!toastMessage) {
    toastMessage = error.message;
  }

  return customFormatter
    ? customFormatter(toastMessage, error.response?.status)
    : toastMessage;
}

function handleStringError(
  error: string,
  customFormatter?: (error: string) => React.ReactNode
) {
  return customFormatter ? customFormatter(error) : error;
}

async function getToastMessage(
  error: unknown,
  customFormatter?: (
    error: string,
    status?: number,
    code?: FirebaseErrorCodes
  ) => React.ReactNode
): Promise<string | React.ReactNode> {
  if (React.isValidElement(error)) return error;

  if (error instanceof AxiosError) {
    if (error.response?.status === ApiResponseStatus.UNAUTHORIZED) {
      return;
    }

    return handleAxiosErrors(error, customFormatter);
  }

  if (error instanceof FirebaseError) {
    return handleFirebaseError(error, customFormatter);
  }

  return handleStringError(error?.toString() ?? '', customFormatter);
}

export async function handleError(
  error: unknown,
  customFormatter?: (
    error: string,
    status?: number,
    code?: FirebaseErrorCodes
  ) => React.ReactNode
) {
  const toastMessage = await getToastMessage(error, customFormatter);

  if (!toastMessage) {
    return;
  }

  if (typeof toastMessage === 'string' && toastMessage.startsWith('modal:')) {
    store.parcelCreationStore.setNoDeliveryOptionAvailable(true);

    const [, message] = toastMessage.split(':');
    store.modalStore.open({
      id: Modals.NO_DELIVERY_OPTION,
      name: Modals.NO_DELIVERY_OPTION,
      props: {
        message,
      },
    });
  } else {
    store.commonStore.toastError(toastMessage);
  }
}

export const capitalizeFirstLetter = (text: string): string =>
  text.charAt(0).toUpperCase() + text.slice(1);

export function removeDuplicates<T, K>(
  items: T[] | undefined,
  idSelector: (item: T) => K
) {
  if (!items) return undefined;
  const seenIds = new Set<K>();
  return items.filter((item) => {
    if (seenIds.has(idSelector(item))) {
      return false;
    }
    seenIds.add(idSelector(item));
    return true;
  });
}

export function constructGoogleMapsUrl(
  destination: Geocode,
  origin?: Geocode,
  travelMode = 'driving'
) {
  if (origin) {
    return `https://www.google.com/maps/dir/?api=1&origin=${origin.latitude},${origin.longitude}&destination=${destination.latitude},${destination.longitude}&travelmode=${travelMode}`;
  }

  return `https://www.google.com/maps/search/?api=1&query=${destination.latitude},${destination.longitude}`;
}

export async function executeWithControlledInterval(
  callback: () => Promise<boolean>,
  interval: number,
  maxDuration: number,
  onMaxWaitTimeReached?: () => void,
  onSuccess?: () => void
) {
  const startTime = Date.now();
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  const clearInterval = () => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  };

  const executeCallback = async () => {
    const elapsedTime = Date.now() - startTime;

    if (elapsedTime < maxDuration) {
      const success = await callback();
      if (success) {
        onSuccess && onSuccess();
        clearInterval();
        return;
      }
      timeoutId = setTimeout(executeCallback, interval);
    } else {
      onMaxWaitTimeReached && onMaxWaitTimeReached();
      clearInterval();
    }
  };

  // Start the controlled interval
  executeCallback();
}

/**
 * Postpones the execution of a callback while the predicate returns true.
 * @param callback - The function to execute once the predicate returns false.
 * @param predicate - A function that returns true to postpone or false to proceed.
 * @param checkInterval - Interval in milliseconds to check the predicate (default: 100ms).
 * * @param maxWaitTime - Maximum wait time in milliseconds before forcing execution (default: 60000ms).
 */
export function postponeExecution(
  callback: () => void,
  predicate: () => boolean,
  checkInterval: number = 100,
  maxWaitTime: number = 60000
): void {
  const startTime = Date.now();
  const interval = setInterval(() => {
    const elapsedTime = Date.now() - startTime;

    if (!predicate() || elapsedTime >= maxWaitTime) {
      clearInterval(interval);
      callback();
    }
  }, checkInterval);
}

import { SeparatorContainer, SeparatorLine } from './separator.styles';

interface Props {
  content?: string;
  mt?: string;
  mb?: string;
  ml?: string;
  mr?: string;
}

const Separator = ({ content, mt, mb, ml, mr }: Props) => (
  <SeparatorContainer
    $ml={ml}
    $mr={mr}
    $mt={mt}
    $mb={mb}>
    <SeparatorLine $width='100%' />
    {content && (
      <>
        {content}
        <SeparatorLine $width='100%' />
      </>
    )}
  </SeparatorContainer>
);

export default Separator;

import React, {
  createContext,
  useState,
  ReactNode,
  useMemo,
  useContext,
  useCallback,
} from 'react';
import { useModal } from '../../../common/modal';
import { Modals } from '../../../constants/modals';

interface EditFormContextType {
  openedItemId: number | null;
  modified: boolean;
  openEditForm: (id: number) => void;
  closeEditForm: () => void;
  setModified: (isModified: boolean) => void;
}

const EditFormContext = createContext<EditFormContextType | undefined>(
  undefined
);

export const EditFormProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [openedItemId, setOpenedItemId] = useState<number | null>(null);
  const [modified, setModified] = useState<boolean>(false);

  const { openModal, closeModal } = useModal();

  const handleCloseEditItem = (id: number) => {
    closeModal(Modals.QUIT_EDIT_ITEM);
    setOpenedItemId(id);
    setModified(false);
  };

  const showQuitEditItemDialog = (id: number) => {
    openModal({
      id: Modals.QUIT_EDIT_ITEM,
      name: Modals.QUIT_EDIT_ITEM,
      props: {
        onApprove: () => handleCloseEditItem(id),
      },
    });
  };

  const openEditForm = useCallback(
    (id: number) => {
      if (openedItemId === id) return;

      if (modified) {
        showQuitEditItemDialog(id);
      } else {
        setOpenedItemId(id);
        setModified(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modified, openedItemId]
  );

  const closeEditForm = () => {
    setOpenedItemId(null);
    setModified(false);
  };

  const contextValue = useMemo(
    () => ({
      openedItemId,
      modified,
      openEditForm,
      closeEditForm,
      setModified,
    }),
    [modified, openedItemId, openEditForm]
  );

  return (
    <EditFormContext.Provider value={contextValue}>
      {children}
    </EditFormContext.Provider>
  );
};

export const useEditForm = (): EditFormContextType => {
  const context = useContext(EditFormContext);
  if (!context) {
    throw new Error('useEditForm must be used within an EditFormProvider');
  }
  return context;
};

import React, { Ref, forwardRef } from 'react';
import { InputField, InputPrefix, InputSuffix } from './input.styles';

interface Props
  extends Omit<
    React.HTMLProps<HTMLInputElement>,
    'prefix' | 'id' | 'onChange' | 'onBlur' | 'onFocus'
  > {
  name: string;
  id: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  $borderNone?: boolean;
  prefix?: {
    element: React.ReactNode;
    isClickable?: boolean;
  };
  suffix?: {
    element: React.ReactNode;
    isClickable?: boolean;
  };
  isInvalid?: boolean;
}

const Input = (
  {
    $borderNone,
    disabled = false,
    id,
    inputMode,
    maxLength,
    name,
    placeholder,
    prefix,
    suffix,
    type = 'text',
    value,
    isInvalid,
    required,
    style,
    readOnly,
    autoComplete,
    onBlur,
    onChange,
    onFocus,
  }: Props,
  ref: Ref<HTMLInputElement>
) => (
  <InputField
    $readOnly={readOnly}
    style={style}
    htmlFor={id}
    $error={isInvalid}
    $borderNone={$borderNone}
    $isDisabled={disabled}>
    {prefix?.element && (
      <InputPrefix $isClickable={prefix.isClickable}>
        {prefix.element}
      </InputPrefix>
    )}
    <input
      ref={ref}
      type={type}
      id={id}
      placeholder={placeholder}
      inputMode={inputMode}
      className='flag-emoji-font'
      name={name}
      disabled={disabled}
      value={value}
      formNoValidate
      maxLength={maxLength}
      required={required}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      style={style}
      readOnly={readOnly}
      autoComplete={autoComplete}
      data-type={isInvalid ? 'invalid-input' : undefined}
    />
    {suffix?.element && (
      <InputSuffix $isClickable={suffix.isClickable}>
        {suffix.element}
      </InputSuffix>
    )}
  </InputField>
);

export default forwardRef(Input);

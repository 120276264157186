import { useEffect, useMemo, useState } from 'react';
import { Map, MapCameraChangedEvent, useMap } from '@vis.gl/react-google-maps';
import { useTranslation } from 'react-i18next';
import debounce from 'debounce';
import { observer } from 'mobx-react-lite';
import MapControls from './map-controls.component';
import ClusteredMarkers from './clustered-markers.component';
import { mapConfig, MapPosition, WithGeocode } from './map.config';
import { toGeocode, toLatLng, updateMapPosition } from './map.helpers';
import { Geocode } from '../../models/pudoItem';
import { MapCenterMarker } from './map-center-marker.component';
import { useStore } from '../../stores/store';
import { StyledMapDataLabel } from './map.styles';
import UserCircleMarker from './user-circle-marker.component';

interface MapProps<T extends WithGeocode> {
  center: Geocode;
  userLocation?: Geocode;
  locationPoints: T[];
  markerIconUrl?: string;
  selectedPoint?: T;
  geoPermissionState?: PermissionState;
  hideCurrentLocation?: boolean;
  shouldResetZoom?: boolean;
  onMarkerClick: (locationPoint: T) => void;
  onCenterChanged?: (location: Geocode) => void;
  onClusterClick?: (clusterPoints: T[]) => void;
  onCurrentLocationClick?: () => void;
  onZoomChange: (
    newZoom: number,
    prevZoom: number,
    clusterZoom: number
  ) => void;
}

const GoogleMap = <T extends WithGeocode>({
  center,
  userLocation,
  locationPoints,
  markerIconUrl,
  selectedPoint,
  geoPermissionState,
  hideCurrentLocation,
  shouldResetZoom,
  onMarkerClick,
  onCenterChanged,
  onClusterClick,
  onCurrentLocationClick,
  onZoomChange,
}: MapProps<T>) => {
  const map = useMap(mapConfig.id);
  const [prevZoom, setPrevZoom] = useState(map?.getZoom());
  const [clusterZoom, setClusterZoom] = useState<number | undefined>(undefined);
  const { t } = useTranslation();
  const [mapDragging, setMapDragging] = useState(false);

  const [mapPositionBeforeMarkerClick, setMapPositionBeforeMarkerClick] =
    useState<MapPosition | undefined>();

  const {
    localizationsStore: { languageCode },
    commonStore: { toastSuccess },
    parcelCreationStore: { isLoadingSearchApi },
  } = useStore();

  const currentYear = new Date().getFullYear();

  const mapDataText: { [key: string]: string } = {
    uk: `Дані карт ©${currentYear} Google`,
    en: `Map data ©${currentYear} Google`,
    ru: `Картографические данные ©${currentYear} Google`,
  };
  const mapDataLabel = mapDataText[languageCode] || mapDataText['en'];

  // Update map's center which comes 'from outside' of map component.
  // Currently it may only be due to change of pudoFilterGeocode from MobX store.
  useEffect(() => {
    if (!map || !center?.latitude || !center?.longitude) return;

    map.setCenter(toLatLng(center));
  }, [center, map]);

  useEffect(() => {
    if (shouldResetZoom) {
      map?.setZoom(mapConfig.zoom.min);
    }
  }, [shouldResetZoom, map]);

  useEffect(() => {
    const position = selectedPoint
      ? {
          center: toLatLng(selectedPoint.geocode),
          zoom: mapConfig.zoom.max,
        }
      : mapPositionBeforeMarkerClick;

    updateMapPosition(map, position);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPoint, map]);

  const handleCenterChanged = (event: MapCameraChangedEvent) => {
    tryUpdateBeforeMarkerClickMapPosition(event);
    const newCenter = toGeocode(event.map.getCenter());
    if (newCenter) {
      onCenterChanged && onCenterChanged(newCenter);
    }
  };

  const showZoomOutToast = useMemo(
    () =>
      debounce((currentMap: google.maps.Map) => {
        toastSuccess(t('zoom_out_is_not_available'));
        currentMap.setZoom(mapConfig.zoom.min);
      }, 1000),
    [t, toastSuccess]
  );

  const handleZoomChanged = (event: MapCameraChangedEvent) => {
    if (shouldResetZoom) return;
    tryUpdateBeforeMarkerClickMapPosition(event);
    const newZoom = event.map.getZoom();
    if (newZoom === undefined) return;

    if (newZoom <= mapConfig.zoom.min - 1) {
      showZoomOutToast(event.map);
    }

    onZoomChange &&
      onZoomChange(newZoom, prevZoom ?? -1, clusterZoom ?? mapConfig.zoom.min);

    if (clusterZoom && newZoom < clusterZoom && !selectedPoint) {
      setClusterZoom(undefined);
    }

    setPrevZoom(newZoom);
  };

  function tryUpdateBeforeMarkerClickMapPosition(event: MapCameraChangedEvent) {
    if (!selectedPoint) {
      setMapPositionBeforeMarkerClick({
        center: event.map.getCenter(),
        zoom: event.map.getZoom(),
      });
    }
  }

  function handleCurrentLocationClick() {
    if (!map) return;

    if (userLocation) {
      map.setCenter(toLatLng(userLocation));
    } else {
      onCurrentLocationClick && onCurrentLocationClick();
    }
  }

  function getCurrentLocationButtonState() {
    if (hideCurrentLocation) return 'hidden';
    if (geoPermissionState !== 'granted') return 'denied';
    if (!userLocation) return 'city';
    return 'nearme';
  }

  return (
    <Map
      id={mapConfig.id}
      clickableIcons={false}
      style={mapConfig.containerStyle}
      mapId={mapConfig.mapId}
      mapTypeId={mapConfig.mapTypeId}
      defaultCenter={toLatLng(center)}
      maxZoom={mapConfig.zoom.max}
      minZoom={mapConfig.zoom.min - 1}
      defaultZoom={mapConfig.zoom.min}
      gestureHandling={isLoadingSearchApi ? 'none' : 'greedy'}
      disableDefaultUI
      onZoomChanged={handleZoomChanged}
      onCenterChanged={handleCenterChanged}
      keyboardShortcuts={false}
      onDragstart={() => setMapDragging(true)}
      onDragend={() => setMapDragging(false)}>
      <MapControls
        onCurrentLocationClick={handleCurrentLocationClick}
        disabled={isLoadingSearchApi}
        currentLocationsState={getCurrentLocationButtonState()}
      />

      <MapCenterMarker
        hidden={!!selectedPoint}
        mapDragging={mapDragging}
        mapLoading={isLoadingSearchApi}
      />

      {userLocation && (
        <UserCircleMarker
          location={toLatLng(userLocation)}
          map={map}
        />
      )}

      <ClusteredMarkers
        locationPoints={locationPoints}
        onMarkerClick={onMarkerClick}
        iconUrl={markerIconUrl}
        onClusterClick={(data, zoom) => {
          setClusterZoom(zoom);
          onClusterClick && onClusterClick(data);
        }}
      />

      <StyledMapDataLabel>{mapDataLabel}</StyledMapDataLabel>
    </Map>
  );
};

export default observer(GoogleMap);

import { HsCode } from './hsCode';
import { PudoItem } from './pudoItem';

export class FirstMile {
  branch_id: number = 0;

  rate_schema_id: string = '';

  carrier: CarrierMile = new CarrierMile();

  service: Service = new Service();

  duration: number = 0;

  price: number = 0;

  currency: string = '';

  middle_miles: MiddleMile[] = [];

  middle_mile: MiddleMile = new MiddleMile();

  image: string = '';

  logo: string = '';
}

export class MiddleMile {
  branch_id: number = 0;

  rate_schema_id: string = '';

  carrier: CarrierMile = new CarrierMile();

  service: Service = new Service();

  duration: number = 0;

  price: number = 0;

  currency: string = '';

  delivery_method: DeliveryMethod = 'air';

  id: number = 0;

  summary_price: number = 0;
}

export class LastMile {
  branch_id: number = 0;

  rate_schema_id: string = '';

  carrier: CarrierMile = new CarrierMile();

  service: Service = new Service();

  duration: number = 0;

  price: number = 0;

  currency: string = '';

  image: string = '';

  logo: string = '';
}

export interface StaticTimeStrings {
  air: string;
  sea: string;
  expedited: string;
  car: string;
}

export interface StaticTimeNumbers {
  air: number;
  sea: number;
  expedited: number;
  car: number;
}

export class CarrierMile {
  name: string = '';
  code: string = '';
  slug: string = '';
  logo: string = '';
  delivery_method: string = '';
}

export class Service {
  id: number = 0;

  name: ServiceType | '' = '';

  code: string = '';
}

export class Duration {
  min: number = -1;

  max: number = -1;
}

export type DeliveryMethod = 'air' | 'car' | 'sea' | 'expedited';

export interface MiddleMileMethodWithMaxDuration {
  method: DeliveryMethod;
  maxDurationDays: number;
}

export type DeliveryType = 'courier' | 'post_box';

export type ShipmentStatus =
  | 'draft'
  | 'created'
  | 'in_delivery'
  | 'received'
  | 'paid'
  | 'ready_to_pick_up'
  | 'surcharge'
  | 'custom_clearance'
  | 'sent'
  | 'declined'
  | 'lost';

export interface TrackingItem {
  text: string | null;
  code: string | null;
  detail_text: string | null;
  carrier: string | null;
  country: string | null;
  state: string | null;
  city: string | null;
  created_at: string | null;
}

export interface IStatusHistory {
  status: ShipmentStatus;
  created_at: string | null;
  tracking: TrackingItem[];
}

interface ShipmentFlags {
  can_pay: boolean;
  can_receive_label: boolean;
  payment_in_process: boolean;
  can_receive_invoice: boolean;
}

export interface ShipmentPrice {
  total_price: number;
  currency?: string; // means currency code e.g. USD, CAD, UAH
}

export interface PriceDetails {
  carrierFee: number;
  deliveryFee: number;
  rateFee: number;
}

export interface Detail {
  carrier: string;
  price_details: PriceDetails;
  service: string;
}

export interface ShipmentPriceDetails {
  description: string;
  details: Detail;
  type: string;
  value: number;
}

export const transformShipmentToParcelListShipment = (
  shipment: Shipment
): ParcelListShipment => ({
  id: shipment.id,
  tracking_number: shipment.tracking_number,
  delivery_time: shipment.delivery_time,
  status: shipment.status,
  items: shipment.items,
  is_favorite: shipment.is_favorite,
  is_archived: shipment.is_archived,
  sent_at: shipment.sent_at,
  delivery_at: shipment.delivery_at,
  updated_at: shipment.updated_at,
  created_at: shipment.created_at,
  status_history: shipment.status_history,
  converted_price: shipment.converted_price,
  flags: shipment.flags,
  file_names: shipment.file_names,
  sender: {
    address: {
      city: shipment.sender?.address?.city,
      country: shipment.sender?.country,
    },
    last_name: shipment.sender?.last_name,
    first_name: shipment.sender?.first_name,
  },
  recipient: {
    address: {
      city: shipment.recipient?.address?.city,
      country: shipment.recipient?.country,
    },
    last_name: shipment.recipient?.last_name,
    first_name: shipment.recipient?.first_name,
  },
});

export interface ParcelListShipmentSender {
  address: {
    city?: string;
    country?: string;
  };
  last_name?: string;
  first_name?: string;
}

export interface ParcelListShipmentRecipient extends ParcelListShipmentSender {}

export interface ParcelListShipment {
  id: number;
  tracking_number: string;
  delivery_time: number | null;
  status: ShipmentStatus | null;
  items: HsCode[] | undefined;
  is_favorite: boolean;
  is_archived: boolean;
  sent_at: string | null;
  delivery_at: string | null;
  created_at: string;
  updated_at: string;
  status_history: IStatusHistory[] | null;
  converted_price: ShipmentPrice | null;
  flags: ShipmentFlags | null;
  sender: ParcelListShipmentSender;
  recipient: ParcelListShipmentRecipient;
  file_names?: ShipmentFileNames;
}

export interface ShipmentMarkers {
  sending_type: string;
  tip: string;
  promo: string;
}

export interface ShipmentFileNames {
  invoice: string;
  label: string;
}

export interface Promocodes {
  id: number;
  code: string;
  discount: number;
}

export class Shipment {
  id: number = -1;

  file_names: ShipmentFileNames | undefined = undefined;

  user_id: number = -1;

  tracking_number: any | null = null;
  tracking_numbers: {
    first_mile: string[];
    last_mile: string[];
  } = { first_mile: [], last_mile: [] };
  parcel: Parcel | null = null;

  price: ShipmentPrice | null = null;

  price_details: ShipmentPriceDetails[] | null = null;

  sender: Sender | null = null;

  recipient: Recipient | null = null;

  items: HsCode[] | undefined = [];

  created_at: string = '';

  updated_at: string = '';

  insurance: number = 0;

  insurance_fee: number = 0;

  status: ShipmentStatus | null = null;

  is_favorite: boolean = false;

  is_archived: boolean = false;

  signature: Signature | null = null;

  delivery_time: number | null = null;

  delivery_at: string | null = null;
  sent_at: string | null = null;

  status_history: IStatusHistory[] | null = null;

  converted_price: ShipmentPrice | null = null;

  flags: ShipmentFlags | null = null;

  markers: ShipmentMarkers | null = null;

  taxes: number = 0;

  promocodes: Promocodes[] | null = null;
}

export interface Parcel {
  value: number;
  weight: number;
  vol_weight: number;
  size_x: number;
  size_y: number;
  size_z: number;
}

export interface Sender {
  id: number;
  first_name: string;
  last_name: string;
  country: string;
  country_name: string;
  email: string;
  phone: Phone | undefined;
  contact_phone: Phone | undefined;
  address: Address | undefined;
  carrier: CarrierRecipient;
  courier_pickup: CourierPickup | undefined;
}

export interface Phone {
  country_code: string | null;
  phone_number: string | null;
}

export interface Address {
  full_address?: string;
  post_code: string;
  region: string;
  city: string;
  street: string;
  building: string;
  section: string;
  apartment: string;
  buzz_code: string;
  note: string;
  country: string;
}

export interface CourierPickup {
  time_to?: string;
  date?: string;
  timezone?: string;
  time_from?: string;
}

export interface CarrierRecipient {
  branch_uuid: string;
  branch_name: string;
  delivery_carrier: string;
  delivery_carrier_name: string;
  delivery_service: DeliveryService;
  logo: string;
  custom_logo?: string;
  pudo_info: PudoItem;
}

export type ServiceType = 'post_office' | 'agent' | 'courier' | 'post_box';

export interface DeliveryService {
  service_type: ServiceType;
}

export interface Recipient {
  id: number;
  first_name: any;
  last_name: any;
  country: string;
  country_name: string;
  email: string;
  phone: Phone | undefined;
  address: Address | undefined;
  carrier: CarrierRecipient;
  courier_pickup: CourierPickup | undefined;
}

export interface Signature {
  is_present: boolean;
  mime_type: string;
  url: string;
}

// NOTE: in order for this to work we should have classes instead of interfaces

// // Define schema for Duration
// const DurationSchema = createModelSchema<Duration>(Duration, {
//   min: primitive(),
//   max: primitive()
// });

// // Define schema for Carrier
// const CarrierSchema = createModelSchema(Carrier, {
//   name: primitive(),
//   code: primitive(),
//   slug: primitive()
// });

// // Define schema for Service
// const ServiceSchema = createModelSchema(Service, {
//   name: primitive(),
//   code: primitive()
// });

// // Define schema for MiddleMile
// const MiddleMileSchema = createModelSchema(MiddleMile, {
//   delivery_method: primitive(),
//   price: primitive(),
//   duration: object(DurationSchema)
// });

// // Define schema for FirstMile
// const FirstMileSchema = createModelSchema(FirstMile, {
//   image: primitive(),
//   carrier: object(CarrierSchema),
//   service: object(ServiceSchema),
//   duration: object(DurationSchema),
//   middle_miles: list(object(MiddleMileSchema)),
//   receiving_options: list(primitive())
// });

// // Define schema for LastMile
// const LastMileSchema = createModelSchema(LastMile, {
//   image: primitive(),
//   carrier: object(CarrierSchema),
//   service: object(ServiceSchema),
//   price: primitive(),
//   duration: object(DurationSchema)
// });

// // Define schema for Shipment
// const ShipmentSchema = createModelSchema(Shipment, {
//   id: primitive()
// });

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../tooltip/tooltip.component';
import Button from '../button/button.component';
import { ButtonWrapper } from './map.styles';
import ZoomControls from './zoom-controls.component';

interface Props {
  onCurrentLocationClick: () => void;
  disabled: boolean;
  currentLocationsState: 'nearme' | 'city' | 'denied' | 'hidden';
}

const MapControls = ({
  disabled,
  currentLocationsState,
  onCurrentLocationClick,
}: Props) => (
  <ButtonWrapper $currentLocationsState={currentLocationsState}>
    <Tooltip placement='left'>
      <TooltipTrigger
        style={{ width: '1.6rem' }}
        asChild>
        {currentLocationsState !== 'hidden' && (
          <Button
            id='current-location'
            onClick={onCurrentLocationClick}
            appearance='tertiary'
            icon={{ glyph: 'location-cross' }}
            size='large'
            fullWidth={false}
            disabled={disabled}
          />
        )}
      </TooltipTrigger>
      {currentLocationsState === 'denied' && (
        <TooltipContent maxWidth='25rem'>
          You have denied access to your current location
        </TooltipContent>
      )}
    </Tooltip>
    <ZoomControls disabled={disabled} />
  </ButtonWrapper>
);

export default MapControls;

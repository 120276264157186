import styled from 'styled-components';
import { FontSizes } from '../typography/typography.styles';

interface SeparatorContainerProps {
  $mt?: string;
  $mb?: string;
  $ml?: string;
  $mr?: string;
}

export const SeparatorContainer = styled.div<SeparatorContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${({ $mt = '2.4rem' }) => $mt};
  margin-bottom: ${({ $mb = '0' }) => $mb};
  margin-left: ${({ $ml = '0' }) => $ml};
  margin-right: ${({ $mr = '0' }) => $mr};
  gap: 9px;

  font-size: ${FontSizes.normal};
  color: ${({ theme }) => theme.colors.baseColors.grey80};
  font-weight: 400;
  letter-spacing: 0.05rem;
  line-height: 2rem;
  word-break: keep-all;
`;

interface Props {
  $width?: string;
}

export const SeparatorLine = styled.span<Props>`
  display: inline-block;
  width: ${({ $width = '35%' }) => $width};
  height: 0.1rem;
  background-color: ${({ theme }) => theme.colors.placeholderText.color};
`;

import { ICountriesMarketing } from '../../../models/countries-marketing/countries-marketing';

export const CountriesMarketingAmerica: ICountriesMarketing[] = [
  {
    name: {
      en: 'Albania',
      uk: 'Албанія',
      ru: 'Албания',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '110',
      units: 'lbs',
    },
    maxDimensions: null,
    dutyFreeCustomsLimit: '$0',
    maxValue: '$200 DDP; $1300 DDU',
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-albania',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'AL',
    flagSrc: '/download/flags/svg/Albania.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'Algeria',
      uk: 'Алжир',
      ru: 'Алжир',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '50000 DZD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'DZ',
    flagSrc: '/download/flags/svg/Algeria.svg',
  },
  {
    name: {
      en: 'Andorra',
      uk: 'Андорра',
      ru: 'Андорра',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '220 EUR',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Europe',
    code: 'AD',
    flagSrc: '/download/flags/svg/Andorra.svg',
  },
  {
    name: {
      en: 'Angola',
      uk: 'Ангола',
      ru: 'Ангола',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '100 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'AO',
    flagSrc: '/download/flags/svg/Angola.svg',
  },
  {
    name: {
      en: 'Anguilla',
      uk: 'Ангілья',
      ru: 'Ангилья',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 XCD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'North America',
    code: 'AI',
    flagSrc: '/download/flags/svg/Anguilla.svg',
  },
  {
    name: {
      en: 'Antigua and Barbuda',
      uk: 'Антигуа і Барбуда',
      ru: 'Антигуа и Барбуда',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '$0',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'North America',
    code: 'AG',
    flagSrc: '/download/flags/svg/Antigua and Barbuda.svg',
  },
  {
    name: {
      en: 'Argentina',
      uk: 'Аргентина',
      ru: 'Аргентина',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '$0',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-argentina',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'AR',
    flagSrc: '/download/flags/svg/Argentina.svg',
    region: 'South America',
  },
  {
    name: {
      en: 'Armenia',
      uk: 'Вірменія',
      ru: 'Армения',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '300 USD',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-armenia',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'AM',
    flagSrc: '/download/flags/svg/Armenia.svg',
    region: 'Asia',
  },
  {
    name: {
      en: 'Aruba',
      uk: 'Аруба',
      ru: 'Аруба',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '$0',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'North America',
    code: 'AW',
    flagSrc: '/download/flags/svg/Aruba.svg',
  },
  {
    name: {
      en: 'Australia',
      uk: 'Австралія',
      ru: 'Австралия',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '1000 AUD',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-australia',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'AU',
    flagSrc: '/download/flags/svg/Australia.svg',
    region: 'Oceania',
  },
  {
    name: {
      en: 'Austria',
      uk: 'Австрія',
      ru: 'Австрия',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '45 EUR',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-austria',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'AT',
    flagSrc: '/download/flags/svg/Austria.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'Azerbaijan',
      uk: 'Азербайджан',
      ru: 'Азербайджан',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '44',
      units: 'lbs',
    },
    maxDimensions: null,
    dutyFreeCustomsLimit: '$0',
    maxValue: '$300',
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-azerbaijan',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'AZ',
    flagSrc: '/download/flags/svg/Azerbaijan.svg',
    region: 'Asia',
  },
  {
    name: {
      en: 'Bahamas',
      uk: 'Багамські Острови',
      ru: 'Багамские Острова',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '$0',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'North America',
    code: 'BS',
    flagSrc: '/download/flags/svg/Bahamas.svg',
  },
  {
    name: {
      en: 'Bahrain',
      uk: 'Бахрейн',
      ru: 'Бахрейн',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '300 BHD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Asia',
    code: 'BH',
    flagSrc: '/download/flags/svg/Bahrain.svg',
  },
  {
    name: {
      en: 'Bangladesh',
      uk: 'Бангладеш',
      ru: 'Бангладеш',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '1000 BDT',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Asia',
    code: 'BD',
    flagSrc: '/download/flags/svg/Bangladesh.svg',
  },
  {
    name: {
      en: 'Barbados',
      uk: 'Барбадос',
      ru: 'Барбадос',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 BBD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'North America',
    code: 'BB',
    flagSrc: '/download/flags/svg/Barbados.svg',
  },
  {
    name: {
      en: 'Belgium',
      uk: 'Бельгія',
      ru: 'Бельгия',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '45 EUR',
    maxValue: '150€',
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-belgium',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'BE',
    flagSrc: '/download/flags/svg/Belgium.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'Belize',
      uk: 'Беліз',
      ru: 'Белиз',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 BZD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'North America',
    code: 'BZ',
    flagSrc: '/download/flags/svg/Belize.svg',
  },
  {
    name: {
      en: 'Bermuda',
      uk: 'Бермудські Острови',
      ru: 'Бермудские Острова',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 BMD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'North America',
    code: 'BM',
    flagSrc: '/download/flags/svg/Bermuda.svg',
  },
  {
    name: {
      en: 'Bolivia',
      uk: 'Болівія',
      ru: 'Боливия',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '$0',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-bolivia',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'South America',
    code: 'BO',
    flagSrc: '/download/flags/svg/Bolivia.svg',
  },
  {
    name: {
      en: 'Bosnia and Herzegovina',
      uk: 'Боснія і Герцеговина',
      ru: 'Босния и Герцеговина',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '44',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '50 BAM',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-bosnia-and-herzegovina',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'BA',
    flagSrc: '/download/flags/svg/Bosnia.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'Botswana',
      uk: 'Ботсвана',
      ru: 'Ботсвана',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 BWP',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'BW',
    flagSrc: '/download/flags/svg/Botswana.svg',
  },
  {
    name: {
      en: 'Brazil',
      uk: 'Бразилія',
      ru: 'Бразилия',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '$0',
    maxValue: null,
    moreInformation: 'https://us.meest.com/services/service/delivery-to-brazil',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'BR',
    flagSrc: '/download/flags/svg/Brazil.svg',
    region: 'South America',
  },
  {
    name: {
      en: 'British Virgin Islands',
      uk: 'Британські Віргінські острови',
      ru: 'Британские Виргинские острова',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '$0',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'North America',
    code: 'VG',
    flagSrc: '/download/flags/svg/British Virgin Islands.svg',
  },
  {
    name: {
      en: 'Brunei',
      uk: 'Бруней',
      ru: 'Бруней',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 BND',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Asia',
    code: 'BN',
    flagSrc: '/download/flags/svg/Brunei.svg',
  },
  {
    name: {
      en: 'Bulgaria',
      uk: 'Болгарія',
      ru: 'Болгария',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '44',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '45 EUR',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-bulgaria',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'BG',
    flagSrc: '/download/flags/svg/Bulgaria.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'Burkina Faso',
      uk: 'Буркіна-Фасо',
      ru: 'Буркина-Фасо',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '$0',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'BF',
    flagSrc: '/download/flags/svg/Burkina Faso.svg',
  },
  {
    name: {
      en: 'Burundi',
      uk: 'Бурунді',
      ru: 'Бурунди',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '$0',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'BI',
    flagSrc: '/download/flags/svg/Burundi.svg',
  },
  {
    name: {
      en: 'Cambodia',
      uk: 'Камбоджа',
      ru: 'Камбоджа',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '50 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Asia',
    code: 'KH',
    flagSrc: '/download/flags/svg/Cambodia.svg',
  },
  {
    name: {
      en: 'Cameroon',
      uk: 'Камерун',
      ru: 'Камерун',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '400 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'CM',
    flagSrc: '/download/flags/svg/Cameroon.svg',
  },
  {
    name: {
      en: 'Canada',
      uk: 'Канада',
      ru: 'Канада',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '20 CAD',
    maxValue: null,
    moreInformation: 'https://us.meest.com/services/service/delivery-to-canada',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'CA',
    flagSrc: '/download/flags/svg/Canada.svg',
    region: 'North America',
  },
  {
    name: {
      en: 'Cape Verde',
      uk: 'Кабо-Верде',
      ru: 'Кабо-Верде',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '200 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'CV',
    flagSrc: '/download/flags/svg/Cape Verde.svg',
  },
  {
    name: {
      en: 'Cayman Islands',
      uk: 'Кайманові Острови',
      ru: 'Каймановы Острова',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '$0',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'North America',
    code: 'KY',
    flagSrc: '/download/flags/svg/Cayman Islands.svg',
  },
  {
    name: {
      en: 'Central African Republic',
      uk: 'Центральноафриканська Республіка',
      ru: 'Центральноафриканская Республика',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '20 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'CF',
    flagSrc: '/download/flags/svg/Central African Republic.svg',
  },
  {
    name: {
      en: 'Chad',
      uk: 'Чад',
      ru: 'Чад',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '20 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'TD',
    flagSrc: '/download/flags/svg/Chad.svg',
  },
  {
    name: {
      en: 'Chile',
      uk: 'Чилі',
      ru: 'Чили',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '41 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'CL',
    flagSrc: '/download/flags/svg/Chile.svg',
    region: 'South America',
  },
  {
    name: {
      en: 'China',
      uk: 'Китай',
      ru: 'Китай',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '20 CAD',
    maxValue: null,
    moreInformation: 'https://us.meest.com/services/service/delivery-to-china',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'CN',
    flagSrc: '/download/flags/svg/China.svg',
    region: 'Asia',
  },
  {
    name: {
      en: 'Colombia',
      uk: 'Колумбія',
      ru: 'Колумбия',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '200 USD',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-colombia',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'CO',
    flagSrc: '/download/flags/svg/Colombia.svg',
    region: 'South America',
  },
  {
    name: {
      en: 'Costa Rica',
      uk: 'Коста-Ріка',
      ru: 'Коста-Рика',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '50 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'CR',
    flagSrc: '/download/flags/svg/Costa Rica.svg',
    region: 'North America',
  },
  {
    name: {
      en: 'Croatia',
      uk: 'Хорватія',
      ru: 'Хорватия',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '45 EUR',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-croatia',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'HR',
    flagSrc: '/download/flags/svg/Croatia.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'Curacao',
      uk: 'Кюрасао',
      ru: 'Кюрасао',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '50 ANG',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'North America',
    code: 'CW',
    flagSrc: '/download/flags/svg/Curacao.svg',
  },
  {
    name: {
      en: 'Cyprus',
      uk: 'Кіпр',
      ru: 'Кипр',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '150 EUR',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'CY',
    flagSrc: '/download/flags/svg/Cyprus.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'Czech Republic',
      uk: 'Чеська Республіка',
      ru: 'Чешская Республика',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '45 EUR',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-czech-republic',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Europe',
    code: 'CZ',
    flagSrc: '/download/flags/svg/Czechia.svg',
  },
  {
    name: {
      en: 'Denmark',
      uk: 'Данія',
      ru: 'Дания',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '45 EUR',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-denmark',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'DK',
    flagSrc: '/download/flags/svg/Denmark.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'Djibouti',
      uk: 'Джибуті',
      ru: 'Джибути',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 DJF',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'DJ',
    flagSrc: '/download/flags/svg/Djibouti.svg',
  },
  {
    name: {
      en: 'Dominica',
      uk: 'Домініка',
      ru: 'Доминика',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'North America',
    code: 'DM',
    flagSrc: '/download/flags/svg/Dominica.svg',
  },
  {
    name: {
      en: 'Dominican Republic',
      uk: 'Домініканська Республіка',
      ru: 'Доминиканская Республика',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '200 USD',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-dominican-republic',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'North America',
    code: 'DO',
    flagSrc: '/download/flags/svg/Dominican Republic.svg',
  },
  {
    name: {
      en: 'Ecuador',
      uk: 'Еквадор',
      ru: 'Эквадор',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '10 USD',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-ecuador',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'South America',
    code: 'EC',
    flagSrc: '/download/flags/svg/Ecuador.svg',
  },
  {
    name: {
      en: 'Egypt',
      uk: 'Єгипет',
      ru: 'Египет',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 EGP',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'EG',
    flagSrc: '/download/flags/svg/Egypt.svg',
    region: 'Africa',
  },
  {
    name: {
      en: 'El Salvador',
      uk: 'Сальвадор',
      ru: 'Сальвадор',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'North America',
    code: 'SV',
    flagSrc: '/download/flags/svg/El Salvador.svg',
  },
  {
    name: {
      en: 'Estonia',
      uk: 'Естонія',
      ru: 'Эстония',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '6-7',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '45 EUR',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-estonia',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'EE',
    flagSrc: '/download/flags/svg/Estonia.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'Ethiopia',
      uk: 'Ефіопія',
      ru: 'Эфиопия',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '25 EUR',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'ET',
    flagSrc: '/download/flags/svg/Ethiopia.svg',
    region: 'Africa',
  },
  {
    name: {
      en: 'Faroe Islands',
      uk: 'Фарерські Острови',
      ru: 'Фарерские Острова',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '45 EUR',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-faroe-islands',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Europe',
    code: 'FO',
    flagSrc: '/download/flags/svg/Faroe Islands.svg',
  },
  {
    name: {
      en: 'Finland',
      uk: 'Фінляндія',
      ru: 'Финляндия',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '45 EUR',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-finland',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'FI',
    flagSrc: '/download/flags/svg/Finland.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'France',
      uk: 'Франція',
      ru: 'Франция',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '45 EUR',
    maxValue: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'FR',
    flagSrc: '/download/flags/svg/France.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'French Guiana',
      uk: 'Французька Гвіана',
      ru: 'Французская Гвиана',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '150 EUR',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'South America',
    code: 'GF',
    flagSrc: '/download/flags/svg/French Guiana.svg',
  },
  {
    name: {
      en: 'Gambia',
      uk: 'Гамбія',
      ru: 'Гамбия',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '100 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'GM',
    flagSrc: '/download/flags/svg/Gambia.svg',
  },
  {
    name: {
      en: 'Georgia',
      uk: 'Грузія',
      ru: 'Грузия',
    },
    transitTime: {
      air: {
        value: '5-10',
        units: 'business days',
      },
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '300 LARI',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-georgia',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'GE',
    flagSrc: '/download/flags/svg/Georgia.svg',
    region: 'Asia',
  },
  {
    name: {
      en: 'Germany',
      uk: 'Німеччина',
      ru: 'Германия',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '45 EUR',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-germany',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'DE',
    flagSrc: '/download/flags/svg/Germany.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'Ghana',
      uk: 'Гана',
      ru: 'Гана',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '5 USD',
    maxValue: null,
    moreInformation: 'https://us.meest.com/services/service/delivery-to-ghana',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'GH',
    flagSrc: '/download/flags/svg/Ghana.svg',
  },
  {
    name: {
      en: 'Gibraltar',
      uk: 'Гібралтар',
      ru: 'Гибралтар',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Europe',
    code: 'GI',
    flagSrc: '/download/flags/svg/Gibraltar.svg',
  },
  {
    name: {
      en: 'Greece',
      uk: 'Греція',
      ru: 'Греция',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '45 EUR',
    maxValue: null,
    moreInformation: 'https://us.meest.com/services/service/delivery-to-greece',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'GR',
    flagSrc: '/download/flags/svg/Greece.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'Greenland',
      uk: 'Гренландія',
      ru: 'Гренландия',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '80 DKK',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'North America',
    code: 'GL',
    flagSrc: '/download/flags/svg/Greenland.svg',
  },
  {
    name: {
      en: 'Greenada',
      uk: 'Гренада',
      ru: 'Гренада',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '20 XCD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'North America',
    code: 'GD',
    flagSrc: '/download/flags/svg/Grenada.svg',
  },
  {
    name: {
      en: 'Guadeloupe',
      uk: 'Гваделупа',
      ru: 'Гваделупа',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '150 EUR',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'North America',
    code: 'GP',
    flagSrc: '/download/flags/svg/Guadeloupe.svg',
  },
  {
    name: {
      en: 'Guam',
      uk: 'Гуам',
      ru: 'Гуам',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Oceania',
    code: 'GU',
    flagSrc: '/download/flags/svg/Guam.svg',
  },
  {
    name: {
      en: 'Guatemala',
      uk: 'Гватемала',
      ru: 'Гватемала',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'North America',
    code: 'GT',
    flagSrc: '/download/flags/svg/Guatemala.svg',
  },
  {
    name: {
      en: 'Guinea',
      uk: 'Гвінея',
      ru: 'Гвинея',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '15 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'GN',
    flagSrc: '/download/flags/svg/Guinea.svg',
  },
  {
    name: {
      en: 'Guinea-Bissau',
      uk: 'Гвінея-Бісау',
      ru: 'Гвинея-Бисау',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '100 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'GW',
    flagSrc: '/download/flags/svg/Guinea-Bissau.svg',
  },
  {
    name: {
      en: 'Guyana',
      uk: 'Гайана',
      ru: 'Гайана',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '100 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'South America',
    code: 'GY',
    flagSrc: '/download/flags/svg/Guyana.svg',
  },
  {
    name: {
      en: 'Haiti',
      uk: 'Гаїті',
      ru: 'Гаити',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'North America',
    code: 'HT',
    flagSrc: '/download/flags/svg/Haiti.svg',
  },
  {
    name: {
      en: 'Honduras',
      uk: 'Гондурас',
      ru: 'Гондурас',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'North America',
    code: 'HN',
    flagSrc: '/download/flags/svg/Honduras.svg',
  },
  {
    name: {
      en: 'Hong Kong',
      uk: 'Гонконг',
      ru: 'Гонконг',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'HK',
    flagSrc: '/download/flags/svg/Hong Kon.svg',
    region: 'Asia',
  },
  {
    name: {
      en: 'Hungary',
      uk: 'Угорщина',
      ru: 'Венгрия',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '45 EUR',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-hungary',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'HU',
    flagSrc: '/download/flags/svg/Hungary.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'Iceland',
      uk: 'Ісландія',
      ru: 'Исландия',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '45 EUR',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-iceland',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'IS',
    flagSrc: '/download/flags/svg/Iceland.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'India',
      uk: 'Індія',
      ru: 'Индия',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: null,
      expedited: null,
    },
    maxWeight: {
      value: '40',
      units: 'lbs',
    },
    maxDimensions: null,
    dutyFreeCustomsLimit: 'All duties and taxes included',
    maxValue: '$500',
    moreInformation: 'https://us.meest.com/services/service/delivery-to-india',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'IN',
    flagSrc: '/download/flags/svg/India.svg',
    region: 'Asia',
  },
  {
    name: {
      en: 'Indonesia',
      uk: 'Індонезія',
      ru: 'Индонезия',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '3 USD',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-indonesia',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'ID',
    flagSrc: '/download/flags/svg/Indonesia.svg',
    region: 'Asia',
  },
  {
    name: {
      en: 'Ireland',
      uk: 'Ірландія',
      ru: 'Ирландия',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '150 EUR',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Europe',
    code: 'IE',
    flagSrc: '/download/flags/svg/Ireland.svg',
  },
  {
    name: {
      en: 'Israel',
      uk: 'Ізраїль',
      ru: 'Израиль',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '150 EUR',
    maxValue: null,
    moreInformation: 'https://us.meest.com/services/service/delivery-to-israel',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'IL',
    flagSrc: '/download/flags/svg/Israel.svg',
    region: 'Asia',
  },
  {
    name: {
      en: 'Italy',
      uk: 'Італія',
      ru: 'Италия',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '$75',
    maxValue: '$1000',
    moreInformation: 'https://us.meest.com/services/service/delivery-to-italy',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'IT',
    flagSrc: '/download/flags/svg/Italy.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'Ivory Coast',
      uk: 'Кот-д’Івуар',
      ru: 'Кот-д’Ивуар',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '20 USD',
    maxValue: null,
    moreInformation: 'https://us.meest.com/services/service/delivery-to-israel',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'CI',
    flagSrc: '/download/flags/svg/Ivory Coast.svg',
  },
  {
    name: {
      en: 'Jamaica',
      uk: 'Ямайка',
      ru: 'Ямайка',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '100 USD',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-jamaica',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'North America',
    code: 'JM',
    flagSrc: '/download/flags/svg/Jamaica.svg',
  },
  {
    name: {
      en: 'Japan',
      uk: 'Японія',
      ru: 'Япония',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '10000 JPY',
    maxValue: null,
    moreInformation: 'https://us.meest.com/services/service/delivery-to-japan',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'JP',
    flagSrc: '/download/flags/svg/Japan.svg',
    region: 'Asia',
  },
  {
    name: {
      en: 'Jersey',
      uk: 'Джерсі',
      ru: 'Джерси',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 GBP',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Europe',
    code: 'JE',
    flagSrc: '/download/flags/svg/Jersey.svg',
  },
  {
    name: {
      en: 'Jordan',
      uk: 'Йорданія',
      ru: 'Иордания',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '100 JOD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Asia',
    code: 'JO',
    flagSrc: '/download/flags/svg/Jordan.svg',
  },
  {
    name: {
      en: 'Kazakhstan',
      uk: 'Казахстан',
      ru: 'Казахстан',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '200 EUR',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-kazakhstan',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'KZ',
    flagSrc: '/download/flags/svg/Kazakhstan.svg',
    region: 'Asia',
  },
  {
    name: {
      en: 'Kenya',
      uk: 'Кенія',
      ru: 'Кения',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'KE',
    flagSrc: '/download/flags/svg/Kenya.svg',
    region: 'Africa',
  },
  {
    name: {
      en: 'Kosovo',
      uk: 'Косово',
      ru: 'Косово',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '$0',
    maxValue: '$200 DDP; $1300 DDU',
    moreInformation: 'https://us.meest.com/services/service/delivery-to-kosovo',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Europe',
    code: 'XK',
    flagSrc: '/download/flags/svg/Kosovo.svg',
  },
  {
    name: {
      en: 'Kuwait',
      uk: 'Кувейт',
      ru: 'Кувейт',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '100 KWD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'KW',
    flagSrc: '/download/flags/svg/Kuwait.svg',
    region: 'Asia',
  },
  {
    name: {
      en: 'Kyrgyzstan',
      uk: 'Киргизстан',
      ru: 'Кыргызстан',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '200 EUR',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-kyrgyzstan',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'KG',
    flagSrc: '/download/flags/svg/Kyrgyzstan.svg',
    region: 'Asia',
  },
  {
    name: {
      en: 'Laos',
      uk: 'Лаос',
      ru: 'Лаос',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 LAK',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Asia',
    code: 'LA',
    flagSrc: '/download/flags/svg/Laos.svg',
  },
  {
    name: {
      en: 'Latvia',
      uk: 'Латвія',
      ru: 'Латвия',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '27',
      height: '27',
      depth: '27',
      perimeter: '59',
    },
    dutyFreeCustomsLimit: '$42',
    maxValue: null,
    moreInformation: 'https://us.meest.com/services/service/delivery-to-latvia',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'LV',
    flagSrc: '/download/flags/svg/Latvia.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'Liechtenstein',
      uk: 'Ліхтенштейн',
      ru: 'Лихтенштейн',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 CHF',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Europe',
    code: 'LI',
    flagSrc: '/download/flags/svg/Liechtenstein.svg',
  },
  {
    name: {
      en: 'Lithuania',
      uk: 'Литва',
      ru: 'Литва',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '27',
      height: '27',
      depth: '27',
      perimeter: '59',
    },
    dutyFreeCustomsLimit: '$42',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-lithuania',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'LT',
    flagSrc: '/download/flags/svg/Lithuania.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'Luxembourg',
      uk: 'Люксембург',
      ru: 'Люксембург',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '27',
      height: '27',
      depth: '27',
      perimeter: '59',
    },
    dutyFreeCustomsLimit: '45 EUR',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-luxembourg',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'LU',
    flagSrc: '/download/flags/svg/Luxembourg.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'Macau',
      uk: 'Макао',
      ru: 'Макао',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 MOP',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Asia',
    code: 'MO',
    flagSrc: '/download/flags/svg/Macao.svg',
  },
  {
    name: {
      en: 'Macedonia',
      uk: 'Македонія',
      ru: 'Македония',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '27',
      height: '27',
      depth: '27',
      perimeter: '59',
    },
    dutyFreeCustomsLimit: '$0',
    maxValue: '$200 DDP; $1300 DDU',
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-macedonia',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Europe',
    code: 'MK',
    flagSrc: '/download/flags/svg/Republic of macedonia.svg',
  },
  {
    name: {
      en: 'Madagascar',
      uk: 'Мадагаскар',
      ru: 'Мадагаскар',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '20 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'MG',
    flagSrc: '/download/flags/svg/Madagascar.svg',
  },
  {
    name: {
      en: 'Malawi',
      uk: 'Малаві',
      ru: 'Малави',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 MWK',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'MW',
    flagSrc: '/download/flags/svg/Malawi.svg',
  },
  {
    name: {
      en: 'Malaysia',
      uk: 'Малайзія',
      ru: 'Малайзия',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '500 MYR',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Asia',
    code: 'MY',
    flagSrc: '/download/flags/svg/Malaysia.svg',
  },
  {
    name: {
      en: 'Malta',
      uk: 'Мальта',
      ru: 'Мальта',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '150 EUR',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'MT',
    flagSrc: '/download/flags/svg/Malta.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'Marshall Islands',
      uk: 'Маршаллові Острови',
      ru: 'Маршалловы Острова',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Oceania',
    code: 'MH',
    flagSrc: '/download/flags/svg/Marshall Islands.svg',
  },
  {
    name: {
      en: 'Martinique',
      uk: 'Мартиніка',
      ru: 'Мартиника',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '150 EUR',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'North America',
    code: 'MQ',
    flagSrc: '/download/flags/svg/Martinique.svg',
  },
  {
    name: {
      en: 'Mayotte',
      uk: 'Майотта',
      ru: 'Майотта',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '150 EUR',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'YT',
    flagSrc: '/download/flags/svg/Mayotte.svg',
  },
  {
    name: {
      en: 'Mexico',
      uk: 'Мексика',
      ru: 'Мексика',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '1000 USD',
    maxValue: null,
    moreInformation: 'https://us.meest.com/services/service/delivery-to-mexico',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'MX',
    flagSrc: '/download/flags/svg/Mexico.svg',
    region: 'North America',
  },
  {
    name: {
      en: 'Micronesia, Federated States of',
      uk: 'Федеративні Штати Мікронезії',
      ru: 'Федеративные Штаты Микронезии',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Oceania',
    code: 'FM',
    flagSrc: '/download/flags/svg/Micronesia.svg',
  },
  {
    name: {
      en: 'Moldova',
      uk: 'Молдова',
      ru: 'Молдавия',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '6-7',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '41',
      height: '41',
      depth: '41',
    },
    dutyFreeCustomsLimit: '$150',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-moldova',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'MD',
    flagSrc: '/download/flags/svg/Moldova.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'Monaco',
      uk: 'Монако',
      ru: 'Монако',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '150 EUR',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Europe',
    code: 'MC',
    flagSrc: '/download/flags/svg/Monaco.svg',
  },
  {
    name: {
      en: 'Mongolia',
      uk: 'Монголія',
      ru: 'Монголия',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 MNT',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Asia',
    code: 'MN',
    flagSrc: '/download/flags/svg/Mongolia.svg',
  },
  {
    name: {
      en: 'Montenegro',
      uk: 'Чорногорія',
      ru: 'Черногория',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '45 EUR',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-montenegro',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'ME',
    flagSrc: '/download/flags/svg/Montenegro.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'Montserrat',
      uk: 'Монтсеррат',
      ru: 'Монтсеррат',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'North America',
    code: 'MS',
    flagSrc: '/download/flags/svg/Montserrat.svg',
  },
  {
    name: {
      en: 'Morocco',
      uk: 'Марокко',
      ru: 'Марокко',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '30 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'MA',
    flagSrc: '/download/flags/svg/Morocco.svg',
  },
  {
    name: {
      en: 'Mozambique',
      uk: 'Мозамбік',
      ru: 'Мозамбик',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '30 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'MZ',
    flagSrc: '/download/flags/svg/Mozambique.svg',
  },
  {
    name: {
      en: 'Myanmar',
      uk: "М'янма",
      ru: 'Мьянма',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Asia',
    code: 'MM',
    flagSrc: '/download/flags/svg/Myanmar.svg',
  },
  {
    name: {
      en: 'Namibia',
      uk: 'Намібія',
      ru: 'Намибия',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'NA',
    flagSrc: '/download/flags/svg/Namibia.svg',
  },
  {
    name: {
      en: 'Nepal',
      uk: 'Непал',
      ru: 'Непал',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 NPR',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Asia',
    code: 'NP',
    flagSrc: '/download/flags/svg/Nepal.svg',
  },
  {
    name: {
      en: 'Netherlands',
      uk: 'Нідерланди',
      ru: 'Нидерланды',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '45 EUR',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-netherlands',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'NL',
    flagSrc: '/download/flags/svg/Netherlands.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'New Zealand',
      uk: 'Нова Зеландія',
      ru: 'Новая Зеландия',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '1000 NZD',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-new-zealand',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'NZ',
    flagSrc: '/download/flags/svg/New Zealand.svg',
    region: 'Oceania',
  },
  {
    name: {
      en: 'Nicaragua',
      uk: 'Нікарагуа',
      ru: 'Никарагуа',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 NIO',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'North America',
    code: 'NI',
    flagSrc: '/download/flags/svg/Nicaragua.svg',
  },
  {
    name: {
      en: 'Niger',
      uk: 'Нігер',
      ru: 'Нигер',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '20 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'NE',
    flagSrc: '/download/flags/svg/Niger.svg',
  },
  {
    name: {
      en: 'Nigeria',
      uk: 'Нігерія',
      ru: 'Нигерия',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '$0',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-nigeria',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'NG',
    flagSrc: '/download/flags/svg/Nigeria.svg',
    region: 'Africa',
  },
  {
    name: {
      en: 'Northern Mariana Islands',
      uk: 'Північні Маріанські Острови',
      ru: 'Северные Марианские Острова',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Oceania',
    code: 'MP',
    flagSrc: '/download/flags/svg/Northern Marianas Islands.svg',
  },
  {
    name: {
      en: 'Norway',
      uk: 'Норвегія',
      ru: 'Норвегия',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '44',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '$0',
    maxValue: null,
    moreInformation: 'https://us.meest.com/services/service/delivery-to-norway',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'NO',
    flagSrc: '/download/flags/svg/Norway.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'Oman',
      uk: 'Оман',
      ru: 'Оман',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Asia',
    code: 'OM',
    flagSrc: '/download/flags/svg/Oman.svg',
  },
  {
    name: {
      en: 'Pakistan',
      uk: 'Пакистан',
      ru: 'Пакистан',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '$0',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-pakistan',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'PK',
    flagSrc: '/download/flags/svg/Pakistan.svg',
    region: 'Asia',
  },
  {
    name: {
      en: 'Palau',
      uk: 'Палау',
      ru: 'Палау',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Oceania',
    code: 'PW',
    flagSrc: '/download/flags/svg/Palau.svg',
  },
  {
    name: {
      en: 'Panama',
      uk: 'Панама',
      ru: 'Панама',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '100 PAB',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'North America',
    code: 'PA',
    flagSrc: '/download/flags/svg/Panama.svg',
  },
  {
    name: {
      en: 'Papua New Guinea',
      uk: 'Папуа Нова Гвінея',
      ru: 'Папуа-Новая Гвинея',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '250 PGK',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Oceania',
    code: 'PG',
    flagSrc: '/download/flags/svg/Papua New Guinea.svg',
  },
  {
    name: {
      en: 'Paraguay',
      uk: 'Парагвай',
      ru: 'Парагвай',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '20 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'South America',
    code: 'PY',
    flagSrc: '/download/flags/svg/Paraguay.svg',
  },
  {
    name: {
      en: 'Peru',
      uk: 'Перу',
      ru: 'Перу',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '200 USD',
    maxValue: null,
    moreInformation: 'https://us.meest.com/services/service/delivery-to-peru',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'PE',
    flagSrc: '/download/flags/svg/Peru.svg',
    region: 'South America',
  },
  {
    name: {
      en: 'Phillipines',
      uk: 'Філіппіни',
      ru: 'Филиппины',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '10000 PHP',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-philippines',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Asia',
    code: 'PH',
    flagSrc: '/download/flags/svg/Phillipines.svg',
  },
  {
    name: {
      en: 'Poland',
      uk: 'Польща',
      ru: 'Польша',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: null,
    },
    maxWeight: {
      value: ' 66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
    },
    dutyFreeCustomsLimit: '59 inch each side. Max. perimeter up to 118 inch',
    maxValue: null,
    moreInformation: 'https://us.meest.com/services/service/delivery-to-poland',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'PL',
    flagSrc: '/download/flags/svg/Poland.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'Portugal',
      uk: 'Португалія',
      ru: 'Португалия',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '45 EUR',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-portugal',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'PT',
    flagSrc: '/download/flags/svg/Portugal.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'Qatar',
      uk: 'Катар',
      ru: 'Катар',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 QAR',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'QA',
    flagSrc: '/download/flags/svg/Qatar.svg',
    region: 'Asia',
  },
  {
    name: {
      en: 'Reunion',
      uk: 'Реюньйон',
      ru: 'Реюньон',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '150 EUR',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'RE',
    flagSrc: '/download/flags/svg/Reunion.svg',
  },
  {
    name: {
      en: 'Romania',
      uk: 'Румунія',
      ru: 'Румыния',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '45 EUR',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-romania',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'RO',
    flagSrc: '/download/flags/svg/Romania.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'Rwanda',
      uk: 'Руанда',
      ru: 'Руанда',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'RW',
    flagSrc: '/download/flags/svg/Rwanda.svg',
  },
  {
    name: {
      en: 'Samoa',
      uk: 'Самоа',
      ru: 'Самоа',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 SDG',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Oceania',
    code: 'WS',
    flagSrc: '/download/flags/svg/Samoa.svg',
  },
  {
    name: {
      en: 'San Marino',
      uk: 'Сан-Марино',
      ru: 'Сан-Марино',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '$0',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-san-marino',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Europe',
    code: 'SM',
    flagSrc: '/download/flags/svg/San Marino.svg',
  },
  {
    name: {
      en: 'Saudi Arabia',
      uk: 'Саудівська Аравія',
      ru: 'Саудовская Аравия',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '1000 SAR',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-saudi-arabia',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'SA',
    flagSrc: '/download/flags/svg/Saudi Arabia.svg',
    region: 'Asia',
  },
  {
    name: {
      en: 'Senegal',
      uk: 'Сенегал',
      ru: 'Сенегал',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '30 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'SN',
    flagSrc: '/download/flags/svg/Senegal.svg',
  },
  {
    name: {
      en: 'Serbia',
      uk: 'Сербія',
      ru: 'Сербия',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '41',
      height: '41',
      depth: '41',
    },
    dutyFreeCustomsLimit: '45 EUR',
    maxValue: '150 EUR',
    moreInformation: 'https://us.meest.com/services/service/delivery-to-serbia',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'RS',
    flagSrc: '/download/flags/svg/Serbia.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'Seychelles',
      uk: 'Сейшели',
      ru: 'Сейшельские Острова',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 SCR',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'SC',
    flagSrc: '/download/flags/svg/Seychelles.svg',
  },
  {
    name: {
      en: 'Sierra Leone',
      uk: 'Сьєрра-Леоне',
      ru: 'Сьерра-Леоне',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'SL',
    flagSrc: '/download/flags/svg/Sierra Leone.svg',
  },
  {
    name: {
      en: 'Singapore',
      uk: 'Сінгапур',
      ru: 'Сингапур',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '400 SGD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'SG',
    flagSrc: '/download/flags/svg/Singapore.svg',
    region: 'Asia',
  },
  {
    name: {
      en: 'Slovakia',
      uk: 'Словаччина',
      ru: 'Словакия',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '27',
      height: '27',
      depth: '27',
      perimeter: '59',
    },
    dutyFreeCustomsLimit: '45 EUR',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-slovakia',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'SK',
    flagSrc: '/download/flags/svg/Slovakia.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'Slovenia',
      uk: 'Словенія',
      ru: 'Словения',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '27',
      height: '27',
      depth: '27',
      perimeter: '59',
    },
    dutyFreeCustomsLimit: '45 EUR',
    maxValue: '150 EUR',
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-slovenia',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'SI',
    flagSrc: '/download/flags/svg/Slovenia.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'Solomon Islands',
      uk: 'Соломонові Острови',
      ru: 'Соломоновы Острова',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Oceania',
    code: 'SB',
    flagSrc: '/download/flags/svg/Solomon Islands.svg',
  },
  {
    name: {
      en: 'South Africa',
      uk: 'Південно-Африканська Республіка',
      ru: 'Южная Африка',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 ZAR',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-south-africa',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'ZA',
    flagSrc: '/download/flags/svg/South-Africa.svg',
    region: 'Africa',
  },
  {
    name: {
      en: 'South Korea',
      uk: 'Південна Корея',
      ru: 'Южная Корея',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '150 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Asia',
    code: 'KR',
    flagSrc: '/download/flags/svg/South Korea.svg',
  },
  {
    name: {
      en: 'Spain',
      uk: 'Іспанія',
      ru: 'Испания',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '45 EUR',
    maxValue: null,
    moreInformation: 'https://us.meest.com/services/service/delivery-to-spain',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'ES',
    flagSrc: '/download/flags/svg/Spain.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'Sri Lanka',
      uk: 'Шрі-Ланка',
      ru: 'Шри-Ланка',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '150 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'LK',
    flagSrc: '/download/flags/svg/Sri-Lanka.svg',
    region: 'Asia',
  },
  {
    name: {
      en: 'St. Kitts and Nevis',
      uk: 'Сент-Кітс і Невіс',
      ru: 'Сент-Китс и Невис',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'North America',
    code: 'KN',
    flagSrc: '/download/flags/svg/St. Kitts and Nevis.svg',
  },
  {
    name: {
      en: 'St. Maarten',
      uk: 'Сінт-Мартен',
      ru: 'Синт-Мартен',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'North America',
    code: 'SX',
    flagSrc: '/download/flags/svg/Sint Maarten.svg',
  },
  {
    name: {
      en: 'St. Vincent and the Grenadines',
      uk: 'Сент-Вінсент і Гренадини',
      ru: 'Сент-Винсент и Гренадины',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 XCD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'North America',
    code: 'VC',
    flagSrc: '/download/flags/svg/Saint Vincent and the Grenadines.svg',
  },
  {
    name: {
      en: 'Suriname',
      uk: 'Суринам',
      ru: 'Суринам',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 XCD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'South America',
    code: 'SR',
    flagSrc: '/download/flags/svg/Suriname.svg',
  },
  {
    name: {
      en: 'Swaziland',
      uk: 'Есватіні',
      ru: 'Эсватини',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'SZ',
    flagSrc: '/download/flags/svg/Swaziland (Eswatini).svg',
  },
  {
    name: {
      en: 'Sweden',
      uk: 'Швеція',
      ru: 'Швеция',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '44',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '45 EUR',
    maxValue: null,
    moreInformation: 'https://us.meest.com/services/service/delivery-to-sweden',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'SE',
    flagSrc: '/download/flags/svg/Sweden.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'Switzerland',
      uk: 'Швейцарія',
      ru: 'Швейцария',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '44',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '100 CHF',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-switzerland',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'CH',
    flagSrc: '/download/flags/svg/Switzerland.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'Taiwan',
      uk: 'Тайвань',
      ru: 'Тайвань',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '2000 TWD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Asia',
    code: 'TW',
    flagSrc: '/download/flags/svg/Taiwan.svg',
  },
  {
    name: {
      en: 'Tajikistan',
      uk: 'Таджикистан',
      ru: 'Таджикистан',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '$0',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-tajikistan',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Asia',
    code: 'TJ',
    flagSrc: '/download/flags/svg/Tajikistan.svg',
  },
  {
    name: {
      en: 'Tanzania',
      uk: 'Танзанія',
      ru: 'Танзания',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 TZS',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'TZ',
    flagSrc: '/download/flags/svg/Tanzania.svg',
    region: 'Africa',
  },
  {
    name: {
      en: 'Thailand',
      uk: 'Таїланд',
      ru: 'Таиланд',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '1500 THB',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Asia',
    code: 'TH',
    flagSrc: '/download/flags/svg/Thailand.svg',
  },
  {
    name: {
      en: 'Togo',
      uk: 'Того',
      ru: 'Того',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '400 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'TG',
    flagSrc: '/download/flags/svg/Togo.svg',
  },
  {
    name: {
      en: 'Tonga',
      uk: 'Тонга',
      ru: 'Тонга',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Oceania',
    code: 'TO',
    flagSrc: '/download/flags/svg/Tonga.svg',
  },
  {
    name: {
      en: 'Trinidad and Tobago',
      uk: 'Тринідад і Тобаго',
      ru: 'Тринидад и Тобаго',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 TTD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'North America',
    code: 'TT',
    flagSrc: '/download/flags/svg/Trinidad and Tobago.svg',
  },
  {
    name: {
      en: 'Tunisia',
      uk: 'Туніс',
      ru: 'Тунис',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 TND',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'TN',
    flagSrc: '/download/flags/svg/Tunisia.svg',
  },
  {
    name: {
      en: 'Turkmenistan',
      uk: 'Туркменістан',
      ru: 'Туркменистан',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Asia',
    code: 'TM',
    flagSrc: '/download/flags/svg/Turkmenistan.svg',
  },
  {
    name: {
      en: 'Turks and Caicos Islands',
      uk: 'Теркс і Кайкос',
      ru: 'Теркс и Кайкос',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'North America',
    code: 'TC',
    flagSrc: '/download/flags/svg/Turks and Caicos Islands.svg',
  },
  {
    name: {
      en: 'U.S. Virgin Islands',
      uk: 'Американські Віргінські Острови',
      ru: 'Американские Виргинские Острова',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'North America',
    code: 'VI',
    flagSrc: '/download/flags/svg/U.S. Virgin Islands.svg',
  },
  {
    name: {
      en: 'Uganda',
      uk: 'Уганда',
      ru: 'Уганда',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'UG',
    flagSrc: '/download/flags/svg/Uganda.svg',
  },
  {
    name: {
      en: 'Ukraine',
      uk: 'Україна',
      ru: 'Украина',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: null,
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '41',
      height: '41',
      depth: '41',
    },
    dutyFreeCustomsLimit: '150 EUR',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-ukraine',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'UA',
    flagSrc: '/download/flags/svg/Ukraine.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'United Arab Emirates',
      uk: "Об'єднані Арабські Емірати",
      ru: 'Объединенные Арабские Эмираты',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '1000 AED',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-united-arab-emirates',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'AE',
    flagSrc: '/download/flags/svg/United-Arab-Emirates.svg',
    region: 'Asia',
  },
  {
    name: {
      en: 'United Kingdom',
      uk: 'Велика Британія',
      ru: 'Великобритания',
    },
    transitTime: {
      air: {
        value: '10-14',
        units: 'business days',
      },
      sea: {
        value: '4-6',
        units: 'weeks',
      },
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '44',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '$0',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-united-kingdom',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'GB',
    flagSrc: '/download/flags/svg/United-Kingdom.svg',
    region: 'Europe',
  },
  {
    name: {
      en: 'Uruguay',
      uk: 'Уругвай',
      ru: 'Уругвай',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '1 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'South America',
    code: 'UY',
    flagSrc: '/download/flags/svg/Uruguay.svg',
  },
  {
    name: {
      en: 'Uzbekistan',
      uk: 'Узбекистан',
      ru: 'Узбекистан',
    },
    transitTime: {
      air: {
        value: '5-10',
        units: 'business days',
      },
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '44',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '59',
      height: '59',
      depth: '59',
      perimeter: '118',
    },
    dutyFreeCustomsLimit: '$1000',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-uzbekistan',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    code: 'UZ',
    flagSrc: '/download/flags/svg/Uzbekistan.svg',
    region: 'Asia',
  },
  {
    name: {
      en: 'Vatican City',
      uk: 'Ватикан',
      ru: 'Ватикан',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '0 EUR',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Europe',
    code: 'VA',
    flagSrc: '/download/flags/svg/Vatican City.svg',
  },
  {
    name: {
      en: 'Vietnam',
      uk: 'В’єтнам',
      ru: 'Вьетнам',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '1000000 VND',
    maxValue: null,
    moreInformation:
      'https://us.meest.com/services/service/delivery-to-vietnam',
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Asia',
    code: 'VN',
    flagSrc: '/download/flags/svg/Vietnam.svg',
  },
  {
    name: {
      en: 'Zambia',
      uk: 'Замбія',
      ru: 'Замбия',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '50 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'ZM',
    flagSrc: '/download/flags/svg/Zambia.svg',
  },
  {
    name: {
      en: 'Zimbabwe',
      uk: 'Зімбабве',
      ru: 'Зимбабве',
    },
    transitTime: {
      air: null,
      sea: null,
      expedited: {
        value: '3-5',
        units: 'business days',
      },
    },
    maxWeight: {
      value: '66',
      units: 'lbs',
    },
    maxDimensions: {
      units: 'inch',
      width: '48',
      height: '24',
      depth: '24',
    },
    dutyFreeCustomsLimit: '10 USD',
    maxValue: null,
    moreInformation: null,
    insurance:
      'All parcels are insured for $60. Additional insurance will cost 5% of the insured value',
    region: 'Africa',
    code: 'ZW',
    flagSrc: '/download/flags/svg/Zimbabwe.svg',
  },
];

import { useField } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { IconsContainer } from '../input/input.styles';
import PasswordValidator from '../password-validator/password-validator.component';
import { useStore } from '../../stores/store';
import FormField from '../input/form-field.component';
import { PassVisibilityIcon } from './form-field-password.styles';
import { validatePassword } from './helpers';
import { MaxLength } from '../form-field-text/form-field-text.component';
import ExternalLink from '../link/external-link.component';

interface Props {
  name: string;
  placeholder: string;
  label: string;
  shouldValidatePassword?: boolean;
  forgotPassword?: boolean;
  shouldValidateOnEmptyDirty?: boolean;
  maxLength?: MaxLength;
  isLabelHidden?: boolean;
  autoComplete?: string;
}

const FormFieldPassword = ({
  name,
  placeholder,
  label,
  shouldValidatePassword,
  forgotPassword,
  shouldValidateOnEmptyDirty = false,
  isLabelHidden,
  maxLength,
  autoComplete,
}: Props) => {
  const [field, meta] = useField(name);
  const [isPassShown, setPassShown] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    navStore: { setAuthCurrentScreen },
  } = useStore();
  const { t } = useTranslation();

  // When show/hide password - return focus to pass input and move cursor to the end
  useEffect(() => {
    if (!isPassShown) return;

    if (inputRef && inputRef.current && inputRef.current.value.length > 0) {
      const { length } = inputRef.current.value;
      inputRef.current.focus();
      inputRef.current.setSelectionRange(length, length);
    }
  }, [isPassShown]);

  const validationErrorIndexes = shouldValidatePassword
    ? validatePassword(field.value)
    : [];

  function handleShowPasswordClick() {
    setPassShown(!isPassShown);
  }

  const isInvalid = shouldValidateOnEmptyDirty
    ? meta.touched && !!meta.error
    : meta.value.length > 0 && meta.touched && !!meta.error;

  const errorMessage =
    (field.value.length === 0 || !shouldValidatePassword) &&
    isInvalid &&
    t(meta.error!);

  return (
    <div>
      <FormField
        {...field}
        autoComplete={autoComplete}
        name={name}
        maxLength={maxLength}
        optionalText={t('optional')}
        label={t(label)}
        labelEndElement={
          forgotPassword && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <ExternalLink
              type='button'
              hasUnderlineOnHover
              as='button'
              onClick={() => setAuthCurrentScreen('password-reset-form')}>
              {t('forgot_password')}
            </ExternalLink>
          )
        }
        suffix={{
          element: (
            <IconsContainer>
              <PassVisibilityIcon
                onClick={handleShowPasswordClick}
                $type={isPassShown ? 'hide' : 'show'}
              />
            </IconsContainer>
          ),
          isClickable: true,
        }}
        errorMessage={errorMessage || undefined}
        isLabelHidden={isLabelHidden}
        ref={inputRef}
        type={isPassShown ? 'text' : 'password'}
        placeholder={placeholder ? t(placeholder) : undefined}
      />
      {shouldValidatePassword &&
        field.value.length > 0 &&
        validationErrorIndexes.length > 0 && (
          <PasswordValidator validationErrorIndexes={validationErrorIndexes} />
        )}
    </div>
  );
};

export default observer(FormFieldPassword);

import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import Button from '../../../common/button/button.component';
import { HsCode } from '../../../models/hsCode';
import {
  FormBtnsBlock,
  FormInputs,
  ParcelItemEditFormContainer,
} from './parcel-items-management.styles';
import FormFieldText from '../../../common/form-field-text/form-field-text.component';
import ItemCategorySearch from './parcel-item-category-search.component';
import { transformToDecimal } from '../../../utils/forms/forms.utils';
import InputMessage from '../../../common/input-message/input-message.component';
import { useStore } from '../../../stores/store';
import { useEditForm } from './edit-form-manager';
import { isAnyValueEmpty } from '../../../utils/generic/generic.utils';

interface FormValue {
  hsCode?: HsCode;
  category?: string;
  quantity?: number;
  declaredValue?: number;
}
interface Props {
  parcelItem?: HsCode;
  displayCancelButton: boolean;
  currencySymbol: string;
}

const pracelItemValidationSchema = Yup.object({
  category: Yup.string().required().min(3),
  quantity: Yup.number()
    .min(1, 'only_numbers')
    .max(1000000, 'only_numbers') // Will be later replaced with maxQuantity from backend
    .required('this_field_cannot_be_empty')
    .integer('only_numbers')
    .typeError('only_numbers'),
  declaredValue: Yup.number()
    .min(0.01, 'this_field_cannot_be_empty')
    .required('this_field_cannot_be_empty')
    .max(10 ** 8 - 0.1, 'only_numbers')
    .typeError('only_numbers'),
});

const ParcelItemEditForm = ({
  parcelItem,
  displayCancelButton,
  currencySymbol,
}: Props) => {
  const {
    parcelCreationStore: {
      setIsNextButtonClicked,
      isNextButtonClicked,
      shipment,
      addItemToShipment,
      modifyItemInShipment,
    },
  } = useStore();

  const { closeEditForm, setModified } = useEditForm();

  const initialValues: FormValue = {
    hsCode: parcelItem,
    category: parcelItem?.description_standart ?? '',
    quantity: parcelItem?.number_of_items ?? 1,
    declaredValue: parcelItem?.value,
  };

  const { t } = useTranslation();

  const handleFormSubmit = async (
    formValues: FormValue,
    formikHelpers: FormikHelpers<FormValue>
  ) => {
    const newParcelItem = {
      ...formValues.hsCode,
      number_of_items: formValues.quantity,
      value: formValues.declaredValue!,
    } as HsCode;

    let declaredValueError: string | undefined;

    if (parcelItem) {
      // take existing id, which is required for update operation
      newParcelItem.id = parcelItem.id;
      declaredValueError = await modifyItemInShipment(newParcelItem!);
    } else {
      declaredValueError = await addItemToShipment(newParcelItem!);
    }

    if (declaredValueError) {
      formikHelpers.setFieldError('declaredValue', declaredValueError);
      return;
    }

    closeEditForm();
  };

  return (
    <ParcelItemEditFormContainer>
      <Formik
        validationSchema={pracelItemValidationSchema}
        initialValues={initialValues}
        onSubmit={(values, helpers) => handleFormSubmit(values, helpers)}>
        {({
          submitForm,
          values,
          setFieldValue,
          dirty,
          isValid,
          isSubmitting,
        }) => {
          const hasValuesChanged =
            JSON.stringify(values) !== JSON.stringify(initialValues);

          setModified(hasValuesChanged);

          const isAllFieldsProvided = !isAnyValueEmpty(values);

          const triggerFieldValidation =
            isNextButtonClicked && !shipment?.items?.length;

          const showSaveChangesError =
            triggerFieldValidation && isAllFieldsProvided;

          return (
            <form
              onSubmit={(event) => {
                event.preventDefault();
                submitForm();
              }}>
              <FormInputs>
                <ItemCategorySearch />

                <FormFieldText
                  name='quantity'
                  placeholder='1'
                  label={t('quantity')}
                  inputMode='numeric'
                  onChange={(event) => {
                    const val = event.target.value.replace(/\D/g, '');
                    setFieldValue(event.target.name, val);
                    setIsNextButtonClicked(false);
                  }}
                  triggerValidation={triggerFieldValidation}
                />

                <FormFieldText
                  name='declaredValue'
                  placeholder=''
                  label={t('declared_value')}
                  infoText={currencySymbol}
                  inputMode='decimal'
                  onChange={(event) => {
                    setFieldValue(
                      event.target.name,
                      transformToDecimal(event.target.value)
                    );
                    setIsNextButtonClicked(false);
                  }}
                  triggerValidation={triggerFieldValidation}
                />
                {showSaveChangesError && (
                  <InputMessage type='error'>
                    {t('please_save_changes')}
                  </InputMessage>
                )}
              </FormInputs>

              <FormBtnsBlock $editMode={displayCancelButton}>
                {displayCancelButton && (
                  <Button
                    appearance='secondary'
                    onClick={closeEditForm}>
                    {t('cancel')}
                  </Button>
                )}
                <Button
                  type='submit'
                  disabled={!dirty || !isValid}
                  isLoading={isSubmitting}>
                  {!parcelItem ? t('add') : t('save_changes')}
                </Button>
              </FormBtnsBlock>
            </form>
          );
        }}
      </Formik>
    </ParcelItemEditFormContainer>
  );
};

export default observer(ParcelItemEditForm);

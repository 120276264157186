import styled from 'styled-components';
import { FontSizes } from '../../../common/typography/typography.styles';

export const QRCodeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.8rem;
  padding: 1.2rem;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.baseColors.blue0};
  color: ${({ theme }) => theme.colors.baseColors.blue100};
  font-size: ${FontSizes.normal};
  font-weight: 600;
  line-height: 2rem;
  text-transform: uppercase;
  margin-top: auto;
  z-index: 1;
  p {
    display: block;
    width: calc(100% - 50px);
  }
  img,
  svg {
    display: block;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
`;

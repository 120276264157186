import { Navigate, RouteObject, createBrowserRouter } from 'react-router-dom';
import Authorization from './app/routes/authorization/authorization.component';
import AddPhoneNumber from './app/features/authentication/phone-verification/add-phone.component';
import PersonalData from './app/routes/personal-data/personal-data.component';
import Services from './app/routes/services/services.component';
import SecuritySettings from './app/routes/security-settings/security-settings.component';
import NotFound from './app/routes/not-found/not-found.component';
import ServerError from './app/routes/server-error/server-error.component';
import ComingSoon from './app/routes/coming-soon/coming-soon.component';
import ParcelDetails from './app/routes/parcel-details/parcel-details.component';
import ParcelCreation from './app/routes/parcel-creation/parcel-creation.component';
import ParcelDelivery from './app/routes/parcel-delivery/parcel-delivery.component';
import ParcelSender from './app/routes/parcel-sender/parcel-sender.component';
import ParcelList from './app/routes/parcel-list/parcel-list.component';
import Main from './app/routes/main/main.component';
import ReceiversList from './app/routes/receivers-list/receivers-list.component';
import PrivateRoute from './app/common/private-route/private-route.component';
import UnauthorizedRoute from './app/common/unauthorized-route/unauthorized-route.component';
import { AppRoute, LOCALE_PARAM } from './app/constants/route';
import AppLayout from './app/layouts/app-layout/app-layout.component';
import Root from './app/routes/root/root.component';
import LocaleSupportedCheckRoute from './app/common/locale-check-route/locale-check-route.component';
import HasMainPageValidator from './app/common/has-main-page-validator/has-main-page-validator.component';
import WidgetCalculatorForm from './app/features/main/widget-calculator/widget-calculator-form.component';
import DataMigrationAgreement from './app/features/authentication/data-migration-agreement/data-migration-agreement.component';
import LeaveFeedback from './app/routes/leave-feedback/leave-feedback.component';
import ParcelReceiver from './app/routes/parcel-receiver/parcel-receiver.component';
import ParcelContent from './app/routes/parcel-content/parcel-content.component';
import Summary from './app/routes/parcel-summary/parcel-summary.component';
import ParcelVerificationPhone from './app/routes/parcel-verification-phone/parcel-verification-phone.component';
import ParcelDeliveryInstructions from './app/routes/parcel-delivery-instructions/parcel-delivery-instructions.component';
import ParcelSignature from './app/routes/parcel-signature/parcel-signature.component';

export const routes: RouteObject[] = [
  {
    element: <AppLayout />,
    children: [
      {
        path: AppRoute.ROOT,
        element: <Root />,
      },
      {
        path: `/:${LOCALE_PARAM}/${AppRoute.PORTAL}/${AppRoute.CALCULATOR}`,
        element: <WidgetCalculatorForm isFrameMode />,
      },
      {
        path: `/:${LOCALE_PARAM}/${AppRoute.PORTAL}`,
        element: (
          <HasMainPageValidator>
            <LocaleSupportedCheckRoute>
              <Main />
            </LocaleSupportedCheckRoute>
          </HasMainPageValidator>
        ),
      },
      {
        path: `/:${LOCALE_PARAM}/${AppRoute.PORTAL}/${AppRoute.NOT_FOUND}`,
        element: <NotFound />,
      },
      {
        path: `/:${LOCALE_PARAM}/${AppRoute.PORTAL}/${AppRoute.SERVER_ERROR}`,
        element: <ServerError />,
      },
      {
        path: `/:${LOCALE_PARAM}/${AppRoute.PORTAL}/${AppRoute.COMING_SOON}`,
        element: <ComingSoon />,
      },
      {
        path: `/:${LOCALE_PARAM}/${AppRoute.PORTAL}/${AppRoute.AUTH}`,
        element: (
          <UnauthorizedRoute>
            <Authorization viewMode='page' />
          </UnauthorizedRoute>
        ),
        children: [
          {
            path: AppRoute.PHONE_VERIFICATION,
            element: <AddPhoneNumber />,
          },
          {
            path: AppRoute.DATA_MIGRATION,
            element: <DataMigrationAgreement />,
          },
        ],
      },
      {
        path: `/:${LOCALE_PARAM}/${AppRoute.PORTAL}/${AppRoute.MY}`,
        element: (
          <PrivateRoute>
            <Services />
          </PrivateRoute>
        ),
        children: [
          { path: AppRoute.LEAVE_FEEDBACK, element: <LeaveFeedback /> },
          { path: AppRoute.PERSONAL_DATA, element: <PersonalData /> },
          {
            path: AppRoute.SECURITY_SETTINGS,
            element: <SecuritySettings />,
          },
          {
            path: AppRoute.PARCEL_LIST,
            element: <ParcelList routeName='parcellist' />,
          },
          {
            path: AppRoute.DRAFTS,
            element: <ParcelList routeName='drafts' />,
          },
          {
            path: AppRoute.ARCHIVE,
            element: <ParcelList routeName='archive' />,
          },
          {
            path: AppRoute.RECEIVERS,
            element: <ReceiversList />,
          },
        ],
      },
      {
        path: `/:${LOCALE_PARAM}/${AppRoute.PORTAL}/${AppRoute.INTERNATIONAL}`,
        element: <Services />,
        children: [
          {
            path: AppRoute.PARCEL_CREATE,
            element: (
              <PrivateRoute>
                <ParcelCreation />
              </PrivateRoute>
            ),
            children: [
              {
                path: AppRoute.PARCEL_CREATION_PARCEL_DETAILS,
                element: <ParcelDetails />,
              },
              {
                path: AppRoute.PARCEL_CREATION_DELIVERY_OPTION,
                element: <ParcelDelivery />,
              },
              {
                path: AppRoute.PARCEL_CREATION_DELIVERY_INSTRUCTIONS,
                element: <ParcelDeliveryInstructions />,
              },
              {
                path: AppRoute.PARCEL_CREATION_SENDER_PUDO,
                element: <ParcelSender />,
              },
              {
                path: AppRoute.PARCEL_CREATION_SENDER_COURIER,
                element: <ParcelSender />,
              },
              {
                path: AppRoute.PARCEL_CREATION_RECEIVER_PUDO,
                element: <ParcelReceiver />,
              },
              {
                path: AppRoute.PARCEL_CREATION_RECEIVER_COURIER,
                element: <ParcelReceiver />,
              },
              {
                path: AppRoute.PARCEL_CREATION_CONTENT,
                element: <ParcelContent />,
              },
              {
                path: AppRoute.PARCEL_CREATION_SUMMARY,
                element: <Summary />,
              },
              {
                path: AppRoute.SIGNATURE,
                element: <ParcelSignature />,
              },
              {
                path: AppRoute.PARCEL_CREATION_PHONE_VERIFICATION,
                element: <ParcelVerificationPhone />,
              },
            ],
          },
        ],
      },
      {
        path: `/:${LOCALE_PARAM}/${AppRoute.PORTAL}/${AppRoute.DOMESTIC}`,
        element: <ComingSoon />,
        children: [
          {
            path: AppRoute.PARCEL_CREATE,
            element: <ComingSoon />,
          },
        ],
      },
      {
        path: '*',
        element: (
          <Navigate
            replace
            to={AppRoute.NOT_FOUND}
          />
        ),
      },
    ],
  },
];

export const router = createBrowserRouter(routes);

import { styled } from 'styled-components';
import { LinkButton } from '../button/button.styles';

export const CollapsabeTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.8rem;
  width: 100%;
  color: #75777b;
  font-family: ${({ theme }) => theme.fonts.body.fontFamily};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;

  ${LinkButton} {
    padding: 0;
    min-height: 1.6rem;
  }
`;

import styled from 'styled-components';
import { ReactComponent as AppleStoreIcon } from '../../../assets/main-page/download-app/apple-store-icon.svg';
import { ReactComponent as GooglePlayIcon } from '../../../assets/main-page/download-app/google-play.svg';

import {
  FontSizes,
  HeaderSecondary,
} from '../../../common/typography/typography.styles';

import { Container } from '../common/container.styles';

export const DownloadAppContainer = styled(Container)`
  --border-radius: 1rem;
  --download-app-background-color: #f5f5f5;

  width: 100%;
  padding: 0;
  @media screen and (min-width: 650px) {
    padding: 0 2.4rem;
  }
`;

export const Section = styled.div`
  --section-bottom-padding: 6rem;
  --iphone-img-width: 37.5rem;

  padding: 2.4rem 0 var(--section-bottom-padding);
  position: relative;
  isolation: isolate;
  @media screen and (min-width: 1006px) {
    --section-bottom-padding: 11rem;

    padding: 11rem 0 var(--section-bottom-padding);
  }
`;

export const IphoneImg = styled.img`
  display: block;
  position: absolute;
  right: 8.62%;
  width: 37.5rem;
  height: 59rem;
  bottom: var(--section-bottom-padding);

  @media screen and (max-width: 1005px) {
    display: none;
  }
`;

export const IphoneImgRemote = styled(IphoneImg)`
  bottom: calc(var(--section-bottom-padding) - 0.5rem);
`;

export const Content = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.baseColors.blue100};
  @media screen and (min-width: 650px) {
    border-radius: 3rem;
  }
`;

export const DownloadAppWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 4rem 2.4rem;
  z-index: 1;

  @media screen and (min-width: 375px) {
    padding: 6rem 3rem;
  }

  @media screen and (min-width: 650px) {
    padding: 6rem 10rem;
  }

  @media screen and (min-width: 1006px) {
    padding: 6rem calc(10rem + var(--iphone-img-width) + 0.5rem) 6rem 10rem;
  }
`;

export const DownloadAppHeader = styled(HeaderSecondary)`
  color: ${({ theme }) => theme.colors.namedColors.white};
  font-size: 4rem;
  line-height: 4.8rem;
  max-width: 100%;

  @media screen and (min-width: 650px) {
    font-size: 6rem;
    line-height: 7rem;
    max-width: 59rem;
  }
`;

export const DownloadAppSectionDescription = styled.p`
  font-family: ${({ theme }) => theme.fonts.body.fontFamily};
  margin-top: 1.2rem;
  font-size: ${FontSizes.medium};
  line-height: 2.2rem;
  max-width: 100%;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.baseColors.grey5};

  @media screen and (min-width: 650px) {
    font-size: 1.8rem;
    line-height: 2.4rem;
    max-width: 59rem;
  }
`;

export const DownloadAppQrCodeBlock = styled.div`
  margin-top: 3.2rem;
  display: flex;
  gap: 3.2rem;
  max-width: 21.5rem;
  @media screen and (min-width: 650px) {
    gap: 4rem;
    margin-top: 4rem;
    max-width: 100%;
  }
`;

export const DownloadAppQrCode = styled.div`
  display: none;
  @media screen and (min-width: 650px) {
    width: 15.3rem;
    height: 15.3rem;
    display: block;
  }
`;

export const DownloadAppIconsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  @media screen and (min-width: 650px) {
    gap: 2.4rem;
  }
`;

export const QRCodeContainer = styled.div`
  height: 100%;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius);
  position: relative;
  background-color: var(--download-app-background-color);
  color: var(--download-app-background-color);

  svg {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const QRCodeImage = styled.img`
  position: absolute;
  width: 90%;
  height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const DownloadAppStyledNavLink = styled.a`
  border-radius: var(--border-radius);
  width: 100%;
  height: 6.4rem;
  background-color: var(--download-app-background-color);
`;

export const StyledAppleStoreIcon = styled(AppleStoreIcon)`
  width: 100%;
  & path {
    fill: ${({ theme }) => theme.colors.baseColors.grey150};
  }
`;

export const StyledGooglePlayIcon = styled(GooglePlayIcon)`
  width: 100%;
`;

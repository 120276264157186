import {
  CSSProperties,
  FC,
  SVGProps,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import debounce from 'debounce';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { TextLabel } from '../typography/typography.styles';
import { InputContainer } from '../form-field-text/form-field-text.styles';
import { ReactComponent as SearchIcon } from '../../assets/magnifying-glass-icon.svg';
import { ReactComponent as CloseCircleIcon } from '../../assets/close-circle-icon.svg';
import {
  ClearSearchButton,
  InputSearchContainer,
  SearchIconContainer,
} from './input-search.styles';
import { createValidationRule } from '../../utils/forms/forms.utils';
import {
  MAX_CHAR_LENGTH,
  MaxLength,
} from '../form-field-text/form-field-text.component';
import InputMessage from '../input-message/input-message.component';
import { NO_VALIDATION_RULE } from '../../constants/validation';
import { ValidationRuleType } from '../../types/input-validation-types';
import { useIsMobile } from '../../hooks/use-is-mobile.hook';
import { LabelWrapper, Note } from '../input/input.styles';

interface Props {
  name: string;
  placeholder: string;
  label?: string;
  type?: string;
  tabIndex?: number;
  focusOnOpen?: boolean;
  disabled?: boolean;
  disableSearchIconHover?: boolean;
  inputStyle?: CSSProperties;
  isError?: boolean;
  readOnly?: boolean;
  inputValue?: string | number | readonly string[];
  autoComplete?: 'off' | string;
  searchIconPosition?: 'left' | 'right';
  treatWholeInputAsSearch?: boolean;
  showClearInputButton?: boolean;
  hideInputIcon?: boolean;
  borderNone?: boolean;
  InputIcon?: FC<SVGProps<SVGSVGElement>>; // Can control icon displayed in main search input field. Magnifying glass by default
  debounceInputChange?: boolean;
  shouldValidateSearchQuery?: boolean;
  onChange?: (value: string) => void;
  onInputContainerClick?: () => void;
  onSearchIconClick?: () => void;
  onInputClick?: () => void;
  onBlur?: () => void;
  onClear?: () => void;
  maxLength?: MaxLength;
  showSkeleton?: boolean;
  validationRule?: ValidationRuleType;
  errorMessage?: string;
  isOptional?: boolean;
}

const InputSearch = ({
  name,
  inputValue,
  placeholder,
  label,
  disabled,
  type,
  tabIndex,
  focusOnOpen,
  inputStyle,
  isError,
  readOnly,
  autoComplete,
  searchIconPosition = 'left',
  disableSearchIconHover,
  treatWholeInputAsSearch,
  showClearInputButton,
  hideInputIcon,
  borderNone,
  InputIcon,
  debounceInputChange,
  shouldValidateSearchQuery,
  onInputContainerClick,
  onSearchIconClick,
  onInputClick,
  onBlur,
  onChange,
  onClear,
  showSkeleton,
  errorMessage,
  isOptional,
  maxLength = MAX_CHAR_LENGTH,
  validationRule = NO_VALIDATION_RULE,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [searchQueryError, setSearchQueryError] = useState<string | undefined>(
    errorMessage
  );

  const validationRuleMethod = useMemo(
    () =>
      !!shouldValidateSearchQuery && validationRule !== NO_VALIDATION_RULE
        ? createValidationRule(validationRule)
        : undefined,
    [validationRule, shouldValidateSearchQuery]
  );

  const { t } = useTranslation();

  const isMobile = useIsMobile();

  useEffect(() => {
    // Set focus on the input element when the component mounts
    !isMobile &&
      focusOnOpen &&
      inputRef &&
      inputRef.current &&
      inputRef.current.focus();
  }, [focusOnOpen, isMobile]);

  useEffect(() => {
    setSearchQueryError(errorMessage);
  }, [errorMessage]);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (validationRuleMethod) {
      try {
        await validationRuleMethod.validate(value);
        setSearchQueryError(undefined);
        onChange && onChange(value);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          setSearchQueryError(error.message);
        }
      }
    } else {
      onChange && onChange(value);
    }
  };

  const handleChangeWrapper = debounceInputChange
    ? debounce(handleChange, 850)
    : handleChange;

  const clearInput = () => {
    if (onClear) {
      onClear();
    } else {
      onChange && onChange('');
    }
    if (inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.focus();
      setSearchQueryError(undefined);
    }
  };

  return (
    <InputSearchContainer
      $isDisabled={disabled}
      $treatWholeInputAsSearch={treatWholeInputAsSearch}
      $hIconPosition={searchIconPosition}>
      {label && (
        <LabelWrapper>
          <TextLabel htmlFor={name}>{label}</TextLabel>
          {isOptional && <Note>{`(${t('optional')})`}</Note>}
        </LabelWrapper>
      )}
      <InputContainer
        $error={!!isError}
        $borderNone={borderNone}
        onClick={() => onInputContainerClick && onInputContainerClick()}>
        {showSkeleton ? (
          <Skeleton
            width='100%'
            height='4rem'
            borderRadius={10}
          />
        ) : (
          <>
            <input
              maxLength={maxLength}
              disabled={disabled}
              value={inputValue}
              style={inputStyle}
              ref={inputRef}
              onChange={handleChangeWrapper}
              onClick={() => onInputClick && onInputClick()}
              onBlur={() => onBlur && onBlur()}
              tabIndex={tabIndex}
              type={type}
              id={name}
              placeholder={placeholder}
              readOnly={readOnly}
              required
              formNoValidate
              autoComplete={autoComplete}
              className='flag-emoji-font'
              data-type={isError ? 'invalid-input' : undefined}
            />
            {!hideInputIcon && (
              <SearchIconContainer
                $isDisabled={disabled}
                $hIconPosition={
                  searchIconPosition === 'right' ? 'right' : 'left'
                }
                $disableSearchHover={disableSearchIconHover}
                onClick={() => onSearchIconClick && onSearchIconClick()}>
                {!InputIcon ? <SearchIcon /> : <InputIcon />}
              </SearchIconContainer>
            )}
            {showClearInputButton && inputRef.current?.value && (
              <ClearSearchButton onClick={() => clearInput()}>
                <CloseCircleIcon />
              </ClearSearchButton>
            )}
          </>
        )}
      </InputContainer>
      {searchQueryError && (
        <InputMessage type='error'>{t(searchQueryError)}</InputMessage>
      )}
    </InputSearchContainer>
  );
};

export default InputSearch;

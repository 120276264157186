import styled, { css } from 'styled-components';

import { Link } from 'react-router-dom';
import { FontSizes } from '../../common/typography/typography.styles';

interface ContainerProps {
  $isAuthPage?: boolean;
  $hideSticky?: boolean;
}

export const Container = styled.div<ContainerProps>`
  --header-active-color: ${({ theme }) => theme.colors.namedColors.primary};

  width: 100%;
  background-color: ${({ theme }) => theme.colors.namedColors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.baseColors.grey10};
  padding: 8px 0;
  min-height: 5.6rem;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ $hideSticky }) =>
    !$hideSticky &&
    css`
      position: sticky;
      top: 0;
      z-index: 1000;
    `}
`;

interface ContentProps {
  $justifyContent?: string;
}

export const Content = styled.div<ContentProps>`
  flex-grow: 1;
  position: relative;
  max-width: 144rem;
  padding: 0 1.6rem;
  display: flex;
  justify-content: ${({ $justifyContent }) =>
    $justifyContent || 'space-between'};

  @media screen and (min-width: 650px) {
    padding: 0 2.4rem;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2.4rem;

  @media screen and (min-width: 650px) {
    gap: 3.2rem;
  }
`;

const LogoStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const LogoLink = styled(Link)`
  ${LogoStyles}
`;

export const LogoImgWrapper = styled.div`
  ${LogoStyles}
`;

export const ServicesContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const RightSide = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.6rem;
  @media screen and (min-width: 650px) {
    gap: 2.4rem;
  }
`;

export const HeaderText = styled.span`
  font-size: ${FontSizes.normal};
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.5px;
  color: inherit;
`;

const lineStyles = css`
  display: block;
  width: 100%;
  height: 3px;
  border-radius: 20px;
  background-color: currentColor;
`;

export const HeaderBurger = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 20px;
  height: 21px;

  &::before,
  &::after {
    content: '';
    ${lineStyles}
  }
`;

export const HeaderBurgerInner = styled.span`
  ${lineStyles};
  width: 75%;
  transition: transform 0.3s ease-out;
`;

interface Props {
  $isActive: boolean;
}

export const HeaderBurgerWrapper = styled.button<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  appearance: none;
  padding: 8px;
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors.baseColors.grey150};
  transition: color 0.1s ease-in;
  cursor: pointer;

  ${({ $isActive }) =>
    $isActive &&
    css`
      ${HeaderBurgerInner} {
        transform: translateX(25%);
      }
    `}
  &:hover {
    color: ${({ theme }) => theme.colors.namedColors.primary};
  }
`;

export const HeaderContactsContainer = styled.div`
  display: flex;
  gap: 1.6rem;

  @media (max-width: 650px) {
    flex-direction: column;
    align-items: start;
    gap: 2.4rem;
    margin-left: 1rem;
  }

  p {
    color: ${({ theme }) => theme.colors.baseColors.grey150};
    font-size: ${FontSizes.normal};
    font-style: normal;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.5px;
    text-transform: lowercase;
  }

  a {
    color: ${({ theme }) => theme.colors.namedColors.primary};
    cursor: pointer;
  }
`;

export const HeaderContact = styled.div``;

import { ReactComponent as BooksIcon } from '../../../../assets/popular-categories/category-books.svg';
import { ReactComponent as CellphoneIcon } from '../../../../assets/popular-categories/category-cellphone.svg';
import { ReactComponent as ChildrenShoesIcon } from '../../../../assets/popular-categories/category-children-shoes.svg';
import { ReactComponent as ChildrenClothesIcon } from '../../../../assets/popular-categories/category-childrens-clothes.svg';
import { ReactComponent as CoffeeIconIcon } from '../../../../assets/popular-categories/category-coffee.svg';
import { ReactComponent as CosmeticsIcon } from '../../../../assets/popular-categories/category-cosmetics.svg';
import { ReactComponent as DryFoodsIcon } from '../../../../assets/popular-categories/category-dry-foods.svg';
import { ReactComponent as ElectronicsIcon } from '../../../../assets/popular-categories/category-electronics.svg';
import { ReactComponent as HatIcon } from '../../../../assets/popular-categories/category-hat.svg';
import { ReactComponent as HouseholdIcon } from '../../../../assets/popular-categories/category-household-items.svg';
import { ReactComponent as JacketIcon } from '../../../../assets/popular-categories/category-jacket.svg';
import { ReactComponent as PantsIcon } from '../../../../assets/popular-categories/category-pants.svg';
import { ReactComponent as PersonalDocumentsIcon } from '../../../../assets/popular-categories/category-personal-documents.svg';
import { ReactComponent as ShoesIcon } from '../../../../assets/popular-categories/category-shoes.svg';
import { ReactComponent as SkirtIcon } from '../../../../assets/popular-categories/category-skirt.svg';
import { ReactComponent as SocksIcon } from '../../../../assets/popular-categories/category-socks.svg';
import { ReactComponent as SouvenirsIcon } from '../../../../assets/popular-categories/category-souvenirs.svg';
import { ReactComponent as SweaterIcon } from '../../../../assets/popular-categories/category-sweater.svg';
import { ReactComponent as SweetsIcon } from '../../../../assets/popular-categories/category-sweets.svg';
import { ReactComponent as TShirtIcon } from '../../../../assets/popular-categories/category-t-shirt.svg';
import { ReactComponent as TeaIcon } from '../../../../assets/popular-categories/category-tea.svg';
import { ReactComponent as ToysIcon } from '../../../../assets/popular-categories/category-toys.svg';
import { ReactComponent as UnderwearIcon } from '../../../../assets/popular-categories/category-underwear.svg';
import { ReactComponent as UsedClothingIcon } from '../../../../assets/popular-categories/category-used-clothing.svg';
import { ReactComponent as VitaminsIcon } from '../../../../assets/popular-categories/category-vitamins.svg';
import { HsCodeResponse } from '../../../../models/hsCode';

const PopularCategoriesCommon: HsCodeResponse[] = [
  {
    description: 'Books',
    hsCode: '4901990000',
    icon: BooksIcon,
  },
  {
    description: 'Cellphone',
    hsCode: '8517120000',
    icon: CellphoneIcon,
  },
  {
    description: `Children's Clothing`,
    hsCode: '6204900000',
    icon: ChildrenShoesIcon,
  },
  {
    description: `Children's Shoes`,
    hsCode: '6402990000',
    icon: ChildrenClothesIcon,
  },
  {
    description: 'Coffee',
    hsCode: '0901210000',
    icon: CoffeeIconIcon,
  },
  {
    description: 'Cosmetics',
    hsCode: '3304990000',
    icon: CosmeticsIcon,
  },
  {
    description: 'Dry Foods',
    hsCode: '2106900000',
    icon: DryFoodsIcon,
  },
  {
    description: 'Electronics',
    hsCode: '8471700000',
    icon: ElectronicsIcon,
  },
  {
    description: 'Hat',
    hsCode: '6506990000',
    icon: HatIcon,
  },
  {
    description: 'Household Items',
    hsCode: '3924900000',
    icon: HouseholdIcon,
  },
  {
    description: 'Jacket',
    hsCode: '6203320000',
    icon: JacketIcon,
  },
  {
    description: 'Pants',
    hsCode: '6203420000',
    icon: PantsIcon,
  },
  {
    description: 'Personal Documents',
    hsCode: '4911990000',
    icon: PersonalDocumentsIcon,
  },
  {
    description: 'Shoes',
    hsCode: '6402990000',
    icon: ShoesIcon,
  },
  {
    description: 'Skirt',
    hsCode: '6204520000',
    icon: SkirtIcon,
  },
  {
    description: 'Socks',
    hsCode: '6115990000',
    icon: SocksIcon,
  },
  {
    description: 'Souvenirs',
    hsCode: '4420900000',
    icon: SouvenirsIcon,
  },
  {
    description: 'Sweater',
    hsCode: '6110200000',
    icon: SweaterIcon,
  },
  {
    description: 'Sweets',
    hsCode: '1704900000',
    icon: SweetsIcon,
  },
  {
    description: 'Tea',
    hsCode: '0902100000',
    icon: TeaIcon,
  },
  {
    description: 'Toys',
    hsCode: '9503900000',
    icon: ToysIcon,
  },
  {
    description: 'T-Shirt',
    hsCode: '6109100000',
    icon: TShirtIcon,
  },
  {
    description: 'Underwear',
    hsCode: '6108990000',
    icon: UnderwearIcon,
  },
  {
    description: 'Used Clothing',
    hsCode: '6309000000',
    icon: UsedClothingIcon,
  },
  {
    description: 'Vitamins',
    hsCode: '2106900000',
    icon: VitaminsIcon,
  },
];

export const getCategories = (popularCategorydescriptions: string[]) =>
  PopularCategoriesCommon.filter((item) =>
    popularCategorydescriptions.includes(item.description)
  );

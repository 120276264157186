import { useTranslation } from 'react-i18next';
import { ReactComponent as AirplaneIcon } from '../../../assets/airplane.svg';
import { ReactComponent as ShipIcon } from '../../../assets/ship.svg';
import { ReactComponent as RocketIcon } from '../../../assets/rocket.svg';
import { ReactComponent as CarIcon } from '../../../assets/car.svg';
import {
  AdvantageTag,
  DeliveryTabTitleContainer,
  DurationText,
} from './parcel-delivery-options-tabs.styles';
import { FlexContainer } from '../../../common/flex-container/flex-container.styles';
import Tag, { TagColor } from '../../../common/tag/tag.component';
import { client, WLClient } from '../../../config';
import { DeliveryMethod } from '../../../models/parcelDeliveryMiles';
import { isMeest } from '../../../utils/client/client.utils';
import { FontSizes } from '../../../common/typography/typography.styles';

const getTagColor = () => {
  const colors: Record<WLClient, TagColor> = {
    meest: 'blue',
    polonez: 'polonez',
  };
  return colors[client];
};
const isMeestClient = isMeest(client);
const getTabTitle = (method: DeliveryMethod, isCalculator?: boolean) =>
  (
    ({
      air: {
        tagColor: getTagColor(),
        icon: <AirplaneIcon />,
        text: 'air',
        advantagesText: 'create_parcel_faster',
      },
      car: {
        tagColor: getTagColor(),
        icon: <CarIcon />,
        text: 'car',
        advantagesText: 'create_parcel_faster',
      },
      sea: {
        tagColor: getTagColor(),
        icon: <ShipIcon />,
        text: 'sea',
        advantagesText: 'create_parcel_cheaper',
      },
      expedited: {
        tagColor: 'red',
        icon: <RocketIcon />,
        text: isCalculator ? 'expedited' : 'air',
        advantagesText: 'create_parcel_expedited',
      },
    }) as const
  )[method];

export interface DeliveryTabProps {
  method: DeliveryMethod;
  durationDaysString?: string;
  showAdvantageTag?: boolean;
  isSelected?: boolean;
  isCalculator?: boolean;
}

const DeliveryTabTitle = ({
  method,
  durationDaysString,
  showAdvantageTag,
  isSelected,
  isCalculator,
}: DeliveryTabProps) => {
  const { t } = useTranslation();
  const content = getTabTitle(method, isCalculator);

  return (
    <DeliveryTabTitleContainer
      $isMeestClient={isMeestClient}
      $isCalculator={isCalculator}
      $isSelected={isSelected}
      $method={method}>
      <FlexContainer
        as='span'
        $justify='center'
        $gap='0.8rem'>
        {content.icon}
        <span>{t(content.text)}</span>
        {durationDaysString && (
          <DurationText
            $fontWeight={!isMeestClient && isCalculator ? '500' : '400'}
            $fontSize={
              !isMeestClient && isCalculator
                ? FontSizes.medium
                : FontSizes.small
            }>
            {`${t('from_countable')} ${durationDaysString}`}
          </DurationText>
        )}
      </FlexContainer>
      {showAdvantageTag && content.advantagesText && (
        <AdvantageTag>
          <Tag
            textColor={isSelected ? 'inherit' : undefined}
            type='filled'
            color={isSelected ? 'white' : content.tagColor}
            size='mini'>
            {t(content.advantagesText)}
          </Tag>
        </AdvantageTag>
      )}
    </DeliveryTabTitleContainer>
  );
};

export default DeliveryTabTitle;

import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { HsCode } from '../../../models/hsCode';
import {
  ActionIcons,
  EditButton,
  RemoveButton,
  ParcelInfo,
  ParcelItemContainer,
  ParcelItemHeader,
  ParcelItemPropName,
  ParcelItemPropValue,
  ParcelProps,
  ParcelItemWrapper,
} from './parcel-items-management.styles';
import { ReactComponent as EditIcon } from '../../../assets/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/delete-icon.svg';
import ParcelItemEditForm from './parcel-item-form.component';
import { getFormattedPrice } from '../../../utils/price/get-formatted-price';
import { useModal } from '../../../common/modal';
import { Modals } from '../../../constants/modals';
import { useStore } from '../../../stores/store';
import CustomSkeleton from '../../../common/skeletons/custom-skeleton-component';
import { meest } from '../../../styles/meest';
import Separator from '../../../common/separator/separator.component';
import { useEditForm } from './edit-form-manager';

interface Props {
  item?: HsCode;
  withSeparator?: boolean;
  currencySymbol: string;
  shouldShowDeleteButton?: boolean;
}

const ParcelItem = ({
  item,
  withSeparator,
  currencySymbol,
  shouldShowDeleteButton,
}: Props) => {
  const {
    localizationsStore: { isLanguageChanging },
    parcelCreationStore: { deleteItemInShipment },
  } = useStore();
  const { t } = useTranslation();
  const { openModal, closeModal } = useModal();

  const { openedItemId, openEditForm } = useEditForm();

  const isEditing = openedItemId === item?.id;

  const handleDeleteItem = () => {
    if (item) {
      deleteItemInShipment(item.id);
    }
    closeModal(Modals.DELETE_ITEM);
  };

  const showDeleteItemModal = () => {
    openModal({
      id: Modals.DELETE_ITEM,
      name: Modals.DELETE_ITEM,
      props: {
        onApprove: handleDeleteItem,
      },
    });
  };

  const showItem = !isEditing && !!item;

  return (
    <ParcelItemWrapper>
      {showItem && (
        <ParcelItemContainer>
          <ParcelInfo>
            {isLanguageChanging ? (
              <CustomSkeleton
                display='grid'
                height='2.2rem'
                pb='1.2rem'
              />
            ) : (
              <ParcelItemHeader>{item.description_standart}</ParcelItemHeader>
            )}
            <ParcelProps>
              <ParcelItemPropName>{t('quanitity')}</ParcelItemPropName>
              <ParcelItemPropValue>{item.number_of_items}</ParcelItemPropValue>
              <ParcelItemPropName>{t('declared_value')}</ParcelItemPropName>
              <ParcelItemPropValue>{`${getFormattedPrice(item?.value, currencySymbol)}`}</ParcelItemPropValue>
            </ParcelProps>
          </ParcelInfo>
          <ActionIcons>
            <EditButton
              onClick={() => openEditForm(item.id)}
              theme={meest}
              disabled={isLanguageChanging}>
              <EditIcon />
            </EditButton>
            {shouldShowDeleteButton && (
              <RemoveButton
                onClick={showDeleteItemModal}
                disabled={isLanguageChanging}>
                <DeleteIcon />
              </RemoveButton>
            )}
          </ActionIcons>
        </ParcelItemContainer>
      )}
      {isEditing && (
        <ParcelItemEditForm
          parcelItem={item}
          displayCancelButton
          currencySymbol={currencySymbol}
        />
      )}
      {withSeparator && <Separator mt='1.6rem' />}
    </ParcelItemWrapper>
  );
};

export default observer(ParcelItem);

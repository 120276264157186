import styled from 'styled-components';

import { InputSearchContainer } from '../input-search/input-search.styles';
import { SidebarInner } from '../sidebar-right/sidebar.styles';
import { scrollStyles } from '../scroll/scroll.styles';
import { FontSizes } from '../typography/typography.styles';

export const InputSearchWithSidebarContainer = styled(InputSearchContainer)`
  span {
    font-family: 'Noto Color Emoji', Montserrat !important;
  }
`;

export const Container = styled(SidebarInner)`
  display: grid;
  gap: 1.6rem;
  grid-template-rows: auto 1fr;
`;

export const Content = styled.div`
  height: 100%;
  overflow: auto;
  ${scrollStyles};
`;

export const PopularLabel = styled.p`
  color: ${({ theme }) => theme.colors.baseColors.grey80};
  font-size: ${FontSizes.small};
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1.6rem;
  letter-spacing: 0.5px;
  padding: 8px 20px;
`;

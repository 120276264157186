import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
import InputSearchWithSidebar from '../../../common/input-search-with-sidebar/input-search-with-sidebar.component';
import {
  ServicesSubSectionContainer,
  ServicesSubSectionHeader,
} from '../../services/services-subsection/services-subsection.styles';
import { ParcelDetailsSearchInputsContainer } from '../../../routes/parcel-details/parcel-details.styles';
import { useStore } from '../../../stores/store';
import {
  getCityFromPlaceDetails,
  getPredictionsWithUserInput,
  getZipCodeFromPlaceDetails,
} from '../../../utils/google-places/google-places.utils';
import {
  getShipmentCountriesByFilter,
  getShipmentCountryName,
  getZipCodeDisplayValue,
} from '../../../utils/parcel-creation/parcel-creation-utils';
import { IShipmentCountry } from '../../../stores/parcelCreationFlowStore';
import { PLACES_API_MINIMAL_REQUEST_LENGTH } from '../../../stores/placesSearchStore';
import {
  VALIDATION_RULE_LATIN_WITH_COMMA,
  VALIDATION_RULE_ONLY_LETTERS,
} from '../../../constants/validation';
import { getSettingReceiverCountry } from '../../../utils/countries/countries.utils';
import { countryInfoList } from '../../../assets/data/countryInfo';
import { ReactComponent as ArrowRightIcon } from '../../../assets/arrow-right-icon.svg';
import { getPostalCodeLabel } from '../delivery-details-form/delivery-details-form.component';

const ParcelDetailsDeparture = () => {
  const {
    parcelCreationStore: {
      shipmentCountries,
      countryDeparture,
      setDepartureShipmentCountry,
      setDestinationShipmentCountry,
      isLoadingShipmentApi,
      isRateSchemaChanged,
      setIsRateSchemaChanged,
      deliveryLimitationsNotFound,
      isNextButtonClicked,
      setOmitLimitationsRequest,
      prefillInProgress,
      resetShipmentDataInCaseDeliveryDetailsChanged,
    },
    placesSearchStore: { getAutocompletePredictions, getPlaceDetails },
    commonStore: { isGuestParcelCreation },
    localizationsStore: { selectedCountry, isLanguageChanging },
    userStore: { user },
  } = useStore();

  const isDefaultFlow =
    getSettingReceiverCountry(countryDeparture) === 'default_flow';

  // In case of 'guest' shipping we should limit set of countries to the site-locale
  const availableDepartureCountries = useMemo(
    () =>
      isGuestParcelCreation && selectedCountry
        ? shipmentCountries.filter(
            (c) => c.countryCode === selectedCountry.country_code
          )
        : shipmentCountries,
    [isGuestParcelCreation, selectedCountry, shipmentCountries]
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (countryDeparture && countryDeparture.zipCode) return;

    if (!countryDeparture && isGuestParcelCreation) {
      const country = availableDepartureCountries[0];
      setDepartureShipmentCountry(country);
      return;
    }

    const canApplyUsersDepartureAddress =
      user?.country &&
      user?.address?.post_code &&
      (!countryDeparture || countryDeparture.countryCode === user.country);

    if (canApplyUsersDepartureAddress) {
      let country = availableDepartureCountries.find(
        (c) => c.countryCode === user.country
      );

      if (country) {
        country = {
          ...country,
          city: user.address?.city ?? '',
          zipCode: user.address?.post_code,
        };
        setDepartureShipmentCountry(country);
      }
      return;
    }

    if (!countryDeparture) {
      const country = availableDepartureCountries
        .filter((c) => c.countryCode === selectedCountry?.country_code)
        ?.at(0);
      if (country) setDepartureShipmentCountry(country);
    }
  }, [
    setDepartureShipmentCountry,
    availableDepartureCountries,
    user,
    isGuestParcelCreation,
    countryDeparture,
    selectedCountry,
  ]);

  const showSkeleton =
    isLoadingShipmentApi || isLanguageChanging || prefillInProgress;

  return (
    <ServicesSubSectionContainer>
      <ServicesSubSectionHeader>{t('from')}</ServicesSubSectionHeader>
      <ParcelDetailsSearchInputsContainer
        $layout={isDefaultFlow ? 'row' : 'column'}>
        <InputSearchWithSidebar<IShipmentCountry>
          disabled={!!isGuestParcelCreation}
          name='country_search'
          inputValue={getShipmentCountryName(countryDeparture, t)}
          defaultOption={countryDeparture}
          placeholder=''
          label={t('country')}
          sidebarTitle={t('country')}
          sidebarInputPlaceholder=''
          displayAllOptionsWithEmptyFilter
          isOptionSelected={(a, b) => a?.countryCode === b?.countryCode}
          getSearchOptions={(filter) =>
            Promise.resolve(
              getShipmentCountriesByFilter(availableDepartureCountries, filter)
            )
          }
          onSearchOptionSelected={(country) => {
            setDepartureShipmentCountry(country);
            setOmitLimitationsRequest(false);
            setDestinationShipmentCountry(null);
            resetShipmentDataInCaseDeliveryDetailsChanged();
          }}
          getKeyForSearchOption={(country) => country.countryCode}
          getDisplayValueForSearchOption={(country) =>
            getShipmentCountryName(country, t)
          }
          errorMessage={t('this_field_cannot_be_empty')}
          mainInputValidationPredicate={(inputValue) => !!inputValue}
          showSkeleton={showSkeleton}
          validationRule={VALIDATION_RULE_ONLY_LETTERS}
          triggerValidation={isNextButtonClicked}
          inputIcon={ArrowRightIcon}
        />

        <InputSearchWithSidebar<google.maps.places.AutocompletePrediction>
          disabled={!countryDeparture}
          inputValue={
            isDefaultFlow
              ? getZipCodeDisplayValue(countryDeparture)
              : countryDeparture?.zipCode ?? ''
          }
          name='zip_search'
          placeholder=''
          label={t(
            isDefaultFlow
              ? 'city&zip-code'
              : getPostalCodeLabel(countryDeparture?.countryCode)
          )}
          sidebarTitle={t(
            isDefaultFlow
              ? 'city&zip-code'
              : getPostalCodeLabel(countryDeparture?.countryCode)
          )}
          sidebarInputPlaceholder=''
          debounceSidebarInputChange
          shouldValidateSearchQuery
          getSearchOptions={async (filter) => {
            setOmitLimitationsRequest(false);
            const predictions = await getAutocompletePredictions({
              value: filter ?? '',
              predictionTypes: ['geocode'],
              countryConstraint:
                countryDeparture?.countryCode.toLocaleLowerCase(),
            });

            return getPredictionsWithUserInput(
              predictions,
              filter,
              PLACES_API_MINIMAL_REQUEST_LENGTH
            );
          }}
          getKeyForSearchOption={(place) => place.place_id}
          getDisplayValueForSearchOption={(place) => place.description}
          onSearchOptionSelected={async (place) => {
            const placeDetails = await getPlaceDetails(place);
            if (placeDetails && countryDeparture) {
              const zipCode = getZipCodeFromPlaceDetails(
                placeDetails.address_components
              );
              const city = getCityFromPlaceDetails(
                placeDetails.address_components
              );

              setDepartureShipmentCountry({
                ...countryDeparture,
                zipCode: zipCode || '',
                city,
              });
              setIsRateSchemaChanged(false);
            } else {
              if (!countryDeparture) return;

              setDepartureShipmentCountry({
                ...countryDeparture,
                zipCode: place.description,
                city: undefined,
              });
            }
            resetShipmentDataInCaseDeliveryDetailsChanged();
          }}
          canModifyInput={false}
          onInputChange={(inputValue) => {
            if (countryDeparture) {
              const zipCode = inputValue;
              setDepartureShipmentCountry({
                ...countryDeparture,
                zipCode,
                city: isDefaultFlow ? countryDeparture.city : undefined,
              });
            }
          }}
          errorMessage={
            isRateSchemaChanged || deliveryLimitationsNotFound
              ? t('no_route_for_entered_zip', {
                  CapitalZIP: countryInfoList.filter(
                    (country) =>
                      country.countryCode === countryDeparture?.countryCode
                  )[0]?.defaultZIP,
                })
              : t('this_field_cannot_be_empty')
          }
          mainInputValidationPredicate={(inputValue) =>
            !!inputValue && !deliveryLimitationsNotFound && !isRateSchemaChanged
          }
          showSkeleton={showSkeleton}
          validationRule={VALIDATION_RULE_LATIN_WITH_COMMA}
          isRateOrDeliveryChanged={
            isRateSchemaChanged || deliveryLimitationsNotFound
          }
          triggerValidation={isNextButtonClicked}
          inputIcon={ArrowRightIcon}
        />
      </ParcelDetailsSearchInputsContainer>
    </ServicesSubSectionContainer>
  );
};

export default observer(ParcelDetailsDeparture);

import { useTranslation } from 'react-i18next';
import { ServicesSubSectionHeader } from '../../../services/services-subsection/services-subsection.styles';
import {
  GridContainer,
  PriceTitle,
  PriceValue,
} from '../../../../routes/parcel-content/parcel-content.styles';
import { TaxesSectionContainer } from '../parcel-taxes-info.styles';
import { Link } from '../../../../common/typography/typography.styles';

interface Props {
  isContentStep?: boolean;
}

const TaxesInfoItaly = ({ isContentStep }: Props) => {
  const { t } = useTranslation();

  const content = !isContentStep ? (
    <GridContainer>
      <PriceTitle>
        {t('create_parcel_summary_taxes_paid_by_receiver')}
      </PriceTitle>
      <PriceValue>
        {t('create_parcel_summary_taxes_paid_by_receiver_text')}
      </PriceValue>
    </GridContainer>
  ) : (
    <PriceValue>
      {t('create_parcel_taxes_it1')}
      <Link
        href='https://www.simplyduty.com/#calc'
        target='_blank'>
        SimplyDuty - Duty and Tax Calculation Made Simple
      </Link>
      {t('create_parcel_taxes_it2')}
    </PriceValue>
  );

  return (
    <TaxesSectionContainer>
      <ServicesSubSectionHeader>{t('taxes_italy')}</ServicesSubSectionHeader>
      {content}
    </TaxesSectionContainer>
  );
};

export default TaxesInfoItaly;

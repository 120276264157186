import styled from 'styled-components';
import {
  FontSizes,
  Link,
  TextBody,
} from '../../common/typography/typography.styles';

export const GridContainer = styled.div`
  display: grid;
  justify-content: start;
  align-items: start;
  gap: 0.4rem;
  width: 100%;

  @media screen and (min-width: 768px) {
    grid-template-columns: 200px auto;
    gap: 1.6rem;
  }
`;

export const PriceTitle = styled(TextBody)`
  color: ${({ theme }) => theme.colors.baseColors.grey100};
`;

export const PriceValue = styled(TextBody)<{ $free?: boolean }>`
  color: ${({ $free, theme }) =>
    $free ? theme.colors.baseColors.green100 : theme.colors.baseColors.grey150};
  font-size: ${FontSizes.medium};
  font-weight: 500;
  letter-spacing: 0.5px;

  ${Link} {
    border-bottom-color: ${({ theme }) => theme.colors.link.color};
    font-size: ${FontSizes.medium};

    display: inline; /* Make sure it’s truly inline */
    white-space: normal; /* Allow normal wrapping */
    word-break: break-word; /* Allow breaks inside long words */
    overflow-wrap: break-word; /* Fallback for older browsers */

    &:hover,
    &:active {
      border-bottom-color: transparent;
    }
  }
`;

import currencySymbolsData from '../assets/data/currency-symbols-map';

export interface Currency {
  value: string;
  label: string;
  symbol: string;
  prefixPlace?: boolean;
}

export const currencies: Currency[] = [
  {
    value: 'USD',
    label: 'USD',
    symbol: currencySymbolsData['USD'],
    prefixPlace: true,
  },
  {
    value: 'CAD',
    label: 'CAD',
    symbol: currencySymbolsData['CAD'],
    prefixPlace: true,
  },
  { value: 'UAH', label: 'UAH', symbol: currencySymbolsData['UAH'] },
  {
    value: 'GBP',
    label: 'GBP',
    symbol: currencySymbolsData['GBP'],
    prefixPlace: true,
  },
  {
    value: 'EUR',
    label: 'EUR',
    symbol: currencySymbolsData['EUR'],
    prefixPlace: true,
  },
  {
    value: 'PLN',
    label: 'PLN',
    symbol: currencySymbolsData['PLN'],
  },
  {
    value: 'KZT',
    label: 'KZT',
    symbol: currencySymbolsData['KZT'],
  },
  {
    value: 'AZN',
    label: 'AZN',
    symbol: currencySymbolsData['AZN'],
  },
  {
    value: 'UZS',
    label: 'UZS',
    symbol: currencySymbolsData['UZS'],
  },
];

export const currenciesMap = currencies.reduce<{ [key: string]: Currency }>(
  (acc, curr) => {
    acc[curr.value] = curr;
    return acc;
  },
  {}
);

export const currencySymbolsMap = currencies.reduce<{ [key: string]: string }>(
  (acc, curr) => {
    acc[curr.value] = curr.symbol;
    return acc;
  },
  {}
);

import { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { useTranslation } from 'react-i18next';
import {
  ClearButton,
  SignatureContent,
  Wrapper,
} from './signature-field.styles';
import Button from '../button/button.component';
import { FlexContainer } from '../flex-container/flex-container.styles';
import InputMessage from '../input-message/input-message.component';

interface Props {
  onChange?: (signatureDataUrl: string) => void;
  onClear?: () => void;
  children?: React.ReactNode;
  isSignatureProcessing?: boolean;
  isSignButtonClicked?: boolean;
}

const SignatureField = ({
  onChange,
  onClear,
  children,
  isSignatureProcessing,
  isSignButtonClicked,
}: Props) => {
  const [isEmpty, setIsEmpty] = useState(true);
  const canvasRef = useRef<SignatureCanvas | null>(null);

  const { t } = useTranslation();

  const handleClear = () => {
    if (!canvasRef?.current) return;

    canvasRef.current.clear();
    setIsEmpty(true);
    onClear?.();
  };

  const handleEnd = () => {
    if (!canvasRef?.current) return;

    const signatureDataUrl = canvasRef.current.getCanvas().toDataURL();
    setIsEmpty(false);
    onChange?.(signatureDataUrl);
  };

  const handleReEnter = () => {
    handleClear();
  };

  return (
    <Wrapper
      $isDisabled={isSignatureProcessing}
      $error={isSignButtonClicked && isEmpty}>
      <SignatureContent>
        <SignatureCanvas
          onEnd={handleEnd}
          clearOnResize={false}
          ref={canvasRef}
          penColor='#000'
          canvasProps={{ className: 'canvas' }}
        />
        {isSignButtonClicked && isEmpty && (
          <InputMessage type='error'>
            {t('this_field_cannot_be_empty')}
          </InputMessage>
        )}
        <FlexContainer
          $mt='2.4rem'
          $align='center'
          $justify='space-between'
          $gap='2.4rem'>
          <ClearButton>
            <Button
              disabled={isEmpty || isSignatureProcessing}
              onClick={handleReEnter}
              appearance='text'>
              {t('clear')}
            </Button>
          </ClearButton>
          {children}
        </FlexContainer>
      </SignatureContent>
    </Wrapper>
  );
};

export default SignatureField;

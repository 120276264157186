import { Placement } from '@floating-ui/react';
import styled, { css } from 'styled-components';
import { exhaustiveGuard } from '../../utils/generic/generic.utils';

interface ContentProps {
  $maxWidth?: string;
  $wordBreak?: 'break-all' | 'break-word';
}

export const Trigger = styled.button`
  display: flex;
`;

export const Content = styled.div<ContentProps>`
  padding: 8px;
  background-color: #2c363f;
  color: #fff;
  border-radius: 6px;
  font-size: 1.2rem;
  line-height: 1.33;
  letter-spacing: 0.5px;
  max-width: ${({ $maxWidth = '180px' }) => $maxWidth};
  width: fit-content;
  font-family: ${({ theme }) => theme.fonts.body.fontFamily};
  word-break: ${({ $wordBreak = 'break-word' }) => $wordBreak};
`;

interface PopoverArrowProps {
  $placement?: Placement;
  $color?: string;
}

const getPosition = (placement: Placement) => {
  switch (placement) {
    case 'top':
    case 'top-start':
    case 'top-end': {
      return css`
        top: 100%;
        transform: rotate(180deg);
      `;
    }
    case 'bottom':
    case 'bottom-start':
    case 'bottom-end': {
      return css`
        bottom: 100%;
      `;
    }
    case 'right':
    case 'right-start':
    case 'right-end': {
      return css`
        right: 100%;
        transform: rotate(-90deg);
      `;
    }

    case 'left':
    case 'left-start':
    case 'left-end': {
      return css`
        left: 100%;
        transform: rotate(90deg);
      `;
    }
    default: {
      exhaustiveGuard(placement);
    }
  }
};

export const PopoverArrow = styled.div<PopoverArrowProps>`
  position: absolute;
  z-index: 1;
  display: block;
  width: 16px;
  height: 16px;
  pointer-events: none;
  ${({ $placement = 'top-start' }) => getPosition($placement)};

  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 16px;
    height: 8px;
    background: #2c363f;
    clip-path: polygon(
      1.6568542494923806px 100%,
      50% 1.6568542494923806px,
      14.34314575050762px 100%,
      1.6568542494923806px 100%
    );
    clip-path: path(
      'M 0 8 A 4 4 0 0 0 2.82842712474619 6.82842712474619 L 6.585786437626905 3.0710678118654755 A 2 2 0 0 1 9.414213562373096 3.0710678118654755 L 13.17157287525381 6.82842712474619 A 4 4 0 0 0 16 8 Z'
    );
    content: '';
  }
`;

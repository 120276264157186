import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Container } from '../common/container.styles';
import { Section } from '../common/section.styles';
import { SectionTitle } from '../common/typography.styles';
import {
  Header,
  SendParcel,
  InstructionsCards,
  InstructionsCardImage,
  InstructionsTextInfo,
  InstructionsCardText,
  CreateNowButton,
  InstructionsCard,
} from './instructions.styles';
import { useStore } from '../../../stores/store';
import { CardTitle } from '../common/card.styles';
import Button from '../../../common/button/button.component';
import { useStartParcelCreation } from '../../../hooks/use-start-parcel-creation.hook';

const Instructions = () => {
  const {
    marketingStore: { fetchInstructions, instructions },
  } = useStore();
  const handleStartParcelCreation = useStartParcelCreation();

  const { t } = useTranslation();

  useEffect(() => {
    fetchInstructions();
  }, [fetchInstructions]);

  const hasInstructionsSender = !!instructions?.send.items.length;

  return (
    <Container>
      <Section>
        <Header>
          <SectionTitle>{t('how_does_it_work')}</SectionTitle>
        </Header>
        {hasInstructionsSender && (
          <SendParcel>
            <InstructionsCards $length={instructions.send.items.length}>
              {instructions?.send.items.map(
                ({ id, title, description, icon: Icon }, index) => (
                  <InstructionsCard
                    key={id}
                    $index={index + 1}
                    $isLast={index === instructions.send.items.length - 1}>
                    <InstructionsCardImage>
                      <Icon />
                    </InstructionsCardImage>
                    <InstructionsTextInfo>
                      <CardTitle>{t(title)} </CardTitle>
                      <InstructionsCardText
                        $isLast={index === instructions.send.items.length - 1}>
                        {t(description)}
                      </InstructionsCardText>
                    </InstructionsTextInfo>
                    {index === instructions.send.items.length - 1 && (
                      <CreateNowButton>
                        <Button
                          size='large'
                          appearance='brand'
                          onClick={() => {
                            handleStartParcelCreation();
                          }}>
                          {t('create_now')}
                        </Button>
                      </CreateNowButton>
                    )}
                  </InstructionsCard>
                )
              )}
            </InstructionsCards>
          </SendParcel>
        )}
      </Section>
    </Container>
  );
};

export default observer(Instructions);

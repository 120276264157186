import { observer } from 'mobx-react-lite';
import { HeaderContactsContainer } from '../header.styles';
import {
  EMAIL_AND_PHONE_TYPES,
  filterAndSortSupportLinks,
  getHref,
  getIcon,
  handleClick,
} from '../../../utils/live-chat/live-chat.utils';
import { useStore } from '../../../stores/store';
import { FlexContainer } from '../../../common/flex-container/flex-container.styles';
import { useIsMobile } from '../../../hooks/use-is-mobile.hook';
import { copyToClipboard } from '../../../utils/generic/generic.utils';

const HeaderContacts = () => {
  const {
    localizationsStore: { selectedCountry },
  } = useStore();

  const isMobile = useIsMobile();

  const contacts = filterAndSortSupportLinks(
    selectedCountry?.support,
    EMAIL_AND_PHONE_TYPES
  ).sort((a, b) => b.type.localeCompare(a.type));

  if (contacts.length === 0) return;

  return (
    <HeaderContactsContainer>
      {contacts.map((c) => (
        <FlexContainer
          key={c.url}
          $gap='4px'
          $mobGap='10px'>
          <a
            href={getHref(c.url, c.type, isMobile)}
            onClick={() =>
              handleClick({
                url: c.url.toLocaleLowerCase(),
                type: c.type,
                isMobile,
                copyToClipboard,
              })
            }
            target='_blank'
            rel='noreferrer'>
            {getIcon(c.type)}
          </a>
          <p>{c.url}</p>
        </FlexContainer>
      ))}
    </HeaderContactsContainer>
  );
};

export default observer(HeaderContacts);

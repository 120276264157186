import { TFunction } from 'i18next';
import RibbonLabel from '../../../../../common/ribbon-label/ribbon-label.component';
import { DeliveryMethod } from '../../../../../models/parcelDeliveryMiles';

const labelConfig = {
  air: {
    type: 'blue',
    text: 'main_page_calculator_optimal',
  },
  car: {
    type: 'blue',
    text: 'main_page_calculator_optimal',
  },
  sea: {
    type: 'green',
    text: 'main_page_calculator_cheapest',
  },
  expedited: {
    type: 'red',
    text: 'main_page_calculator_fastest',
  },
};

export function getLabel(
  deliveryMethod: DeliveryMethod,
  t: TFunction<'translation', undefined>
) {
  const { type, text } = labelConfig[deliveryMethod];

  return (
    <RibbonLabel
      type={type as 'red' | 'green' | 'blue'}
      text={t(text)}
    />
  );
}

import { useTranslation } from 'react-i18next';
import { VALIDATION_RULE_LATIN_WITH_COMMA } from '../../../constants/validation';
import SearchWithSidebar from '../../../common/search-with-sidebar/search-with-sidebar.component';
import { useStore } from '../../../stores/store';
import { getCityFromPlaceDetails } from '../../../utils/google-places/google-places.utils';
import { toGeocode } from '../../../common/map/map.helpers';
import { Geocode } from '../../../models/pudoItem';

export const changeCitySidebarName = 'change_city';

interface Props {
  countryCode?: string;
  onCitySelected: (cityName: string, location: Geocode) => void;
  infoNode?: React.ReactNode;
}

const ChangeCitySidebar = ({
  countryCode,
  onCitySelected,
  infoNode,
}: Props) => {
  const {
    placesSearchStore: { getAutocompletePredictions, getPlaceDetails },
    commonStore: { toastError },
  } = useStore();

  const { t } = useTranslation();

  return (
    <SearchWithSidebar<google.maps.places.AutocompletePrediction>
      name={changeCitySidebarName}
      sidebarTitle={t('choose_city')}
      sidebarInputPlaceholder=''
      debounceSidebarInputChange
      shouldValidateSearchQuery
      getSearchOptions={async (filter) =>
        getAutocompletePredictions({
          value: filter ?? '',
          predictionTypes: ['geocode'],
          countryConstraint: countryCode?.toLocaleLowerCase(),
        })
      }
      getKeyForSearchOption={(place) => place.place_id}
      getDisplayValueForSearchOption={(place) => place.description}
      onSearchOptionSelected={async (place) => {
        const placeDetails = await getPlaceDetails(place);

        if (placeDetails) {
          const city = getCityFromPlaceDetails(placeDetails.address_components);
          const location = toGeocode(placeDetails.location);

          if (city && location) {
            onCitySelected(city, location);
          }

          return;
        }

        toastError(t('pudo_map_address_not_found'));
      }}
      showSkeleton={false}
      validationRule={VALIDATION_RULE_LATIN_WITH_COMMA}
      infoNode={infoNode}
    />
  );
};

export default ChangeCitySidebar;

import {
  DeliveryMethod,
  FirstMile,
  LastMile,
  ShipmentPrice,
} from '../../../models/parcelDeliveryMiles';
import { getMiddleMileByDeliveryMethod } from '../../../utils/parcel-creation/parcel-creation-utils';
import { ShipmentCountryInfo } from '../../../models/shipmentCountryInfo';

const meestCarriers = ['MEESTPOST', 'meest'];

export const getMaxValidation = (value: string, configValue?: number) => {
  if (!value) return false;
  if (!configValue) return true;
  return +value <= configValue;
};

export const getMinValidation = (value: string, configValue: number = 0) => {
  if (!value) return false;
  if (+value === 0) return false;
  if (!configValue) return true;
  return +value >= configValue;
};

export const isCountryNameValid = (
  countryName: string,
  destinationCountries: ShipmentCountryInfo[] | undefined
) => {
  const foundCountry = destinationCountries?.find((c) =>
    c.countryDetails.localized_name.includes(countryName)
  );
  return foundCountry;
};

export const getCheapestDeliveryOptions = (
  lowestFirstMilePrices: FirstMile[],
  lastMiles: LastMile[] | null,
  middleMileMethod: DeliveryMethod
) => {
  if (!lastMiles) return [];

  const options = lowestFirstMilePrices.map((firstMile) => {
    const matchingLastMiles = lastMiles.filter(
      (lastMile) => lastMile.rate_schema_id === firstMile.rate_schema_id
    );

    if (!matchingLastMiles.length) return null;

    const bestLastMile = matchingLastMiles.reduce((prev, curr) => {
      const prevTotal =
        getMiddleMileByDeliveryMethod(firstMile, middleMileMethod)!
          .summary_price + prev.price;
      const currTotal =
        getMiddleMileByDeliveryMethod(firstMile, middleMileMethod)!
          .summary_price + curr.price;
      return currTotal < prevTotal ? curr : prev;
    });

    return {
      firstMile,
      lastMile: bestLastMile,
      price: {
        total_price:
          getMiddleMileByDeliveryMethod(firstMile, middleMileMethod)!
            .summary_price + bestLastMile.price,
      },
    };
  });

  const filteredOptions = options.filter(
    (
      option
    ): option is {
      firstMile: FirstMile;
      lastMile: LastMile;
      price: ShipmentPrice;
    } => option !== null
  );

  return filteredOptions.sort((a, b) => {
    if (
      meestCarriers.includes(a.lastMile.carrier.code) &&
      !meestCarriers.includes(b.lastMile.carrier.code)
    ) {
      return -1;
    }
    if (
      !meestCarriers.includes(a.lastMile.carrier.code) &&
      meestCarriers.includes(b.lastMile.carrier.code)
    ) {
      return 1;
    }
    return a.price.total_price - b.price.total_price;
  });
};

import { observer } from 'mobx-react-lite';
import {
  DeliveryDescriptionContainer,
  DeliveryImg,
  ParcelDeliveryOptionContainer,
  DeliveryPriceContainer,
  DeliveryServiceName,
} from './parcel-delivery-option.styles';
import {
  FirstMile,
  LastMile,
  ShipmentPrice,
} from '../../../../../models/parcelDeliveryMiles';
import { getFormattedPrice } from '../../../../../utils/price/get-formatted-price';
import { useStore } from '../../../../../stores/store';

interface Props {
  deliveryOption: {
    firstMile: FirstMile | undefined;
    lastMile: LastMile | undefined;
    price: ShipmentPrice;
  };
}

const ParcelDeliveryOption = ({ deliveryOption }: Props) => {
  const {
    userStore: { getCurrencySymbol },
  } = useStore();

  const { firstMile, price } = deliveryOption;

  const firstMileImgUrl = firstMile?.logo
    ? firstMile.logo
    : firstMile?.image ?? '';

  return (
    <ParcelDeliveryOptionContainer>
      <DeliveryDescriptionContainer>
        <DeliveryImg $imgUrl={firstMileImgUrl} />
        <DeliveryServiceName>{firstMile?.service.name}</DeliveryServiceName>
      </DeliveryDescriptionContainer>
      <DeliveryPriceContainer>
        {getFormattedPrice(
          price.total_price,
          getCurrencySymbol(price.currency)
        )}
      </DeliveryPriceContainer>
    </ParcelDeliveryOptionContainer>
  );
};

export default observer(ParcelDeliveryOption);

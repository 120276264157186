import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import { AppRoute, SearchParam } from '../../constants/route';
import { generateMyRoute } from '../../utils/uri/uri.utils';

const HasMainPageValidator = ({ children }: { children: React.ReactNode }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const redirect = searchParams.get(SearchParam.REDIRECT);

  const {
    navStore: { hasMarketingPage },
  } = useStore();

  if (!hasMarketingPage) {
    // TODO: had to use both useNavigate and <Navigate> here because of strange bug.
    // Without useNavigate, navigation didn't work for path with trailing slash, e.g. http://localhost:3000/gb-en/portal/
    // Without <Navigate> it doesn't work for path without slash, e.g. http://localhost:3000/gb-en/portal
    // Only when both are present it works correctly.

    navigate(redirect || generateMyRoute(AppRoute.PARCEL_LIST), {
      replace: true,
    });
    return <Navigate to={redirect || generateMyRoute(AppRoute.PARCEL_LIST)} />;
  }

  return children;
};

export default observer(HasMainPageValidator);

import styled, { css } from 'styled-components';
import { ComponentPropsWithoutRef } from 'react';
import { FontSizes } from '../typography/typography.styles';
import { SpinnerCircle } from '../spinner/spinner.styles';
import { BaseMarginComponent } from '../base-margin/base-margin.component';
import { ButtonContentStyle, ButtonSize, ButtonAppearance } from './types';
import {
  getBadgeSizes,
  getContentStyle,
  getIconSizeConfig,
  getSizeConfig,
} from './button.helpers';
import { client } from '../../config';
import { isMeest } from '../../utils/client/client.utils';

interface Props extends ComponentPropsWithoutRef<'button'> {
  disabled?: boolean;
  $isLoading?: boolean;
  $contentStyle?: ButtonContentStyle;
  $size?: ButtonSize;
  $fullWidth?: boolean;
  $appearance?: ButtonAppearance;
  $onlyIcon?: boolean;
  $isSelected?: boolean;
  $selectable?: boolean;
  $showBadge?: boolean;
  $badgeContent?: string | number;
  $pt?: string;
  $pb?: string;
  $pr?: string;
  $pl?: string;
  $justifyContent?: string;
  $hideLinkUnderline?: boolean;
}

export const ButtonSpinner = styled(SpinnerCircle)`
  width: 2rem;
  height: 2rem;
`;

const BaseButton = styled(BaseMarginComponent('button'))<Props>`
  --button-color--disabled: #c1dbf0;

  text-decoration: none;
  position: relative;
  border: 0;
  background: none;
  appearance: none;
  cursor: pointer;
  display: ${({ $fullWidth }) => ($fullWidth ? 'flex' : 'inline-flex')};
  justify-content: ${({ $justifyContent = 'center' }) => $justifyContent};
  align-items: center;
  background-color: var(--button-background-color);
  outline: 3px solid transparent;
  border: 1px solid var(--button-border-color);
  transition-property: border-color, background-color, color, outline-color;
  transition-duration: 0.2s;

  svg path {
    transition: fill 0.2s;
  }

  font-family: ${({ theme }) => theme.fonts.body.fontFamily};
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
  line-height: 1.14;
  ${({ $selectable, $isSelected, theme }) => {
    if (!$selectable) {
      return css`
        color: var(--button-color);
      `;
    }

    const color = $isSelected ? theme.colors.namedColors.primary : '#75777b';
    return css`
      color: ${color};
    `;
  }};

  ${({ $size, $appearance, $isLoading, $pr, $pl, $pt, $pb, $onlyIcon }) => {
    const config = $onlyIcon
      ? getIconSizeConfig($size)
      : getSizeConfig($size, $appearance, $isLoading);

    return css`
      padding-top: ${$pt || config.pt};
      padding-bottom: ${$pb || config.pb};
      padding-left: ${$pl || config.pl};
      padding-right: ${$pr || config.pr};
      gap: ${config.gap};
      border-radius: ${config.borderRadius};
      font-size: ${config.fontSize};
      font-weight: ${config.fontWeight};
      min-height: ${config.minHeight};
      svg,
      ${ButtonSpinner} {
        width: ${config.iconSize};
        height: ${config.iconSize};
        flex-shrink: 0;
      }

      ${ButtonSpinner} {
        border-width: ${$size === 'mini' ? '1px' : '2px'};
      }
    `;
  }};

  font-weight: ${({ $contentStyle }) => getContentStyle($contentStyle)};
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: var(--button-color--hover);
      background-color: var(--button-background-color--hover);
      border-color: var(--button-border-color--hover);
    }
  }

  &:focus-visible {
    color: var(--button-color--focus);
    background-color: var(--button-background-color--focus);
    border-color: var(--button-border-color--focus);
    outline-color: var(--button-outline-color--focus);
  }

  &:active {
    color: var(--button-color--active);
    background-color: var(--button-background-color--active);
    border-color: var(--button-border-color--active);
  }

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      background-color: var(--button-background-color);
      pointer-events: none !important;
    `}
  &:disabled {
    color: var(--button-color--disabled);
    background-color: var(--button-background-color--disabled);
    border-color: var(--button-border-color--disabled);
    cursor: not-allowed;
  }

  ${({ $badgeContent = '', $showBadge, $size = 'medium' }) =>
    $showBadge &&
    css`
      &::after {
        content: '${$badgeContent}';
        width: 1.6rem;
        height: 1.6rem;
        position: absolute;
        ${getBadgeSizes($size)}
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.baseColors.red100};
        border: 1px solid ${({ theme }) => theme.colors.namedColors.white};

        display: flex;
        align-items: center;
        justify-content: center;

        color: ${({ theme }) => theme.colors.namedColors.white};
        font-size: ${FontSizes.small};
        font-style: normal;
        font-weight: 500;
        line-height: 1.6rem;
        letter-spacing: 0.5px;
      }
    `}
`;

export const PrimaryButton = styled(BaseButton)<Props>`
  --button-color: ${({ theme }) => theme.colors.primaryButton.color};
  --button-background-color: ${({ theme }) =>
    theme.colors.primaryButton.backgroundColor};
  --button-border-color: ${({ theme }) =>
    theme.colors.primaryButton.borderColor};
  --button-outline-color: ${({ theme }) =>
    theme.colors.primaryButton.outlineColor};
  --button-color--hover: ${({ theme }) =>
    theme.colors.primaryButton.colorHover};
  --button-background-color--hover: ${({ theme }) =>
    theme.colors.primaryButton.backgroundColorHover};
  --button-border-color--hover: ${({ theme }) =>
    theme.colors.primaryButton.borderColorHover};
  --button-color--focus: ${({ theme }) =>
    theme.colors.primaryButton.colorFocus};
  --button-background-color--focus: ${({ theme }) =>
    theme.colors.primaryButton.backgroundColorFocus};
  --button-border-color--focus: ${({ theme }) =>
    theme.colors.primaryButton.borderColorFocus};
  --button-outline-color--focus: ${({ theme }) =>
    theme.colors.primaryButton.outlineColorFocus};
  --button-color--active: ${({ theme }) =>
    theme.colors.primaryButton.colorActive};
  --button-background-color--active: ${({ theme }) =>
    theme.colors.primaryButton.backgroundColorActive};
  --button-border-color--active: ${({ theme }) =>
    theme.colors.primaryButton.borderColorActive};
  --button-color--disabled: ${({ theme }) =>
    theme.colors.primaryButton.colorDisabled};
  --button-background-color--disabled: ${({ theme }) =>
    theme.colors.primaryButton.backgroundColorDisabled};
  --button-border-color--disabled: ${({ theme }) =>
    theme.colors.primaryButton.borderColorDisabled};
`;

export const SecondaryButton = styled(BaseButton)`
  --button-color--disabled: ${({ theme }) =>
    theme.colors.secondaryButton.colorDisabled};
  --button-color: ${({ theme }) => theme.colors.secondaryButton.color};
  --button-background-color: ${({ theme }) =>
    theme.colors.secondaryButton.backgroundColor};
  --button-border-color: ${({ theme }) =>
    theme.colors.secondaryButton.borderColor};
  --button-outline-color: ${({ theme }) =>
    theme.colors.secondaryButton.outlineColor};
  --button-color--hover: ${({ theme }) =>
    theme.colors.secondaryButton.colorHover};
  --button-background-color--hover: ${({ theme }) =>
    theme.colors.secondaryButton.backgroundColorHover};
  --button-border-color--hover: ${({ theme }) =>
    theme.colors.secondaryButton.borderColorHover};
  --button-color--focus: ${({ theme }) =>
    theme.colors.secondaryButton.colorFocus};
  --button-background-color--focus: ${({ theme }) =>
    theme.colors.secondaryButton.backgroundColorFocus};
  --button-border-color--focus: ${({ theme }) =>
    theme.colors.secondaryButton.borderColorFocus};
  --button-outline-color--focus: ${({ theme }) =>
    theme.colors.secondaryButton.outlineColorFocus};
  --button-color--active: ${({ theme }) =>
    theme.colors.secondaryButton.colorActive};
  --button-background-color--active: ${({ theme }) =>
    theme.colors.secondaryButton.backgroundColorActive};
  --button-border-color--active: ${({ theme }) =>
    theme.colors.secondaryButton.borderColorActive};
  --button-background-color--disabled: ${({ theme }) =>
    theme.colors.secondaryButton.backgroundColorDisabled};
  --button-border-color--disabled: ${({ theme }) =>
    theme.colors.secondaryButton.borderColorDisabled};
`;

export const SecondaryFilledButton = styled(SecondaryButton)`
  --button-background-color: ${({ theme }) =>
    theme.colors.secondaryFilledButton.backgroundColor};
  --button-border-color: ${({ theme }) =>
    theme.colors.secondaryFilledButton.borderColor};
  --button-background-color--hover: ${({ theme }) =>
    theme.colors.secondaryFilledButton.backgroundColorHover};
  --button-background-color--focus: ${({ theme }) =>
    theme.colors.secondaryFilledButton.backgroundColorFocus};
  --button-background-color--disabled: ${({ theme }) =>
    theme.colors.secondaryFilledButton.backgroundColorDisabled};
`;

export const TertiaryButton = styled(BaseButton)`
  --button-color: ${({ theme }) => theme.colors.tertiaryButton.color};
  --button-background-color: ${({ theme }) =>
    theme.colors.tertiaryButton.backgroundColor};
  --button-border-color: ${({ theme }) =>
    theme.colors.tertiaryButton.borderColor};
  --button-outline-color: ${({ theme }) =>
    theme.colors.tertiaryButton.outlineColor};
  --button-color--hover: ${({ theme }) =>
    theme.colors.tertiaryButton.colorHover};
  --button-background-color--hover: ${({ theme }) =>
    theme.colors.tertiaryButton.backgroundColorHover};
  --button-border-color--hover: ${({ theme }) =>
    theme.colors.tertiaryButton.borderColorHover};
  --button-color--focus: ${({ theme }) =>
    theme.colors.tertiaryButton.colorFocus};
  --button-background-color--focus: ${({ theme }) =>
    theme.colors.tertiaryButton.backgroundColorFocus};
  --button-border-color--focus: ${({ theme }) =>
    theme.colors.tertiaryButton.borderColorFocus};
  --button-outline-color--focus: ${({ theme }) =>
    theme.colors.tertiaryButton.outlineColorFocus};
  --button-color--active: ${({ theme }) =>
    theme.colors.tertiaryButton.colorActive};
  --button-background-color--active: ${({ theme }) =>
    theme.colors.tertiaryButton.backgroundColorActive};
  --button-border-color--active: ${({ theme }) =>
    theme.colors.tertiaryButton.borderColorActive};
  --button-color--disabled: ${({ theme }) =>
    theme.colors.tertiaryButton.colorDisabled};
  --button-background-color--disabled: ${({ theme }) =>
    theme.colors.tertiaryButton.backgroundColorDisabled};
  --button-border-color--disabled: ${({ theme }) =>
    theme.colors.tertiaryButton.borderColorDisabled};
`;

export const TextButton = styled(BaseButton)`
  --button-color: ${({ theme }) => theme.colors.textButton.color};
  --button-color--hover: ${({ theme }) => theme.colors.textButton.colorHover};
  --button-color--focus: ${({ theme }) => theme.colors.textButton.colorFocus};
  --button-outline-color--focus: ${({ theme }) =>
    theme.colors.textButton.outlineColorFocus};
  --button-color--disabled: ${({ theme }) =>
    theme.colors.textButton.colorDisabled};
  --button-color--active: ${({ theme }) => theme.colors.textButton.colorActive};
`;
export const PolonezLinkButton = styled(BaseButton)`
  --button-color: ${({ theme }) => theme.colors.linkButton.color};
  --button-color--hover: ${({ theme }) => theme.colors.linkButton.colorHover};
  --button-color--focus: ${({ theme }) => theme.colors.linkButton.colorFocus};
  --button-outline-color--focus: ${({ theme }) =>
    theme.colors.linkButton.outlineColorFocus};
  --button-color--disabled: ${({ theme }) =>
    theme.colors.linkButton.colorDisabled};
  --button-color--active: ${({ theme }) => theme.colors.linkButton.colorActive};
`;

export const LinkButton = styled(
  isMeest(client) ? TextButton : PolonezLinkButton
)`
  text-decoration: ${({ $hideLinkUnderline }) =>
    $hideLinkUnderline ? 'none' : 'underline'};
`;

export const TextDangerButton = styled(BaseButton)`
  --button-color: ${({ theme }) => theme.colors.textDangerButton.color};
  --button-color--hover: ${({ theme }) =>
    theme.colors.textDangerButton.colorHover};
  --button-color--focus: ${({ theme }) =>
    theme.colors.textDangerButton.colorFocus};
  --button-outline-color--focus: ${({ theme }) =>
    theme.colors.textDangerButton.outlineColorFocus};
  --button-color--disabled: ${({ theme }) =>
    theme.colors.textDangerButton.colorDisabled};
  --button-color--active: ${({ theme }) =>
    theme.colors.textDangerButton.colorActive};
  text-decoration: ${({ $hideLinkUnderline }) =>
    $hideLinkUnderline ? 'none' : 'underline'};
`;

export const InvertedDangerButton = styled(BaseButton)`
  --button-color: ${({ theme }) => theme.colors.invertedDangerButton.color};
  --button-background-color: ${({ theme }) =>
    theme.colors.invertedDangerButton.backgroundColor};
  --button-border-color: ${({ theme }) =>
    theme.colors.invertedDangerButton.borderColor};
  --button-color--hover: ${({ theme }) =>
    theme.colors.invertedDangerButton.colorHover};
  --button-background-color--hover: ${({ theme }) =>
    theme.colors.invertedDangerButton.backgroundColorHover};
  --button-color--focus: ${({ theme }) =>
    theme.colors.invertedDangerButton.colorFocus};
  --button-background-color--focus: ${({ theme }) =>
    theme.colors.invertedDangerButton.backgroundColorFocus};
  --button-color--active: ${({ theme }) =>
    theme.colors.invertedDangerButton.colorActive};
  --button-background-color--active: ${({ theme }) =>
    theme.colors.invertedDangerButton.backgroundColorActive};
  --button-border-color--active: ${({ theme }) =>
      theme.colors.invertedDangerButton.borderColorActive}
    --button-border-color--hover:
    ${({ theme }) => theme.colors.invertedDangerButton.borderColorHover}
    --button-border-color--focus:
    ${({ theme }) => theme.colors.invertedDangerButton.borderColorFocus}
    --button-color--disabled:
    ${({ theme }) => theme.colors.invertedDangerButton.colorDisabled};
  --button-background-color--disabled: ${({ theme }) =>
    theme.colors.invertedDangerButton.backgroundColorDisabled};
  --button-border-color--disabled: ${({ theme }) =>
    theme.colors.invertedDangerButton.borderColorDisabled};
`;

export const DangerButton = styled(BaseButton)`
  --button-color: ${({ theme }) => theme.colors.dangerButton.color};
  --button-background-color: ${({ theme }) =>
    theme.colors.dangerButton.backgroundColor};
  --button-border-color: ${({ theme }) =>
    theme.colors.dangerButton.borderColor};
  --button-color--hover: ${({ theme }) => theme.colors.dangerButton.colorHover};
  --button-background-color--hover: ${({ theme }) =>
    theme.colors.dangerButton.backgroundColorHover};
  --button-border-color--hover: ${({ theme }) =>
    theme.colors.baseColors.borderColorHover};
  --button-color--focus: ${({ theme }) => theme.colors.dangerButton.colorFocus};
  --button-background-color--focus: ${({ theme }) =>
    theme.colors.dangerButton.backgroundColorFocus};
  --button-border-color--focus: ${({ theme }) =>
    theme.colors.dangerButton.red1borderColorFocus00};
  --button-outline-color--focus: ${({ theme }) =>
    theme.colors.dangerButton.outlineColorFocus};
  --button-color--active: ${({ theme }) =>
    theme.colors.dangerButton.colorActive};
  --button-background-color--active: ${({ theme }) =>
    theme.colors.dangerButton.backgroundColorActive};
  --button-border-color--active: ${({ theme }) =>
    theme.colors.dangerButton.borderColorActive};
  --button-color--disabled: ${({ theme }) =>
    theme.colors.dangerButton.colorDisabled};
  --button-background-color--disabled: ${({ theme }) =>
    theme.colors.dangerButton.backgroundColorDisabled};
  --button-border-color--disabled: ${({ theme }) =>
    theme.colors.dangerButton.borderColorDisabled};

  ${SpinnerCircle} {
    border-width: 3px;
    border-color: #e60033;
    border-top-color: ${({ theme }) => theme.colors.namedColors.white};
    border-right-color: ${({ theme }) => theme.colors.namedColors.white};
  }
`;

export const BrandButton = styled(DangerButton)`
  --button-background-color: ${({ theme }) =>
    theme.colors.brandButton.backgroundColor};
  --button-background-color--hover: ${({ theme }) =>
    theme.colors.brandButton.backgroundColorHover};
  --button-background-color--active: ${({ theme }) =>
    theme.colors.brandButton.backgroundColorActive};
`;

export const PolonezCalculatorButton = styled(BaseButton)`
  --button-background-color: ${({ theme }) =>
    theme.colors.polonezCalculatorButton.color};
  --button-color: ${({ theme }) => theme.colors.primaryButton.color};
  --button-border-color: ${({ theme }) =>
    theme.colors.primaryButton.borderColor};
  --button-outline-color: ${({ theme }) =>
    theme.colors.primaryButton.outlineColor};
  --button-color--hover: ${({ theme }) =>
    theme.colors.primaryButton.colorHover};
  --button-background-color--hover: ${({ theme }) =>
    theme.colors.primaryButton.backgroundColorHover};
  --button-border-color--hover: ${({ theme }) =>
    theme.colors.primaryButton.borderColorHover};
  --button-color--focus: ${({ theme }) =>
    theme.colors.primaryButton.colorFocus};
  --button-background-color--focus: ${({ theme }) =>
    theme.colors.primaryButton.backgroundColorFocus};
  --button-border-color--focus: ${({ theme }) =>
    theme.colors.primaryButton.borderColorFocus};
  --button-outline-color--focus: ${({ theme }) =>
    theme.colors.primaryButton.outlineColorFocus};
  --button-color--active: ${({ theme }) =>
    theme.colors.primaryButton.colorActive};
  --button-background-color--active: ${({ theme }) =>
    theme.colors.primaryButton.backgroundColorActive};
  --button-border-color--active: ${({ theme }) =>
    theme.colors.primaryButton.borderColorActive};
  --button-color--disabled: ${({ theme }) =>
    theme.colors.primaryButton.colorDisabled};
  --button-background-color--disabled: ${({ theme }) =>
    theme.colors.primaryButton.backgroundColorDisabled};
  --button-border-color--disabled: ${({ theme }) =>
    theme.colors.primaryButton.borderColorDisabled};
`;

export const PolonezCalculatorLinkButton = styled(TextButton)`
  --button-color: ${({ theme }) => theme.colors.namedColors.accent};
  text-decoration: ${({ $hideLinkUnderline }) =>
    $hideLinkUnderline ? 'none' : 'underline'};
`;
export const PolonezClearAllButton = styled(BaseButton)`
  --button-color: ${({ theme }) => theme.colors.polonezClearAllButton.color};
  --button-border-color: ${({ theme }) =>
    theme.colors.polonezClearAllButton.borderColor};
  --button-background-color: ${({ theme }) =>
    theme.colors.polonezClearAllButton.backgroundColor};
`;
export const DefaultButton = styled(BaseButton)`
  --button-color: ${({ theme }) => theme.colors.namedColors.primary};
  --button-background-color: ${({ theme }) => theme.colors.baseColors.blue0};
  --button-color--hover: ${({ theme }) => theme.colors.namedColors.primary};
  --button-background-color--hover: #e5f4ff;
`;

const SocialSigninButton = styled(BaseButton)`
  width: 5.6rem;
  height: 4.8rem;
  padding: 1.2rem 1.6rem;
  outline: none;

  & svg {
    width: 2.4rem;
    height: 2.4rem;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.namedColors.primary};
      color: ${({ theme }) => theme.colors.namedColors.white};
    }
  }

  &:focus-visible {
    background-color: ${({ theme }) => theme.colors.namedColors.primary};
    color: #ffffff;
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.namedColors.main};
    border-color: ${({ theme }) => theme.colors.namedColors.main};
  }
`;

export const GoogleSigninButton = styled(SocialSigninButton)`
  border: 1px solid ${({ theme }) => theme.colors.baseColors.grey50};
  background-color: transparent;
`;

export const AppleSigninButton = styled(SocialSigninButton)`
  border: transparent;
  background-color: ${({ theme }) => theme.colors.baseColors.grey150};

  & svg {
    path {
      fill: ${({ theme }) => theme.colors.namedColors.white};
    }
  }
`;

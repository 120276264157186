import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { LinkFooter } from '../../common/typography/typography.styles';
import {
  ChangeCountrySelectTitle,
  FooterColumn,
  FooterTopContainer,
  FooterColumnTitle,
  BecomeAnAgentLink,
} from './footer.styles';
import Select, { IDropdownItem } from '../../common/select/select.component';
import { useStore } from '../../stores/store';
import useNavigateNewLocale from '../../hooks/use-navigate-to-new-locale.hook';
import { isMeest } from '../../utils/client/client.utils';
import { client } from '../../config';
import { FooterLinks } from '../../models/footerLinksModel';
import { mergeLinks } from './helpers/utils';

const FooterTop = () => {
  const {
    localizationsStore: { availableCountries, selectedCountry },
    marketingStore: { footerLinks, countriesWithTermsAndConditions },
    widgetCalculatorStore: { shipmentCountries, resetStore },
  } = useStore();

  const { t } = useTranslation();
  const navigateToNewLocale = useNavigateNewLocale();

  const links: FooterLinks | undefined = mergeLinks(
    selectedCountry?.links,
    footerLinks
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedCountry]);

  const mapCountries = useMemo(
    () =>
      availableCountries?.map((country) => ({
        value: { alias: country.alias, countryCode: country.country_code },
        label: `${country.flag_emoji_2} ${country.countryDetails.localized_name}`,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [availableCountries]
  );

  if (!links || !mapCountries) return;

  const handleChangeCountry = async (
    item: IDropdownItem<{ countryCode: string; alias: string }>
  ) => {
    const newCountry =
      shipmentCountries.find((c) => c.countryCode === item.value.countryCode) ??
      null;

    resetStore(newCountry);
    navigateToNewLocale({
      countryCode: item.value.alias.toLocaleLowerCase(),
      withPageReload: false,
    });
    setTimeout(() => window.scrollTo({ top: 0 }), 10);
  };

  return (
    <FooterTopContainer>
      <FooterColumn>
        <FooterColumnTitle>{t('footer_our_company')}</FooterColumnTitle>
        {isMeest(client) && (
          <LinkFooter href={links?.about_us}>{t('footer_about_us')}</LinkFooter>
        )}
        <LinkFooter
          href={selectedCountry?.links.privacy_policy ?? links.privacy_policy}>
          {t('footer_privacy_policy')}
        </LinkFooter>
        {links.terms_of_use && (
          <LinkFooter href={links.terms_of_use}>
            {countriesWithTermsAndConditions.includes(
              selectedCountry?.country_code ?? ''
            )
              ? t('footer_terms_and_conditions')
              : t('footer_terms_of_use')}
          </LinkFooter>
        )}
        {links.delivery_rules && (
          <LinkFooter href={links.delivery_rules}>
            {t('footer_delivery_rules')}
          </LinkFooter>
        )}
      </FooterColumn>

      <FooterColumn>
        <FooterColumnTitle>{t('footer_services')}</FooterColumnTitle>
        <LinkFooter href={links?.international_shipping}>
          {t('footer_international_shipping')}
        </LinkFooter>
        <LinkFooter href={links.all_services}>
          {t('footer_all_services')}
        </LinkFooter>
      </FooterColumn>

      <FooterColumn>
        <FooterColumnTitle>{t('footer_contact_us')}</FooterColumnTitle>
        <LinkFooter href={links.customer_support}>
          {t('footer_customer_support')}
        </LinkFooter>
        <LinkFooter href={links.faq}>{t('footer_faq')}</LinkFooter>
      </FooterColumn>

      <FooterColumn>
        <FooterColumnTitle>{t('footer_location')}</FooterColumnTitle>
        <Select
          shouldMatchWidthOfInput
          title={
            <ChangeCountrySelectTitle>
              {t('footer_location_text')}
            </ChangeCountrySelectTitle>
          }
          width='100%'
          items={mapCountries}
          dropdownPosition='bottom-center'
          value={{
            value: selectedCountry?.country_code,
            label: `${selectedCountry?.flag_emoji_2} ${selectedCountry?.countryDetails?.localized_name}`,
          }}
          onChange={handleChangeCountry}
        />
        {isMeest(client) && links.become_agent && (
          <BecomeAnAgentLink
            target='_blank'
            rel='noopener noreferrer'
            href={links.become_agent}>
            {t('become_an_agent_button')}
          </BecomeAnAgentLink>
        )}
      </FooterColumn>
    </FooterTopContainer>
  );
};

export default observer(FooterTop);

import FocusTrap from 'focus-trap-react';
import Button from '../button/button.component';
import CloseButton from '../close-button/close-button.component';
import { ModalDialogContainer } from './modal-dialog.styles';

interface Props {
  isLoading?: boolean;
  isOpen: boolean;
  isDanger?: boolean;
  onClose?: () => void;
  onApprove?: () => void;
  approveBtnContent?: string;
  cancelBtnContent?: string;
  width?: string;
  padding?: string;
  children: React.ReactNode;
  displayButtons?: boolean;
  useApproveLogicForDialogClosing?: boolean; // May be useful when dialog was designed in a way when approve logic should be applied to cancel button
}

const ModalDialog = ({
  children,
  isDanger = false,
  isOpen,
  onClose = () => {},
  onApprove = () => {},
  approveBtnContent,
  cancelBtnContent,
  width = '40rem',
  padding = '3.2rem 4rem',
  displayButtons = true,
  useApproveLogicForDialogClosing = false,
  isLoading,
}: Props) => (
  <ModalDialogContainer
    $widthModal={width}
    $paddingModal={padding}
    isOpen={isOpen}
    onBackgroundClick={!useApproveLogicForDialogClosing ? onClose : onApprove}
    onEscapeKeydown={!useApproveLogicForDialogClosing ? onClose : onApprove}>
    <FocusTrap
      focusTrapOptions={{
        initialFocus: false,
      }}>
      <div>
        <CloseButton
          onClick={(e) => {
            e.stopPropagation();
            !useApproveLogicForDialogClosing ? onClose() : onApprove();
          }}
        />
        {children}
        {displayButtons && !!approveBtnContent && (
          <Button
            isLoading={isLoading}
            appearance={isDanger ? 'danger' : 'primary'}
            onClick={(e) => {
              e.stopPropagation();
              onApprove();
            }}
            $mb={cancelBtnContent ? '1.6rem' : '0'}>
            {approveBtnContent}
          </Button>
        )}
        {displayButtons && !!cancelBtnContent && (
          <Button
            appearance='text'
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}>
            {cancelBtnContent}
          </Button>
        )}
      </div>
    </FocusTrap>
  </ModalDialogContainer>
);

export default ModalDialog;

import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import {
  ServicesSubSectionContainer,
  ServicesSubSectionHeader,
} from '../../services/services-subsection/services-subsection.styles';
import { Content, CustomHeader, MarginContainer } from './insurance-fee.styles';
import { FlexContainer } from '../../../common/flex-container/flex-container.styles';
import Button from '../../../common/button/button.component';
import { useStore } from '../../../stores/store';
import FormFieldText from '../../../common/form-field-text/form-field-text.component';
import InsuranceTooltip from '../../../common/insurance-tooltip/insurance-tooltip.component';
import { InsuranceLimits } from '../../../models/insuranceLimits';
import { getFormattedPrice } from '../../../utils/price/get-formatted-price';
import {
  GridContainer,
  PriceValue,
  PriceTitle,
} from '../../../routes/parcel-content/parcel-content.styles';
import CustomSkeleton from '../../../common/skeletons/custom-skeleton-component';
import { meest } from '../../../styles/meest';
import { client } from '../../../config';
import { isMeest } from '../../../utils/client/client.utils';
import { transformToDecimal } from '../../../utils/forms/forms.utils';

interface FormValues {
  insuranceAmount: string;
  insuranceFee: string;
}

const calculateInsuranceFee = (amount: number, limits?: InsuranceLimits) => {
  if (!limits) return 0;

  if (Number(amount) === limits?.min_insurance) {
    return 0;
  }
  if (amount > limits?.max_insurance) {
    return parseFloat((limits.max_insurance * 0.05).toFixed(2));
  }
  return parseFloat((amount * 0.05).toFixed(2));
};

interface Props {
  editable?: boolean;
}

const InsuranceFee = ({ editable = true }: Props) => {
  const {
    userStore: { getCurrencySymbol },
    parcelCreationStore: {
      modifyShipmentInsurance,
      shipment,
      isLoadingShipmentApi,
      fetchInsuranceLimits,
      insuranceLimits,
    },
    localizationsStore: { isLanguageChanging },
  } = useStore();

  useEffect(() => {
    fetchInsuranceLimits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isEditing, setIsEditing] = useState(false);

  const { t } = useTranslation();

  if (!shipment) return;

  const shipmentCurrencyCode = shipment?.converted_price?.currency;

  const { insurance, insurance_fee: insuranceFee } = shipment;

  const getInsuranceFeeElement = (amount: number, fee: number) => (
    <GridContainer>
      <PriceTitle>{t('insurance_fee')}</PriceTitle>
      {!insuranceLimits ? (
        <CustomSkeleton
          width='12rem'
          height='1.8rem'
        />
      ) : (
        <PriceValue
          $free={amount <= insuranceLimits.min_insurance || !amount || !fee}>
          {fee > 0 && amount > insuranceLimits.min_insurance
            ? `${getFormattedPrice(fee, getCurrencySymbol(shipmentCurrencyCode))}`
            : t('free')}
        </PriceValue>
      )}
    </GridContainer>
  );

  const initialValues = {
    insuranceAmount:
      insurance === 0 && insuranceLimits
        ? insuranceLimits?.min_insurance.toString()
        : insurance.toString(),
    insuranceFee: insuranceFee.toString(),
  };

  const validationSchema = Yup.object({
    insuranceAmount: Yup.number()
      .max(
        insuranceLimits?.max_insurance ?? 0,
        t('max_insurance_amount', {
          maxInsuranceAmount: getFormattedPrice(
            insuranceLimits?.max_insurance,
            getCurrencySymbol(shipmentCurrencyCode)
          ),
        })
      )
      .min(
        insuranceLimits?.min_insurance ?? 0,
        t('min_insurance_amount', {
          minInsuranceAmount: getFormattedPrice(
            insuranceLimits?.min_insurance,
            getCurrencySymbol(shipmentCurrencyCode)
          ),
        })
      )
      .required(
        t('min_insurance_amount', {
          minInsuranceAmount: getFormattedPrice(
            insuranceLimits?.min_insurance,
            getCurrencySymbol(shipmentCurrencyCode)
          ),
        })
      ),
  });

  const handleFormSubmit = (formValues: FormValues) => {
    modifyShipmentInsurance(Number.parseFloat(formValues.insuranceAmount));
    setIsEditing(false);
  };

  return (
    <ServicesSubSectionContainer>
      <ServicesSubSectionHeader $isEditable>
        <CustomHeader>
          {t('insurance')}
          <InsuranceTooltip />
        </CustomHeader>
        {!isEditing && editable && (
          <Button
            size='large'
            theme={meest}
            contentStyle={isMeest(client) ? 'thin' : 'normal'}
            fullWidth={false}
            appearance='text'
            onClick={() => setIsEditing(true)}
            disabled={isLoadingShipmentApi || isLanguageChanging}>
            {t('edit')}
          </Button>
        )}
      </ServicesSubSectionHeader>

      <Content>
        {isEditing ? (
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            validateOnChange
            onSubmit={(values) => handleFormSubmit(values)}>
            {({ isValid, values, handleSubmit, resetForm, setValues }) => (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}>
                <MarginContainer
                  $mbTablet='1.6rem'
                  $mb='2rem'>
                  <FormFieldText
                    validationMethod='always'
                    label='insurance_amount'
                    name='insuranceAmount'
                    infoText={getCurrencySymbol(shipmentCurrencyCode)}
                    inputMode='decimal'
                    onChange={(event) => {
                      const newInsurance = transformToDecimal(
                        event.target.value
                      );
                      const newInsuranceFee = calculateInsuranceFee(
                        Number.parseFloat(newInsurance),
                        insuranceLimits!
                      );
                      setValues({
                        insuranceFee: newInsuranceFee.toString(),
                        insuranceAmount: newInsurance,
                      });
                    }}
                  />
                </MarginContainer>
                {getInsuranceFeeElement(
                  Number.parseFloat(values.insuranceAmount),
                  Number.parseFloat(values.insuranceFee)
                )}
                <MarginContainer $mt='2.4rem'>
                  <FlexContainer $justify='end'>
                    <Button
                      appearance='secondary'
                      onClick={() => {
                        resetForm();
                        setIsEditing(false);
                      }}>
                      {t('cancel')}
                    </Button>
                    <Button
                      type='submit'
                      disabled={!isValid}>
                      {t('save_changes')}
                    </Button>
                  </FlexContainer>
                </MarginContainer>
              </form>
            )}
          </Formik>
        ) : (
          <GridContainer>
            <PriceTitle>{t('insurance_amount')}</PriceTitle>
            {!insuranceLimits ? (
              <CustomSkeleton
                width='8rem'
                height='1.8rem'
              />
            ) : (
              <PriceValue>{`${getFormattedPrice(insurance, getCurrencySymbol(shipmentCurrencyCode))}`}</PriceValue>
            )}
          </GridContainer>
        )}
        {!isEditing && getInsuranceFeeElement(insurance, insuranceFee)}
      </Content>
    </ServicesSubSectionContainer>
  );
};

export default observer(InsuranceFee);

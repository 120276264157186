import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import ModalDialog from '../modal-dialog/modal-dialog.component';
import { HeaderSecondary } from '../typography/typography.styles';
import { ModalIconBody } from '../modal-dialog/modal-dialog.styles';
import { ReactComponent as MeestQRIcon } from '../../assets/meest-qr-icon.svg';
import { ReactComponent as PolonezQRIcon } from '../../assets/polonez-qr-icon.svg';
import { useIsMobile } from '../../hooks/use-is-mobile.hook';
import Button from '../button/button.component';
import { client } from '../../config';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
const getQRIcon = () => {
  const icons = {
    meest: <MeestQRIcon />,
    polonez: <PolonezQRIcon />,
  };
  return icons[client];
};

const QRCodeModal = ({ isOpen = false, onClose }: Props) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const handleRedirect = () => {
    window.open('https://vaua1.test-app.link/1ADeT5OvhIb', '_blank');
  };

  return (
    <ModalDialog
      isOpen={isOpen}
      onClose={onClose}
      displayButtons={false}>
      <HeaderSecondary>{t('scan_qr_modal')}</HeaderSecondary>
      <ModalIconBody>{getQRIcon()}</ModalIconBody>
      {isMobile && (
        <Button
          onClick={handleRedirect}
          appearance='text'
          $pb='0'
          $pt='16px'
          className='mobile-qr-popup-link'>
          {t('download_now')}
        </Button>
      )}
    </ModalDialog>
  );
};

export default observer(QRCodeModal);

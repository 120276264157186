import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Footnote,
  SelectOption,
  TabsContainerTop,
} from './parcel-delivery-options-tabs.styles';
import { WithSelectionCallback } from '../../../common/input-select-with-sidebar/input-select-with-sidebar.component';
import DeliveryTabTitle from './parcel-delivery-tab-title.component';
import { getFirstMilesByDeliveryMethodSorted } from '../../../utils/parcel-creation/parcel-creation-utils';
import { useStore } from '../../../stores/store';
import ParcelDeliveryOption from '../parcel-delivery-option/parcel-delivery-option.component';
import { DeliveryMethod, FirstMile } from '../../../models/parcelDeliveryMiles';
import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from '../../../common/tabs/tabs.component';
import { getSetting } from '../../../utils/countries/countries.utils';
import Tip from '../../../common/toasts/tip.component';
import { ReactComponent as ClockIcon } from '../../../assets/clock-blue.svg';

const ParcelDeliveryOptionsTabs = ({
  onSelect,
}: WithSelectionCallback<FirstMile>) => {
  const {
    parcelCreationStore: {
      countryDeparture,
      firstMiles,
      staticTimeStrings,
      middleMileMethodsWithMaxDuration,
      selectedMiddleMileMethod,
      setMiddleMileMethod,
    },
  } = useStore();

  const [middleMileState, setMiddleMileState] = useState<DeliveryMethod | null>(
    selectedMiddleMileMethod
  );

  const { t } = useTranslation();

  const [tabIndex, setTabIndex] = useState(
    () =>
      middleMileMethodsWithMaxDuration.findIndex(
        (mm) => mm.method === selectedMiddleMileMethod
      ) ?? 0
  );

  const [tabAnimationActive, setTabAnimationActive] = useState(true);

  const handeMiddleMileSelect = (index: number) => {
    setMiddleMileState(middleMileMethodsWithMaxDuration[index].method);
    setTabIndex(index);
  };

  const dynamicDeliveryTimeSettingValue = getSetting(
    countryDeparture?.settings ?? [],
    'delivery_options',
    'dynamic_delivery_times'
  );

  const showDurationByTip =
    dynamicDeliveryTimeSettingValue === 'from_static_config';

  function handleFirstMileSelect(firstMile: FirstMile, method: DeliveryMethod) {
    onSelect(firstMile);
    setMiddleMileMethod(method);
  }

  const isSingleMiddleMile = middleMileMethodsWithMaxDuration.length === 1;

  return (
    <Container>
      <Tabs
        defaultIndex={tabIndex}
        onChange={handeMiddleMileSelect}
        theme='gray'>
        <TabsContainerTop>
          {!isSingleMiddleMile && (
            <TabList>
              {middleMileMethodsWithMaxDuration.map((mile, i) => (
                <Tab
                  theme={mile.method === 'expedited' ? 'red' : 'blue'}
                  tabIndex={i.toString()}
                  tabAnimationCondition={tabAnimationActive}
                  onClick={() => setTabAnimationActive(false)}
                  key={mile.method}>
                  <DeliveryTabTitle
                    isSelected={middleMileState === mile.method}
                    method={mile.method}
                    showAdvantageTag
                  />
                </Tab>
              ))}
            </TabList>
          )}
          {staticTimeStrings && middleMileState && showDurationByTip && (
            <Tip
              appearance='info'
              title={staticTimeStrings[middleMileState]}
              icon={<ClockIcon />}
            />
          )}
        </TabsContainerTop>

        {middleMileMethodsWithMaxDuration.map((middleMile) => (
          <TabPanel key={middleMile.method}>
            {getFirstMilesByDeliveryMethodSorted(
              firstMiles ?? [],
              middleMile.method
            ).map((firstMile, index) => (
              <SelectOption
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                onClick={() =>
                  handleFirstMileSelect(firstMile, middleMile.method)
                }>
                <ParcelDeliveryOption
                  middleMileMethod={middleMileState!}
                  deliveryOption={firstMile}
                  isPriceResponsive={false}
                  showTotalDuration
                  showDurationInCard={!showDurationByTip}
                />
              </SelectOption>
            ))}
            {middleMileState === 'expedited' && (
              <Footnote>{t('expedited_footnote')}</Footnote>
            )}
          </TabPanel>
        ))}
      </Tabs>
    </Container>
  );
};

export default observer(ParcelDeliveryOptionsTabs);

import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ProgressButtonContainer,
  FlexContainer,
  PriceContainer,
  PriceExpandContainer,
  PriceText,
  PriceTitle,
  TotalText,
  TotalTitle,
  ParcelCreationBottomContainer,
  PaddingContainer,
  PriceExpandContent,
  TimeTitle,
  TimeText,
  DeliveryTimeContainer,
  Recaptcha,
  Trigger,
} from './parcel-creation-bottom.styles';
import Button from '../../../common/button/button.component';
import { useStore } from '../../../stores/store';
import { getFormattedPrice } from '../../../utils/price/get-formatted-price';
import { ReactComponent as AirplaneIcon } from '../../../assets/airplane.svg';
import { ReactComponent as ShipIcon } from '../../../assets/ship.svg';
import { ReactComponent as RocketIcon } from '../../../assets/rocket.svg';
import { ReactComponent as CarIcon } from '../../../assets/car.svg';
import ReCAPTCHA from '../../../common/recaptcha/recaptcha.component';
import { ReactComponent as ChevronDown } from '../../../assets/chevron-down.svg';
import { TRecaptchaToken } from '../../../stores/parcelCreationFlowStore';
import { generateMyRoute } from '../../../utils/uri/uri.utils';
import { AppRoute } from '../../../constants/route';
import { handlePaymentRedirect } from '../../../utils/payments/payments.utils';
import { getGeocodingInfo } from '../../../utils/google-places/google-places.utils';
import { toGeocode } from '../../../common/map/map.helpers';
import { constructGoogleMapsUrl } from '../../../utils/generic/generic.utils';
import { DeliveryMethod } from '../../../models/parcelDeliveryMiles';
import { useModal } from '../../../common/modal';
import { Modals } from '../../../constants/modals';
import { useIsMobile } from '../../../hooks/use-is-mobile.hook';
import {
  getDeliveryTimeTitle,
  scrollToFirstInvalidInput,
} from '../../../utils/parcel-creation/parcel-creation-utils';

export const MiddleMileIcon: React.FC<{ method: DeliveryMethod | null }> = ({
  method,
}) => {
  switch (method) {
    case 'air':
      return <AirplaneIcon />;
    case 'car':
      return <CarIcon />;
    case 'sea':
      return <ShipIcon />;
    case 'expedited':
      return <RocketIcon />;
    default:
      return null;
  }
};

const ParcelCreationBottom = ({ isMobileView = false }) => {
  const {
    userStore: { getCurrencySymbol },
    parcelCreationStore: {
      shipment,
      navigation,
      validateCurrentStep,
      proceedToNextStep,
      proceedToPrevStep,
      createParcel,
      totalPrice,
      selectedFirstMile,
      selectedLastMile,
      sendingOptionPrice,
      lastMilePriceDiff,
      totalDuration,
      deliveryTimeName,
      guestRecaptchaToken,
      selectedMiddleMileMethod,
      isNextStepButtonLoading,
      setGuestRecaptchaToken,
      getPaymentLink,
      promocode,
      isLoadingShipmentApi,
      isAgentFlowShowMap,
      selectedSenderPudo,
      countryDeparture,
      setIsNextStepButtonLoading,
      isFinishWithPay,
      isSignatureRequired,
      setIsNextButtonClicked,
      isNextButtonClicked,
      setIsSignButtonClicked,
      prefillInProgress,
    },
    commonStore: { isGuestParcelCreation, toastSuccess },
    parcelListStore: { addParcelInPaymentProgress },
    localizationsStore: { isLanguageChanging },
  } = useStore();
  const [isPriceContainerExpanded, setIsPriceContainerExpanded] =
    useState(false);
  const navigate = useNavigate();
  const [recaptchaToken, setRecaptchaToken] =
    useState<TRecaptchaToken>(guestRecaptchaToken);

  const { openModal } = useModal();

  const isMobile = useIsMobile();

  const { t } = useTranslation();

  const shipmentCurrencyCode = shipment?.converted_price?.currency;

  const isAgentServiceType =
    shipment?.sender?.carrier?.delivery_service &&
    shipment.sender.carrier.delivery_service.service_type === 'agent';

  const handlePayParcel = async () => {
    const link = getPaymentLink();
    if (link) {
      handlePaymentRedirect(link);
      if (!shipment) return;
      addParcelInPaymentProgress(shipment.id);
    }
  };

  const handleCreateParcel = async () => {
    setIsNextStepButtonLoading(true);
    await createParcel();
    navigate(generateMyRoute(AppRoute.PARCEL_LIST));
    toastSuccess(t('new_parcel_has_been_created'));

    if (!isAgentServiceType && isFinishWithPay) {
      await handlePayParcel();
    }

    setIsNextStepButtonLoading(false);
  };

  const handleProceedToNextStep = async () => {
    setGuestRecaptchaToken(recaptchaToken);
    await proceedToNextStep(navigate);
  };

  const handleNavigateTo = async () => {
    if (!selectedSenderPudo) return;

    setIsNextStepButtonLoading(true);
    const senderZipGeoInfo = await getGeocodingInfo(countryDeparture?.zipCode);
    const senderGeocode = toGeocode(senderZipGeoInfo?.geometry.location);
    window.open(
      constructGoogleMapsUrl(selectedSenderPudo.geocode, senderGeocode),
      '_blank'
    );
    setIsNextStepButtonLoading(false);
  };

  const deliveryTimeBlock = (
    <>
      <TimeTitle>
        {getDeliveryTimeTitle(selectedMiddleMileMethod, deliveryTimeName)}
      </TimeTitle>
      <TimeText>
        <MiddleMileIcon method={selectedMiddleMileMethod} />
        {totalDuration}
      </TimeText>
    </>
  );

  const isFirstStep =
    navigation.current!.name === AppRoute.PARCEL_CREATION_PARCEL_DETAILS;
  const isDeliveryInstructionsStep =
    navigation.current!.name === 'delivery-instructions';
  const isSummaryStep =
    navigation.current!.name === AppRoute.PARCEL_CREATION_SUMMARY;
  const isSignatureStep = navigation.current!.name === AppRoute.SIGNATURE;
  const shouldOpenSignatureModal =
    isSummaryStep && isSignatureRequired && !isMobile;
  const isRecaptchaRequired = isFirstStep && isGuestParcelCreation;
  const isRecaptchaValid = !!(recaptchaToken || guestRecaptchaToken);
  const isLastStep = navigation.next === null && navigation.map.length > 2;
  const isSubmitEnabled = isRecaptchaRequired
    ? !!(isRecaptchaValid && validateCurrentStep())
    : !!validateCurrentStep();
  const isFirstLastMileSelected = selectedFirstMile && selectedLastMile;
  const shouldShowInsuranceFee =
    navigation.current?.name === 'parcel-content' ||
    (!!shipment?.insurance_fee && shipment?.insurance_fee > 0);

  const shouldShowTaxes = shipment && shipment.taxes > 0;

  const shouldShowPromocode =
    !!promocode?.discount && promocode?.discount !== 0;

  const getSubmitButtonText = () => {
    if (!isLastStep) {
      return t('next_step');
    }

    if (isAgentFlowShowMap) {
      return t('navigate_to');
    }

    if (isAgentServiceType || !isFinishWithPay) {
      return t('create_parcel');
    }

    if (isSignatureRequired && isMobile) {
      return t('sign_and_create');
    }

    if (isFinishWithPay) {
      return t('pay');
    }

    return t('create_parcel');
  };

  const handleSubmitButtonClick = async () => {
    setIsNextButtonClicked(true);
    if (isSignatureStep) setIsSignButtonClicked(true);
    if (!isSubmitEnabled) {
      setTimeout(scrollToFirstInvalidInput, 100);
      return;
    }

    if (!isLastStep && shouldOpenSignatureModal) {
      openModal({
        id: Modals.SIGNATURE,
        name: Modals.SIGNATURE,
        props: {
          handleCreateParcel,
        },
      });
      return;
    }

    if (!isLastStep) {
      await handleProceedToNextStep();
      return;
    }

    if (isAgentFlowShowMap) {
      await handleNavigateTo();
      return;
    }

    await handleCreateParcel();
  };

  const showPriceBlock =
    !isFirstStep &&
    isFirstLastMileSelected &&
    !isDeliveryInstructionsStep &&
    !isSignatureStep;

  return (
    <ParcelCreationBottomContainer
      $transparentBackground={!selectedFirstMile || isFirstStep}
      $mobileShow={isMobileView}>
      {showPriceBlock && (
        <>
          <PriceContainer>
            {selectedFirstMile && (
              <DeliveryTimeContainer
                $pr='2.4rem'
                $pl='2.4rem'>
                {deliveryTimeBlock}
              </DeliveryTimeContainer>
            )}
            <PaddingContainer
              $pr='2.4rem'
              $pl='2.4rem'>
              <Trigger
                aria-expanded={isPriceContainerExpanded}
                aria-controls='price-details'
                aria-label='Accordion trigger'
                onClick={() => setIsPriceContainerExpanded((prev) => !prev)}
                $expanded={isPriceContainerExpanded}>
                <ChevronDown />
                <TotalTitle>{t('total')}</TotalTitle>
              </Trigger>
              <TotalText>
                {getFormattedPrice(
                  totalPrice,
                  getCurrencySymbol(shipmentCurrencyCode)
                )}
              </TotalText>
            </PaddingContainer>
          </PriceContainer>

          <PriceExpandContainer
            id='price-details'
            $expanded={isPriceContainerExpanded}>
            <PriceExpandContent>
              <span className='separatorLine' />
              {selectedFirstMile && (
                <FlexContainer>
                  <PriceTitle>
                    {`${selectedFirstMile?.carrier?.name}: ${selectedFirstMile?.service?.name}`}
                  </PriceTitle>
                  <PriceText>{`${getFormattedPrice(sendingOptionPrice, getCurrencySymbol(shipmentCurrencyCode))}`}</PriceText>
                </FlexContainer>
              )}

              {selectedLastMile && (
                <FlexContainer>
                  <PriceTitle>
                    {`${selectedLastMile.carrier?.name}: ${selectedLastMile.service?.name}`}
                  </PriceTitle>
                  <PriceText>
                    {getFormattedPrice(
                      lastMilePriceDiff,
                      getCurrencySymbol(shipmentCurrencyCode)
                    )}
                  </PriceText>
                </FlexContainer>
              )}

              {shouldShowInsuranceFee && (
                <FlexContainer>
                  <PriceTitle>{t('insurance_fee')}</PriceTitle>
                  <PriceText>
                    {getFormattedPrice(
                      shipment?.insurance_fee || 0,
                      getCurrencySymbol(shipmentCurrencyCode)
                    )}
                  </PriceText>
                </FlexContainer>
              )}

              {shouldShowTaxes && (
                <FlexContainer>
                  <PriceTitle>{t('taxes')}</PriceTitle>
                  <PriceText>
                    {getFormattedPrice(
                      shipment.taxes,
                      getCurrencySymbol(shipmentCurrencyCode)
                    )}
                  </PriceText>
                </FlexContainer>
              )}

              {shouldShowPromocode && (
                <FlexContainer>
                  <PriceTitle>{t('promocode_discount')}</PriceTitle>
                  <PriceText>
                    <span>
                      -
                      {getFormattedPrice(
                        Math.abs(promocode.discount),
                        getCurrencySymbol(shipmentCurrencyCode)
                      )}
                    </span>
                  </PriceText>
                </FlexContainer>
              )}
            </PriceExpandContent>
          </PriceExpandContainer>
        </>
      )}
      {isRecaptchaRequired && !guestRecaptchaToken && (
        <Recaptcha id='recaptcha-wrapper'>
          <ReCAPTCHA
            onExpired={() => {
              setRecaptchaToken(null);
            }}
            onChange={setRecaptchaToken}
            isError={isNextButtonClicked && !recaptchaToken}
          />
        </Recaptcha>
      )}

      <ProgressButtonContainer
        $mt='0'
        $mtMobile='0'
        $numberOfBtns={isFirstStep ? 1 : 2}>
        {!isFirstStep && (
          <Button
            $pt='1.4rem'
            $pb='1.4rem'
            appearance='secondary'
            disabled={
              isLoadingShipmentApi ||
              isLanguageChanging ||
              isNextStepButtonLoading
            }
            onClick={() => {
              proceedToPrevStep(navigate);
              if (isSignatureStep) setIsSignButtonClicked(false);
            }}>
            {t('back')}
          </Button>
        )}
        <Button
          $mt='0'
          $pt='1.4rem'
          $pb='1.4rem'
          isLoading={isNextStepButtonLoading || prefillInProgress}
          disabled={isLoadingShipmentApi || isLanguageChanging}
          onClick={handleSubmitButtonClick}>
          {getSubmitButtonText()}
        </Button>
      </ProgressButtonContainer>
    </ParcelCreationBottomContainer>
  );
};

export default observer(ParcelCreationBottom);

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  ServicesSubSectionContainer,
  ServicesSubSectionHeader,
} from '../../features/services/services-subsection/services-subsection.styles';
import { useStore } from '../../stores/store';
import { ParcelDetailsSearchInputsContainer } from '../parcel-details/parcel-details.styles';
import InputSelectWithSidebar from '../../common/input-select-with-sidebar/input-select-with-sidebar.component';
import ParcelDeliveryOptionsTabs from '../../features/parcel-creation/parcel-delivery-options-tabs/parcel-delivery-options-tabs.component';
import ParcelReceivingOptionsTabs from '../../features/parcel-creation/parcel-delivery-options-tabs/parcel-receiving-options-tabs.component';
import { FirstMile, LastMile } from '../../models/parcelDeliveryMiles';
import ParcelDeliveryOption from '../../features/parcel-creation/parcel-delivery-option/parcel-delivery-option.component';
import DeliveryPrice from '../../features/parcel-creation/parcel-delivery-option/delivery-price.component';
import {
  DeliveryOptionContainer,
  DeliveryOptionWrapper,
} from '../../features/parcel-creation/parcel-delivery-option/parcel-delivery-option.styles';
import { TextLabel } from '../../common/typography/typography.styles';
import HintTooltip from '../../common/hint-tooltip/hint-tooltip.component';

const ParcelDelivery = () => {
  const {
    parcelCreationStore: {
      selectedLastMile,
      setFirstMile,
      setLastMile,
      selectedFirstMile,
      isNextButtonClicked,
      lastMilesForSelectedFirstMile,
    },
  } = useStore();

  const { t } = useTranslation();

  const isLastMileDisabled = !selectedFirstMile;

  const isSingleLastMile =
    lastMilesForSelectedFirstMile.length === 1 && selectedLastMile;

  return (
    <ServicesSubSectionContainer id='deliveryOptionsSubSectionContainer'>
      <ServicesSubSectionHeader>
        {t('delivery_options_delivery')}
      </ServicesSubSectionHeader>
      <ParcelDetailsSearchInputsContainer
        $layout='column'
        $gap='2rem'
        id='deliveryOptionsSearchContainer'>
        <InputSelectWithSidebar<FirstMile>
          disabled={false}
          name='sending'
          onSelectHandler={(firstMile) => {
            setFirstMile(firstMile);
          }}
          selectedFromOutsideItem={selectedFirstMile}
          renderSelectedItem={(firstMile) => (
            <ParcelDeliveryOption
              deliveryOption={firstMile}
              isPriceResponsive
              showDurationInCard={false}
            />
          )}
          placeholder={t('choose_option')}
          label={t('sending_option')}
          labelHintText={t('drop_off_options_tooltip')}
          sidebarTitle={t('choose_sending_options')}
          SidebarContent={ParcelDeliveryOptionsTabs}
          error={t('this_field_cannot_be_empty')}
          errorValidationPredicate={(firstMile) => firstMile === undefined}
          infoMessage={t('could_be_paid_only_by_cash_at_the_agents_office')}
          showInfoMessagePredicate={(firstMile) =>
            firstMile?.service.code === 'agent'
          }
          maxInputHeight='100%'
          triggerValidation={isNextButtonClicked}
        />

        {selectedFirstMile && (
          <DeliveryPrice
            deliveryOption={selectedFirstMile}
            isPriceResponsive
            responsiveContainerId='deliveryPriceResponsive'
          />
        )}

        {isSingleLastMile && (
          <DeliveryOptionWrapper>
            <TextLabel>
              {t('receiving_option')}
              <HintTooltip
                contentMaxWidth='22rem'
                placement='top'
                text={t('pick_up_options_tooltip')}
              />
            </TextLabel>
            <DeliveryOptionContainer>
              <ParcelDeliveryOption
                deliveryOption={selectedLastMile}
                isPriceResponsive
                showDurationInCard={false}
                hideZeroPrice
              />
            </DeliveryOptionContainer>
          </DeliveryOptionWrapper>
        )}

        {!isSingleLastMile && (
          <InputSelectWithSidebar<LastMile>
            disabled={isLastMileDisabled}
            name='receiving'
            onSelectHandler={(lastMile) => setLastMile(lastMile)}
            renderSelectedItem={(lastMile) => (
              <ParcelDeliveryOption
                deliveryOption={lastMile}
                isPriceResponsive
                showDurationInCard={false}
                hideZeroPrice
              />
            )}
            selectedFromOutsideItem={selectedLastMile} // required to reset form when new first mile is selected
            placeholder={t('choose_option')}
            label={t('receiving_option')}
            labelHintText={t('pick_up_options_tooltip')}
            sidebarTitle={t('choose_receiving_options')}
            SidebarContent={ParcelReceivingOptionsTabs}
            error={t('this_field_cannot_be_empty')}
            errorValidationPredicate={(lastMile) => lastMile === undefined}
            maxInputHeight='100%'
            triggerValidation={!isLastMileDisabled && isNextButtonClicked}
          />
        )}
        {!isSingleLastMile && selectedLastMile && (
          <DeliveryPrice
            deliveryOption={selectedLastMile}
            isPriceResponsive
            responsiveContainerId='deliveryPriceResponsive'
            hideZeroPrice
          />
        )}
      </ParcelDetailsSearchInputsContainer>
    </ServicesSubSectionContainer>
  );
};

export default observer(ParcelDelivery);

import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { APIProvider } from '@vis.gl/react-google-maps';
import debounce from 'debounce';
import { runInAction } from 'mobx';
import Skeleton from 'react-loading-skeleton';
import { useStore } from '../../../stores/store';
import InputSearch from '../../../common/input-search/input-search.component';
import {
  CityButtonWrapper,
  CitySkeletonWrapper,
  FilteredPudos,
  LocationBlock,
  PudoMapContainer,
  PudoSearchContainer,
  PudoSearchSidebarContent,
  PudoSearchWithSidebarContainer,
  SearchAndFilterBlock,
} from './parcel-pudo-search-sidebar.styles';
import { InputContainer } from '../../../common/form-field-text/form-field-text.styles';
import {
  ArrowIconContainer,
  InputPlaceholder,
  InputSelect,
} from '../../../common/input-select-with-sidebar/input-select-with-sidebar.styles';
import { ReactComponent as ArrowRightIcon } from '../../../assets/arrow-right-icon.svg';
import { Geocode, PudoItem } from '../../../models/pudoItem';
import PudoListItem from '../pudo-list-item/pudo-list-item.component';
import PudoListItemSkeleton from '../pudo-list-item/pudo-list-item-skeleton.component';
import { SelectOption } from '../parcel-delivery-options-tabs/parcel-delivery-options-tabs.styles';
import Sidebar from '../../../common/sidebar-right/sidebar.component';
import PudoFilter from '../pudo-filter/pudo-filter.component';
import { UserRole } from '../delivery-details-form/delivery-details-form.component';
import IconButton from '../../../common/icon-button/icon-button';
import InputMessage from '../../../common/input-message/input-message.component';
import { SidebarName } from '../../../types/sidebar.types';
import { useModal } from '../../../common/modal';
import Button from '../../../common/button/button.component';
import GoogleMap from '../../../common/map/map.component';
import useGeolocation from '../../../hooks/use-geolocation.hook';
import { Modals } from '../../../constants/modals';
import {
  calculateCurrentAndMaxDistances,
  getFilteredPudos,
  LocalPudoFilter,
} from './pudo-search.helpers';
import EmptyList from '../../../common/empty-list/empty-list.component';
import { envConfig } from '../../../config';
import TogglePudoDisplayModeButton from './toggle-pudo-display-mode-button-container.component';
import { useMediaQuery } from '../../../hooks/use-media-query.hook';
import ChangeCitySidebar, {
  changeCitySidebarName,
} from './change-city-sidebar.component';
import usePrevious from '../../../hooks/use-previous.hook';
import { getCityFromLatLng } from '../../../utils/google-places/google-places.utils';

interface Props {
  placeholder: string;
  sidebarTitle: string;
  allowNearme?: boolean;
  disabled?: boolean;
  role: UserRole;
  fieldName: 'pudo';
}

type LocationSearchType = 'city' | 'nearme';

const skeletonItems = Array.from({ length: 10 });

const ParcelPudoSearchWithSidebar = ({
  placeholder,
  sidebarTitle,
  disabled,
  allowNearme,
  role,
  fieldName,
}: Props) => {
  const {
    navStore: {
      toggleSidebarByName,
      closeAllRightSidebars,
      setPudoFiltersOpened,
      isSidebarOpenedByName,
      openedRightSidebars,
    },
    parcelCreationStore: {
      isLoadingSearchApi,
      countryDeparture,
      countryDestination,
      foundPudoItems,
      foundPudoMeta,
      preservePudos,
      countAppliedFilters,
      currentLocation,
      pudoFilterSearch,
      pudoFilterTimeWeight,
      pudoFilterGeocode,
      pudoFilterDistance,
      isNextButtonClicked,
      setPudoFilterGeocode,
      setPudoFilterSearch,
      setCurrentLocation,
      getPudosForRole,
      setPreservePudos,
      setFoundPudos,
      setFoundPudoMeta,
      clearPudoFilters,
      isAgentFlowShowMap,
    },
    commonStore: { toastError },
    userStore: { user },
  } = useStore();

  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 767px)');
  const { openModal, closeModal } = useModal();

  const [filteredPudos, setFilteredPudos] = useState<PudoItem[]>([]);
  const [pudoFilterLocal, setPudoFilterLocal] = useState<LocalPudoFilter>({});
  const [isLocalFiltering, setIsLocalFiltering] = useState(false);
  const [pudoWithOpenedDetails, setPudoWithOpenedDetails] = useState<
    PudoItem | undefined
  >();
  const [pudosInCluster, setPudosInCluster] = useState<PudoItem[] | undefined>(
    undefined
  );

  const [locationSearchType, setLocationSearchType] =
    useState<LocationSearchType>(allowNearme ? 'nearme' : 'city');
  const [showMap, setShowMap] = useState(false);

  const country =
    role === UserRole.sender ? countryDeparture : countryDestination;

  // city & zip & geocode are required for change city scenario
  const [cityName, setCityName] = useState<string | undefined>(
    locationSearchType === 'nearme' ? undefined : country?.city
  );
  const [cityGeocode, setCityGeocode] = useState<Geocode | undefined>();

  const prevCity = usePrevious(cityName);
  const shouldMapResetZoom = useMemo(
    () => !!prevCity && !!cityName && prevCity !== cityName,
    [prevCity, cityName]
  );

  const [field, meta] = useField(fieldName);
  const { setFieldValue, setFieldTouched, validateField } = useFormikContext();

  const {
    isGeolocationAvailable,
    isLocationLoading,
    tryGetLocation,
    geoPermissionState,
  } = useGeolocation({
    onLocationSuccess: handleLocationSuccess,
    onLocationError: handleLocationError,
  });

  const sidebarName: SidebarName = `${fieldName}_${role}`;

  const isMapSidebarOpened = isSidebarOpenedByName(sidebarName);

  const isFieldInvalid = (isNextButtonClicked || meta.touched) && !!meta.error;

  useEffect(() => {
    if (!isNextButtonClicked) return;

    validateField(fieldName);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNextButtonClicked]);

  // *************
  // For requesting new pudo's when one of the API filter's changes:
  // geocode, distance, working time, max weight.
  useEffect(() => {
    async function updateFilteredPudos(
      useCache: boolean,
      localFilter: LocalPudoFilter
    ) {
      // Wait while current user's location is obtained from browser before executing initial request.
      if (locationSearchType === 'nearme' && !pudoFilterGeocode) return;

      setFilteredPudos([]);
      // API filtering with new filters (may be from cache)
      setCityName(undefined);
      const response = await getPudosForRole({ role, useCache });
      if (!response) return;

      const { pudos, pudosMeta } = response;
      // Local filtering by search string
      setIsLocalFiltering(true);
      setFilteredPudos(await getFilteredPudos(pudos, localFilter));
      setIsLocalFiltering(false);

      if (!pudosMeta) return;

      const metaLoc = {
        lat: pudosMeta.latitude,
        lng: pudosMeta.longitude,
      };

      const newCity = pudosMeta.city ?? (await getCityFromLatLng(metaLoc));

      setCityName(newCity);

      // Set API geocode filter to pudoMeta right after pudos for postal code were requested
      // so pudoFilterGeocode is always a signle source of map's center 'from outside' of map component.
      if (!pudoFilterGeocode) {
        runInAction(() => {
          setPreservePudos(true);
          setPudoFilterGeocode(pudosMeta);
        });
      } else {
        setPreservePudos(false);
      }
    }

    if (!isMapSidebarOpened) return;
    updateFilteredPudos(preservePudos, pudoFilterLocal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    role,
    isMapSidebarOpened,
    pudoFilterTimeWeight,
    pudoFilterGeocode,
    pudoFilterDistance,
    pudoFilterSearch,
    locationSearchType,
  ]);

  // *************
  // For local filtering to react on input search string change
  useEffect(() => {
    async function updateFilteredPudos() {
      setIsLocalFiltering(true);
      setFilteredPudos(await getFilteredPudos(foundPudoItems, pudoFilterLocal));
      setIsLocalFiltering(false);
    }

    updateFilteredPudos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pudoFilterLocal]);

  // *************
  // Clear current location in case user reset geolocation access during parcel creation (so testers are happy...)
  useEffect(() => {
    async function tryResetCurrentLocation() {
      if (geoPermissionState !== 'granted' && currentLocation) {
        setCurrentLocation(undefined);
      }
    }

    tryResetCurrentLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoPermissionState]);

  // *************
  // Close details sidebar when user refreshed page with selected pudo
  useEffect(() => {
    if (openedRightSidebars.length > 1) {
      const detailsSidebar = openedRightSidebars
        .filter((s) => s !== sidebarName)
        ?.at(0);
      detailsSidebar && toggleSidebarByName(detailsSidebar);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // *************
  // Destructor to clear related MobX state
  useEffect(
    () => () => {
      setFoundPudos(undefined);
      setFoundPudoMeta(undefined);
      setCurrentLocation(undefined);
      clearPudoFilters();
    },
    [setFoundPudos, setFoundPudoMeta, clearPudoFilters, setCurrentLocation]
  );

  // ***********
  // Moves map to the new location when new city is selected
  useEffect(() => {
    if (!cityGeocode) return;
    setPudoFilterGeocode(cityGeocode);
  }, [cityGeocode, setPudoFilterGeocode]);

  function handleGeoPermissionChange(state: PermissionState) {
    switch (state) {
      case 'granted':
        setCityName(undefined);
        tryGetLocation();
        break;
      case 'prompt':
        openModal({
          id: Modals.ALLOW_LOCATION_ACCESS,
          name: Modals.ALLOW_LOCATION_ACCESS,
          props: {
            onApprove: () => {
              closeModal(Modals.ALLOW_LOCATION_ACCESS);
              setCityName(undefined);
              tryGetLocation();
            },
            onClose: () => {
              setCurrentLocation(undefined);
              setLocationSearchType('city');
              closeModal(Modals.ALLOW_LOCATION_ACCESS);
            },
          },
        });
        break;
      case 'denied':
        setCurrentLocation(undefined);
        setLocationSearchType('city');
        toastError(t('the_location_service_is_off'));
        break;
      default:
        break;
    }
  }

  async function handleLocationSearchTypeChanged(
    prevSearchType: LocationSearchType,
    searchType: LocationSearchType
  ) {
    // For case when cluster is selected
    if (prevSearchType !== searchType) {
      setPudoFilterLocal((prev) => ({ ...prev, ids: undefined }));
    }

    if (searchType === 'nearme') {
      if (isGeolocationAvailable) {
        handleGeoPermissionChange(geoPermissionState);
      } else {
        toastError(t('geolocation_is_not_supported'));
        setLocationSearchType('city');
      }
    }

    if (searchType === 'city') {
      // For city search remove lat-lon filter from store
      // or for change city scenario provide selected city's geocode
      setLocationSearchType('city');
    }
  }

  async function handleLocationSuccess(position: GeolocationPosition) {
    // Cache location in store (just the location (lat, lon)).
    // Do not mix with the location filter which also includes distance.
    setCurrentLocation(position.coords);
    setPudoFilterGeocode(position.coords);
    setLocationSearchType('nearme');
  }

  function handleLocationError(error: GeolocationPositionError) {
    toastError(
      error?.code === 1 ? t('service_needs_location') : `${error?.message}`
    );
    setLocationSearchType('city');
  }

  const handleOpenSidebarClick = () => {
    if (disabled) return;

    if (allowNearme) {
      handleLocationSearchTypeChanged(locationSearchType, 'nearme');
    } else {
      setPudoFilterGeocode();
    }
    setPudoFilterLocal({});
    setPudoWithOpenedDetails(undefined);
    toggleSidebarByName(sidebarName);
  };

  function handleSidebarClosing({ rollbackCity }: { rollbackCity?: boolean }) {
    setFieldTouched(fieldName, true, true);
    setPudoFiltersOpened(false);
    clearPudoFilters();
    if (allowNearme) {
      setLocationSearchType('nearme');
    }
    closeAllRightSidebars();
    if (rollbackCity) {
      setCityName(undefined);
      setCityGeocode(undefined);
    }
  }

  async function handlePudoChosen(pudo: PudoItem) {
    await setFieldValue(fieldName, pudo, true);
    handleSidebarClosing({});
    if (role === UserRole.receiver) {
      updateReceiverCountryWithNewCityData(pudo);
    }
  }

  function updateReceiverCountryWithNewCityData(pudo: PudoItem) {
    if (!countryDestination || !cityName) return;
    if (
      countryDestination.city === cityName &&
      countryDestination.zipCode === pudo.address.post_code
    )
      return;

    runInAction(() => {
      countryDestination.city = cityName;
      countryDestination.zipCode = pudo.address.post_code;
      countryDestination.region = '';
    });
  }

  function handlePudoSelected(pudo: PudoItem) {
    setPudoFilterLocal((prev) => ({
      ...prev,
      ids: [pudo.id],
    }));
    setPudoWithOpenedDetails(pudo);
  }

  function handlePudoDeselected() {
    setPudoFilterLocal((prev) => ({
      ...prev,
      ids: pudosInCluster?.map((p) => p.id) ?? undefined,
    }));
    setPudoWithOpenedDetails(undefined);
  }

  function handleClusterSelected(pudos: PudoItem[]) {
    setPudosInCluster(pudos);
    setPudoFilterLocal((prev) => ({
      ...prev,
      ids: pudos.map((p) => p.id),
    }));
  }

  function handleClusterDeselected() {
    setPudosInCluster(undefined);
    setPudoFilterLocal(() => ({
      ids: undefined,
    }));
  }

  function handleCityChanged(city: string, location: Geocode) {
    handleClusterDeselected();
    setCityName(city);
    setPudoFilterGeocode(location);
    setCurrentLocation(undefined);
    handleLocationSearchTypeChanged(locationSearchType, 'city');
  }

  const tryUpdateGeocodeFilter = (center: Geocode) => {
    if (pudoWithOpenedDetails) return;

    const [currentDistance, maxDistance] = calculateCurrentAndMaxDistances(
      foundPudoItems ?? [],
      pudoFilterGeocode!,
      center,
      user?.measures
    );

    if (currentDistance > maxDistance) {
      setPudoFilterGeocode(center);
    }
  };

  const showToggleDisplayModeButton =
    isMobile &&
    isSidebarOpenedByName(sidebarName, true) &&
    (filteredPudos?.length > 0 || isLoadingSearchApi);

  const mapNode = (
    <>
      <PudoMapContainer $hidden={isMobile && !showMap}>
        <APIProvider apiKey={envConfig.REACT_APP_GOOGLE_MAP_API_KEY!}>
          {pudoFilterGeocode && (
            <GoogleMap<PudoItem>
              onZoomChange={(newZoom, _, clusterZoom) => {
                const shouldDeselectCluster =
                  !pudoWithOpenedDetails && newZoom < clusterZoom;

                if (shouldDeselectCluster) {
                  handleClusterDeselected();
                }
              }}
              onClusterClick={handleClusterSelected}
              center={pudoFilterGeocode}
              userLocation={currentLocation}
              locationPoints={filteredPudos}
              selectedPoint={pudoWithOpenedDetails}
              markerIconUrl={foundPudoMeta?.carrier_map_icon}
              geoPermissionState={geoPermissionState}
              hideCurrentLocation={!allowNearme}
              shouldResetZoom={shouldMapResetZoom}
              onMarkerClick={handlePudoSelected}
              onCenterChanged={debounce(tryUpdateGeocodeFilter, 750)}
              onCurrentLocationClick={() => {
                handleLocationSearchTypeChanged(locationSearchType, 'nearme');
              }}
            />
          )}
        </APIProvider>
      </PudoMapContainer>
      {isMobile && pudoWithOpenedDetails && (
        <PudoListItem
          shouldShowNavigateTo={role === UserRole.sender}
          pudo={pudoWithOpenedDetails}
          withDetails
          openSidebarWithDetails={!!pudoWithOpenedDetails?.id}
          onChoose={handlePudoChosen}
          onMoreDetails={handlePudoSelected}
          onBackToList={handlePudoDeselected}
        />
      )}
    </>
  );

  const renderCityContent = () => {
    if (!cityName) {
      return (
        <CitySkeletonWrapper>
          <Skeleton
            width='8rem'
            height='2rem'
            borderRadius={5}
          />
          {!isAgentFlowShowMap && <ArrowRightIcon className='arrowRightIcon' />}
        </CitySkeletonWrapper>
      );
    }

    if (isAgentFlowShowMap) {
      return <p>{cityName}</p>;
    }

    return (
      <Button
        onClick={() => toggleSidebarByName(changeCitySidebarName)}
        appearance='text'>
        {cityName}
        <ArrowRightIcon className='arrowRightIcon' />
      </Button>
    );
  };

  const showEmptyResult = pudoFilterLocal
    ? !filteredPudos?.at(0)
    : !foundPudoItems?.at(0);

  return (
    <PudoSearchWithSidebarContainer
      $mb='0'
      $isDisabled={disabled}>
      <InputContainer
        $error={isFieldInvalid}
        $maxInputHeight='20rem'
        data-type={isFieldInvalid ? 'invalid-input' : undefined}>
        <InputSelect
          tabIndex={0}
          $isDisabled={disabled}
          onClick={() => handleOpenSidebarClick()}>
          <ArrowIconContainer $isDisabled={disabled}>
            <ArrowRightIcon />
          </ArrowIconContainer>
          {field.value ? (
            <PudoListItem pudo={field.value} />
          ) : (
            placeholder && <InputPlaceholder>{placeholder}</InputPlaceholder>
          )}
        </InputSelect>
      </InputContainer>

      <Sidebar
        name={sidebarName}
        sidebarWidth='112rem'
        withBlurredBackground
        noBodyPadding
        header={sidebarTitle}
        onClose={() => {
          handleSidebarClosing({
            rollbackCity: true,
          });
        }}>
        <PudoSearchSidebarContent>
          {!isMobile && mapNode}
          <PudoSearchContainer>
            <LocationBlock>
              <CityButtonWrapper>
                {renderCityContent()}
                <ChangeCitySidebar
                  countryCode={country?.countryCode}
                  onCitySelected={handleCityChanged}
                  infoNode={
                    allowNearme && (
                      <Button
                        onClick={() => {
                          handleLocationSearchTypeChanged(
                            locationSearchType,
                            'nearme'
                          );
                          toggleSidebarByName(changeCitySidebarName);
                        }}
                        appearance='text'
                        size='small'
                        className='nearMeButton'
                        fullWidth={false}
                        disabled={isLoadingSearchApi}
                        icon={{ glyph: 'location-cross', position: 'left' }}>
                        {t('near_me')}
                      </Button>
                    )
                  }
                />
              </CityButtonWrapper>
            </LocationBlock>
            <SearchAndFilterBlock>
              <InputSearch
                name={`search_${sidebarTitle}`}
                placeholder={
                  isMobile ? t('search') : t('search_branch_number_or_address')
                }
                focusOnOpen
                searchIconPosition='left'
                disableSearchIconHover
                showClearInputButton
                onChange={debounce(setPudoFilterSearch, 750)}
              />
              <IconButton
                fullWidth={false}
                size={isMobile ? 'medium' : 'large'}
                appearance='secondary'
                icon={{ glyph: 'filter' }}
                showBadge={!!countAppliedFilters}
                onClick={(e) => {
                  e.stopPropagation();
                  setPudoFiltersOpened(true);
                }}
              />
            </SearchAndFilterBlock>
            {!showMap &&
            (isLoadingSearchApi || isLocationLoading || isLocalFiltering) ? (
              skeletonItems.map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <SelectOption key={index}>
                  <PudoListItemSkeleton withDetails />
                </SelectOption>
              ))
            ) : (
              <>
                {isMobile && mapNode}

                {!showMap && (
                  <FilteredPudos>
                    {filteredPudos.map((pudo) => (
                      <SelectOption
                        key={pudo.id}
                        $isSelected={pudo.id === field.value?.id}>
                        <PudoListItem
                          shouldShowNavigateTo={role === UserRole.sender}
                          pudo={pudo}
                          withDetails
                          openSidebarWithDetails={
                            pudoWithOpenedDetails?.id === pudo.id
                          }
                          onChoose={handlePudoChosen}
                          onMoreDetails={handlePudoSelected}
                          onBackToList={handlePudoDeselected}
                        />
                      </SelectOption>
                    ))}
                    {showEmptyResult && (
                      <EmptyList
                        isSidebar
                        title={
                          locationSearchType === 'nearme'
                            ? undefined
                            : t('no_search_results')
                        }
                        description={
                          locationSearchType === 'nearme'
                            ? t('no_delivery_options_for_selected_route')
                            : t('please_try_again')
                        }
                      />
                    )}
                  </FilteredPudos>
                )}
              </>
            )}
          </PudoSearchContainer>
        </PudoSearchSidebarContent>
      </Sidebar>

      {showToggleDisplayModeButton && (
        // Had to place toggle button here because of stacking issues on mobile Safari.
        // Button was hidden below scrollable content and I spen 2 days struggling with it.
        <TogglePudoDisplayModeButton
          onClick={() => setShowMap((prev) => !prev)}
          mode={showMap ? 'list' : 'map'}
          disabled={isLoadingSearchApi || isLocationLoading}
        />
      )}

      {isFieldInvalid && (
        <InputMessage type='error'>
          {t('this_field_cannot_be_empty')}
        </InputMessage>
      )}
      <PudoFilter />
    </PudoSearchWithSidebarContainer>
  );
};

export default observer(ParcelPudoSearchWithSidebar);

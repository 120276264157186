import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CollapsabeTextContainer } from './collpsable-text.styles';
import Button from '../button/button.component';
import { isMeest } from '../../utils/client/client.utils';
import { client } from '../../config';

interface Props {
  visibleText: string;
  hiddenText: string;
}
const isMeestClient = isMeest(client);

const CollapsableText = ({ visibleText, hiddenText }: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { t } = useTranslation();

  return (
    <CollapsabeTextContainer>
      <p>
        {isCollapsed ? `${visibleText.trim()}...` : visibleText}
        {!isCollapsed && hiddenText}
      </p>
      <Button
        appearance={isMeestClient ? 'link' : 'polonez-calculator-link'}
        hideLinkUnderline={isMeestClient}
        fullWidth={false}
        contentStyle='thin'
        onClick={(e) => {
          e.preventDefault();
          setIsCollapsed((prev) => !prev);
        }}>
        {isCollapsed
          ? t('main_page_calculator_see_more')
          : t('main_page_calculator_see_less')}
      </Button>
    </CollapsabeTextContainer>
  );
};

export default CollapsableText;

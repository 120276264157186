import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import {
  CalculateButton,
  CalculatoContainer,
  TrackingFormContainer,
} from './widget-calculator.styles';
import Button from '../../../common/button/button.component';
import { createValidationRule } from '../../../utils/forms/forms.utils';
import { VALIDATION_RULE_LATIN_WITH_NUMBERS } from '../../../constants/validation';
import { useStore } from '../../../stores/store';
import { useIsMobile } from '../../../hooks/use-is-mobile.hook';
import InputSearch from '../../../common/input-search/input-search.component';

interface Props {
  hidden?: boolean;
}

interface FormValues {
  trackingId: string;
}

const TrackingForm = ({ hidden }: Props) => {
  const {
    marketingStore: { getTrackingUrl },
  } = useStore();

  const [showSearchInput, setShowSearchInput] = useState(false);

  const { t } = useTranslation();

  const isMobile = useIsMobile();

  const inputName = 'trackingId';

  const handleFormSubmit = ({ trackingId }: FormValues) => {
    const trackUrl = getTrackingUrl(trackingId);
    window.open(trackUrl, '_blank');
  };

  const validationSchema = Yup.object({
    trackingId: createValidationRule(VALIDATION_RULE_LATIN_WITH_NUMBERS)
      .max(20)
      .required('this_field_cannot_be_empty'),
  });

  const initialValues: FormValues = {
    trackingId: '',
  };

  return (
    <CalculatoContainer $hidden={hidden}>
      <Formik
        enableReinitialize={false}
        onSubmit={handleFormSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={initialValues}>
        {({
          handleSubmit,
          setFieldValue,
          resetForm,
          values,
          errors,
          setErrors,
        }) => {
          const onChange = (value: string) => {
            setFieldValue(inputName, value, true);
          };

          // eslint-disable-next-line
          useEffect(() => {
            if (hidden) {
              setShowSearchInput(false);
              resetForm();
            }
            // eslint-disable-next-line
          }, [hidden, resetForm]);

          return (
            <form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                if (
                  values.trackingId.length > 0 &&
                  values.trackingId.length < 6
                ) {
                  setErrors({
                    trackingId:
                      'main_page_calculator_tracking_tab_validation_error_charcters_limit',
                  });
                } else {
                  handleSubmit();
                }
              }}
              autoComplete='off'
              style={{ width: '100%' }}>
              <TrackingFormContainer $isSearchVisible={!!errors.trackingId}>
                <InputSearch
                  name={inputName}
                  showClearInputButton
                  inputValue={values.trackingId}
                  onChange={onChange}
                  onClear={() => resetForm()}
                  borderNone={!isMobile}
                  placeholder={t('CV123456789CA')}
                  hideInputIcon
                  errorMessage={errors.trackingId}
                  maxLength={20}
                  label={t('main_page_tracking_placeholder')}
                  inputStyle={{ display: showSearchInput ? 'block' : 'none' }}
                  onInputClick={() => setShowSearchInput(true)}
                  focusOnOpen={showSearchInput}
                  type='search'
                />
                <CalculateButton>
                  <Button
                    isLoading={false}
                    size='large'
                    type='submit'>
                    {t('main_page_tracking_track')}
                  </Button>
                </CalculateButton>
              </TrackingFormContainer>
            </form>
          );
        }}
      </Formik>
    </CalculatoContainer>
  );
};

export default TrackingForm;

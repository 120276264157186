import { useState } from 'react';
import { TabList, TabPanel, Tabs as ReactTabs } from 'react-tabs';
import { Container } from './tabs.styles';
import Tab from './tab.component';

export type TabsTheme = 'gray' | 'white';
export type TabsSize = 'mini' | 'small' | 'medium' | 'large';
export type TabsCursor = 'default' | 'pointer';
export type TabsContentWeight = 500 | 700;

export interface Props {
  theme?: TabsTheme;
  defaultIndex?: number;
  children: React.ReactNode;
  size?: TabsSize;
  contentWeight?: TabsContentWeight;
  cursor?: TabsCursor;
  selectedIndex?: number;
  onChange?: (index: number) => void;
  gap?: string;
  justifyContent?: string;
}

const Tabs = ({
  onChange,
  children,
  defaultIndex = 0,
  theme = 'white',
  size = 'small',
  contentWeight = 500,
  cursor = 'pointer',
  selectedIndex,
  gap,
  justifyContent,
}: Props) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(defaultIndex);
  const isControlled = selectedIndex !== undefined;
  const handleChange = (index: number) => {
    if (!isControlled) {
      setSelectedTabIndex(index);
    }

    onChange && onChange(index);
  };

  const index = isControlled ? selectedIndex : selectedTabIndex;

  return (
    <Container
      $cursor={cursor}
      $contentWeight={contentWeight}
      $size={size}
      $theme={theme}
      $gap={gap}
      $justifyContent={justifyContent}>
      <ReactTabs
        selectedIndex={index}
        onSelect={handleChange}>
        {children}
      </ReactTabs>
    </Container>
  );
};

export { Tabs, TabList, Tab, TabPanel };

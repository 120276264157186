import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Content,
  DownloadAppIconsBlock,
  DownloadAppContainer,
  DownloadAppHeader,
  DownloadAppQrCode,
  DownloadAppQrCodeBlock,
  DownloadAppSectionDescription,
  DownloadAppWrapper,
  Section,
  DownloadAppStyledNavLink,
  StyledAppleStoreIcon,
  StyledGooglePlayIcon,
  IphoneImg,
  IphoneImgRemote,
} from './download-app.styles';
import iphoneBackgroundWebp2x from '../../../assets/main-page/download-app/Iphone16Pro_2x.webp';
import iphoneBackground2x from '../../../assets/main-page/download-app/Iphone16Pro_2x.png';
import AppDownloadQRCode from './download-app-qrcode.component';
import { VisuallyHidden } from '../../../common/visually-hidden/visually-hidden.styles';
import { useStore } from '../../../stores/store';
import iphoneBackgroundWebp from '../../../assets/main-page/download-app/Iphone16Pro.webp';
import iphoneBackground from '../../../assets/main-page/download-app/Iphone16Pro.png';
import { envConfig } from '../../../config';
import { FooterLinks } from '../../../models/footerLinksModel';
import { mergeLinks } from '../../footer/helpers/utils';

const getBaseUrl = () => {
  if (!envConfig.REACT_APP_API_URL) return;

  return new URL(envConfig.REACT_APP_API_URL).origin;
};

const DownloadApp = () => {
  const {
    localizationsStore: { selectedCountry, selectedLocalization },
    marketingStore: { footerLinks },
  } = useStore();
  const { t } = useTranslation();

  const links: FooterLinks | undefined = mergeLinks(
    selectedCountry?.links,
    footerLinks
  );

  const remoteImageUrl = `${getBaseUrl()}/downloads/marketing/download_app/app_sample/${selectedLocalization.code}.png`;

  const [useRemote, setUseRemote] = useState(true);

  const handleImgError = () => {
    setUseRemote(false);
  };

  return (
    <DownloadAppContainer>
      <Section>
        <Content>
          <DownloadAppWrapper>
            <DownloadAppHeader>
              {t('meest_in_your_smartphone')}
            </DownloadAppHeader>
            <DownloadAppSectionDescription>
              {t(
                'install_the_app_and_control_your_postal_items_directly_from_your_smartphone'
              )}
            </DownloadAppSectionDescription>
            <DownloadAppQrCodeBlock>
              <DownloadAppQrCode>
                <AppDownloadQRCode />
              </DownloadAppQrCode>
              <DownloadAppIconsBlock>
                <DownloadAppStyledNavLink
                  target='_blank'
                  href={links?.app_store}>
                  <VisuallyHidden>
                    {t('download_on_the_app_store')}
                  </VisuallyHidden>
                  <StyledAppleStoreIcon />
                </DownloadAppStyledNavLink>
                <DownloadAppStyledNavLink
                  target='_blank'
                  href={links?.play_market}>
                  <VisuallyHidden>{t('get_it_on_google_pay')}</VisuallyHidden>
                  <StyledGooglePlayIcon />
                </DownloadAppStyledNavLink>
              </DownloadAppIconsBlock>
            </DownloadAppQrCodeBlock>
          </DownloadAppWrapper>
        </Content>
        {useRemote ? (
          <IphoneImgRemote
            decoding='async'
            width={375}
            height={590}
            loading='lazy'
            src={remoteImageUrl}
            alt=''
            onError={handleImgError}
          />
        ) : (
          <picture>
            <source
              width={375}
              height={590}
              type='image/webp'
              srcSet={`${iphoneBackgroundWebp} 1x, ${iphoneBackgroundWebp2x} 2x`}
            />
            <IphoneImg
              decoding='async'
              width={375}
              height={590}
              loading='lazy'
              src={iphoneBackground}
              srcSet={`${iphoneBackground} 1x, ${iphoneBackground2x} 2x`}
              alt=''
            />
          </picture>
        )}
      </Section>
    </DownloadAppContainer>
  );
};

export default DownloadApp;

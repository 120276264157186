import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from '../stores/store';
import { AppRoute } from '../constants/route';
import { generateLocaleRoute } from '../utils/uri/uri.utils';

export const useCountryAndLocalizationFromUrl = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    localizationsStore: {
      selectedCountry,
      selectLocalizationByCode,
      selectCountryByCodeFromUrl,
      availableCountries,
    },
  } = useStore();

  // Please do not replace with useLayoutEffect.
  // useLayoutEffect causes bug when root page is still displayed, even though uri is update to e.g. /us-en/portal
  useEffect(() => {
    if (!availableCountries) return;

    const path = location.pathname;
    const matchesLocalePath = path.match(/^\/([a-z]{2})-([a-z]{2})(\/.*)?/i);

    if (matchesLocalePath) {
      const [, countryCode, localizationCode] = matchesLocalePath;
      selectCountryByCodeFromUrl(countryCode);
      selectLocalizationByCode(localizationCode);
      return;
    }
    // if locale is not in the url but is available in local storage (selected country is enough cause language has default value)
    // then navigate to localParam (main)

    // if there is no locale neither in url nor in store then navigate to root where initial country selection happens
    navigate(selectedCountry ? generateLocaleRoute() : AppRoute.ROOT);
  }, [
    availableCountries,
    selectedCountry,
    location.pathname,
    selectCountryByCodeFromUrl,
    selectLocalizationByCode,
    navigate,
  ]);
};

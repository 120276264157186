import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../stores/store';
import InputSearchWithSidebar from '../input-search-with-sidebar/input-search-with-sidebar.component';
import { SidebarName } from '../../types/sidebar.types';
import { PlacesSearchProps } from '../../stores/placesSearchStore';
import { VALIDATION_RULE_LATIN_WITH_COMMA } from '../../constants/validation';
import { ValidationRuleType } from '../../types/input-validation-types';

interface Props {
  name: SidebarName;
  placeholder: string;
  label?: string;
  selectedCountryCode?: string;
  tabIndex?: number;
  formikValidateField: (
    field: string
  ) => Promise<void> | Promise<string | undefined>;
  setValues?: (props: PlacesSearchProps) => void;
  onSearchFinished?: () => void;
  triggerValidation?: boolean;
  validationRule?: ValidationRuleType;
  isOptional?: boolean;
}
const PlacesFormFieldSearch = ({
  name,
  placeholder,
  label,
  selectedCountryCode,
  tabIndex = 0,
  triggerValidation,
  isOptional,
  validationRule = VALIDATION_RULE_LATIN_WITH_COMMA,
  setValues,
  onSearchFinished,
  formikValidateField,
}: Props) => {
  const [field, meta] = useField(name);

  const { t } = useTranslation();

  const {
    placesSearchStore: { getAutocompletePredictions, getPlaceDetails },
    commonStore: { toastAllert },
  } = useStore();

  const message = {
    primaryMessage: t('alert_parcel_creation_autofil_title'),
    secondaryMessage: t('alert_parcel_creation_autofil_description'),
  };

  return (
    <InputSearchWithSidebar<google.maps.places.AutocompletePrediction>
      name={name}
      inputValue={field.value}
      placeholder={placeholder}
      label={label}
      sidebarTitle={t('search_address')}
      sidebarInputPlaceholder={t('start_typing_your_address')}
      tabIndex={tabIndex}
      displayAllOptionsWithEmptyFilter
      debounceSidebarInputChange
      shouldValidateSearchQuery
      getSearchOptions={async (filter) =>
        getAutocompletePredictions({
          value: filter ?? '',
          countryConstraint: selectedCountryCode?.toLocaleLowerCase(),
        })
      }
      onSearchOptionSelected={(addressPrediction) => {
        getPlaceDetails(addressPrediction, setValues);
        toastAllert(message);
      }}
      onSearchFinished={() => onSearchFinished && onSearchFinished()}
      getKeyForSearchOption={(addressPrediction) => addressPrediction.place_id}
      getDisplayValueForSearchOption={(addressPrediction) =>
        addressPrediction.description
      }
      errorMessage={meta.error}
      onMainInputClick={() => formikValidateField(name)}
      validationRule={validationRule}
      triggerValidation={triggerValidation}
      isOptional={isOptional}
    />
  );
};

export default PlacesFormFieldSearch;

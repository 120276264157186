import { Formik, FormikProps, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { ContactDetailsValues } from '../../../models/contactDetailsValues';
import FormFieldPhone, {
  getCountryCallingCodeWrapper,
} from '../../../common/form-field-phone/form-field-phone.component';
import { ParcelParticipantDetailsContainer } from '../parcel-participant-personal/parcel-participant-personal.styles';
import FormFieldText from '../../../common/form-field-text/form-field-text.component';
import { IContactDetailsFormValues } from '../../../routes/parcel-receiver/parcel-receiver.component';
import { ServicesSubSectionHeader } from '../../services/services-subsection/services-subsection.styles';
import { useStore } from '../../../stores/store';

interface ContactDetailsFormChangeHandlerProps {
  updatePhone: (phone: string) => void;
  updateCountryCode: (countryCode: string) => void;
  updateEmail: (email: string) => void;
  updateAdditionPhone: (phone: string) => void;
  updateAdditionCountryCode: (countryCode: string) => void;
}

const ContactDetailsFormChangeHandler = ({
  updatePhone,
  updateCountryCode,
  updateEmail,
  updateAdditionPhone,
  updateAdditionCountryCode,
}: ContactDetailsFormChangeHandlerProps) => {
  const { values } = useFormikContext<IContactDetailsFormValues>();
  const {
    phone,
    phone_country_code: phoneCountryCode,
    email,
    contact_phone_number: contactPhoneNumber,
    contact_phone_country_code: contactPhoneCountryCode,
  } = values as ContactDetailsValues;

  useEffect(() => {
    updateEmail(email);

    const phoneWithCountryCallingCode =
      getCountryCallingCodeWrapper(phoneCountryCode) + phone;
    updatePhone(phoneWithCountryCallingCode);
    updateCountryCode(phoneCountryCode);

    if (contactPhoneCountryCode) {
      const additionPhoneWithCountryCallingCode =
        getCountryCallingCodeWrapper(contactPhoneCountryCode) +
        contactPhoneNumber;
      updateAdditionPhone(additionPhoneWithCountryCallingCode);
      updateAdditionCountryCode(contactPhoneCountryCode);
    }

    // eslint-disable-next-line
  }, [values]);
  return null;
};

interface Props {
  initialValues: {
    phone: string;
    phone_country_code: string;
    email: string;
    contact_phone_number?: string;
    contact_phone_country_code?: string;
  };
  validationSchema: Yup.Schema<ContactDetailsValues> | any;
  storeParticipantPhone: (phone: string) => void;
  storeParticipantPhoneCountryCode: (countryCode: string) => void;
  storeParticipantEmail: (email: string) => void;
  storeParticipantAdditionalPhone?: (phone: string) => void;
  storeParticipantAdditionalPhoneCountryCode?: (countryCode: string) => void;
  isDropdownDisabled?: boolean;
  isPhoneReadOnly?: boolean;
  isEmailReadOnly?: boolean;
  showAdditionalPhoneNumber?: boolean;
  defaultCountryCode?: string;
  innerRef?: React.Ref<FormikProps<IContactDetailsFormValues>>;
}

const ParcelParticipantContactForm = ({
  initialValues,
  validationSchema,
  storeParticipantPhone,
  storeParticipantPhoneCountryCode,
  storeParticipantEmail,
  isDropdownDisabled = false,
  showAdditionalPhoneNumber = false,
  defaultCountryCode,
  storeParticipantAdditionalPhone = () => {},
  storeParticipantAdditionalPhoneCountryCode = () => {},
  isPhoneReadOnly,
  isEmailReadOnly,
  innerRef,
}: Props) => {
  const { t } = useTranslation();

  const {
    parcelCreationStore: { isNextButtonClicked },
  } = useStore();

  return (
    <ParcelParticipantDetailsContainer>
      <ServicesSubSectionHeader>
        {t('contact_information')}
      </ServicesSubSectionHeader>
      <Formik
        innerRef={innerRef}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={() => {}}>
        {({ handleSubmit }) => (
          <form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}>
            <div className='inputs'>
              <FormFieldPhone
                phoneFieldName='phone'
                codeFieldName='phone_country_code'
                placeholder='(555) 000 - 0000'
                label={t('phone_number')}
                $mb='0'
                isDropdownDisabled={isDropdownDisabled}
                readOnly={isPhoneReadOnly}
                triggerValidation={isNextButtonClicked}
                hideSvgOnDisabled={false}
              />

              {showAdditionalPhoneNumber && (
                <FormFieldPhone
                  phoneFieldName='contact_phone_number'
                  codeFieldName='contact_phone_country_code'
                  placeholder='(555) 000 - 0000'
                  label={t('additional_phone_number')}
                  tooltipText={t('additional_phone_number_tip')}
                  $mb='0'
                  isDropdownDisabled={isDropdownDisabled}
                  defaultCountryCode={defaultCountryCode}
                  triggerValidation={isNextButtonClicked}
                  hideSvgOnDisabled={false}
                />
              )}

              <FormFieldText
                inputMode='email'
                name='email'
                placeholder=''
                label={t('email')}
                $mb='0'
                readOnly={isEmailReadOnly}
                triggerValidation={isNextButtonClicked}
              />

              <ContactDetailsFormChangeHandler
                updatePhone={storeParticipantPhone}
                updateCountryCode={storeParticipantPhoneCountryCode}
                updateEmail={storeParticipantEmail}
                updateAdditionPhone={storeParticipantAdditionalPhone}
                updateAdditionCountryCode={
                  storeParticipantAdditionalPhoneCountryCode
                }
              />
            </div>
          </form>
        )}
      </Formik>
    </ParcelParticipantDetailsContainer>
  );
};

export default observer(ParcelParticipantContactForm);

import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ReactComponent as MeestInfoIcon } from '../../../../../assets/info-tip.svg';
import { ReactComponent as PolonezInfoIcon } from '../../../../../assets/alert-circle.svg';

import { useStore } from '../../../../../stores/store';
import { MarginContainer } from '../../../../../common/base-margin/base-margin.component';
import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from '../../../../../common/tabs/tabs.component';
import DeliveryTabTitle from '../../../../parcel-creation/parcel-delivery-options-tabs/parcel-delivery-tab-title.component';
import ParcelDeliveryOption from '../parcel-delivery-option/parcel-delivery-option.component';
import { getFirstMilesByDeliveryMethodSorted } from '../../../../../utils/parcel-creation/parcel-creation-utils';
import { SelectOption } from '../parcel-delivery-option/parcel-delivery-option.styles';
import Button from '../../../../../common/button/button.component';
import { AppRoute, SearchParam } from '../../../../../constants/route';
import {
  FooterButtons,
  Footer,
  FooterInfoBlock,
  OptionsContainer,
  TabListContainer,
  TabPanelFlexContainer,
  Container,
} from './estimated-delivery-cost.styles';
import {
  generateLocaleRoute,
  generateStartParcelCreationRoute,
} from '../../../../../utils/uri/uri.utils';
import { useStartParcelCreation } from '../../../../../hooks/use-start-parcel-creation.hook';
import { getCheapestDeliveryOptions } from '../../helpers';
import { getLabel } from './helpers';
import CollapsableText from '../../../../../common/collapsable-text/collapsable-text.components';
import { useIsMobile } from '../../../../../hooks/use-is-mobile.hook';
import { DeliveryMethod } from '../../../../../models/parcelDeliveryMiles';
import { isMeest } from '../../../../../utils/client/client.utils';
import { client } from '../../../../../config';

interface Props {
  isFrameMode?: boolean;
}
const getIcon = () => {
  const icons = {
    meest: <MeestInfoIcon />,
    polonez: <PolonezInfoIcon />,
  };
  return icons[client];
};
const isMeestClient = isMeest(client);

const EstimatedDeliveryCost = ({ isFrameMode }: Props) => {
  const {
    widgetCalculatorStore: {
      countryDeparture,
      countryDestination,
      parcelDimensions,
      firstMiles,
      lastMiles,
      middleMileMethodsWithMaxDuration,
      selectedMiddleMileMethod,
      staticDeliveryTimeString,
      setMiddleMileMethod,
    },
    parcelCreationStore: { shippingType },
    navStore: { setServicesSidebarVisibility },
    userStore: { user, measures },
  } = useStore();
  const handleStartParcelCreation = useStartParcelCreation();

  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const isMobile = useIsMobile();

  const middleMileMethod = selectedMiddleMileMethod ?? 'air';

  const lowestFirstMilePrices = getFirstMilesByDeliveryMethodSorted(
    firstMiles ?? [],
    middleMileMethod
  );

  const cheapestDeliveryOptions = getCheapestDeliveryOptions(
    lowestFirstMilePrices,
    lastMiles,
    middleMileMethod
  );

  const handleCreateOnline = () => {
    setServicesSidebarVisibility(false);
    const urlState = { state: { key: new Date().getTime() } };
    if (shippingType === 'international') {
      const data = encodeURIComponent(
        JSON.stringify({
          // Clear zip + city info because on 1st step user should provide its own data
          countryDeparture: {
            countryCode: countryDeparture?.countryCode,
          },
          countryDestination: {
            countryCode: countryDestination?.countryCode,
          },
          measures,
          parcelDimensions,
        })
      );
      const urlCreateParcelWithData = `${generateStartParcelCreationRoute()}?${SearchParam.DATA}=${data}`;
      let urlDestination = urlCreateParcelWithData;

      if (!user) {
        urlDestination = `${generateLocaleRoute(AppRoute.AUTH)}?${SearchParam.REDIRECT}=${encodeURIComponent(urlCreateParcelWithData)}`;
      }

      if (isFrameMode) {
        const origin = new URL(window.location.href).searchParams.get('origin');
        if (!origin) {
          console.warn("Missing search parameter 'origin' for iFrame mode.");
          return;
        }
        urlDestination = `${urlDestination}&${SearchParam.REQUEST_SOURCE}=iframe`;
        window.parent.postMessage({ url: urlDestination }, origin);
        return;
      }

      if (user) {
        handleStartParcelCreation(urlDestination);
      } else {
        navigate(urlDestination, urlState);
      }
    } else {
      navigate(generateLocaleRoute(AppRoute.COMING_SOON), urlState);
    }
  };

  const footerTextVisible = !isMobile
    ? t('delivery_cost_info_1').slice(0, 182)
    : t('delivery_cost_info_1').slice(0, 84);

  const footerTextHidden = !isMobile
    ? t('delivery_cost_info_1').slice(182)
    : t('delivery_cost_info_1').slice(84);

  const handleSelectMiddleMile = (index: number) => {
    setMiddleMileMethod(middleMileMethodsWithMaxDuration[index].method);
    setSelectedOptionIndex(-1);
  };

  const getMethodIndex = (method: DeliveryMethod | null) => {
    const index =
      method !== null
        ? middleMileMethodsWithMaxDuration.findIndex(
            (mile) => mile.method === method
          )
        : 0;
    return index;
  };

  const selectedMiddleMileIndex = getMethodIndex(selectedMiddleMileMethod);
  const isSingleMiddleMile = middleMileMethodsWithMaxDuration.length === 1;
  const getTheme = (mileMethod: DeliveryMethod) => {
    if (mileMethod === 'expedited') {
      return 'red';
    }
    if (isMeestClient) {
      return 'blue';
    }
    return 'polonez-calculator';
  };
  return (
    <Container>
      <Tabs
        size={isMeestClient || isMobile ? 'small' : 'large'}
        gap={isMeestClient && isMobile ? '2px' : '5px'}
        justifyContent={isMeestClient && isMobile ? 'space-between' : 'start'}
        onChange={(index) => handleSelectMiddleMile(index)}
        selectedIndex={selectedMiddleMileIndex}
        theme={isMeestClient ? 'white' : 'gray'}>
        {!isSingleMiddleMile && (
          <TabListContainer
            $isOneTab={isSingleMiddleMile}
            $minWidth={isMeestClient && isMobile ? '51rem' : 'fit-content'}>
            <TabList>
              {middleMileMethodsWithMaxDuration.map((mile, i) => (
                <Tab
                  fullWidth={isMeestClient || !isMobile}
                  tabIndex={i.toString()}
                  theme={getTheme(mile.method)}
                  key={mile.method}>
                  <DeliveryTabTitle
                    isSelected={selectedMiddleMileMethod === mile.method}
                    durationDaysString={
                      staticDeliveryTimeString
                        ? staticDeliveryTimeString[mile.method].replace('~', '')
                        : ''
                    }
                    method={mile.method}
                    isCalculator
                  />
                </Tab>
              ))}
            </TabList>
          </TabListContainer>
        )}
        <MarginContainer
          $mt='24px'
          $mb='24px'>
          <TabPanelFlexContainer>
            {middleMileMethodsWithMaxDuration.map((middleMile) => (
              <TabPanel key={middleMile.method}>
                <OptionsContainer
                  $isOneOption={cheapestDeliveryOptions.length === 1}>
                  {cheapestDeliveryOptions.map((deliveryOption, index) => (
                    <SelectOption
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      $isSelected={selectedOptionIndex === index}
                      onClick={() => setSelectedOptionIndex(index)}>
                      {index === 0 && getLabel(middleMile.method, t)}
                      <ParcelDeliveryOption deliveryOption={deliveryOption} />
                    </SelectOption>
                  ))}
                </OptionsContainer>
              </TabPanel>
            ))}
          </TabPanelFlexContainer>
        </MarginContainer>
      </Tabs>
      <Footer>
        <FooterInfoBlock>
          {getIcon()}
          <CollapsableText
            visibleText={footerTextVisible}
            hiddenText={footerTextHidden}
          />
        </FooterInfoBlock>
        <FooterButtons>
          <Button
            fullWidth={false}
            size='large'
            appearance={isMeest(client) ? 'brand' : 'polonez-calculator'}
            onClick={handleCreateOnline}>
            {t('create_online')}
          </Button>
        </FooterButtons>
      </Footer>
    </Container>
  );
};

export default observer(EstimatedDeliveryCost);

import { useTranslation } from 'react-i18next';
import { FlexContainer } from '../../../common/flex-container/flex-container.styles';
import Separator from '../../../common/separator/separator.component';
import { TextLabel } from '../../../common/typography/typography.styles';
import { TextTotalValue } from './parcel-items-management.styles';
import { getFormattedPrice } from '../../../utils/price/get-formatted-price';

interface Props {
  totalValue: number;
  currencySymbol: string;
}

const TotalValue = ({ totalValue, currencySymbol }: Props) => {
  const { t } = useTranslation();
  return (
    <div>
      <Separator
        mt='0'
        mb='1.6rem'
      />
      <FlexContainer
        $justify='flex-start'
        $mobAlign='start'
        $gap='6.4rem'
        $mobGap='4px'
        $mobDirection='column'>
        <TextLabel>{t('total_declared_value')}</TextLabel>
        <TextTotalValue>{`${getFormattedPrice(totalValue, currencySymbol)}`}</TextTotalValue>
      </FlexContainer>
    </div>
  );
};

export default TotalValue;

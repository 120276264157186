import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';
import { useStore } from '../../../stores/store';
import { ServicesSubSectionContainer } from '../../services/services-subsection/services-subsection.styles';
import { HeaderSecondary } from '../../../common/typography/typography.styles';
import PromocodeSummaryForm from './promocode-summary-form.component';
import PromocodeItem from './promocode-item.component';
import { createValidationRule } from '../../../utils/forms/forms.utils';
import { getFormattedPrice } from '../../../utils/price/get-formatted-price';
import { VALIDATION_RULE_IS_NOT_CYRILLIC } from '../../../constants/validation';

interface FormValues {
  promocode_add: string;
}

const validationSchema = Yup.object({
  promocode_add: createValidationRule(VALIDATION_RULE_IS_NOT_CYRILLIC)
    .trim()
    .notRequired(),
});

const PromocodeSummary = observer(() => {
  const { t } = useTranslation();
  const {
    userStore: { getCurrencySymbol },
    parcelCreationStore: {
      applyPromoCode,
      handleRemovePromoCode,
      shipment,
      promocode,
    },
  } = useStore();

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, setErrors }: FormikHelpers<FormValues>
  ) => {
    try {
      await applyPromoCode(values.promocode_add);
    } catch (error: any) {
      setErrors({ promocode_add: t(error.message) });
    } finally {
      setSubmitting(false);
    }
  };

  const showPromoItem = !!promocode;

  return (
    <ServicesSubSectionContainer>
      <HeaderSecondary>{t('promocode_promocode')}</HeaderSecondary>

      {!showPromoItem ? (
        <Formik
          initialValues={{ promocode_add: '' }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}>
          <PromocodeSummaryForm />
        </Formik>
      ) : (
        <PromocodeItem
          code={promocode.code}
          savings={`${t('you_save')} ${getFormattedPrice(
            Math.abs(promocode.discount || 0),
            getCurrencySymbol(shipment?.converted_price?.currency)
          )}`}
          onRemove={() => handleRemovePromoCode(promocode.code)}
        />
      )}
    </ServicesSubSectionContainer>
  );
});

export default PromocodeSummary;

import { useEffect, useState } from 'react';

interface Props {
  onLocationSuccess: (position: GeolocationPosition) => void;
  onLocationError: (error: GeolocationPositionError) => void;
}

const useGeolocation = ({ onLocationSuccess, onLocationError }: Props) => {
  const [isLocationLoading, setIsLocationLoading] = useState(false);
  const [geoPermissionState, setGeoPermissionState] =
    useState<PermissionState>('prompt');

  const handleLocationSuccess: PositionCallback = (
    position: GeolocationPosition
  ) => {
    onLocationSuccess(position);
    setIsLocationLoading(false);
  };

  const handleLocationError: PositionErrorCallback = (
    error: GeolocationPositionError
  ) => {
    onLocationError(error);
    setIsLocationLoading(false);
  };

  const tryGetLocation = () => {
    setIsLocationLoading(true);
    navigator.geolocation.getCurrentPosition(
      handleLocationSuccess,
      handleLocationError
    );
  };

  useEffect(() => {
    const requestGeoPermission = async () => {
      const permission = await navigator.permissions.query({
        name: 'geolocation',
      });
      setGeoPermissionState(permission.state);
    };
    requestGeoPermission();
  });

  return {
    isGeolocationAvailable: !!navigator.geolocation,
    isLocationLoading,
    tryGetLocation,
    geoPermissionState,
  };
};

export default useGeolocation;

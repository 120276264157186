import styled from 'styled-components';
import { SectionSubtitle, SectionTitle } from '../common/typography.styles';
import { Section } from '../common/section.styles';

export const CountryCard = styled.button<{ $isSelected: boolean }>`
  font-family: inherit;
  display: flex;
  align-items: center;
  padding: 1.6rem 2rem;
  gap: 1.6rem;
  border-radius: 1.6rem;
  border: 0.1rem solid rgb(68, 131, 182);
  background: ${({ $isSelected, theme }) =>
    $isSelected ? theme.colors.baseColors.blue80 : '#003d6e'};
  color: ${({ theme }) => theme.colors.namedColors.white};
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.4rem;
  letter-spacing: 0.05rem;
  cursor: pointer;
  transition: background 0.3s;

  max-width: 100%;
  max-height: 5.6rem;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background: ${({ theme }) => theme.colors.baseColors.blue80};
  }

  img {
    border-radius: 0.5rem;
  }

  @media (max-width: 950px) {
    padding: 1.6rem 1.2rem;
    gap: 0.8rem;
    text-align: left;
    border: none;
  }
`;

export const TransitTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const TransitTimeItem = styled.div`
  display: flex;
  gap: 0.8rem;

  svg {
    color: #e5f4ff;

    width: 2rem;
    height: 2rem;

    @media (max-width: 650px) {
      min-width: 1.6rem;
      height: 1.6rem;
    }
  }
`;

export const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: 60% 0;
  mix-blend-mode: lighten;
  z-index: -1;

  @media (max-width: 585px) {
    object-position: 0 100%;
  }
`;

export const CountriesSection = styled(Section)`
  position: relative;
  max-height: 86rem;
  min-height: 80rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.baseColors.blue150};
  isolation: isolate;

  @media (max-width: 950px) {
    padding: 4rem 0;
    max-height: 100%;
  }
`;

export const CountriesHeader = styled(SectionTitle)`
  color: ${({ theme }) => theme.colors.namedColors.white};
  margin: 0.8rem 0 3.2rem;

  @media (max-width: 585px) {
    margin: 2.4rem 0;
  }
`;

export const CountriesSubHeader = styled(SectionSubtitle)`
  color: ${({ theme }) => theme.colors.namedColors.white};
  text-transform: uppercase;

  &:nth-child(2n) {
    margin-bottom: 2.4rem;

    @media (max-width: 585px) {
      font-size: 16px;
      margin-bottom: 0;
    }
  }
`;

export const CountriesListContainer = styled.div`
  display: flex;
  gap: 4.5rem;
  justify-content: space-between;
  width: 100%;
  margin-top: 4rem;

  & > div:first-child {
    flex: 1;
  }

  & > div:last-child {
    flex: 0 1 auto;
    max-width: 60rem;
    height: 100%;
    width: 100%;
  }

  @media (max-width: 950px) {
    flex-direction: column;

    margin-top: 2.5rem;

    & > div:first-child {
      flex: 0 1 auto;
      width: 100%;
    }

    & > div:last-child {
      max-width: 100%;
      width: 100%;
    }
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.6rem;
  justify-content: flex-start;
  max-height: 56rem;
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #c1dbf033;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cce8ff;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #cce8ff;
  }

  @media (max-width: 650px) {
    -ms-overflow-style: none;
  }

  @media (max-width: 950px) {
    height: 19rem;
  }
`;

export const DeliveryInfoWrapper = styled.div`
  padding: 3.2rem;
  border-radius: 2rem;
  background: rgba(0, 61, 110, 0.9);
  color: ${({ theme }) => theme.colors.namedColors.white};
`;

export const DeliveryInfoWrapperEmpty = styled(DeliveryInfoWrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40rem;
`;

export const DeliveryInfoTitle = styled.h3`
  margin-bottom: 2.4rem;
  font-size: 2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.namedColors.white};

  img {
    position: relative;
    border-radius: 0.5rem;
    top: -1px;
  }
`;

export const DeliveryInfoDescription = styled.h3`
  svg {
    color: #0061af;

    margin-bottom: 2.4rem;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.2rem;
  letter-spacing: 0.05rem;
  color: ${({ theme }) => theme.colors.namedColors.white};

  @media (max-width: 950px) {
    font-size: 1.4rem;
  }
`;

export const DeliveryInfoList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

export const DeliveryInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
`;

export const DeliveryInfoLabel = styled.div`
  flex: 1;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: 0.05rem;
  text-align: left;
`;

export const DeliveryInfoValue = styled.div`
  flex: 1;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.2rem;
  letter-spacing: 0.05rem;
`;

export const DeliveryInfoMore = styled.a`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.namedColors.white};
  line-height: 1.6rem;
  letter-spacing: 0.05rem;
  text-decoration-line: underline;
  cursor: pointer;
  text-underline-offset: 0.2em;
  max-width: max-content;
`;

export const SearchPanelWrapper = styled.div`
  display: flex;
  gap: 7.5rem;

  @media (max-width: 950px) {
    gap: 2.4rem;
    flex-direction: column;
  }
`;

export const WrapperSearch = styled.div`
  max-width: 40rem;
  width: 100%;

  @media (max-width: 950px) {
    max-width: 100%;
  }

  div {
    div {
      input {
        border-radius: 1rem;
        border: 0.1rem solid #e5f4ff;
        background: rgba(255, 255, 255, 0.1);
        color: white;

        &::placeholder {
          color: #cce8ff;
        }
      }

      span {
        svg path {
          fill: #cce8ff;
        }
      }
    }
  }
`;

export const WrapperFilter = styled.div`
  display: flex;
  gap: 1.2rem;
  flex-wrap: wrap;

  @media (max-width: 950px) {
    overflow-x: scroll;
    flex-wrap: nowrap;
    width: calc(100% + 4rem);
    margin-right: -4rem;
    padding-right: 4rem;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const FilterChips = styled.button<{ $isSelected: boolean }>`
  padding: 0.8rem 2.4rem;
  cursor: pointer;
  text-align: center;
  color: ${({ $isSelected }) => ($isSelected ? '#005fad' : '#fff')};
  font-family: inherit;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.2rem;
  letter-spacing: 0.05rem;

  border-radius: 1rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.namedColors.white};
  background: ${({ $isSelected }) => ($isSelected ? '#fff' : '#005fad')};

  transition: background 0.3s;

  &:hover {
    color: ${({ theme }) => theme.colors.namedColors.blue100};
    background: ${({ $isSelected, theme }) =>
      $isSelected
        ? theme.colors.namedColors.white
        : theme.colors.namedColors.blue150};
  }

  @media (max-width: 950px) {
    flex-grow: unset;
    white-space: nowrap;
    min-width: auto;
  }
`;

import { AdvancedMarker } from '@vis.gl/react-google-maps';
import { useEffect, useRef } from 'react';
import { ReactComponent as CurrentLocationMarkerIcon } from '../../assets/user-location.svg';

interface Props {
  map: google.maps.Map | null;
  location: google.maps.LatLngLiteral;
}

const UserCircleMarker = ({ map, location }: Props) => {
  const circleRef = useRef<google.maps.Circle | null>(null);

  // We need to set Circle imperetively cause @vis.gl
  // doesn't directly use <Circle /> from @react-google-maps/api
  useEffect(() => {
    if (!map) return;

    if (circleRef.current) {
      circleRef.current.setMap(null);
    }

    const circle = new google.maps.Circle({
      center: location,
      radius: 75,
      fillColor: '#4285F4',
      fillOpacity: 0.2,
      strokeColor: '#4285F4',
      strokeOpacity: 0.5,
      strokeWeight: 1,
    });

    circle.setMap(map);

    circleRef.current = circle;
  }, [map, location]);

  return (
    <AdvancedMarker
      zIndex={950}
      position={location}>
      <CurrentLocationMarkerIcon
        style={{ position: 'absolute', transform: 'translate(-50%, -50%)' }}
      />
    </AdvancedMarker>
  );
};

export default UserCircleMarker;

import { Trans, useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import ModalDialog from '../modal-dialog/modal-dialog.component';
import { useStore } from '../../stores/store';
import { Modals } from '../../constants/modals';
import { ModalTextBody } from '../modal-dialog/modal-dialog.styles';
import { HeaderSecondary } from '../typography/typography.styles';

const LaunchNotificationDialog = () => {
  const { t } = useTranslation();

  const {
    modalStore: { isModalVisible, close },
    localizationsStore: { selectedCountry },
  } = useStore();

  const supportLink = selectedCountry?.support
    .at(0)
    ?.find((link) => link.type === 'email')
    ?.url?.toLocaleLowerCase();

  if (!supportLink) return;

  return (
    <ModalDialog
      isDanger={false}
      approveBtnContent={t('continue')}
      isOpen={isModalVisible(Modals.LAUNCH_NOTIFICATION)}
      useApproveLogicForDialogClosing
      onApprove={() => close(Modals.LAUNCH_NOTIFICATION)}>
      <HeaderSecondary>
        {t('notification_launch_maintanence_title')}
      </HeaderSecondary>
      <ModalTextBody>
        <Trans
          i18nKey='notification_launch_maintanence'
          components={{
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            1: (
              <>
                <br />
                <br />
              </>
            ),
          }}
          values={{
            supportLink,
          }}
        />
      </ModalTextBody>
    </ModalDialog>
  );
};

export default observer(LaunchNotificationDialog);

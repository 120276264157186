import { ReactComponent as MeestFb } from './meest/facebook-footer.svg';
import { ReactComponent as MeestInstagram } from './meest/instagram-footer.svg';
import { ReactComponent as MeestLinkedIn } from './meest/linkedin-footer.svg';
import { ReactComponent as MeestTwitter } from './meest/twitter-footer.svg';
import { ReactComponent as PolonezFb } from './polonez/facebook-footer.svg';
import { ReactComponent as PolonezLinkedIn } from './polonez/linkedin-footer.svg';
import { ReactComponent as PolonezInstagram } from './polonez/instagram-footer.svg';
import { ReactComponent as PolonezTiktok } from './polonez/tiktok-footer.svg';
import { ReactComponent as PolonezYoutube } from './polonez/youtube-footer.svg';
import { isMeest } from '../../utils/client/client.utils';
import { client } from '../../config';

export type SocialPlatform =
  | 'facebook'
  | 'twitter'
  | 'linkedin'
  | 'instagram'
  | 'tiktok'
  | 'youtube';

type IconMap = Record<SocialPlatform, React.FC<React.SVGProps<SVGSVGElement>>>;

export const meestIcons: Partial<IconMap> = {
  facebook: MeestFb,
  instagram: MeestInstagram,
  linkedin: MeestLinkedIn,
  twitter: MeestTwitter,
};
export const polonezIcons: Partial<IconMap> = {
  facebook: PolonezFb,
  instagram: PolonezInstagram,
  linkedin: PolonezLinkedIn,
  tiktok: PolonezTiktok,
  youtube: PolonezYoutube,
};

export const getClientIcons = (): Partial<IconMap> =>
  isMeest(client) ? meestIcons : polonezIcons;
